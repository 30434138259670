import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    NumberInput, Paragraph, Select, TextInput, Title,
} from 'ui-library';
import { useLocale } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptCurrencies } from 'adaptors/adaptCurrencies';
import useFormatting from 'locale/useFormatting';
import { useSetupPortfolioForm } from './hooks/useSetupPortfolioForm';
import { adaptProducts } from '../../../ProductSelection/adaptors/adaptProducts';
import './NoGoalForm.css';

function NoGoalForm(props) {
    const {
        active,
        dataCurrencies,
        productId,
        isSubmitted,
        defaultData,
        onChange: onChangeProp,
        onSubmit,
    } = props;
    const { t } = useTranslation();
    const { numberSeparators } = useLocale();
    const { getFormattedNumber } = useFormatting();
    // OnBoarding Domain
    const { productOffer } = useOnBoardingSelector(onBoardingDataSelector);

    // Data
    const listCurrency = useMemo(() => {
        const products = adaptProducts(dataCurrencies, {
            productOffer,
        });
        const currencies = (products || []).find((i) => i.id === productId)?.currencies;

        return adaptCurrencies(currencies || []);
    }, [dataCurrencies, productOffer, productId]);
    const defaultCurrency = useMemo(() => listCurrency.find((item) => item.label === 'USD'), [listCurrency]);

    const minimumInvestmentValue = useMemo(() => {
        const products = adaptProducts(dataCurrencies, { t, getFormattedNumber, productOffer });

        return (products || []).find(
            (i) => i.id === productId,
        )?.minimumInvestmentValue;
    },
    [dataCurrencies, getFormattedNumber, productOffer]);

    // Helpers hooks
    const {
        values, control, formErrors, onChange, handleSubmit,
    } = useSetupPortfolioForm({ defaultData, minimumInvestmentValue });
    const { selectedCurrency } = values;

    // Effects
    useEffect(() => {
        if (!selectedCurrency && !defaultData?.selectedCurrency) onChange('selectedCurrency')(defaultCurrency);
    }, [defaultCurrency, onChange, defaultData.selectedCurrency, selectedCurrency]);
    useEffect(() => {
        if (active && isSubmitted) handleSubmit(onSubmit)();
    }, [active, isSubmitted, handleSubmit, onSubmit]);
    useEffect(() => {
        if (active) onChangeProp({ goalState: values });
    }, [active, onChangeProp, values]);

    // Renderers
    const selectAfter = useMemo(() => {
        if (listCurrency.length > 0) {
            return (
                <Controller
                    name="selectedCurrency"
                    control={control}
                    defaultValue={selectedCurrency?.value}
                    render={({ value }) => (
                        <Select
                            options={listCurrency}
                            value={value}
                            error={formErrors.selectedCurrency}
                            onChange={(val) => onChange('selectedCurrency')(
                                listCurrency.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrency, control, selectedCurrency, formErrors.selectedCurrency, onChange]);

    if (!active) return null;

    return (
        <form className="no-goal-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="group-title">
                <Title type={3}>{t('onBoarding.goal.portfolioDetails')}</Title>
                <Paragraph type="secondary">{t('onBoarding.goal.noGoalDetailsText')}</Paragraph>
            </div>
            <Controller
                name="goalName"
                control={control}
                render={({ value }) => (
                    <TextInput
                        name="goalName"
                        label={t('onBoarding.goal.portfolioName')}
                        onChange={(e) => onChange('goalName')(e.currentTarget.value)}
                        labelInfobox
                        helpText={t('onBoarding.goal.portfolioNameHelpText')}
                        placeholder={t('onBoarding.goal.portfolioNamePlaceholder')}
                        error={formErrors.goalName}
                        value={value}
                    />
                )}
            />
            <Controller
                name="initialInvestment"
                control={control}
                render={({ value }) => (
                    <NumberInput
                        name="initialInvestment"
                        label={t('onBoarding.goal.initialInvestment')}
                        addonAfter={selectAfter}
                        value={value}
                        onChange={onChange('initialInvestment')}
                        labelInfobox
                        helpText={t('onBoarding.goal.initialInvestmentText')}
                        error={formErrors.initialInvestment}
                        withSelectBox
                        {...numberSeparators}
                    />
                )}
            />
        </form>
    );
}

NoGoalForm.propTypes = {
    active: PropTypes.bool,
    dataCurrencies: PropTypes.shape({
        ProductOffers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.number,
            Products: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                Name: PropTypes.string,
                Image: PropTypes.string,
                Description: PropTypes.string,
            })),
            Currencies: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                CurrencyCode: PropTypes.string,
            })),
        })),
    }),
    productId: PropTypes.number,
    isSubmitted: PropTypes.bool,
    defaultData: PropTypes.shape({
        goalName: PropTypes.string,
        targetValue: PropTypes.string,
        targetDate: PropTypes.string,
        initialInvestment: PropTypes.string,
        recurringPayment: PropTypes.string,
        selectedCurrency: PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
        recurringPaymentFrequency: PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

NoGoalForm.defaultProps = {
    active: true,
    dataCurrencies: null,
    productId: null,
    isSubmitted: false,
    defaultData: {},
    onChange: () => {},
    onSubmit: () => {},
};

export default NoGoalForm;
