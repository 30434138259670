import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { sorterByDate, SORT_DIRECTION_DESC } from 'utils/sorting';
import { adaptNews } from '../adapters/adaptNews';

export const useAnnouncements = (newsItem, params) => {
    const { assetNews, currentPage } = (params || {});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n } = useTranslation();

    const getAnnouncements = useCallback(async ({ pageSize, page }) => {
        try {
            const rowIndex = page ? ((page - 1) * pageSize) + 1 : 0;
            const { data: rawAnnouncements } = (assetNews === undefined || assetNews > 0)
                && await ServiceManager.commonContent('getNews', [2, i18n.language, pageSize ? { pageSize, startRowIndex: rowIndex || 0 } : {}]);

            try {
                const adapted = adaptNews(rawAnnouncements);
                const sorted = sorterByDate('dateSort')(adapted, { direction: SORT_DIRECTION_DESC });

                setNewsList(sorted);
                setLoading(false);

                return sorted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);

            return null;
        }
    }, [assetNews, i18n.language]);

    useEffect(() => {
        if (newsItem === undefined) {
            setLoading(true);
            setError(null);

            getAnnouncements({ pageSize: assetNews, page: currentPage });
        } else {
            setLoading(false);
            setNewsList([newsItem]);
        }
    }, [newsItem, i18n.language, assetNews, currentPage]);

    return {
        data: newsList, isLoading, error, getAnnouncements,
    };
};
