import { createSelector } from 'reselect/lib/index';
import { get } from 'lodash/fp';

export const sessionSelector = (state) => state.session;

export const isCurrentSessionCheckedSelector = createSelector(
    sessionSelector,
    (value) => get('isCurrentSessionChecked', value) === true,
);

export const isSessionValidSelector = createSelector(
    sessionSelector,
    (value) => get('isSessionValid', value) === true,
);
