import { find } from 'lodash/fp';
import {
    getfromDataObject, validateData, validateNumber,
    NA,
    formatValueByType, TYPE_CURRENCY, TYPE_RATIO, TYPE_PERCENT,
} from 'utils/formatting';
import { BondId, StructuredProductId } from 'constants/instrument';

const MAXIMUM_FRACTION_DIGITS = 2;
const MINIMUM_FRACTION_DIGITS = 2;

export const adaptPortfolioDetails = ({
    data, positionId, getFormattedNumber, securityTypeId,
}) => {
    const positions = data?.Positions || [];
    const item = positions.length ? find(['Security.Id', Number(positionId)], positions) : '';

    const currency = validateData(item
        ? item.Security.Currency.CurrencyCode
        : data?.Currency?.CurrencyCode);
    const costPrice = (securityTypeId === BondId || securityTypeId === StructuredProductId)
        ? validateNumber(item?.BuyPrice) * 100
        : validateNumber(item?.BuyPrice);
    const priceTop = (securityTypeId === BondId || securityTypeId === StructuredProductId)
        ? validateNumber(item?.ValuationPrice) * 100
        : validateNumber(item?.ValuationPrice);
    const priceBottom = item
        ? validateNumber(item.ValuationPrice * item.FxRate)
        : 0;
    const positionCurrency = validateData(data?.Currency?.CurrencyCode);
    const costPriceBottom = item
        ? validateNumber(item.BuyPrice * item.FxRate) : 0;
    const totalValueBottom = item ? validateNumber(item.InvestmentValuePortfolioCurrency) : 0;

    const additionalValue = (priceValue) => {
        if (
            positionCurrency === currency
            || securityTypeId === BondId
            || securityTypeId === StructuredProductId
        ) return null;

        return `${formatValueByType(priceValue, TYPE_RATIO, false, getFormattedNumber)} ${positionCurrency}*`;
    };

    return {
        portfolioName: getfromDataObject(data, 'Name'),
        productId: getfromDataObject(data?.Product, 'Id'),
        quantity: item ? (item.Quantity || 0) : 0,
        units: item
            ? getFormattedNumber(
                validateNumber(item.Quantity),
                {
                    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
                    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
                },
            )
            : 0,
        currency: validateData(item
            ? item.Security.Currency.CurrencyCode
            : data?.Currency?.CurrencyCode),
        currencyId: validateData(item ? item.Security.Currency.Id : data?.Currency?.Id),
        positionCurrency,
        tradingPrice: item ? validateNumber(item.BuyPrice) : 0,
        FxRate: item ? validateNumber(item.FxRate) : 0,
        factor: item && item.Factor ? validateNumber(item.Factor) : 1,
        portfolioAllocation: item ? validateNumber(item.Allocation) * 100 : 0,
        costPrice: item ? costPrice : 0,
        costPriceBottom,
        additionalCostPrice: additionalValue(costPriceBottom, positionCurrency),
        priceTop: item ? priceTop : 0,
        priceBottom,
        additionalPrice: additionalValue(priceBottom, positionCurrency),
        priceType: (securityTypeId === BondId || securityTypeId === StructuredProductId)
            ? TYPE_PERCENT
            : TYPE_RATIO,
        priceCurrency: (securityTypeId === BondId || securityTypeId === StructuredProductId)
            ? null
            : currency,
        isin: item ? getfromDataObject(item.Security, 'Isin') : NA,
        absolutePerformance: item ? validateNumber(item.MonetaryPerformance) : 0,
        totalValueTop: item ? validateNumber(item.InvestmentValueSecurityCurrency) : 0,
        totalValueBottom: item ? validateNumber(item.InvestmentValuePortfolioCurrency) : 0,
        additionalTotalValue: additionalValue(totalValueBottom, positionCurrency),
        performanceSi: item ? validateNumber(item.Performance) * 100 : 0,
        portfolioStatusId: data?.PortfolioStatusId || NA,
    };
};
