import { useCallback, useReducer } from 'react';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { NA } from 'utils/formatting';

const initialState = {
    dataRaw: {},
    error: null,
    isLoading: false,
    errorModify: null,
    isModifying: false,
    risk: {},
    errorRisk: null,
    isLoadingRisk: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setErrorModify':
            return { ...state, errorModify: action.payload };
        case 'setIsModifying':
            return { ...state, isModifying: action.payload };
        case 'setRisk':
            return { ...state, risk: action.payload };
        case 'setErrorRisk':
            return { ...state, errorRisk: action.payload };
        case 'setIsLoadingRisk':
            return { ...state, isLoadingRisk: action.payload };
        default:
            return state;
    }
};

export const usePortfolioModify = (options = {}, tradingCard = {}) => {
    const {
        clientId,
        portfolioId,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);
    // External hook
    const { getTradingCard } = tradingCard;

    // Callbacks
    const modifyPosition = useCallback(async ({ Trades } = {}) => {
        dispatch({ type: 'setErrorModify', payload: null });
        dispatch({ type: 'setIsModifying', payload: true });

        try {
            const response = await SM.portfolioManagement('patchTradingCard', [clientId, portfolioId, { Trades }]);

            try {
                dispatch({ type: 'setIsModifying', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorModify', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsModifying', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, portfolioId]);
    const getModifiedPositions = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await getTradingCard();

            try {
                dispatch({ type: 'setDataRaw', payload: response });
                dispatch({ type: 'setIsLoading', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [getTradingCard]);
    const getRisk = useCallback(async ({ currencyId, positions, filters } = {}) => {
        dispatch({ type: 'setErrorRisk', payload: null });
        dispatch({ type: 'setIsLoadingRisk', payload: true });

        if (!positions?.length || !currencyId || currencyId === NA) return null;

        try {
            const params = {
                InstrumentSet: {
                    CurrencyId: currencyId,
                    Allocations: positions,
                    AllocationType: 'Percentage',
                },
                ...filters,
            };
            const response = await SM.performanceService('calculateKPIPerformance', [params]);

            try {
                dispatch({ type: 'setRisk', payload: response?.data });
                dispatch({ type: 'setIsLoadingRisk', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorRisk', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingRisk', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    return {
        errorModify: state.errorModify,
        isModifying: state.isModifying,
        modifyPosition,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getModifiedPositions,
        risk: state.risk,
        errorRisk: state.errorRisk,
        isLoadingRisk: state.isLoadingRisk,
        getRisk,
    };
};
