import { Button } from 'ui-library';
import React from 'react';
import PropTypes from 'prop-types';
import './ButtonsBlockRow.css';

const ButtonsBlockRow = (props) => {
    const {
        leftButton, additionalButton, primaryButton, additionalComponent, children,
    } = props;

    return (
        <div className="buttons-block-row">
            {children && (
                <div className="buttons-block__content">{children}</div>
            )}
            <div className="buttons-block__col">
                {leftButton && (
                    <Button
                        type={leftButton?.type || 'secondary'}
                        size="small"
                        loading={leftButton?.loading}
                        disabled={leftButton?.disabled}
                        onClick={leftButton?.onClick}
                    >
                        {leftButton.text}
                    </Button>
                )}
            </div>
            <div className="buttons-block__col">
                {additionalButton && (
                    <Button
                        type={additionalButton?.type || 'secondary'}
                        size="small"
                        loading={additionalButton?.loading}
                        disabled={additionalButton?.disabled}
                        onClick={additionalButton?.onClick}
                    >
                        {additionalButton.text}
                    </Button>
                )}
                {additionalComponent}
                {primaryButton && (
                    <Button
                        type={primaryButton?.type || 'primary'}
                        size="small"
                        loading={primaryButton?.loading}
                        disabled={primaryButton?.disabled}
                        onClick={primaryButton?.onClick}
                    >
                        {primaryButton.text}
                    </Button>
                )}
            </div>
        </div>
    );
};

ButtonsBlockRow.propTypes = {
    leftButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    additionalButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    primaryButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    additionalComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ButtonsBlockRow.defaultProps = {
    leftButton: null,
    additionalButton: null,
    primaryButton: null,
    additionalComponent: null,
    children: null,
};

export default ButtonsBlockRow;
