export const clientIdSelector = (state) => (state.clientId);

export const onBoardingDataSelector = (state) => (state.onBoardingData);

export const productOffersSelector = (state) => (state.productOffers);

export const productsSelector = (state) => (state.products);

export const knowledgeExperienceSelector = (state) => (state.knowledgeExperience);

export const goalCreationSelector = (state) => (state.goalCreation);

export const goalOptimizeSelector = (state) => (state.goalOptimize);

export const goalSummarySelector = (state) => (state.goalSummary);

export const productFeeSelector = (state) => (state.productFee);
