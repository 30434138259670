import PropTypes from 'prop-types';
import { isToday, isYesterday, isTomorrow } from 'utils';
import { formatTime, formatDate } from 'utils/datetime';

const CellAdaptedDate = (t) => (date) => {
    if (isToday(date)) return formatTime(date);
    if (isTomorrow(date)) return t('messages.tomorrow');
    if (isYesterday(date)) return t('messages.yesterday');

    return formatDate(date);
};

CellAdaptedDate.propTypes = {
    isReplied: PropTypes.bool,
    date: PropTypes.string,
};

CellAdaptedDate.defaultProps = {
    isReplied: true,
    date: null,
};

export default CellAdaptedDate;
