import { BAR, LIST, PIE } from 'constants/constants';
import { checkNullData, getParent, roundAllocations } from 'utils';
import { sortAllocations } from 'utils/sortingAllocation';
import {
    allocationPercentFormat,
    formatCurrencyWithPrecision,
    formatPercentage,
    NA,
} from 'utils/formatting';

const adaptChartTypes = (data, currency) => ({
    [PIE]: { data },
    [BAR]: {
        data: [
            {
                data: data.map(({ value, color }) => ({ y: value, color })),
            },
        ],
        labels: data.map(({ name }) => name),
    },
    [LIST]: {
        data: data.map((item) => ({
            name: item.name,
            value: formatCurrencyWithPrecision(item.value, 0, currency),
            percent: allocationPercentFormat(item.percent),
            children: item.children,
        })),
    },
});

const getAllocationsGroups = (positions, sectors, assetClasses, currencyPortfolio, t) => {
    const percents = roundAllocations(positions, 100, 1);
    const groups = positions.reduce(
        (obj, item) => {
            const result = obj;
            const allocation = percents.find(({ id }) => id === item.Security.Id)
                ? percents.find(({ id }) => id === item.Security.Id).value
                : item.Allocation * 100;

            if (item.Security.Type) {
                const typeName = getParent(item.Security.Type).Name;

                result.type[typeName] = obj.type[typeName] || {};
                const type = result.type[typeName];

                type.percentage = (type.percentage || 0) + allocation;
                type.amount = (type.amount || 0) + item.InvestmentValue;
            }

            if (item.Security.AssetClass) {
                const assetParent = assetClasses[
                    assetClasses.findIndex((i) => i.children.includes(item.Security.AssetClass.Id))
                ];
                const assetName = assetParent
                    ? assetParent.Name
                    : getParent(item.Security.AssetClass).Name;

                result.asset[assetName] = obj.asset[assetName] || {
                    children: [],
                };
                const asset = result.asset[assetName];
                const assetInd = asset.children.findIndex(
                    (child) => child.id === item.Security.AssetClass.Id,
                );

                asset.id = assetParent ? assetParent.Id : item.Security.AssetClass.Id;
                asset.color = assetParent?.Color || item.Security.AssetClass.Color;
                asset.percentage = (asset.percentage || 0) + allocation;
                asset.amount = (asset.amount || 0) + item.InvestmentValue;

                if (assetInd !== -1) {
                    asset.children[assetInd].percent += percents.find(
                        ({ id }) => id === item.Security.Id,
                    ).value;
                    asset.children[assetInd].value += item.InvestmentValue
                        ? item.InvestmentValue
                        : 0;
                } else {
                    asset.children.push({
                        id: item.Security.AssetClass.Id,
                        name: item.Security.AssetClass.Name,
                        color: item.Security.AssetClass.Color,
                        percent: percents.find(({ id }) => id === item.Security.Id).value,
                        value: item.InvestmentValue ? item.InvestmentValue : 0,
                    });
                }
            }

            if (item.Security.Currency !== undefined) {
                const currencyName = item.Security.Currency.Name;

                result.currency[currencyName] = obj.currency[currencyName] || {};
                const currency = result.currency[currencyName];

                currency.percentage = (currency.percentage || 0) + allocation;
                currency.amount = (currency.amount || 0) + item.InvestmentValue;
            }

            if (
                item.Security.Country !== undefined
                && item.Security.Country.Continent !== undefined
            ) {
                const countryName = item.Security.Country.Continent.Name;

                result.country[countryName] = obj.country[countryName] || {};
                const country = result.country[countryName];

                country.percentage = (country.percentage || 0) + allocation;
                country.amount = (country.amount || 0) + item.InvestmentValue;
            }

            if (item.Security.Sector !== undefined) {
                const sectorParent = sectors[sectors.findIndex((
                    (i) => i.children.includes(item.Security.Sector.Id)
                ))];
                const sectorName = sectorParent
                    ? sectorParent.Name
                    : getParent(item.Security.Sector).Name;

                result.sector[sectorName] = obj.sector[sectorName] || {
                    children: [],
                };
                const sector = result.sector[sectorName];
                const sectorInd = sector.children.findIndex(
                    (child) => child.id === item.Security.Sector.Id,
                );

                sector.id = sectorParent ? sectorParent.Id : item.Security.Sector.Id;
                sector.color = sectorParent?.Color || item.Security.Sector.Color;
                sector.percentage = (sector.percentage || 0) + allocation;
                sector.amount = (sector.amount || 0) + item.InvestmentValue;

                if (sectorInd !== -1) {
                    sector.children[sectorInd].percent += percents.find(
                        ({ id }) => id === item.Security.Id,
                    ).value;
                    sector.children[sectorInd].value += item.InvestmentValue
                        ? item.InvestmentValue
                        : 0;
                } else {
                    sector.children.push({
                        id: item.Security.Sector.Id,
                        name: item.Security.Sector.Name,
                        color: item.Security.Sector.Color,
                        percent: percents.find(({ id }) => id === item.Security.Id).value,
                        value: item.InvestmentValue ? item.InvestmentValue : 0,
                    });
                }
            }

            return obj;
        },
        {
            type: {},
            asset: {},
            currency: {},
            country: {},
            sector: {},
        },
    );

    const gruopArrays = Object.keys(groups).map((groupKey) => {
        const group = groups[groupKey];

        return sortAllocations(
            groupKey,
            Object.keys(group).map((key) => ({
                id: group[key].id,
                name: key,
                color: group[key].color,
                value: group[key].amount,
                percent: group[key].percentage,
                children: group[key].children
                    ? group[key].children.map((item) => ({
                        ...item,
                        percent: allocationPercentFormat(item.percent),
                        value: item.value
                            ? formatCurrencyWithPrecision(item.value, 0, currencyPortfolio)
                            : NA,
                    }))
                    : undefined,
                label: formatPercentage(group[key].percentage * 100),
            })),
        );
    });

    return {
        chart: [
            {
                name: 'Asset Classes',
                title: t('allocationTabs.assetClasses'),
                data: adaptChartTypes(gruopArrays[1], currencyPortfolio),
            },
            {
                name: 'Type',
                title: t('allocationTabs.type'),
                data: adaptChartTypes(gruopArrays[0], currencyPortfolio),
            },
            {
                name: 'Currency',
                title: t('allocationTabs.currencies'),
                data: adaptChartTypes(gruopArrays[2], currencyPortfolio),
            },
            {
                name: 'Region',
                title: t('allocationTabs.regions'),
                data: adaptChartTypes(gruopArrays[3], currencyPortfolio),
            },
            {
                name: 'Sector',
                title: t('allocationTabs.sectors'),
                data: adaptChartTypes(gruopArrays[4], currencyPortfolio),
            },
        ],
        currency: currencyPortfolio,
    };
};

const traversalTree = (current, childrenArr) => {
    childrenArr.push(current.Data.Id);

    if (current.Children) {
        current.Children.forEach((child) => {
            traversalTree(child, childrenArr);
        });
    }
};

const flatArray = (array) => {
    const flatted = [];

    array.forEach((item) => {
        const { length } = flatted;

        flatted.push({ ...item.Data, children: [] });
        traversalTree(item, flatted[length].children);
    });

    return flatted;
};

export const adaptParentsAllocations = (positions, sectors, assetClasses, currency, t) => {
    if (checkNullData(positions)) return { chart: [{ data: { pie: {}, bar: {}, list: {} } }] };

    const flatSectors = flatArray(sectors);
    const flatAssetClasses = flatArray(assetClasses);

    return getAllocationsGroups(positions, flatSectors, flatAssetClasses, currency, t);
};
