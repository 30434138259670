import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    ContentBox, Title, Row, Column, BreadCrumbs, Paragraph,
} from 'ui-library';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useFormatting, dateTimeFormats } from 'locale';
import { useAnnouncements } from 'pages/pages/News/hooks/useAnnouncements';
import './SingleNewsView.css';

function SingleNewsView(props) {
    const {
        location: { query },
        match: {
            params: { newsId },
        },
    } = props;
    const { data, isLoading, error } = useAnnouncements(query);
    const newsItem = data.find((item) => item.id === Number(newsId)) || { title: '' };
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();

    const formattedDate = getFormattedDate(newsItem.date, {
        hour: dateTimeFormats.TWO_DIGIT,
        minute: dateTimeFormats.TWO_DIGIT,
    });
    const languageChanged = () => {
        history.push('/news');
    };

    useEffect(() => {
        i18n.on('languageChanged', languageChanged);

        return () => i18n.off('languageChanged', languageChanged);
    }, []);

    return (
        <Row className="single-news-item">
            <Preloader isLoading={isLoading} error={error}>
                <Column size="12">
                    <EmptyContent data={newsItem} text={t('news.noNews')}>
                        <ContentBox>
                            <div>
                                <BreadCrumbs current={newsItem.title}>
                                    <Link key="news" to="/news/announcements">{t('news.announcements')}</Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {newsItem.title}
                                </Title>
                            </div>
                            <hr />
                            <Row className="mb-0">
                                <Column size={['md-4', 'sm-12']}>
                                    <img
                                        className="single-news-item--image"
                                        src={newsItem.image}
                                        alt="news-img"
                                    />
                                </Column>
                                <Column size={['md-8', 'sm-12']}>
                                    <Paragraph type="secondary" className="single-news-item--date">
                                        {formattedDate}
                                    </Paragraph>
                                    <Paragraph className="single-news-item--content">
                                        {newsItem.content}
                                    </Paragraph>
                                </Column>
                            </Row>
                        </ContentBox>
                    </EmptyContent>
                </Column>
            </Preloader>
        </Row>
    );
}

SingleNewsView.propTypes = {
    location: PropTypes.shape({
        query: {
            id: PropTypes.number,
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
        },
    }).isRequired,
    match: PropTypes.shape({
        params: { newsId: PropTypes.string.isRequired },
    }).isRequired,
};

SingleNewsView.defaultProps = {};

export default SingleNewsView;
