import { roundAllocations } from 'utils';

export const adaptBandwidthData = (positions, constraints) => (positions || [])
    .map((item) => {
        const constraint = (constraints || [])
            .find(({ AssetClass: { Id } }) => Id === item.id) || {};
        const newData = item.allocationNewData !== item.allocationData
            ? item.allocationNewData : item.allocationData;

        return {
            id: item.id,
            name: item.name,
            range: { min: constraint?.MinWeight * 100, max: constraint?.MaxWeight * 100 },
            data: { curr: item.allocationData, new: newData },
        };
    })
    .filter(({ range: { min, max } }) => !Number.isNaN(min) && !Number.isNaN(max));

export const adaptBandwidth = (options) => {
    const {
        portfolio, strategy, positions, numberFormat, t, labelFormat,
    } = (options || {});
    const bandwidthConstraints = strategy
        ? strategy.constraints?.AssetClassConstraints
        : portfolio?.constraints?.AssetClassConstraints;

    return {
        title: t('clientDashboard.portfolio.bandwidth'),
        data: adaptBandwidthData(positions, bandwidthConstraints),
        optional: {
            numberFormat,
            labelWidth: 250,
            labelNew: strategy?.bandwidthTitle || t('confirmation.new'),
            labelCurrent: portfolio?.bandwidthTitle || t('confirmation.current'),
            labelFormat,
        },
    };
};

export const adaptSustainability = (options) => {
    const {
        data = [], currency, name, portfolioId,
    } = (options || {});
    const roundedAllocation = roundAllocations(data)
        .reduce((acc, item) => ({ ...acc, [item.id]: item.value }), {});

    return {
        name,
        portfolioId,
        securities: data.map((item) => ({
            isin: item?.Security.Isin || item?.Security.Name.replace(' ', ''),
            percentage: roundedAllocation[item?.Security.Id],
        })),
        total: {
            value: data.reduce((acc, item) => acc + item?.InvestmentValuePortfolioCurrency, 0),
            currency,
        },
    };
};
