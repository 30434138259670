import { loginByURL, reset2FA } from 'redux/auth/authActions';
import history from 'services/history';
import {
    factorSelector,
    isFistStepDoneSelector,
    providerSelector,
    isLoginDoneSelector,
} from 'redux/auth/authSelectors';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { Login } from './Login';

const mapStateToProps = (state) => ({
    isFistStepDone: isFistStepDoneSelector(state),
    isLoginDone: isLoginDoneSelector(state),
    factor: factorSelector(state),
    provider: providerSelector(state),
    router: get('router', state),
});

const mapDispatchToProps = (dispatch) => ({
    redirectToDashboard: () => {
        history.push('/dashboard');
    },
    reset2FA: () => dispatch(reset2FA()),
    loginByURL: ({ urlContactId, urlAccessToken }) => (
        dispatch(loginByURL({ urlContactId, urlAccessToken }))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
