import React, {
    useCallback, useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import {
    Column, Row, PageVariant, Paragraph, notification,
} from 'ui-library';
import InfoboxWithButton from 'components/InfoboxWithButton';
import InvestmentAllocation from 'components/InvestmentAllocation';
import News from 'components/News';
import NewProposalInfobox from 'components/NewProposalInfobox';
import { LOGGED_IN } from 'constants/constants';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { useProposals } from 'hooks/useProposals';
import { useFormatting } from 'locale';
import { useClientProfile } from 'prodivers/clientProfile';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import AssetDevelopment from './components/AssetDevelopment';
import Overview from './components/Overview/Overview';
import RelationshipManager from './components/RelationshipManager';
import { useWithClientProfile } from './hooks/useWithClientProfile';
import { useWithInvestmentOverview } from './hooks/useWithInvestmentOverview';
import { useNews } from '../News/hooks/useNews';
import { useLoadMoreNews } from '../News/hooks/useLoadMoreNews';
import { useParentsAllocations } from './hooks/useParentsAllocations';
import PortfoliosList from './components/PortfoliosCard';
import './Dashboard.css';

function Dashboard(props) {
    const {
        contactId: clientId,
    } = props;
    const {
        data: { RMProfile, lastLogin, IsProspect },
        isLoading: isLoadingProfile,
        error: errorProfile,
    } = useWithClientProfile(clientId);
    const proposalsObj = useProposals(clientId, lastLogin);
    const {
        data,
        isLoading: isLoadingInvestmentOverview,
        error: errorInvestmentOverview,
    } = useWithInvestmentOverview({
        clientId, proposals: proposalsObj, usePortfolioOverview: true,
    });
    const { investmentAllocation, isLoadingAllocation, errorAllocation } = useParentsAllocations(
        data.positions,
        isLoadingInvestmentOverview,
        data.overview.currency,
    );

    // news
    const pageSize = 6;
    const {
        data: newsData, isLoading, error, getRssNews,
    } = useNews(undefined, { rssNews: pageSize, currentPage: 1 });
    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: newsData, pageSize, getNewsData: getRssNews,
    });

    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    if (clientId === undefined) {
        return (
            <PageVariant
                type="error"
                title={t('error.title')}
                message={t('error.clientNotFound')}
                actionButtonLabel={t('error.button')}
                onActionButtonClick={() => {
                    history.push('/');
                }}
            />
        );
    }

    const oRelationManagerRowClick = ({ Icon }) => ({
        onClick: () => Icon.href && window.open(Icon.href, '_self'),
    });
    const onBoarding = () => history.push('/onboarding/start');
    const renderProspectInfobox = () => (
        <InfoboxWithButton buttonText={t('client.onBoardingToWealth')} buttonProps={{ onClick: onBoarding }}>
            {t('clientDashboard.onboardProspect')}
        </InfoboxWithButton>
    );

    const renderCashOverview = useCallback(() => (
        <Paragraph type="secondary" className="overview-cash">
            {`${t('clientDashboard.cash')}: ${getFormattedNumber(data?.overview?.cashAmount)} ${data?.overview?.currency}`}
        </Paragraph>
    ), [data?.overview?.cashAmount, data?.overview?.currency, getFormattedNumber]);

    const { data: { LastName, Salutation } } = useClientProfile();
    const { isMobileSize } = useDetectedMobileDevice();
    const isJustLoggedIn = sessionStorage.getItem(LOGGED_IN) === '1';

    useEffect(() => {
        if (LastName && Salutation && isJustLoggedIn) {
            notification.open({
                content: `${t('Dashboard.WelcomeBack')} ${Salutation} ${LastName}`,
                placement: isMobileSize ? 'top' : 'topRight',
                className: 'welcome-notification',
            });
            sessionStorage.removeItem(LOGGED_IN);
        }
    }, [t, LastName, Salutation, isJustLoggedIn]);

    return (
        <div className="dashboard">
            {IsProspect && renderProspectInfobox()}
            <NewProposalInfobox
                proposals={proposalsObj.data}
                portfolios={data.portfolios}
            />
            <section className="first-section">
                <Row>
                    <Column size="lg-6" className="ColPrimary base-margin-bottom">
                        <Overview
                            data={data.overview}
                            isLoading={isLoadingInvestmentOverview}
                            error={errorInvestmentOverview}
                            additionalData={renderCashOverview}
                        />
                    </Column>
                    <Column size="lg-6" className="ColSecondary base-margin-bottom">
                        <RelationshipManager
                            data={RMProfile}
                            isLoading={isLoadingProfile}
                            error={errorProfile}
                            onRow={oRelationManagerRowClick}
                        />
                    </Column>
                </Row>
            </section>
            <section className="second-section">
                <Row>
                    <Column size="lg-6" className="ColPrimary base-margin-bottom">
                        <InvestmentAllocation
                            data={investmentAllocation}
                            isLoading={isLoadingAllocation}
                            error={errorAllocation}
                        />
                    </Column>
                    <Column size="lg-6" className="ColSecondary base-margin-bottom">
                        <AssetDevelopment
                            currency={data.overview.currency || ''}
                            isLoading={isLoadingInvestmentOverview}
                            clientId={clientId}
                        />
                    </Column>
                </Row>
            </section>
            <section className="portfolios-list base-margin-bottom">
                <PortfoliosList
                    data={data.portfolios}
                    isLoading={isLoadingInvestmentOverview}
                    error={errorInvestmentOverview}
                />
            </section>
            <section className="base-margin-bottom">
                <News
                    data={newsList}
                    isLoading={isLoading}
                    error={error}
                    pageSize={pageSize}
                    nextArticles={nextArticles}
                    nextArticlesSize={3}
                    withLink
                    loadMore
                />
            </section>
        </div>
    );
}

Dashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

Dashboard.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Dashboard);
