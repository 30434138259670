import React from 'react';
import { Infobox, Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { instrumentDocumentsColumns } from './constants';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import './InstrumentDocumentsTable.css';

const InstrumentDocumentsTable = (props) => {
    const {
        data, columns, error, isLoading, errorDocument,
    } = props;
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('position.documents.noDocuments')}>
                <Table
                    data={data}
                    columns={columns || instrumentDocumentsColumns(t)}
                    className="instrument-documents-table"
                />
                {errorDocument && <Infobox accent error>{t('position.errorDocumentDownload')}</Infobox>}
            </EmptyContent>
        </Preloader>
    );
};

InstrumentDocumentsTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.func,
            PropTypes.shape({
                [PropTypes.string]: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
            }),
        ]),
    })),
    columns: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            render: PropTypes.node,
            sortable: PropTypes.bool,
            sorter: PropTypes.func,
        }),
    })),
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    errorDocument: PropTypes.shape({
        message: PropTypes.string,
    }),
};

InstrumentDocumentsTable.defaultProps = {
    data: [],
    columns: undefined,
    error: null,
    isLoading: false,
    errorDocument: null,
};

export default InstrumentDocumentsTable;
