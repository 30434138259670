import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentBox, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';

import './RelationshipManager.css';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';

function RelationshipManager({ data, isLoading, error }) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();

    return (
        <PageSection>
            <ContentBox underline={false} className="relationship-manager">
                <Title type={2}>{t('dashboard.relationshipManager.title')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <div className="media">
                        <div className="picture Avatar">
                            <span className="avatar-content">
                                {data?.Img && <img src={data?.Img} alt={data?.Name} width="72" height="72" />}
                            </span>
                        </div>
                        <div className="media-body">
                            <Title type={3}>{data?.Name}</Title>
                            <div className="media-links">
                                {isMobileSize ? (
                                    <>
                                        <Link
                                            to={{ pathname: '/messages/new', search: '?back=relationshipManager' }}
                                            className="Link Link_secondary"
                                        >
                                            {t('messages.sendMessage')}
                                        </Link>
                                        {data?.PhoneNumber && (
                                            <a href={data?.PhoneNumber} className="Link Link_secondary call-now">
                                                {t('dashboard.relationshipManager.callNow')}
                                            </a>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {data?.Email && (
                                            <a href={`mailto:${data?.Email}`} className="Link Link_secondary">
                                                {data?.Email}
                                            </a>
                                        )}
                                        {data?.PhoneNumberBase && data?.PhoneNumber && (
                                            <a href={data?.PhoneNumber} className="Link Link_secondary call-now">
                                                {data?.PhoneNumberBase}
                                            </a>
                                        )}
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

RelationshipManager.propTypes = {
    data: PropTypes.shape({
        Contacts: PropTypes.arrayOf(
            PropTypes.shape({
                Information: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                Icon: PropTypes.shape({
                    type: PropTypes.string,
                    href: PropTypes.string,
                    size: PropTypes.number,
                }),
            }),
        ),
        Img: PropTypes.string,
        Name: PropTypes.string,
        PhoneNumber: PropTypes.string,
        PhoneNumberBase: PropTypes.string,
        Email: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

RelationshipManager.defaultProps = {
    error: undefined,
};

export default RelationshipManager;
