import { renderers } from 'ui-library';
import {
    CellPercent,
    CellWithMoreActions,
    CellSwitch,
    CellValue,
    CellPDFView,
} from 'components/renderers';
import { sorterString } from 'utils/sorting';

const { CellWithIcon, CellTooltiped, CellWithButtonIcon } = renderers;

export const DISCRETIONARY = 'Discretionary';
export const EXECUTION_ONLY = 'Execution Only';
export const EXECUTION_ONLY_ID = 4;

export const projectionOptional = (t, yTitle, yAxisFormat, tooltipFormat) => ({
    yTitle,
    yAxisFormat,
    tooltipFormat,
    topLabel: t('confirmation.veryStrongStock'),
    bottomLabel: t('confirmation.veryWeakStock'),
    middleLabel: t('confirmation.expectedValue'),
    middleTopLabel: t('confirmation.strongStock'),
    middleBottomLabel: t('confirmation.weakStock'),
});

export const cashFlowsColumns = (t) => [
    {
        key: 'Account',
        title: t('portfolios.cashFlowsColumns.account'),
        render: CellTooltiped,
        minWidth: 290,
    },
    {
        key: 'Date',
        title: t('portfolios.cashFlowsColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        width: 187,
        minWidth: 90,
    },
    {
        key: 'Type',
        title: t('portfolios.cashFlowsColumns.type'),
        minWidth: 90,
    },
    {
        key: 'Currency',
        title: t('portfolios.cashFlowsColumns.currency'),
        minWidth: 50,
    },
    {
        key: 'Amount',
        title: t('portfolios.cashFlowsColumns.amount'),
        className: 'text-right',
        minWidth: 150,
    },
];

export const ORDER_TYPE = {
    1: 'orderType.buy',
    2: 'orderType.sell',
};

// TODO: unused code below, delete after approval
export const assetClassesPieChartData = [
    {
        name: 'Cash',
        value: 11340.01,
    },
    {
        name: 'Equities',
        value: 51780.01,
    },
    {
        name: 'Bonds',
        value: 36880.01,
    },
    {
        name: 'Others',
        value: 10080.01,
    },
];

export const invPrfLineChartData = [
    {
        data: [
            [1167609600000, -1],
            [1167696000000, 1],
            [1167782400000, 2.5],
            [1167868800000, 1],
            [1167955200000, 0.5],
            [1168214400000, 3],
        ],
    },
];

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = ['YTD', 'Last 12 months', '2 Years', '5 Years', 'Max'];

export const positionsColumns = [
    {
        key: 'name',
        title: 'Name',
        sortable: true,
        render: CellSwitch,
        width: 150,
        minWidth: 150,
    },
    {
        title: 'ISIN',
        key: 'isin',
    },
    {
        title: 'Type',
        key: 'type',
        hidden: true,
    },
    {
        title: 'Units',
        key: 'units',
        width: 100,
        minWidth: 100,
        className: 'text-right',
        render: CellValue,
    },
    {
        title: 'Currency',
        key: 'currency',
        className: 'currency-cel',
        width: 110,
        minWidth: 110,
    },
    {
        title: 'Last price',
        key: 'latestPrice',
        width: 100,
        minWidth: 100,
        className: 'text-right',
        render: CellValue,
    },
    {
        key: 'performance',
        title: 'Performance',
        className: 'text-right',
        width: 140,
        minWidth: 140,
        render: CellPercent(),
    },
    {
        title: 'Sub-asset class',
        key: 'subAssetClass',
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: 'Country',
        key: 'country',
        width: 250,
        minWidth: 250,
        hidden: true,
    },
    {
        title: 'Region',
        key: 'region',
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: 'Sector',
        key: 'sector',
        width: 100,
        minWidth: 100,
        hidden: true,
    },
    {
        title: 'Sub-sector',
        key: 'subSector',
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: 'Cost price',
        key: 'costPrice',
        className: 'text-right',
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: 'Value in security currency',
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: 'FX rate',
        key: 'fxRate',
        className: 'text-right',
        width: 150,
        minWidth: 150,
        hidden: true,
    },
    {
        title: 'Unrealized gain/loss',
        key: 'unrealizedGainLoss',
        className: 'text-right',
        width: 200,
        minWidth: 200,
        hidden: true,
    },
    {
        title: 'Allocation',
        key: 'allocation',
        className: 'text-right',
        width: 120,
        minWidth: 120,
    },
    {
        title: 'Value',
        className: 'text-right',
        key: 'valuation',
        width: 150,
        minWidth: 150,
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 40,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const positionsData = [
    {
        Name: 'Bonds',
        Product: 'Execution only',
        Profile: 'Conservative',
        Value: '2.130.291,10',
        Performance: '+24.59%',
        Icon: 'action',
        subRows: [
            {
                Name: 'RC EUROP STOCK THIRTEEN SMP CR0849899652',
                Product: 'Execution only',
                Profile: 'Conservative',
                Value: '2.130.291,10',
                Performance: '+24.59%',
                Icon: 'action',
            },
            {
                Name: 'CR EUROP STOCK THIRTEEN SMP CR0865999652',
                Product: 'Execution only',
                Profile: 'Conservative',
                Value: '2.130.291,10',
                Performance: '+24.59%',
                Icon: 'action',
            },
        ],
    },
    {
        Name: 'Equities',
        Product: 'Discretionary',
        Profile: 'Balanced',
        Value: '771.277,90',
        Performance: '+5.98%',
        Icon: 'action',
    },
];

export const transactionsColumns = [
    {
        key: 'Name',
        render: CellSwitch,
        width: 200,
    },
    {
        key: 'Date',
        sortable: true,
        defaultSortOrder: 'ascend',
        width: 187,
    },
    {
        key: 'Type',
    },
    {
        key: 'Number',
        className: 'text-right',
    },
    {
        key: 'Price',
        className: 'text-right',
    },
    {
        key: 'FxRate',
        title: 'FX Rate',
        className: 'text-right',
    },
    {
        key: 'Total',
        className: 'text-right',
    },
];

export const getDocumentsColumns = (t) => [
    {
        key: 'icon',
        title: '',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
    {
        key: 'name',
        title: t('clientDashboard.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
        lastSorter: true,
    },
];
