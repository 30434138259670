import { checkNullData } from 'utils';
import { getLocaleString } from '../locale/utils';

export const adaptBenchmark = (data, { language }) => {
    if (checkNullData(data)) return {};

    return {
        id: data.id,
        name: getLocaleString(data.name, language),
        data: [[+(data.Volatility * 100), +(data.ExpectedReturn * 100)]],
        tradingPriceOfUnderlying: data.tradingPrice,
        calculatedRiskOfUnderlying: data.Volatility,
    };
};
