import { useMemo } from 'react';

const htmlDecode = (input) => {
    if (!input) return null;
    const doc = new DOMParser().parseFromString(input, 'text/html');

    return doc.documentElement?.textContent;
};
const grabAllSubLabels = (components) => (components || [])
    .map((item) => (item.tag === 'label' && item.content
        ? { title: item.content, key: item.key }
        : grabAllSubLabels(item.components || item.columns)))
    .flat();

export const useCustomPanel = (schema) => useMemo(() => (schema?.components || [])
    .map((item) => ({
        title: htmlDecode(item?.title),
        key: item?.key,
        custom: item?.custom,
        subLabels: grabAllSubLabels(item?.components),
    }))
    .filter((item) => item?.title && item?.key), [schema]);
