import React, {
    useMemo, useEffect, useCallback, useState,
} from 'react';
import {
    Column, Icon, Paragraph, ProjectionChart, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { get, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import {
    formatDate, setYearToDate, yearFormat, dateFormat,
    getFirstDayOfUnit, addUnits,
} from 'utils/datetime';
import { getClassNames } from 'utils';
import Preloader from 'components/Preloader';
import { dateTimeFormats, useFormatting } from 'locale';
import RadioButtonsGroup from 'components/RadioBlocks';
import {
    goalCreationSelector,
    onBoardingDataSelector,
    goalOptimizeSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useGoalPerformance } from '../../hooks/useGoalPerformance';
import { adaptProducts } from '../../../ProductSelection/adaptors/adaptProducts';
import './Optimize.css';

const Optimize = (props) => {
    const {
        onNext,
        onPageChange,
    } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();

    // OnBoarding Domain
    const {
        getRiskCategory, product, saveGoalDetails, getGoalDetails, productOffer,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        isLoading, error, getModelPortfolioId,
        dataCurrencies, isLoadingCurrencies, errorCurrencies, getCurrencyCodes,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        data: optimize, isLoading: isLoadingOptimize, error: errorOptimize, optimizeGoal,
    } = useOnBoardingSelector(goalOptimizeSelector);

    // Portfolio Domain
    const {
        dataRaw, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const [selectedOptimizeGoal, setSelectedOptimizeGoal] = useState(
        goalDetails?.selectedOptimizeGoal || 'TargetAmount',
    );
    const goalState = useMemo(() => ({
        ...goalDetails, selectedOptimizeGoal,
    }), [goalDetails, selectedOptimizeGoal]);
    const [optimizeGoalData, setOptimizeGoalData] = useState({});
    const expectedReturn = useMemo(() => dataRaw?.CalculatedExpectedReturn,
        [dataRaw?.CalculatedExpectedReturn]);
    const dataForOptimize = useMemo(() => {
        if (isNumber(+goalState?.initialInvestment)
            && goalState?.targetValue
            && goalState?.targetDate
            && expectedReturn
            && goalState?.selectedOptimizeGoal
            && isNumber(+goalState?.recurringPayment)) {
            const data = {
                InitialAmount: +goalState?.initialInvestment,
                TargetAmount: goalState?.targetValue,
                ExpectedReturn: expectedReturn,
                TargetYears:
                    formatDate(
                        goalState?.targetDate,
                        yearFormat,
                    ) - formatDate(new Date(), yearFormat),
                PaymentFrequency: 'Monthly',
                RecurringAmount: +goalState?.recurringPayment,
            };

            return Object.keys(data)
                .filter((key) => key !== goalState?.selectedOptimizeGoal)
                .reduce((obj, key) => ({
                    ...obj,
                    [key]: data[key],
                }), {});
        }

        return null;
    }, [
        expectedReturn,
        goalState?.initialInvestment,
        goalState?.recurringPayment,
        goalState?.targetDate,
        goalState?.targetValue,
        goalState?.selectedOptimizeGoal,
    ]);
    const goalOptions = [
        {
            value: 'TargetAmount',
            label: {
                name: t('onBoarding.goal.lowerTargetAmount'),
            },
        },
        {
            value: 'InitialAmount',
            label: {
                name: t('onBoarding.goal.higherInitialAmount'),
            },
        },
        {
            value: 'TargetYears',
            label: {
                name: t('onBoarding.goal.longerTimePeriod'),
            },
        },
        {
            value: 'RecurringAmount',
            label: {
                name: t('onBoarding.goal.higherRecurringPayment'),
            },
        },
    ];
    const minimumInvestmentValue = useMemo(() => {
        const products = adaptProducts(dataCurrencies, { t, getFormattedNumber, productOffer });

        return (products || []).find(
            (i) => i.id === +product?.id,
        )?.minimumInvestmentValue;
    }, [t, dataCurrencies, getFormattedNumber, productOffer, product?.id]);
    const optimizedData = useMemo(() => ({
        TargetValue: optimize?.TargetAmount,
        InitialInvestment: optimize?.InitialAmount
            && (optimize?.InitialAmount > minimumInvestmentValue
                ? optimize?.InitialAmount : minimumInvestmentValue),
        TargetDate: optimize?.TargetYears,
        RecurringPayment: optimize?.RecurringAmount
            && (optimize?.RecurringAmount > 0 ? optimize?.RecurringAmount : 0),
    }), [optimize]);
    const optimizedTargetDateFormated = useMemo(() => {
        const monthsToAdd = Math.ceil(12 * optimizedData.TargetDate);

        return getFormattedDate(addUnits(
            new Date(), monthsToAdd, 'month', dateFormat,
        ), {
            year: dateTimeFormats.TWO_DIGIT,
            month: dateTimeFormats.TWO_DIGIT,
            day: undefined,
        });
    }, [getFormattedDate, optimizedData.TargetDate]);
    const targetDateFormated = useMemo(() => getFormattedDate(setYearToDate(
        new Date(),
        formatDate(goalState?.targetDate, yearFormat),
    ), {
        year: dateTimeFormats.TWO_DIGIT,
        month: dateTimeFormats.TWO_DIGIT,
        day: undefined,
    }), [getFormattedDate, goalState?.targetDate]);

    // Helpers hooks
    const {
        isLoadingProjection,
        dataExternal, chartData, defaultMinMax, projectionOptional,
    } = useGoalPerformance({
        goalState, optimizeGoalData, dataPortfolio: dataRaw, selectedOptimizeGoal,
    });

    // Effects
    useEffect(() => {
        (async () => {
            if (!goalDetails.selectedCurrency?.value) return;

            const riskCategoryId = (await getRiskCategory())?.Id;
            const modelObj = await getModelPortfolioId({ productId: +product?.id, riskCategoryId });

            getModelPortfolio(modelObj?.Id, { currencyId: goalDetails.selectedCurrency?.value });
        })();
    }, [
        getRiskCategory,
        getModelPortfolioId,
        getModelPortfolio,
        product?.id,
        goalDetails.selectedCurrency?.value,
    ]);
    useEffect(() => {
        if (dataForOptimize) optimizeGoal(dataForOptimize);
    }, [optimizeGoal, dataForOptimize]);
    useEffect(() => {
        const optimizeGoalObj = {};

        if (optimizedData?.TargetValue) {
            optimizeGoalObj.targetValue = optimizedData.TargetValue;
        }
        if (optimizedData?.TargetDate) {
            const monthsToAdd = Math.ceil(12 * optimizedData.TargetDate);

            optimizeGoalObj.targetDate = addUnits(getFirstDayOfUnit('month'), monthsToAdd, 'month', dateFormat);
        }
        if (optimizedData.InitialInvestment !== undefined
            && isNumber(+optimizedData.InitialInvestment)) {
            optimizeGoalObj.initialInvestment = optimizedData.InitialInvestment;
        }
        if (optimizedData?.RecurringPayment !== undefined
            && isNumber(+optimizedData?.RecurringPayment)) {
            optimizeGoalObj.recurringPayment = optimizedData.RecurringPayment;
        }
        setOptimizeGoalData(optimizeGoalObj);
    }, [setOptimizeGoalData, optimizedData]);
    useEffect(() => {
        getCurrencyCodes({ productId: +product?.id });
    }, [getCurrencyCodes, product.id]);

    // Callbacks
    const onNextClick = useCallback(() => {
        saveGoalDetails({ ...goalState, ...optimizeGoalData });
        onNext();
    }, [goalState, optimizeGoalData, saveGoalDetails, onNext]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.goal.optimize.title')}
            error={error || errorModel || errorCurrencies}
            isLoading={isLoading || isLoadingModel || isLoadingCurrencies}
            className="optimize-page"
            prevButton={{
                onClick: () => onPageChange(''),
            }}
            nextButton={{
                onClick: onNextClick,
            }}
        >
            <div className="optimize-goal">
                <div className="group-title">
                    <Title type={3}>{t('onBoarding.goal.optimize.subTitle')}</Title>
                    <Paragraph type="secondary">{t('onBoarding.goal.optimize.subTitleText')}</Paragraph>
                </div>
                <div className="select-goal">
                    <RadioButtonsGroup
                        value={goalState?.selectedOptimizeGoal}
                        options={goalOptions}
                        onChange={setSelectedOptimizeGoal}
                        withoutImg
                    />
                </div>
                <div className="our-suggestion">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.optimize.ourSuggestion')}</Title>
                        <Paragraph type="secondary">{t('onBoarding.goal.optimize.ourSuggestionText')}</Paragraph>
                    </div>
                    <Preloader isLoading={isLoadingOptimize} error={errorOptimize}>
                        <div className="suggestion">
                            <div className="suggestion-left">
                                <div className="suggestion-text">
                                    <span className="suggestion-title">{t('onBoarding.goal.goalName')}</span>
                                    <div className="suggestion-value">
                                        <span>{goalState?.goalName}</span>
                                    </div>
                                </div>
                                <div className={getClassNames('suggestion-text',
                                    { isOptimize: optimizedData.TargetValue })}
                                >
                                    <span className="suggestion-title">{t('onBoarding.goal.targetValue')}</span>
                                    <div className="suggestion-value">
                                        <span>
                                            {
                                                getFormattedNumber(Number(goalState?.targetValue))
                                            }
                                        </span>
                                        <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                        {optimizedData.TargetValue && (
                                            <>
                                                <span className="optimized">
                                                    <div className="arrow" />
                                                    {
                                                        getFormattedNumber(Number(
                                                             optimizedData?.TargetValue,
                                                        ))
                                                    }
                                                </span>
                                                <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={getClassNames('suggestion-text',
                                    {
                                        isOptimize: isNumber(optimizedData.InitialInvestment)
                                            // eslint-disable-next-line no-restricted-globals
                                            && !isNaN(optimizedData.InitialInvestment),
                                    })}
                                >
                                    <span className="suggestion-title">{t('onBoarding.goal.initialInvestment')}</span>
                                    <div className="suggestion-value">
                                        <span>
                                            {getFormattedNumber(+goalState?.initialInvestment)}
                                        </span>
                                        <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                        {isNumber(+optimizedData.InitialInvestment)
                                        // eslint-disable-next-line no-restricted-globals
                                        && !isNaN(optimizedData.InitialInvestment)
                                        && (
                                            <>
                                                <span className="optimized">
                                                    <div className="arrow" />
                                                    {getFormattedNumber(Number(
                                                        +optimizedData?.InitialInvestment,
                                                    ))}
                                                </span>
                                                <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="suggestion-right">
                                <div className={getClassNames('suggestion-text',
                                    { isOptimize: optimizedData.TargetDate })}
                                >
                                    <span className="suggestion-title">{t('onBoarding.goal.targetDate')}</span>
                                    <div className="suggestion-value">
                                        <span>
                                            {
                                                targetDateFormated
                                            }
                                        </span>
                                        {optimizedData?.TargetDate && (
                                            <span className="optimized">
                                                <div className="arrow" />
                                                {
                                                    optimizedTargetDateFormated
                                                }
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="suggestion-text">
                                    <span className="suggestion-title">{t('onBoarding.goal.paymentFrequency')}</span>
                                    <div className="suggestion-value">
                                        <span>{t('onBoarding.goal.monthly')}</span>
                                    </div>
                                </div>
                                <div className={getClassNames('suggestion-text',
                                    {
                                        isOptimize: isNumber(optimizedData.RecurringPayment)
                                            // eslint-disable-next-line no-restricted-globals
                                            && !isNaN(optimizedData.RecurringPayment),
                                    })}
                                >
                                    <span className="suggestion-title">{t('onBoarding.goal.recurringPayment')}</span>
                                    <div className="suggestion-value">
                                        <span>
                                            {
                                                getFormattedNumber(+goalState?.recurringPayment)
                                            }
                                        </span>
                                        <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                        {isNumber(optimizedData.RecurringPayment)
                                        // eslint-disable-next-line no-restricted-globals
                                        && !isNaN(optimizedData.RecurringPayment) && (
                                            <>
                                                <span className="optimized">
                                                    <div className="arrow" />
                                                    {getFormattedNumber(Number(
                                                        optimizedData?.RecurringPayment,
                                                    ))}
                                                </span>
                                                <span className="suggestion-currency">{get(goalState, 'selectedCurrency.label')}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Preloader>
                </div>
                <div className="group-title">
                    <Title type={3}>{t('onBoarding.goal.result')}</Title>
                    <div className="positive">
                        <div className="icon">
                            <Icon type="check-filled" size={16} />
                        </div>
                        <Paragraph type="secondary">{t('onBoarding.goal.resultTextFirst')}</Paragraph>
                    </div>
                </div>
                <div className="chart">
                    <Column size={['xl-9', 'md-12']}>
                        <Preloader isLoading={isLoadingProjection}>
                            <ProjectionChart
                                data={chartData || []}
                                showLegend
                                dataExternal={dataExternal || []}
                                colorScheme="positiveAndNegative"
                                legendLabels={[t('onBoarding.goal.expectedCase'), t('onBoarding.goal.goodScenario'), t('onBoarding.goal.badScenario')]}
                                {...defaultMinMax}
                                {...projectionOptional}
                                yearsText={t('charts.years')}
                            />
                        </Preloader>
                    </Column>
                </div>
            </div>
        </OnBoardingBaseTemplate>
    );
};

Optimize.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    goalState: PropTypes.objectOf(PropTypes.object).isRequired,
    setSelectedOptimizeGoal: PropTypes.func.isRequired,
    setOptimizeGoalData: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    onChange: PropTypes.func,
    dataCharts: PropTypes.arrayOf(PropTypes.any).isRequired,
    dataExternal: PropTypes.arrayOf(PropTypes.any).isRequired,
    projection: PropTypes.objectOf(PropTypes.object).isRequired,
    result: PropTypes.bool.isRequired,
    isNext: PropTypes.bool.isRequired,
    expectedReturn: PropTypes.number.isRequired,
    isLoadingProjection: PropTypes.bool.isRequired,
    currentStep: PropTypes.number.isRequired,
};

Optimize.defaultProps = {
    onPageChange: () => {},
    onChange: () => {},
};

export default Optimize;
