import { roundAllocations } from 'utils';

export const optimizeQuickEdit = (positions, total = 100) => {
    const newPositions = (positions || []).map(({ Id, Allocation, CurrencyId }) => ({
        Id, Allocation: (+Allocation) / total, CurrencyId,
    }));
    const newPositionsObj = (newPositions || []).reduce((acc, item) => ({
        ...acc, [item.Id]: item.CurrencyId,
    }), {});

    return roundAllocations(newPositions).map(({ id, value }) => ({
        Id: id, Allocation: value, CurrencyId: newPositionsObj[id],
    }));
};
