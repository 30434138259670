import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown, MenuItem } from 'ui-library';
import { getClassNames } from 'utils';
import './CellWithMoreActions.css';

const CellWithMoreActions = ({ value, actions, disabled }) => {
    const className = getClassNames('cell-with-more-actions__icon', {
        'table-cell_disabled': disabled,
    });

    return (
        <Dropdown placement="bottomRight" title={<Icon role="button" tabIndex={0} className={className} type={value} />}>
            {actions
            && actions.map(
                (action, i) => (
                    <MenuItem key={i.toString()} onClick={action.onClick}>
                        {action.text}
                    </MenuItem>
                ),
            )}
        </Dropdown>
    );
};


CellWithMoreActions.propTypes = {
    value: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    disabled: PropTypes.bool,
};

CellWithMoreActions.defaultProps = {
    value: '',
    actions: [],
    disabled: false,
};

export default CellWithMoreActions;
