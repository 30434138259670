import React from 'react';
import { Column } from 'ui-library';
import PropTypes from 'prop-types';
import { OverviewItem } from '../../../Overview';

const OverviewColumn = ({
    size, className, overviewItems, emptyColumn,
}) => {
    if (emptyColumn) {
        return (
            <Column
                className={className}
                size={size}
            />
        );
    }

    return (
        <Column
            className={className}
            size={size}
        >
            {overviewItems?.map(({ customRender, ...overviewInformation }) => (
                customRender ? customRender()
                    : (
                        <OverviewItem
                            key={overviewInformation.title}
                            title={overviewInformation.title}
                            {...overviewInformation}
                        />
                    )
            ))}
        </Column>
    );
};

OverviewColumn.propTypes = {
    size: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    overviewItems: PropTypes.arrayOf(PropTypes.shape({})),
    emptyColumn: PropTypes.bool,
};

OverviewColumn.defaultProps = {
    size: ['md-6', 'sm-6', 'lg-3'],
    className: '',
    overviewItems: [],
    emptyColumn: false,
};

export default React.memo(OverviewColumn);
