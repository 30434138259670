import React from 'react';
import PropTypes from 'prop-types';
import { useQuestionnaire } from '../hooks/useQuestionnaire';
import { useChosenRisk } from '../hooks/useChosenRisk';
import { useFormData } from '../hooks/useFormData';
import { useRiskCategories } from '../hooks/useRiskCategories';
import RiskProfileContext from './context';

const RiskProfileProvider = (options) => {
    const {
        options: {
            clientId,
            questionnaire: questionnaireOptions,
            riskCategories: riskCategoriesOptions,
        },
        children,
    } = options;
    const questionnaire = useQuestionnaire({ ...questionnaireOptions, clientId });
    const chosenRisk = useChosenRisk({ clientId });
    const formData = useFormData();
    const riskCategories = useRiskCategories({ ...riskCategoriesOptions });
    const state = {
        clientId,
        questionnaire,
        chosenRisk,
        formData,
        riskCategories,
    };
    const getValue = (callback) => callback(state);

    return (
        <RiskProfileContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </RiskProfileContext.Provider>
    );
};

RiskProfileProvider.propTypes = {
    options: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
        questionnaire: PropTypes.shape({
            productId: PropTypes.number,
            questionnaireTypeId: PropTypes.number,
        }).isRequired,
    }).isRequired,
};

RiskProfileProvider.defaultProps = {};

export default RiskProfileProvider;
