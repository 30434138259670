import React, { useMemo } from 'react';
import { usePortfolio } from 'domain/Portfolio';
import Preloader from 'components/Preloader';
import { PageNotFound } from '../../404';

const withFundingInformationAccess = (Component) => (props) => {
    const { portfolio: { data, isLoading, error } } = usePortfolio();

    const withFundingInformation = useMemo(
        () => data?.portfolioStatusId !== 1, [data?.portfolioStatusId],
    );

    /* NOT_IMPLEMENTED check */
    if (withFundingInformation) {
        return (
            <Preloader isLoading={isLoading} error={error}>
                <PageNotFound />
            </Preloader>
        );
    }

    return <Component {...props} />;
};

export default withFundingInformationAccess;
