import { TYPE_PERCENT } from '../../../utils/formatting';
import { OVERVIEW_SIZE_LAGRE } from '../../Overview';

export const adaptFullPositionOverview = ({
    price, priceType, currency, performanceYTD, expReturnValue, expRiskValue, t,
}) => [{
    columnsData: [
        {
            overviewItemsInfo: [{
                title: t('overview.price'),
                value: price,
                type: priceType,
                currency: currency?.isoCode,
                currencySize: OVERVIEW_SIZE_LAGRE,
            }],
        },
        {
            overviewItemsInfo: [{
                title: t('overview.performanceYTD'),
                value: performanceYTD,
                type: TYPE_PERCENT,
                colored: true,
            }],
        },
        {
            overviewItemsInfo: [{
                title: t('overview.expReturn'),
                value: expReturnValue,
                type: TYPE_PERCENT,
            }],
        },
        {
            overviewItemsInfo: [{
                title: t('overview.expRisk'),
                value: expRiskValue,
                type: TYPE_PERCENT,
            }],
        },
    ],
},
];
