import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BondId, StructuredProductId } from 'constants/instrument';
import { TYPE_PERCENT, TYPE_RATIO } from 'utils/formatting';
import { adaptFullPositionOverview } from './adapters/adaptFullPositionOverview';
import { adaptPositionOverview } from './adapters/adaptPositionOverview';
import { OverviewInformation } from '../OverviewInformation';

function PositionOverview(props) {
    const {
        data: {
            isin,
            price,
            typeId,
            currency,
        },
        performance: {
            Volatility, ExpectedReturn, performanceYTD,
        },
        portfolioData,
        hideHelpText,
    } = props;
    const { t } = useTranslation();
    const quantity = portfolioData?.quantity;
    const showFullData = useMemo(() => Number(quantity) > 0, [quantity]);

    // Data
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(typeId)
        ? TYPE_PERCENT : TYPE_RATIO), [typeId]);

    const overviewRowInformation = useMemo(() => (showFullData
        ? adaptPositionOverview({
            price, priceType, currency, isin, t, portfolioData,
        })
        : adaptFullPositionOverview({
            price,
            priceType,
            performanceYTD,
            currency,
            t,
            expReturnValue: ExpectedReturn && ExpectedReturn * 100,
            expRiskValue: Volatility && Volatility * 100,
        })),
    [
        showFullData,
        price,
        priceType,
        currency,
        isin,
        t,
        portfolioData,
        performanceYTD,
        ExpectedReturn,
        Volatility,
    ]);

    if (!showFullData) {
        return (
            <div className="instrument-overview">
                <OverviewInformation
                    columnSize={['md-6', 'sm-6', 'lg-3']}
                    columnClassName="overview-column"
                    rowsData={overviewRowInformation}
                />
            </div>
        );
    }

    return (
        <div className="instrument-overview">
            <OverviewInformation
                columnClassName="overview-column"
                rowsData={overviewRowInformation}
                columnSize={['md-6', 'sm-6', 'lg-3']}
            />
            {!hideHelpText && (
                <div className="helpText">
                    <span>{`*${t('overview.estimatedConvertedValue')}`}</span>
                </div>
            )}
        </div>
    );
}

PositionOverview.propTypes = {
    data: PropTypes.shape({
        isin: PropTypes.string,
        price: PropTypes.number,
        typeId: PropTypes.number,
        currency: PropTypes.shape({
            isoCode: PropTypes.string,
        }),
    }),
    performance: PropTypes.shape({
        Volatility: PropTypes.number,
        ExpectedReturn: PropTypes.number,
        performanceYTD: PropTypes.number,
    }),
    portfolioData: PropTypes.shape({
        quantity: PropTypes.number,
        price: PropTypes.string,
        units: PropTypes.oneOfType(
            [PropTypes.string, PropTypes.number],
        ),
        currency: PropTypes.string,
        costPrice: PropTypes.number,
        costPriceInCurrency: PropTypes.string,
        totalValue: PropTypes.number,
        totalValueInCurrency: PropTypes.string,
        allocation: PropTypes.number,
        performanceSi: PropTypes.number,
        performanceAbsolute: PropTypes.number,
    }),
    hideHelpText: PropTypes.bool,
};

PositionOverview.defaultProps = {
    data: {},
    performance: {},
    portfolioData: {},
    hideHelpText: false,
};

export default PositionOverview;
