import React, { useState, useCallback, useMemo } from 'react';
import { TextInput } from 'ui-library';
import { UnmaskPasswordButton } from './components/UnmaskPasswordButton';
import { getClassNames } from '../../utils';
import { PasswordInputProps } from './PasswordInput.types';
import './PasswordInput.css';

const PasswordInput: React.FC<PasswordInputProps> = (
    {
        className = '',
        ...textInputProps
    },
) => {
    const [type, setType] = useState('password');

    const handlePasswordVisibility = useCallback(() => {
        setType(type === 'password' ? 'text' : 'password');
    }, [type]);

    const isClosedEyeIcon = useMemo(() => type === 'password', [type]);

    const textInputClassName = useMemo(() => getClassNames(className, 'password-input'), [className]);

    return (
        <TextInput
            className={textInputClassName}
            {...textInputProps}
            type={type}
            addonAfter={(
                <UnmaskPasswordButton
                    onClick={handlePasswordVisibility}
                    isClosedEyeIcon={isClosedEyeIcon}
                />
            )}
        />
    );
};

export default React.memo(PasswordInput);
