import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConfirmationPage from 'components/ConfirmationPage';
import { PageNotFound } from './pages/404';
import Dashboard from './pages/Dashboard/Router';
import Settings from './pages/Settings/Settings';
import Messages from './pages/Messages/Router';
import News from './pages/News/Router';
import Portfolios from './pages/Portfolios/Router';
import Documents from './pages/Documents';
import Details from './pages/Details';
import KnowledgeExperience from './pages/KnowledgeExperience/Router';
import { RootTemplate } from './templates';

export const RootRouter = ({ match: { path }, location: { pathname } }) => (
    <RootTemplate pathname={pathname}>
        <Switch>
            <Route path={`${path}settings`} component={Settings} />
            <Route path={`${path}dashboard`} component={Dashboard} />
            <Route path={`${path}messages`} component={Messages} />
            <Route path={`${path}news`} component={News} />
            <Route path={`${path}details`} component={Details} />
            <Route path={`${path}documents`} component={Documents} />
            <Route path={`${path}confirm`} component={ConfirmationPage} />
            <Route path={`${path}success-page`} component={ConfirmationPage} />
            <Route path={`${path}knowledge-experience`} component={KnowledgeExperience} />
            <Route path={`${path}portfolios`} component={Portfolios} />
            <Route exact path="/404" component={PageNotFound} />
            <Redirect exact from={path} to={`${path}login`} />
            <Redirect from="*" to="/404" />
        </Switch>
    </RootTemplate>
);

RootRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

RootRouter.defaultProps = {};

RootRouter.displayName = 'Router';
