import { useCallback } from 'react';
import { PAYMENTS, PAYMENT } from 'constants/constants';

const getItem = (key) => {
    try {
        return JSON.parse(sessionStorage.getItem(key)) || {};
    } catch {
        return {};
    }
};

export const usePaymentsData = () => {
    const orignalSetItem = sessionStorage.setItem;

    // eslint-disable-next-line func-names
    sessionStorage.setItem = function (key, newValue) {
        const setItemEvent = new Event('itemInserted');

        // console.log('newValue', newValue);
        setItemEvent.key = key;
        setItemEvent.value = newValue;
        window.dispatchEvent(setItemEvent);
        // eslint-disable-next-line prefer-rest-params
        orignalSetItem.apply(this, arguments);
    };

    const getPayment = useCallback((payment) => getItem(PAYMENTS)?.[payment], []);
    const savePayment = useCallback((data) => {
        sessionStorage.setItem(PAYMENTS, JSON.stringify(data));
    }, []);
    const clearPayments = useCallback(() => {
        sessionStorage.removeItem(PAYMENTS);
    }, []);

    const getPaymentCode = useCallback(() => getItem(PAYMENT), []);
    const savePaymentCode = useCallback((data) => {
        sessionStorage.setItem(PAYMENT, JSON.stringify(data));
    }, []);
    const clearPaymentCode = useCallback(() => {
        sessionStorage.removeItem(PAYMENT);
    }, []);

    const clearPayment = useCallback(() => {
        clearPaymentCode();
        clearPayments();
    }, []);

    return {
        getPaymentCode,
        savePaymentCode,
        getPayment,
        savePayment,
        clearPayments: clearPayment,
    };
};
