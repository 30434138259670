import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Infobox } from 'ui-library';
import './RiskDisclaimer.css';

const RiskDisclaimer = ({ PRP, CRP }) => {
    const { t } = useTranslation();

    return (
        <Infobox accent className="risk-disclaimer-info-box">
            {`${t('clientDashboard.riskProfileDisclaimer.1')} `}
            <b>{`"${PRP}"`}</b>
            {` ${t('clientDashboard.riskProfileDisclaimer.2')} `}
            <b>{`"${CRP}"`}</b>
            {` ${t('clientDashboard.riskProfileDisclaimer.3')}`}
        </Infobox>
    );
};

RiskDisclaimer.propTypes = {
    PRP: PropTypes.string.isRequired,
    CRP: PropTypes.string.isRequired,
};

export default RiskDisclaimer;
