export const validate = (values) => {
    const errors = {};

    if (values.subject !== undefined && !values.subject) {
        errors.subject = 'Subject should be provided';
    }

    if (values.contactReason !== undefined && !values.contactReason) {
        errors.contactReason = 'Contact reason should be defined';
    }

    if (values.message !== undefined && !values.message) {
        errors.message = 'Message should be provided';
    }

    return errors;
};
