import React, { useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Loading } from 'components/Loading';
import { useTranslation } from 'react-i18next';
import {
    Button, Infobox, TextInput, Title,
} from 'ui-library';
import { FormInput, FormError } from '../../types';
import './UsernameIdentity.css';

interface UsernameIdentityProps {
    isLoading: boolean
    isSubmitting: boolean
    error?: string | null | undefined
    isUserNameSameWithEmail?: boolean | null
    onSubmit: React.FormEventHandler<HTMLFormElement>
    control: Control
    values: FormInput,
    formErrors: FormError,
    changePassword?: boolean | null | undefined,
}

export const UsernameIdentity: React.FC<UsernameIdentityProps> = ({
    isLoading,
    isSubmitting,
    error,
    isUserNameSameWithEmail,
    onSubmit,
    control,
    values,
    formErrors,
    changePassword,
}) => {
    const { t, i18n } = useTranslation();

    const ErrorMemo = useMemo(() => (error && <Infobox error>{t(error)}</Infobox>), [
        error,
        t,
    ]);

    const title = changePassword ? t('forgotPassword.changePasswordTitle') : t('ForgotPassword.Title');
    const description = changePassword ? t('forgotPassword.changePasswordDescription') : t('ForgotPassword.Description');

    const UsernameFieldMemo = useMemo(
        () => (!isUserNameSameWithEmail && (
            <Controller
                as={<TextInput />}
                control={control}
                name="username"
                type="username"
                className="form-control"
                value={values.username}
                error={formErrors.username}
                label={t('ForgotPassword.UsernameLabel')}
                required
            />
        )),
        [isUserNameSameWithEmail, t, control, values.username, formErrors.username],
    );

    const ContentMemo = useMemo(
        () => (isLoading ? (
            <Loading />
        ) : (
            <>
                {ErrorMemo}
                <p className="info-text">{description}</p>
                {UsernameFieldMemo}
                <Controller
                    as={<TextInput />}
                    control={control}
                    className="form-control"
                    name="email"
                    value={values.email}
                    error={formErrors.email}
                    label={t('ForgotPassword.EmailLabel')}
                    required
                />
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button type="primary" htmltype="submit" disabled={isSubmitting}>
                        {t('ForgotPassword.ContinueButtonLabel')}
                    </Button>
                </div>
            </>
        )),
        [
            isLoading,
            isSubmitting,
            ErrorMemo,
            UsernameFieldMemo,
            i18n.language,
            control,
            formErrors.email,
            values.email,
        ],
    );

    return (
        <div className="UsernameIdentity content-pane">
            <form onSubmit={onSubmit}>
                <Title type={2}>{title}</Title>
                {ContentMemo}
            </form>
        </div>
    );
};

UsernameIdentity.defaultProps = {
    error: null,
    isUserNameSameWithEmail: false,
    changePassword: false,
};
