import { checkNullData } from '../utils';
import {
    getDatesDifference, getFirstDayOfYear, getTimestamp, subtractUnits,
} from '../utils/datetime';
import { adaptPlotsAmount } from '../utils/adaptPlotsAmount';
import { sorterByDateCommon } from '../utils/sorting';
import { adaptStartDate, isNeedToAddFlatLine } from '../utils/charts';

const adaptDate = (Date) => getTimestamp(Date);

export const adaptPlotsChart = ({
    data,
    chartStartDate,
    chartEndDate,
    adaptValue,
    isMobileSize,
}) => {
    if (checkNullData(data) || checkNullData(data)) return [];

    const lastDate = data[data.length - 1].Date;
    const isReversedArray = getDatesDifference(lastDate, data[0].Date, 'days') < 0;
    const dateToCompare = isReversedArray ? lastDate : data[0].Date;
    const needDivide = chartStartDate ? getDatesDifference(chartStartDate, dateToCompare, 'days') > 0 : false;
    const isFirstDayOfYear = chartStartDate === getFirstDayOfYear();

    let valueToDivideIdx = null;
    let valueToDivide = null;

    if (needDivide) {
        valueToDivideIdx = data.findIndex(({ Date }) => {
            const diff = getDatesDifference(Date, chartStartDate, 'days');

            return isReversedArray ? diff <= 0 : diff >= 0;
        });

        if (isFirstDayOfYear && !isReversedArray) {
            valueToDivideIdx -= 1;
        }

        valueToDivide = data[valueToDivideIdx];
    }

    const initValues = needDivide
        ? data.filter((item, idx) => (isReversedArray
            ? idx <= valueToDivideIdx : idx >= valueToDivideIdx))
        : data;


    let values = adaptPlotsAmount(initValues, isMobileSize);

    if (isReversedArray) {
        values = values.sort(sorterByDateCommon('Date'));
    }

    const adaptedData = [];

    values.forEach((item, idx) => {
        const { Date } = item;

        if (idx === 0) {
            const start = adaptStartDate(chartStartDate, Date);

            if (isNeedToAddFlatLine(start, Date)) {
                adaptedData.push([adaptDate(start), 0]);
                adaptedData.push([adaptDate(subtractUnits(Date, 1, 'days')), 0]);
            }
        }

        if (valueToDivide && getDatesDifference(Date, valueToDivide.Date, 'days') < 0) {
            return;
        }

        if (chartEndDate && getDatesDifference(Date, chartEndDate, 'days') > 0) {
            return;
        }

        adaptedData.push([
            adaptDate(Date),
            adaptValue(item, { valueToDivide, firstValue: values[0] }),
        ]);
    });

    return adaptedData;
};
