import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Column, MultiSelect, Row, TextInput, Select,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import LoadingOverlap from '../LoadingOverlap';
import './AddPositionSearchForm.css';

const AddPositionSearchForm = (props) => {
    const {
        searchDisabled,
        onSearch,
        onChangeISIN,
        typeValue,
        typeOptions,
        onChangeType,
        currencyValue,
        currencyOptions,
        onChangeCurrency,
        ratingAgencyValue,
        ratingAgencyOptions,
        onChangeRatingAgency,
        ratingValue,
        ratingOptions,
        onChangeRating,
        isLoadingRatings,
    } = props;
    const { t } = useTranslation();
    const onSubmit = (e, ...values) => {
        e.preventDefault();
        onSearch(e, ...values);
    };

    return (
        <form className="add-position-search-form" onSubmit={onSubmit}>
            <Row>
                <Column size={['sm-6', 'lg-6']}>
                    <TextInput
                        onChange={onChangeISIN}
                        label={t('position.nameISIN')}
                        withInfobox={false}
                    />
                </Column>
            </Row>
            <Row>
                <Column size={['sm-3', 'lg-3']}>
                    <MultiSelect
                        placeholder={t('position.pleaseSelect')}
                        onChange={onChangeType}
                        value={typeValue}
                        label={t('position.type')}
                        options={typeOptions}
                        withInfobox={false}
                        withSelectTool
                        selectControlsLabel={{
                            selectAll: t('headerControls.selectAll'),
                            reset: t('headerControls.reset'),
                        }}
                    />
                </Column>
                <Column size={['sm-3', 'lg-3']}>
                    <MultiSelect
                        placeholder={t('position.pleaseSelect')}
                        onChange={onChangeCurrency}
                        value={currencyValue}
                        label={t('position.currency')}
                        options={currencyOptions}
                        withInfobox={false}
                        withSelectTool
                        selectControlsLabel={{
                            selectAll: t('headerControls.selectAll'),
                            reset: t('headerControls.reset'),
                        }}
                    />
                </Column>
                <Column size={['sm-3', 'lg-3']}>
                    <Select
                        placeholder={t('position.pleaseSelect')}
                        onChange={onChangeRatingAgency}
                        value={ratingAgencyValue}
                        label={t('position.ratingAgencies')}
                        options={ratingAgencyOptions}
                    />
                </Column>
                <Column size={['sm-3', 'lg-3']}>
                    <LoadingOverlap isLoading={isLoadingRatings}>
                        <MultiSelect
                            placeholder={t('position.pleaseSelect')}
                            onChange={onChangeRating}
                            value={ratingValue}
                            label={t('position.ratings')}
                            options={ratingOptions}
                            withInfobox={false}
                            withSelectTool
                            disabled={!ratingAgencyValue}
                            selectControlsLabel={{
                                selectAll: t('headerControls.selectAll'),
                                reset: t('headerControls.reset'),
                            }}
                        />
                    </LoadingOverlap>
                </Column>
            </Row>
            <Row>
                <Column size="12">
                    <Button
                        onClick={onSearch}
                        size="small"
                        type="secondary"
                        disabled={searchDisabled}
                        htmltype="submit"
                    >
                        {t('position.search')}
                    </Button>
                </Column>
            </Row>
        </form>
    );
};

AddPositionSearchForm.propTypes = {
    searchDisabled: PropTypes.bool,
    onSearch: PropTypes.func,
    onChangeISIN: PropTypes.func,
    typeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    typeOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    onChangeType: PropTypes.func,
    currencyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    onChangeCurrency: PropTypes.func,
    ratingAgencyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ratingAgencyOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    onChangeRatingAgency: PropTypes.func,
    ratingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ratingOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    onChangeRating: PropTypes.func,
    isLoadingRatings: PropTypes.bool,
};

AddPositionSearchForm.defaultProps = {
    searchDisabled: false,
    onSearch: () => {},
    onChangeISIN: () => {},
    typeValue: undefined,
    typeOptions: [],
    onChangeType: () => {},
    currencyValue: undefined,
    currencyOptions: [],
    onChangeCurrency: () => {},
    ratingAgencyValue: undefined,
    ratingAgencyOptions: [],
    onChangeRatingAgency: () => {},
    ratingValue: undefined,
    ratingOptions: [],
    onChangeRating: () => {},
    isLoadingRatings: false,
};

export default AddPositionSearchForm;
