import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useChosenRisk = (options = {}) => {
    const {
        clientId,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();

    // Callbacks
    const setChosenRisk = useCallback(async (
        riskId,
    ) => {
        if (!riskId) return null;

        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            await SM.compliance('setChosenRisk', [clientId, riskId]);
            const response = await SM.compliance('acceptRisk', [clientId]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        setChosenRisk,
    };
};
