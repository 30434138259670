import React from 'react';
import { Table } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { cashFlowsColumns, cashFlowsRowMarginIdx } from '../constants';

function CashFlows({
    isLoading, error, data, portfolio,
}) {
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('portfolios.noCashFlowsFound')}>
                <div className="data-wrapper">
                    <Table
                        data={data}
                        columns={cashFlowsColumns(t, portfolio?.currency)}
                        onRow={(data1, index) => (cashFlowsRowMarginIdx.includes(index) ? { className: 'row-margin-left' } : {})}
                    />
                </div>
            </EmptyContent>
        </Preloader>
    );
}

CashFlows.propTypes = {
    portfolio: PropTypes.shape({
        currency: PropTypes.string,
    }),
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        CurrentMonth: PropTypes.string,
        KeyValue: PropTypes.string,
        SinceInception: PropTypes.string,
        YTD: PropTypes.string,
    })),
};

CashFlows.defaultProps = {
    error: null,
    isLoading: false,
    portfolio: undefined,
    data: [],
};

export default CashFlows;
