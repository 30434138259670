import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Infobox } from 'ui-library';
import { useFormatting } from 'locale';

const RiskBandwidthDisclaimer = (props) => {
    const {
        risk, min, max, CRP,
    } = props;
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    return (
        <Infobox accent className="with-mb with-mt">
            {`${t('clientDashboard.riskBandwidthDisclaimer.1')} `}
            <b>{`${getFormattedNumber(risk * 100)}%`}</b>
            {` ${t('clientDashboard.riskBandwidthDisclaimer.2')} `}
            <b>{`${getFormattedNumber(min * 100)}%`}</b>
            {` ${t('clientDashboard.riskBandwidthDisclaimer.3')} `}
            <b>{`${getFormattedNumber(max * 100)}%`}</b>
            {` ${t('clientDashboard.riskBandwidthDisclaimer.4')} `}
            <b>{`"${CRP}"`}</b>
            {` ${t('clientDashboard.riskBandwidthDisclaimer.5')} `}
        </Infobox>
    );
};

RiskBandwidthDisclaimer.propTypes = {
    risk: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    CRP: PropTypes.string.isRequired,
};

RiskBandwidthDisclaimer.defaultProps = {
    risk: 0,
    min: 0,
    max: 0,
};

export default RiskBandwidthDisclaimer;
