import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from 'services/history';
import {
    Button, Column, PageVariant, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { ListPreview, Preview } from 'templates/ListPreview';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Metadata from './components/Metadata';
import Attachments from './components/Attachments';
import ListComponent from './components/ListComponent';
import { useMessaging } from './hooks/useMessaging';

import './Messages.css';

function Messages({ contactId: clientId, match }) {
    const { t } = useTranslation();
    const {
        params: { messageId },
    } = match;
    const {
        tab,
        needUpdate,
        getData,
        onSearch,
        changeTab,
        getPreview,
        deleteItem,
        getDataCallback,
        data: messages,
    } = useMessaging(clientId);
    const { downloadFile } = useDownloadFile(clientId);

    if (clientId === undefined) {
        return (
            <PageVariant
                type="error"
                title={t('error.title')}
                message={t('error.clientNotFound')}
                actionButtonLabel={t('error.button')}
                onActionButtonClick={() => {
                    history.push('/');
                }}
            />
        );
    }

    const messagesLength = messages?.length || 0;

    const onNewMessage = () => {
        history.push('/messages/new');
    };
    const onReply = ({
        id, subtypeId, recipients, title,
    }) => {
        history.push(`/messages/reply/${id}`, {
            title,
            subtypeId,
            contactId: clientId,
            threadId: recipients[0].threadId,
        });
    };

    const titleComponent = (
        <>
            <Column size={['sm-4', '6']}>
                <Title type={2}>{t('messages.title')}</Title>
            </Column>
            <Column size={['sm-8', '6']} className="text-sm-right button-group">
                <Button type="primary" size="small" onClick={onNewMessage}>
                    {t('messages.newMessage')}
                </Button>
            </Column>
        </>
    );

    const listComponent = (props) => (
        <div className="messages-list">
            <ListComponent
                tab={tab}
                needUpdate={needUpdate}
                changeTab={changeTab}
                onNewMessage={onNewMessage}
                useDynamicSorting
                {...props}
            />
        </div>
    );
    const renderActions = (props) => tab === 'inbox' && (
        <Button type="secondary" size="small" onClick={() => onReply(props)}>
            {t('messages.reply')}
        </Button>
    );
    const renderMetadata = (props) => Metadata({ ...props, t });
    const renderAttachments = (props) => Attachments({ ...props, downloadFile, t });

    return (
        <div className="messages">
            <ListPreview
                getData={getData}
                onSearch={onSearch}
                preSelect={messageId}
                getPreview={getPreview}
                listComponent={listComponent}
                titleComponent={titleComponent}
                getDataCallback={getDataCallback}
                dataLength={messagesLength}
                searchBarActive
            >
                <Preview
                    deleteItem={deleteItem}
                    deleteTitle={t('messages.deleteSelected')}
                    deleteText={t('messages.deleteCurrentContent')}
                    renderActions={renderActions}
                    renderMetadata={renderMetadata}
                    renderAttachments={renderAttachments}
                    showDelete
                />
            </ListPreview>
        </div>
    );
}

Messages.propTypes = {
    contactId: PropTypes.number,
    match: PropTypes.shape({
        params: PropTypes.shape({ messageId: PropTypes.string }),
    }),
};

Messages.defaultProps = {
    contactId: undefined,
    match: { params: {} },
};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Messages);
