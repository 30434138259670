import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import {
    SELECTED_GOAL_CHANGES,
    SELECTED_GOAL_DETAILS,
    SELECTED_PRODUCT,
    SELECTED_PRODUCT_OFFER,
    CREATED_GOAL,
    SELECTED_RISK_APPETITE,
    SELECTED_RISK_CAPACITY,
    RISK_PROFILE,
    MODIFY_POSITIONS_MODIFIED,
    REVIEW_DATA,
    CREATED_PROPOSAL_ID, IBAN, KNOWLEDGE_EXPERIENCE,
} from '../constants';

const getItem = (key, defValue) => {
    try {
        return JSON.parse(sessionStorage.getItem(key)) || defValue || {};
    } catch {
        return defValue || {};
    }
};

export const useOnBoardingData = (options = {}) => {
    const { clientId } = options;
    const { i18n } = useTranslation();

    // ProductOffer
    const productOffer = getItem(SELECTED_PRODUCT_OFFER);
    const saveProductOffer = useCallback((data) => {
        sessionStorage.setItem(SELECTED_PRODUCT_OFFER, JSON.stringify(data));
    }, []);
    const clearProductOffer = useCallback(() => {
        sessionStorage.removeItem(SELECTED_PRODUCT_OFFER);
    }, []);

    // Product
    const product = getItem(SELECTED_PRODUCT);
    const saveProduct = useCallback((data) => {
        sessionStorage.setItem(SELECTED_PRODUCT, JSON.stringify(data));
    }, []);
    const clearProduct = useCallback(() => {
        sessionStorage.removeItem(SELECTED_PRODUCT);
    }, []);

    // RiskAppetite
    const riskAppetite = getItem(SELECTED_RISK_APPETITE);
    const saveRiskAppetite = useCallback((data) => {
        sessionStorage.setItem(SELECTED_RISK_APPETITE, JSON.stringify(data));
    }, []);
    const clearRiskAppetite = useCallback(() => {
        sessionStorage.removeItem(SELECTED_RISK_APPETITE);
    }, []);

    // RiskCapacity
    const riskCapacity = getItem(SELECTED_RISK_CAPACITY);
    const saveRiskCapacity = useCallback((data) => {
        sessionStorage.setItem(SELECTED_RISK_CAPACITY, JSON.stringify(data));
    }, []);
    const clearRiskCapacity = useCallback(() => {
        sessionStorage.removeItem(SELECTED_RISK_CAPACITY);
    }, []);

    // Risk Category
    const riskCategory = getItem(RISK_PROFILE);
    const getRiskCategory = useCallback(async () => {
        const riskCategorySaved = getItem(RISK_PROFILE, null);

        if (typeof riskCategorySaved === 'string') return riskCategorySaved;

        const clientProfile = await ServiceManager.compliance('getClientRiskCategory', [clientId, i18n.language]);
        const chosenRiskCategory = clientProfile?.data?.ChosenRiskCategory
            || clientProfile?.data?.CalculatedRiskCategory
            || null;

        sessionStorage.setItem(RISK_PROFILE, JSON.stringify(chosenRiskCategory));

        return chosenRiskCategory;
    }, [clientId, i18n.language]);
    const clearRiskCategory = useCallback(() => {
        sessionStorage.removeItem(RISK_PROFILE);
    }, []);

    // Knowledge and experience
    const knowledgeAndExperience = getItem(KNOWLEDGE_EXPERIENCE);
    const saveKnowledgeAndExperience = useCallback((data) => {
        sessionStorage.setItem(KNOWLEDGE_EXPERIENCE, JSON.stringify(data));
    }, []);
    const clearKnowledgeAndExperience = useCallback(() => {
        sessionStorage.removeItem(KNOWLEDGE_EXPERIENCE);
    }, []);

    // Goal Details
    const goalDetails = useMemo(() => getItem(SELECTED_GOAL_DETAILS), []);
    const getGoalDetails = useCallback(() => getItem(SELECTED_GOAL_DETAILS), []);
    const saveGoalDetails = useCallback((data) => {
        sessionStorage.setItem(SELECTED_GOAL_DETAILS, JSON.stringify(data));
    }, []);
    const clearGoalDetails = useCallback(() => {
        sessionStorage.removeItem(SELECTED_GOAL_DETAILS);
    }, []);

    // Goal Changes
    const goalChanges = useMemo(() => getItem(SELECTED_GOAL_CHANGES, ''), []);
    const getGoalChanges = useCallback(() => getItem(SELECTED_GOAL_CHANGES), []);
    const saveGoalChanges = useCallback((data) => {
        sessionStorage.setItem(SELECTED_GOAL_CHANGES, JSON.stringify(data));
    }, []);
    const clearGoalChanges = useCallback(() => {
        sessionStorage.removeItem(SELECTED_GOAL_CHANGES);
    }, []);

    // Created Goal
    const createdGoal = getItem(CREATED_GOAL);
    const saveCreatedGoal = useCallback((data) => {
        sessionStorage.setItem(CREATED_GOAL, JSON.stringify(data));
    }, []);
    const clearCreatedGoal = useCallback(() => {
        sessionStorage.removeItem(CREATED_GOAL);
    }, []);

    // Modify positions MODIFY_POSITIONS
    const modifiedPositions = getItem(MODIFY_POSITIONS_MODIFIED, []);
    const getModifiedPositions = useCallback(() => getItem(MODIFY_POSITIONS_MODIFIED, []), []);
    const saveModifiedPositions = useCallback((data) => {
        sessionStorage.setItem(MODIFY_POSITIONS_MODIFIED, JSON.stringify(data));
    }, []);
    const clearModifiedPositions = useCallback(() => {
        sessionStorage.removeItem(MODIFY_POSITIONS_MODIFIED);
    }, []);

    // proposal
    const createdProposalId = getItem(CREATED_PROPOSAL_ID);
    const saveProposalId = useCallback((data) => {
        sessionStorage.setItem(CREATED_PROPOSAL_ID, JSON.stringify(data));
    }, []);
    const clearCreatedProposalId = useCallback(() => {
        sessionStorage.removeItem(CREATED_PROPOSAL_ID);
    }, []);

    // Iban
    const Iban = getItem(IBAN, ' ');
    const saveIban = useCallback((data) => {
        sessionStorage.setItem(IBAN, JSON.stringify(data));
    }, []);
    const clearIban = useCallback(() => {
        sessionStorage.removeItem(IBAN);
    }, []);

    // Review Data
    const ReviewData = getItem(REVIEW_DATA, ' ');
    const saveReviewData = useCallback((data) => {
        sessionStorage.setItem(REVIEW_DATA, JSON.stringify(data));
    }, []);
    const clearReviewData = useCallback(() => {
        sessionStorage.removeItem(REVIEW_DATA);
    }, []);

    // Clear data
    const clearData = useCallback(() => {
        clearProduct();
        clearGoalDetails();
        clearGoalChanges();
        clearRiskAppetite();
        clearRiskCapacity();
        clearRiskCategory();
        clearModifiedPositions();
        clearCreatedProposalId();
        clearProductOffer();
        clearKnowledgeAndExperience();
        clearReviewData();
    }, [
        clearProduct,
        clearGoalDetails,
        clearGoalChanges,
        clearRiskAppetite,
        clearRiskCapacity,
        clearRiskCategory,
        clearProductOffer,
        clearModifiedPositions,
        clearCreatedProposalId,
        clearKnowledgeAndExperience,
        clearReviewData,
    ]);
    const clearAllOnBoarding = useCallback(() => {
        clearData();
        clearIban();
        clearCreatedGoal();
    }, [clearData, clearIban, clearCreatedGoal]);

    return {
        productOffer,
        saveProductOffer,
        clearProductOffer,
        clearProduct,
        product,
        saveProduct,
        getRiskCategory,
        riskCategory,
        clearRiskCategory,
        goalDetails,
        getGoalDetails,
        saveGoalDetails,
        goalChanges,
        getGoalChanges,
        saveGoalChanges,
        clearGoalChanges,
        createdGoal,
        saveCreatedGoal,
        clearCreatedGoal,
        clearData,
        riskAppetite,
        saveRiskAppetite,
        clearRiskAppetite,
        riskCapacity,
        saveRiskCapacity,
        clearRiskCapacity,
        modifiedPositions,
        getModifiedPositions,
        saveModifiedPositions,
        clearModifiedPositions,
        createdProposalId,
        saveProposalId,
        clearCreatedProposalId,
        Iban,
        saveIban,
        clearIban,
        knowledgeAndExperience,
        saveKnowledgeAndExperience,
        clearAllOnBoarding,
        ReviewData,
        saveReviewData,
    };
};
