import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'ui-library';
import history from 'services/history';

import { useTranslation } from 'react-i18next';
import { Footer } from 'components/Footer';
import { Logo } from 'components/Logo';
import { useStrategyChange } from 'hooks/useStrategyChange';
import { useTradingCard } from 'domain/Portfolio';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { useQuickEdit } from '../pages/QuickEdit/hooks/useQuickEdit';
import { useModify } from '../pages/ModifyPortfolio/hooks/useModify';

export const PortfoliosActionsTemplate = ({ children, portfolioId }) => {
    const clientId = useSelector(contactIdSelector);
    const { t } = useTranslation();
    const { clearQuickEditLocally } = useQuickEdit();
    const { clearModifiedLocally } = useModify();
    const { onStrategyReset } = useStrategyChange();
    const { deleteTradingCard } = useTradingCard({ clientId, portfolioId });

    const portfoliosClose = useCallback((goHome) => {
        Modal.confirm({
            title: t('riskProfile.closeProcess'),
            content: t('onBoarding.closeModalText'),
            okText: t('confirmation.yes'),
            onOk: async () => {
                history.push(`${goHome ? '/' : `/portfolios/${portfolioId}`}`);
                clearQuickEditLocally();
                clearModifiedLocally();
                await deleteTradingCard();
                onStrategyReset();
            },
            cancelText: t('confirmation.no'),
            className: 'close-client-modal',
        });
    }, [
        clearModifiedLocally,
        clearQuickEditLocally,
        deleteTradingCard,
        onStrategyReset,
        portfolioId,
        t,
    ]);

    const headerProps = useMemo(
        () => ({
            leftButton: { type: 'close', label: t('confirm.closeProcess') },
            onLeftButtonClick: () => portfoliosClose(),
            rightButton: {},
        }),
        [portfoliosClose, t],
    );

    return (
        <>
            <Header
                {...headerProps}
                title={t('login.title')}
                content={<Logo />}
                link={({ children: logoChildren }) => <span onClick={() => portfoliosClose(true)} className="action-logo" role="presentation">{logoChildren}</span>}
            />
            <main className="container">{children}</main>
            <Footer />
        </>
    );
};

PortfoliosActionsTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    portfolioId: PropTypes.string.isRequired,
};

PortfoliosActionsTemplate.defaultProps = {};

PortfoliosActionsTemplate.displayName = 'PortfoliosActionsTemplate';
