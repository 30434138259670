import { useCallback, useEffect, useState } from 'react';
import { getItemFromSS } from 'utils/storage';
import AdapterError from 'errors/AdapterError';

const RISK_PROFILE_DATA = 'risk-profile/data';
const SELECTED_RISK = 'risk-profile/selected-risk';

export const useFormData = () => {
    // Form data
    const [data, setData] = useState();
    const getDataFromSS = useCallback(() => getItemFromSS(RISK_PROFILE_DATA), []);
    const initializeData = useCallback(({ groupIds }) => {
        if (!groupIds) throw new AdapterError('There is no groupIds for initializeData function');
        const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

        setData(newData);
        sessionStorage.setItem(RISK_PROFILE_DATA, JSON.stringify(newData));
    }, []);
    const saveData = useCallback(({ groupId, questionId, value }) => {
        if (!groupId) throw new AdapterError('There is no groupId for saveData function');
        const newData = { ...data };

        if (questionId) {
            newData[groupId][questionId] = value;
        } else {
            newData[groupId] = value;
        }

        setData(newData);
        sessionStorage.setItem(RISK_PROFILE_DATA, JSON.stringify(newData));
    }, [data]);
    const clearData = useCallback(() => {
        setData(null);
        sessionStorage.removeItem(RISK_PROFILE_DATA);
    }, []);

    useEffect(() => {
        const dataFromSS = getDataFromSS();

        if (dataFromSS) setData(getDataFromSS());
    }, [getDataFromSS]);


    const selectedRisk = getItemFromSS(SELECTED_RISK);
    const saveSelectedRisk = useCallback((risk) => {
        sessionStorage.setItem(SELECTED_RISK, JSON.stringify(risk));
    }, []);
    const clearSelectedRisk = useCallback(() => {
        sessionStorage.removeItem(SELECTED_RISK);
    }, []);

    return {
        data,
        getDataFromSS,
        initializeData,
        saveData,
        clearData,
        selectedRisk,
        saveSelectedRisk,
        clearSelectedRisk,
    };
};
