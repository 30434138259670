import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Title } from 'ui-library';
import ImageWithPlaceholder from '../ImageWithPlaceholder';
import './ProductItem.css';

const ProductItem = ({ name, image, description }) => (
    <div className="product-item">
        <div className="image-wrapper">
            <ImageWithPlaceholder src={image} alt={name} />
        </div>
        <div className="product-item__description">
            <Title type={4}>{name}</Title>
            <Paragraph type="secondary">{description}</Paragraph>
        </div>
    </div>
);

ProductItem.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
};

ProductItem.defaultProps = {
    name: null,
    image: null,
    description: null,
};

export default ProductItem;
