import { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { flattenDeep } from 'lodash';

const SEARCH_RATINGS = gql`
    query SearchRatings($positionId: Int!) {
        securities(
        where: {
            id: { eq: $positionId }
        }
        ){
             nodes {
                securityRatings { rating { value, ratingAgency { name } } }
            }
        }
    }
`;

export const useRatings = (positionId) => {
    const [
        getRatings,
        { loading: isLoading, error, data: dataRatings },
    ] = useLazyQuery(SEARCH_RATINGS);

    useEffect(() => {
        if (positionId) {
            getRatings({ variables: { positionId: +positionId } });
        }
    }, [positionId, getRatings]);

    const securityRatings = dataRatings?.securities?.nodes.map((item) => item.securityRatings);
    const ratings = flattenDeep(securityRatings).map((item) => (
        { name: item?.rating?.ratingAgency.name, value: item?.rating?.value }));

    return {
        error,
        isLoading,
        data: ratings,
    };
};
