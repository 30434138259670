/* eslint-disable no-restricted-globals */
const autoLogout = () => {
    let timer;
    let modalTimeout;
    let logoutTimeout;
    const cnf = {
        SESSION_TIMEOUT_DIALOG_COUNTDOWN: 60, // 1 minute
    };

    const setSessionTimeout = () => {
        modalTimeout = setTimeout(() => {
            self.postMessage({ action: 'showModal', data: cnf.SESSION_TIMEOUT_DIALOG_COUNTDOWN });
        }, cnf.sessionTimeoutWarning * 1000);
        logoutTimeout = setTimeout(() => {
            self.postMessage({ action: 'logout' });
        }, (cnf.sessionTimeoutWarning + cnf.SESSION_TIMEOUT_DIALOG_COUNTDOWN) * 1000);
    };
    const clearSessionTimeout = () => {
        if (timer) clearInterval(timer);
        if (modalTimeout) clearTimeout(modalTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };
    const resetTimeout = () => {
        clearSessionTimeout();
        setSessionTimeout();
    };
    const setDialogCountdown = () => {
        let remainSeconds = cnf.SESSION_TIMEOUT_DIALOG_COUNTDOWN;

        timer = setInterval(() => {
            if (remainSeconds === 1) clearInterval(timer);
            remainSeconds -= 1;
            self.postMessage({ action: 'dialogCountdown', data: remainSeconds });
        }, 1000);
    };

    const init = (timeout) => {
        cnf.sessionTimeoutWarning = (timeout - 60);
        setSessionTimeout();
    };

    self.onmessage = (e) => {
        if (e.data?.action === 'init') init(e.data?.timeout);
        if (e.data?.action === 'reset') resetTimeout();
        if (e.data?.action === 'clear') clearSessionTimeout();
        if (e.data?.action === 'setCountdown') setDialogCountdown();
    };
};


let code = autoLogout.toString();

code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const workerScript = URL.createObjectURL(blob);

export default workerScript;
