import React, { useEffect, useState } from 'react';
import { useClarityWidget } from '../hooks/useClarityWidget';
import ClarityContext from './context';
import clarity from '../service/clarity';
import { getAccessToken, setClarityRefreshToken } from '../service/claritySettings';

const ClarityProvider = (options) => {
    const {
        children,
    } = options;
    const clarityWidget = useClarityWidget();
    const [token, setToken] = useState(clarity.basicAuth);
    const state = {
        token,
        clarityWidget,
    };
    const getValue = (callback) => callback(state);

    useEffect(() => {
        (async () => {
            const accessToken = await getAccessToken();

            setToken(accessToken);
            setClarityRefreshToken();
        })();
    }, []);

    return (
        <ClarityContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </ClarityContext.Provider>
    );
};

ClarityProvider.propTypes = {
};

ClarityProvider.defaultProps = {
};

export default ClarityProvider;
