import { useCallback, useState } from 'react';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';

export const useRejectProposal = (clientId, portfolioId, proposalId) => {
    const [isRejecting, setRejecting] = useState(false);
    const [errorRejecting, setError] = useState(null);

    const onReject = useCallback(
        (url, urlState) => {
            setRejecting(true);
            setError(null);
            ServiceManager.portfolioManagement('patchPortfolioProposal', [
                clientId,
                Number(proposalId),
                {
                    PortfolioProposalStatusId: {
                        DoUpdate: true,
                        Value: 4,
                    },
                },
            ])
                .then(() => {
                    try {
                        setRejecting(false);
                        history.push(url, urlState);
                    } catch (errPatch) {
                        setRejecting(false);
                        setError(errPatch);
                        throw new AdapterError(errPatch);
                    }
                })
                .catch((errPatch) => {
                    setRejecting(false);
                    setError(errPatch);
                });
        },
        [clientId, portfolioId, proposalId],
    );

    return { onReject, isRejecting, errorRejecting };
};
