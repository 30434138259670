import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui-library';
import { formatDate, yearFormat } from 'utils/datetime';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { getComparePositionsColumns } from './constants';
import { useComparePortfolio } from './hooks/useComparePortfolio';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';

function ComparePortfolioPositions(props) {
    const { contactId, onPageChange, location: { pathname } } = props;
    const { t } = useTranslation();

    // OnBoarding Domain
    const {
        getGoalDetails, product,
    } = useOnBoardingSelector(onBoardingDataSelector);

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate, formatDate],
    );
    const positionLink = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;
    const options = useMemo(
        () => ({
            productId: +product.id,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            projectionYears,
            positionLink,
            nameLength: 50,
        }),
        [
            product.id,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            positionLink,
        ],
    );

    // Helpers hooks
    const { data, isLoading, error } = useComparePortfolio(contactId, +product.id, options);

    // Callbacks
    const onPrev = () => {
        onPageChange('');
    };
    const onContinue = () => {
        onPageChange('compare');
    };

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('onBoarding.enter3rdPartyPortfolioDetails')}`}
            error={error}
            isLoading={isLoading}
            className="goal-review on-boarding_modify"
            prevButton={{
                onClick: onPrev,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                disabled: isLoading,
                onClick: onContinue,
            }}
        >
            <div className="modify-goal_content">
                <Table
                    className="positions-table"
                    data={data.positions}
                    columns={getComparePositionsColumns(t)}
                    expandedColumn="name"
                    defaultExpandAllRows
                />
            </div>
        </OnBoardingBaseTemplate>
    );
}

ComparePortfolioPositions.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ComparePortfolioPositions.defaultProps = {
    onPageChange: () => { },
};

export default ComparePortfolioPositions;
