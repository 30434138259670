import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExternalNews from './pages/ExternalNews';
import Announcements from './pages/Announcements';
import SingleNewsView from './pages/Announcements/SingleNewsView';
import Research from './pages/Research';
import ResearchDetails from './pages/Research/ResearchDetails';

const Router = (props) => {
    const {
        match: { path },
    } = props;

    return (
        <Switch>
            <Route exact path={`${path}/announcements`} component={Announcements} />
            <Route exact path={`${path}/announcements/:newsId`} component={SingleNewsView} />
            <Route exact path={`${path}/research`} component={Research} />
            <Route exact path={`${path}/research/:researchId`} component={ResearchDetails} />
            <Route exact path={`${path}/external`} component={ExternalNews} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
