import React from 'react';
import { Button, Infobox } from 'ui-library';
import PropTypes from 'prop-types';
import './InfoboxWithButton.css';

const InfoboxWithButton = ({
    children, buttonText, buttonProps, ...rest
}) => (
    <Infobox {...rest} className="infobox-with_button" warning>
        <div className="infobox__left">
            {children}
        </div>
        <div className="infobox__right">
            <Button size="small" type="primary" {...buttonProps}>
                {buttonText}
            </Button>
        </div>
    </Infobox>
);

InfoboxWithButton.propTypes = {
    buttonProps: PropTypes.shape({
        type: PropTypes.string,
        onClick: PropTypes.func,
    }),
    buttonText: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

InfoboxWithButton.defaultProps = {
    buttonProps: {},
    buttonText: null,
    children: null,
};

export default InfoboxWithButton;
