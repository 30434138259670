import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { MODIFY } from '../../SinglePortfolioView/pages/PositionDetails/constants';

const editAllocationsForExecute = (data) => data.map(({ Allocation, InstrumentId }) => ({
    Allocation,
    InstrumentId,
}));

const executeProcessByAction = (action, clientId, portfolioId, params) => {
    if (action === MODIFY) {
        return new Promise((resolve, reject) => {
            ServiceManager.portfolioManagement('postTradingCartOrder', [clientId, portfolioId])
                .then(() => {
                    ServiceManager.portfolioManagement('deleteTradingCartOrder', [
                        clientId,
                        portfolioId,
                    ])
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => reject(error));
                })
                .catch((error) => reject(error));
        });
    }

    return ServiceManager.portfolioManagement('postPortfolioRebalance', [
        clientId,
        portfolioId,
        params,
    ]);
};

export const useExecuteStrategy = (clientId, portfolioId, actionType) => {
    const [isAccepting, setAccepting] = useState(false);
    const [errorAccepting, setError] = useState(null);

    const { t } = useTranslation();

    const onAcceptExecute = useCallback(
        ({ modelPortfolioId, allocations }) => {
            setAccepting(true);
            setError(null);

            const params = {
                ModelPortfolioId: allocations ? null : modelPortfolioId,
                InstrumentAllocations: allocations ? editAllocationsForExecute(allocations) : [],
            };

            executeProcessByAction(actionType, clientId, portfolioId, params)
                .then(() => {
                    try {
                        setAccepting(false);
                        history.push('/success-page', {
                            title: t('confirm.title'),
                            message: t('confirmation.acceptExecuteContent'),
                            confirmButton: t('confirmation.backToPortfolio'),
                            redirectURL: `/portfolios/${portfolioId}`,
                        });
                    } catch (err) {
                        setAccepting(false);
                        setError(err);
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setAccepting(false);
                    setError(err);
                });
        },
        [clientId, portfolioId],
    );

    return { onAcceptExecute, isAccepting, errorAccepting };
};
