export const adaptAnswers = (data, values, isEmptyGroup = false) => ({
    ...data,
    Groups: data.Groups.map((group) => ({
        ...group,
        Steps: group.Steps.map((step) => ({
            ...step,
            Questions: step.Questions.map((question) => {
                const answer = values?.[group.Id]?.[question.Id];
                let answerToSet;

                if (typeof answer === 'string') answerToSet = { Value: answer };
                else if (typeof answer === 'number') answerToSet = { Ids: [answer] };
                else if (!answer && isEmptyGroup) answerToSet = { Ids: [] };
                else answerToSet = { Ids: answer };

                return answer || (!answer && isEmptyGroup)
                    ? { ...question, Value: answerToSet } : question;
            }),
        })),
    })),
});
