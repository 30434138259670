import { useContext } from 'react';
import ClarityContext from './context';

const useSelector = (callback) => {
    const { getValue } = useContext(ClarityContext);

    if (getValue === undefined) {
        throw new Error('useClarity can only be used inside ClarityContext');
    }

    return getValue(callback);
};

export default useSelector;
