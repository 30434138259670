import React from 'react';
import PropTypes from 'prop-types';
import { renderers } from 'ui-library';
import './CellLink.css';
import { getClassNames } from 'utils';

const { CellLinked } = renderers;

function CellLink({
    label, link, state, disabled,
}) {
    const className = getClassNames('table-cell_checkboxed', {
        'table-cell_disabled': disabled,
    });

    return (
        <span className={className}>
            {CellLinked({
                value: label,
                link: { pathname: link, state },
            })}
        </span>
    );
}

CellLink.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    state: PropTypes.shape({}),
    disabled: PropTypes.bool,
};

CellLink.defaultProps = {
    label: '',
    link: '',
    state: {},
    disabled: false,
};

export default CellLink;
