import React from 'react';
import { Table, Title } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageSection from 'components/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Paragraph from 'ui-library/dist/components/Paragraph';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { reviewPortfoliosColumns } from './constants';
import { useReviewPortfolios } from './hooks/useReviewPortfolios';
import './ReviewPortfolios.css';

function ReviewPortfolios({ riskName }) {
    const { t } = useTranslation();
    const clientId = useSelector(contactIdSelector);
    const { data, isLoading, error } = useReviewPortfolios({ clientId, riskName });

    return (
        <PageSection>
            <div className="review-portfolios">
                <div className="review-portfolios-table">
                    <div className="header-with-description">
                        <Title type={2}>{t('riskProfileReview.portfolios.title')}</Title>
                        <Paragraph type="secondary">{t('riskProfileReview.portfolios.description')}</Paragraph>
                    </div>
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data} text={t('portfolios.noPortfoliosFound')}>
                            <Table
                                data={data}
                                columns={reviewPortfoliosColumns(t)}
                            />
                        </EmptyContent>
                    </Preloader>
                </div>
            </div>
        </PageSection>
    );
}

ReviewPortfolios.propTypes = {
    riskName: PropTypes.string,
};

ReviewPortfolios.defaultProps = {
    riskName: undefined,
};

export default ReviewPortfolios;
