/* eslint-disable import/no-cycle */
import { get } from 'lodash/fp';
import {
    CLEAN_ENROLL,
    DEVICE_ENROLL_FAIL,
    DEVICE_ENROLL_SUCCESS,
    ENROLL_FAIL,
    ENROLL_START,
    ENROLL_SUCCESS,
    DEVICE_ENROLL_START,
    UNENROLL_START,
    UNENROLL_SUCCESS,
    UNENROLL_FAIL,
} from './enrollActions';
import {
    GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_START,
    GET_USER_PROFILE_SUCCESS,
} from '../auth/authActions';

const defaultState = {
    isLoading: 0,
    isEnrollDeviceSuccess: false,
    isEnrollSuccess: false,
    isUnenrollSuccess: false,
    isEnrollUnsuccess: false,
    isUnenrollUnsuccess: false,
};

const initState = defaultState;

export default function reducer(state = initState, action = {}) {
    switch (action.type) {
        case CLEAN_ENROLL:
            return {
                ...initState,
            };
        case DEVICE_ENROLL_START:
            return {
                ...state,
            };
        case DEVICE_ENROLL_SUCCESS:
            return {
                ...state,
                qrCode: undefined,
                isEnrollDeviceSuccess: true,
            };
        case DEVICE_ENROLL_FAIL:
            return {
                ...state,
                error: action.payload,
                isEnrollDeviceSuccess: false,
                isEnrollUnsuccess: true,
            };
        case ENROLL_START:
            return {
                ...state,
                isLoading: state.isLoading + 1,
                isEnrollDeviceSuccess: false,
                isEnrollSuccess: false,
                isUnenrollSuccess: false,
                isEnrollUnsuccess: false,
                isUnenrollUnsuccess: false,
                error: undefined,
            };
        case ENROLL_SUCCESS:
            return {
                ...state,
                qrCode: get('AsynchronousParameters.ActivationQrCodeUrl', action.payload),
                isLoading: state.isLoading - 1,
                isEnrollSuccess: true,
            };
        case ENROLL_FAIL:
            return {
                ...state,
                error: action.payload,
                isEnrollSuccess: false,
                isLoading: state.isLoading - 1,
                isEnrollUnsuccess: true,
            };
        case UNENROLL_START:
            return {
                ...state,
                isLoading: state.isLoading + 1,
                isEnrollDeviceSuccess: false,
                isEnrollSuccess: false,
                isUnenrollSuccess: false,
                isEnrollUnsuccess: false,
                isUnenrollUnsuccess: false,
                error: undefined,
            };
        case UNENROLL_SUCCESS:
            return {
                ...state,
                isLoading: state.isLoading - 1,
                isUnenrollSuccess: true,
            };
        case UNENROLL_FAIL:
            return {
                ...state,
                error: action.payload,
                isUnenrollSuccess: false,
                isUnenrollUnsuccess: false,
                isLoading: state.isLoading - 1,
            };
        case GET_USER_PROFILE_START:
            return {
                ...state,
                isLoading: state.isLoading + 1,
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: state.isLoading - 1,
            };
        case GET_USER_PROFILE_FAIL:
            return {
                ...state,
                isLoading: state.isLoading - 1,
            };
        default:
            return state;
    }
}
