import React from 'react';
import PropTypes from 'prop-types';
import Passcode from '../LoginPasscode/LoginPasscodeContainer';

import QRCode from '../LoginQRCode/LoginQRCode';
import Approve from '../LoginApprove/LoginApprove';
import { APPROVE, PASSCODE, QR_CODE } from '../loginTypes';

export const TwoFASwitcher = ({
    onSuccess, onOfflineMode, factor, provider, transactionProps,
}) => {
    if (factor === PASSCODE) {
        return <Passcode {...transactionProps} onSuccess={onSuccess} provider={provider} />;
    }
    if (factor === QR_CODE) {
        return <QRCode onSuccess={onSuccess} activateOfflineMode={onOfflineMode} />;
    }
    if (factor === APPROVE) {
        return <Approve onSuccess={onSuccess} activateOfflineMode={onOfflineMode} />;
    }

    return null;
};

TwoFASwitcher.propTypes = {
    transactionProps: PropTypes.shape({
        isTrustedDevice: PropTypes.bool,
    }),
    onOfflineMode: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    factor: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};

TwoFASwitcher.defaultProps = {
    transactionProps: {
        isTrustedDevice: false,
    },
};

TwoFASwitcher.displayName = 'TwoFASwitcher';
