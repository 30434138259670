import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { object, string } from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';

export const useDomesticForm = ({ defaultData, defaultShowBankDetails = false } = {}) => {
    const { t } = useTranslation();
    const [showBankDetails, setShowBankDetails] = useState(defaultShowBankDetails);

    const schema = useMemo(() => object().shape({
        iban: string().required(t('payments.validation.iban')),
        bankName: showBankDetails ? string().required(t('payments.validation.bankName')) : string(),
        bankPostCode: showBankDetails ? string().required(t('payments.validation.postCode')) : string(),
        bankCity: showBankDetails ? string().required(t('payments.validation.city')) : string(),
        bankCountry: showBankDetails ? object().required(t('payments.validation.country')) : object(),
        payeeName: string().required(t('payments.validation.payeeName')),
        currency: object().required(t('payments.validation.currency')),
        amount: string().required(t('payments.validation.amount')),
        executionDate: string(),
        personalNote: string(),
        messageForPayee: string(),
        debitAccount: object().required(t('payments.validation.debitAccount')),
    }), [t, showBankDetails]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, control, watch, formState, trigger,
    } = useForm({ resolver, reValidateMode: 'onChange', shouldUnregister: false });

    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset(defaultData);
        }
    }, [reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        setValue(type, value);
        if (formState.isSubmitted) trigger(type);
    }, [setValue, trigger, formState.isSubmitted]);

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
        showBankDetails,
        setShowBankDetails,
        reset,
    };
};
