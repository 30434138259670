import AdapterError from 'errors/AdapterError';
import { passwordPolicies } from '../policies/password';

export const buildPolicyErrorMessage = (ValidationResultPolices, t) => {
    const errorPolicies: Array<string> = ValidationResultPolices.reduce((accu, policy) => {
        if (policy.IsValid) return accu;
        const passwordPolicy = passwordPolicies.find(
            (item) => (item.Name === policy.Policy),
        );

        if (passwordPolicy === undefined) {
            throw new AdapterError('Password Policy issue: Unknown policy. Please cover all policies.');
        }

        accu.push(t(passwordPolicy.Description));

        return accu;
    }, []);

    return errorPolicies.join(', ');
};
