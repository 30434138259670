import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    ContentBox,
    Title,
    BreadCrumbs,
    Modal,
    Accordion,
    AccordionPanel as Panel,
    Table, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { contactIdSelector } from 'redux/auth/authSelectors';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import WidgetError from 'components/WidgetError';
import Preloader from 'components/Preloader';
import { portfolioSelector, usePortfolioSelector, proposalSelector } from 'domain/Portfolio';
import AccordionWrapper from 'components/AccordionWrapper';
import { NA } from 'utils/formatting';
import { getDocumentsColumns } from '../../../common/constants';
import { useStrategyDocuments } from '../../../../hooks/useStrategyDocuments';
import { onPreventRedirect } from '../../../common/utils';
import Transactions from '../../../common/components/Transactions';
import { useSecurityTransactions } from '../../../common/hooks/useSecurityTransactions';
import { PROPOSAL } from '../PositionDetails/constants';
import { useAcceptProposal } from './hooks/useAcceptProposal';
import { useRejectProposal } from './hooks/useRejectProposal';

function DetailedOrders(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId, proposalId } },
    } = props;
    const { t } = useTranslation();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: proposal, isLoading: isLoadingProposal, error: errorProposal,
        getProposalDetails,
    } = usePortfolioSelector(proposalSelector);
    const adaptOptions = useMemo(() => ({
        currency: dataRaw?.Currency,
        portfolioValue: data?.portfolioValue,
        securitiesValue: data?.securitiesValue,
    }), [dataRaw.Currency, data?.securitiesValue, data?.portfolioValue]);

    useEffect(() => {
        if (adaptOptions?.currency) {
            getProposalDetails(proposalId, { adaptOptions });
        }
    }, [getProposalDetails, adaptOptions, proposalId]);

    // Hooks
    const {
        securityTransactionsData,
        isLoadingSecurityTransactions,
        errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: proposal?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        actionType: PROPOSAL,
        proposalId,
    });
    const { documents, errorDocuments, isLoadingDocuments } = useStrategyDocuments(
        data.productId,
    );
    const {
        onAccept, isAccepting, errorAccepting,
    } = useAcceptProposal(clientId, portfolioId, proposalId);
    const {
        onReject, isRejecting, errorRejecting,
    } = useRejectProposal(clientId, portfolioId, proposalId);

    // Callbacks
    const baseURL = `/portfolios/${portfolioId}`;
    const onCancel = (url) => {
        Modal.confirm({
            title: t('proposal.backToPortfolio'),
            content: t('proposal.backToPortfolioContent'),
            okText: t('proposal.backToPortfolio'),
            onOk: () => {
                history.push(url);
            },
            cancelText: t('proposal.cancel'),
            className: 'discard-changes',
        });
    };
    const onBack = () => {
        history.push(`${baseURL}/proposal/${proposalId}`);
    };
    const onRejectProposal = () => {
        Modal.confirm({
            title: t('proposal.rejectProposal'),
            content: t('proposal.rejectProposalContent'),
            okText: t('proposal.reject'),
            onOk: () => {
                onReject(`/portfolios/${portfolioId}/proposal/${proposalId}/reject-success`, {
                    title: proposal.title || NA,
                });
            },
            cancelText: t('proposal.cancel'),
            className: 'discard-changes',
        });
    };
    const onExecuteChanges = () => {
        Modal.confirm({
            title: t('confirmation.executeChanges'),
            content: t('confirmation.executeChangesContent'),
            okText: t('confirmation.executeChanges'),
            onOk: () => {
                onAccept(clientId, portfolioId, proposalId);
            },
            cancelText: t('confirmation.cancel'),
            className: 'execute',
        });
    };

    return (
        <Preloader isLoading={isLoading || isLoadingProposal} error={error || errorProposal}>
            <ContentBox className="change-strategy with-mb" underline={false}>
                <BreadCrumbs current={t('confirmation.ordersToBeExecuted')}>
                    <Link
                        to="/portfolios"
                        onClick={(e) => onPreventRedirect(e, '/portfolios', onCancel)}
                    >
                        {t('portfolios.title')}
                    </Link>
                    <Link
                        to={baseURL}
                        onClick={(e) => onPreventRedirect(e, baseURL, onCancel)}
                    >
                        {data.title}
                    </Link>
                    <Link to={`${baseURL}/proposal/${proposalId}`}>
                        {t('proposal.reviewProposal')}
                    </Link>
                </BreadCrumbs>
                <Title type={1}>
                    {t('confirmation.ordersToBeExecuted')}
                </Title>
                <AccordionWrapper className="confirmation">
                    <Accordion defaultActiveKey={['1', '2']}>
                        <Panel
                            header={t('confirmation.ordersToBeExecuted')}
                            className="security-transactions"
                            key="1"
                        >
                            <Transactions
                                data={securityTransactionsData}
                                isLoading={isLoadingSecurityTransactions}
                                error={errorSescurityTransactions}
                                defaultExpandAllRows
                            />
                        </Panel>
                        <Panel
                            header={t('confirmation.documents')}
                            className="documents"
                            key="2"
                        >
                            <Preloader
                                isLoading={isLoadingDocuments}
                                error={errorDocuments}
                            >
                                <Table columns={getDocumentsColumns(t)} data={documents} />
                            </Preloader>
                        </Panel>
                    </Accordion>
                    {errorAccepting && (
                        <WidgetError accent error message={errorAccepting.message} />
                    )}
                </AccordionWrapper>
                {errorRejecting && <Infobox error>{errorRejecting.message}</Infobox>}
                {errorAccepting && <Infobox error>{errorAccepting.message}</Infobox>}
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        loading: isAccepting || isRejecting,
                        onClick: onBack,
                    }}
                    additionalButton={{
                        type: 'danger',
                        text: t('proposal.reject'),
                        loading: isAccepting || isRejecting,
                        onClick: onRejectProposal,
                    }}
                    primaryButton={{
                        text: t('proposal.accept'),
                        loading: isAccepting || isRejecting,
                        onClick: onExecuteChanges,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
            proposalId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

DetailedOrders.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(DetailedOrders);
