import React from 'react';
import {
    Label as LabelUI, Paragraph, Toggle,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import './ConfirmationDisclaimer.css';
import PropTypes from 'prop-types';

const ConfirmationDisclaimer = ({
    text, onChange, value, error, agreeOnText,
}) => {
    const { t } = useTranslation();

    return (
        <div className="confirmation-disclaimer">
            <Paragraph type="secondary">{text}</Paragraph>
            <LabelUI label={t('confirmation.confirmation')} />
            <div className="toggle-block">
                <Toggle
                    value={value}
                    onChange={onChange}
                />
                <span>{agreeOnText}</span>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

ConfirmationDisclaimer.propTypes = {
    value: PropTypes.shape({
        [PropTypes.number]: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]),
    }),
    error: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    agreeOnText: PropTypes.string,
};

ConfirmationDisclaimer.defaultProps = {
    value: undefined,
    error: undefined,
    text: '',
    agreeOnText: '',
    onChange: () => {},
};

export default ConfirmationDisclaimer;
