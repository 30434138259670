export const $_GET = (parameterName) => {
    let result = null;
    let tmp = [];
    const items = global.location.search.substr(1).split('&');

    for (let index = 0; index < items.length; index += 1) {
        tmp = items[index].split('=');
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }

    return result;
};
