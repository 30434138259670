import moment from 'moment';

import { isWeekendDay } from 'utils/datetime';

export const executionDateDefault = () => {
    const nextDay = moment().add(1, 'day');

    if (isWeekendDay(nextDay)) {
        if (moment().day() === 5) {
            return moment().add(3, 'day');
        }
        if (moment().day() === 6) {
            return moment().add(2, 'day');
        }
        if (moment().day() === 0) {
            return moment().add(1, 'day');
        }
    }

    return nextDay;
};
