import { NA, validateNumber } from 'utils/formatting';
import { getDatesDifference, subtractUnits } from 'utils/datetime';
import { parseXml } from 'utils/xmlParser';

export const adaptMetrics = ({
    data, additionalData, t = (key) => key, language,
}) => {
    const currency = data?.currency?.isoCode || NA;
    const optionType = data?.securityOptionExtension?.isPut;
    const strikePrice = data?.securityOptionExtension?.optionStrike;
    const moneyness = data?.securityOptionExtension?.isPut !== undefined
    && additionalData?.tradingPriceOfUnderlying && strikePrice
        ? ((-1) ** +(data?.securityOptionExtension?.isPut))
        * (additionalData?.tradingPriceOfUnderlying - strikePrice)
        : NA;
    const delta = 0.65; // TODO: remove these mock data for type Option (AT-1258)
    const leverage = data?.tradingPrice // TODO: remove these mock data for type Option (AT-1258)
    && additionalData?.tradingPriceOfUnderlying
        ? (delta * additionalData?.tradingPriceOfUnderlying) / data?.tradingPrice : NA;
    const riskCategory = parseXml(data?.riskCategory?.name)?.root?.[language]?.['#text'];
    const fundDomicile = parseXml(data?.country?.name)?.root?.[language]?.['#text'];

    return {
        currency,
        riskCategory: riskCategory ?? NA,
        uId: data?.uId,
        name: data?.name ?? NA,
        typeId: data?.typeId,
        expectedRisk: additionalData?.calculatedRisk ? additionalData?.calculatedRisk * 100 : NA,
        expectedReturn: additionalData?.calculatedExpectedReturn
            ? additionalData?.calculatedExpectedReturn * 100 : NA,

        // Equity
        expReturn: additionalData?.calculatedExpectedReturn
            ? validateNumber(additionalData?.calculatedExpectedReturn) * 100
            : NA,
        expRisk: additionalData?.calculatedRisk
            ? validateNumber(additionalData?.calculatedRisk) * 100 : NA,
        sharpeRatio: additionalData?.sharpeRatio ? validateNumber(additionalData?.sharpeRatio) : NA,
        analystRating: data?.securityRatings?.[0]?.rating?.value ?? NA,
        // TODO: remove these mock data for type Equity
        analystPriceTarget: data?.tradingPrice ? Math.round(
            (data ? validateNumber(data?.tradingPrice) : 0) * 1.1,
        ) : NA,
        earningPerShare: data?.tradingPrice
            ? validateNumber(data?.tradingPrice) * 0.05 : NA,
        marketCap: data?.tradingPrice
            ? validateNumber(data?.tradingPrice) * 100000000 : NA,
        peRatio: 20.00,
        dividendYield: 3.00,

        // Bond
        creditRating: data?.securityRatings?.[0]?.rating?.value ?? NA,
        nominalValue: data?.tradingUnit
            ? validateNumber(data?.tradingUnit) : NA,
        coupon: data?.securityBondExtension?.coupon
            ? validateNumber(data?.securityBondExtension.coupon) * 100 : NA,
        couponFrequency: data?.securityBondExtension?.bondCouponFrequency?.name ?? NA,
        bondMaturityDate: data?.securityBondExtension?.maturityDate ?? NA,
        duration: data?.securityBondExtension?.maturityDate
            ? getDatesDifference(data?.securityBondExtension.maturityDate, new Date(), 'years') * 0.85
            : NA,
        issuerType: data?.securityBondExtension?.issuerType?.name ?? NA,
        // TODO: remove these mock data for type Bond
        couponType: t('security.mockData.couponType.fixed'),
        accruedInterest: data?.tradingUnit
            ? validateNumber(data?.tradingUnit) * 0.1 : NA,
        yieldToMaturity: data?.securityBondExtension?.coupon
            ? data?.securityBondExtension.coupon * 1.1 * 100 : NA,
        issueDate: data?.securityBondExtension?.maturityDate
            ? subtractUnits(data?.securityBondExtension.maturityDate, 10, 'years') : NA,

        // Fund
        fundCurrency: currency,
        // TODO: remove these mock data for type Fund (AT-1256)
        beta: 1.10,
        TER: 0.75,
        fundVolume: 100000000,
        fundDomicile: fundDomicile ?? NA,


        // Option
        strikePrice: strikePrice
            ? validateNumber(strikePrice) : NA,
        optionType: optionType ?? NA,
        style: data?.securityOptionExtension?.optionExerciseType?.name ?? NA,
        underlying: additionalData?.name ?? NA,
        optionMaturityDate: data?.securityOptionExtension?.maturityDate ?? NA,
        moneyness,
        volatilityOfUnderlying: additionalData?.calculatedRiskOfUnderlying !== undefined
            ? validateNumber(additionalData.calculatedRiskOfUnderlying) * 100 : NA,
        // TODO: remove these mock data for type Option (AT-1258)
        settlementTerms: t('security.mockData.settlementTerms.physical'),
        delta,
        leverage,

        // Future
        futureMaturityDate: data?.securityFutureExtension?.maturityDate ?? NA,
        // TODO remove these mock data for type Future (AT-1257)
        initialMargin: 0.1 * 100,
        maintenanceMargin: 0.1 * 100,

        // Money market
        // TODO remove these mock data for type Money market (AT-1253)
        netAssetValue: 1.01,
        moneyMarketCoupon: 0.0225 * 100,
        moneyMarketType: t('security.mockData.moneyMarketType.T-Bill'),
        moneyMarketMaturityDate: '27-01-2021',
        moneyMarketYieldToMaturity: 1.1 * 0.0225 * 100,

        // Structured Product
        // TODO remove these mock data for type Structured Product (AT-1260)
        structuredProductCoupon: 0.0645 * 100,
        premiumComponent: 0.9 * 0.0645 * 100,
        interestShare: (0.0645 - (0.9 * 0.0645)) * 100,
        structuredProductPricing: t('security.mockData.structuredProductPricing.clean'),
        initialFixingDate: '20-01-2020',
        paymentDate: '27-01-2020',
        redemptionDate: '27-01-2021',
        barrierReached: t('security.mockData.barrierReached.no'),
    };
};
