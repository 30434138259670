import {
    contactIdSelector,
    passcodeLengthSelector,
    passwordSelector,
    usernameSelector,
    preAuthenticateSessionIdSelector,
} from 'redux/auth/authSelectors';
import { logout, status2FASuccess } from 'redux/auth/authActions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LoginPasscode } from './LoginPasscode';

const mapStateToProps = (state) => ({
    username: usernameSelector(state),
    password: passwordSelector(state),
    contactId: contactIdSelector(state),
    passcodeLength: passcodeLengthSelector(state),
    preAuthenticateSessionId: preAuthenticateSessionIdSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onPasscodeSuccess: (data) => dispatch(status2FASuccess(data)),
    onLogin: () => {
        dispatch(logout());
    },
});

const Translated = withTranslation()(LoginPasscode);

export default connect(mapStateToProps, mapDispatchToProps)(Translated);
