import { useEffect, useMemo, useState } from 'react';
import { useModelPortfolio } from 'domain/Portfolio';
import {
    goalCreationSelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';

export const useBaseModelPortfolio = () => {
    const [riskCategory, setRiskCategory] = useState(null);

    // OnBoarding Domain
    const {
        getRiskCategory, getGoalDetails, product,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const { getModelPortfolioId } = useOnBoardingSelector(goalCreationSelector);

    // Portfolio Domain
    const {
        dataRaw, data, isLoading, error, getModelPortfolio,
    } = useModelPortfolio();

    useEffect(() => {
        (async () => {
            const risk = await getRiskCategory();

            setRiskCategory(risk);
            const riskCategoryId = risk?.Id;
            const modelObj = await getModelPortfolioId(
                { productId: +product?.id, riskCategoryId },
            );

            getModelPortfolio(modelObj?.Id,
                { currencyId: goalDetails.selectedCurrency?.value });
        })();
    }, [
        getModelPortfolio,
        getModelPortfolioId,
        getRiskCategory,
        goalDetails.selectedCurrency,
        product?.id,
    ]);

    return {
        riskCategory,
        dataRaw,
        data,
        isLoading,
        error,
    };
};
