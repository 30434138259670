import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from './utils/Backend';
import SessionStoreManager from './utils/SessionStoreManager';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const RESOURCE_NAME = 'Web-Client-Cockpit';

i18n
    // load translation using /resourcesets/{name}/?language={language}
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        keySeparator: false,
        locale: 'US',
        react: {
            wait: true,
        },
        backend: {
            resourceName: RESOURCE_NAME,
            SaveManager: SessionStoreManager,
        },
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
