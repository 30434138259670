import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    ContentBox,
    Modal,
    Accordion,
    AccordionPanel as Panel,
    Table,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { contactIdSelector } from 'redux/auth/authSelectors';
import WidgetError from 'components/WidgetError';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import { CHANGE_MODEL, CHANGE_STRATEGY } from 'constants/constants';
import { portfolioSelector, useModelPortfolio, usePortfolioSelector } from 'domain/Portfolio';
import AccordionWrapper from 'components/AccordionWrapper';
import PageHeader from 'components/PageHeader';
import { useModelChange } from 'hooks/useModelChange';
import { useChangeStrategyConfig } from 'hooks/useChangeStrategyConfig';
import { getDocumentsColumns } from '../../common/constants';
import { useStrategyDocuments } from '../../../hooks/useStrategyDocuments';
import { onPreventRedirect } from '../../common/utils';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import Transactions from '../../common/components/Transactions';
import { useSecurityTransactions } from '../../common/hooks/useSecurityTransactions';
import withActionAccess from '../../../hocs/withActionAccess';

function DetailedOrders(props) {
    const {
        contactId: clientId,
        match: {
            params: { portfolioId },
        },
    } = props;
    const { t } = useTranslation();
    const modelId = JSON.parse(sessionStorage.getItem(CHANGE_MODEL));

    // Portfolio Domain
    const { data, isLoading, error } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();

    const { showDocuments } = useChangeStrategyConfig();

    useEffect(() => {
        getModelPortfolio(modelId);
    }, [getModelPortfolio, modelId]);

    // Hooks
    const { onModelReset } = useModelChange();
    const {
        securityTransactionsData,
        isLoadingSecurityTransactions,
        errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: modelPortfolio?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        actionType: CHANGE_STRATEGY,
    });
    const { documents, errorDocuments, isLoadingDocuments } = useStrategyDocuments(
        data.productId,
    );
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId,
        portfolioId,
        CHANGE_STRATEGY,
    );

    // Callbacks
    const baseURL = `/portfolios/${portfolioId}`;
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangeStrategyContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onBack = () => {
        history.push(`${baseURL}/actions/change-strategy`);
    };
    const onExecuteChanges = () => {
        const executeParams = { modelPortfolioId: Number(modelId) };

        Modal.confirm({
            title: t('confirmation.executeChanges'),
            content: t('confirmation.executeChangesContent'),
            okText: t('confirmation.executeChanges'),
            onOk: () => {
                onStrategyReset();
                onAcceptExecute(executeParams);
            },
            cancelText: t('confirmation.cancel'),
            className: 'execute',
        });
    };

    return (
        <ContentBox className="change-strategy">
            <Preloader isLoading={isLoading || isLoadingModel} error={error || errorModel}>
                <PageHeader
                    title={t('confirmation.ordersToBeExecuted')}
                    breadCrumbsCurrent={t('confirmation.ordersToBeExecuted')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                            label: t('portfolios.title'),
                        },
                        {
                            to: baseURL,
                            onClick: (e) => onPreventRedirect(e, baseURL, onCancel),
                            label: data.title,
                        },
                        {
                            to: `${baseURL}/actions/change-strategy`,
                            label: t('overview.changeStrategy'),
                        },
                    ]}
                />
                <AccordionWrapper className="confirmation">
                    <Accordion defaultActiveKey={['1', '2']}>
                        <Panel
                            header={t('confirmation.ordersToBeExecuted')}
                            className="security-transactions"
                            key="1"
                        >
                            <Transactions
                                data={securityTransactionsData}
                                isLoading={isLoadingSecurityTransactions}
                                error={errorSescurityTransactions}
                                defaultExpandAllRows
                            />
                        </Panel>
                        {showDocuments && (
                            <Panel
                                header={t('confirmation.documents')}
                                className="documents"
                                key="2"
                            >
                                <Preloader
                                    isLoading={isLoadingDocuments}
                                    error={errorDocuments}
                                >
                                    <Table columns={getDocumentsColumns(t)} data={documents} />
                                </Preloader>
                            </Panel>
                        )}
                    </Accordion>
                    {errorAccepting && (
                        <WidgetError accent error message={errorAccepting.message} />
                    )}
                </AccordionWrapper>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: onBack,
                    }}
                    primaryButton={{
                        text: t('confirmation.execute'),
                        loading: isAccepting,
                        onClick: onExecuteChanges,
                    }}
                />
            </Preloader>
        </ContentBox>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

DetailedOrders.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(withActionAccess(DetailedOrders));
