// eslint-disable-next-line no-underscore-dangle
const isCancel = (error) => Object.getPrototypeOf(error)?.__CANCEL__;

class ErrorsManager {
    constructor() {
        if (ErrorsManager.instance) {
            return ErrorsManager.instance;
        }

        ErrorsManager.instance = this;
        this.DFSLoggerConfig = { enabled: false };

        return ErrorsManager.instance;
    }

    configure(config) {
        const {
            log,
            DFSLoggerConfig = { enabled: false },
        } = config;

        this.log = log;
        this.DFSLoggerConfig = DFSLoggerConfig;
    }

    async logToDFS(errorModel) {
        const { enabled, endpointFunc } = this.DFSLoggerConfig;

        if (enabled !== true || typeof endpointFunc !== 'function') {
            return;
        }

        const { message, stack } = errorModel;

        try {
            const messageObj = { message, stack };

            await endpointFunc({ type: 'Error', message: JSON.stringify(messageObj) });
        } catch (errLogToDFS) {
            const styles = {
                title: 'color: brown; font-size: 16px; font-weight: bold;',
                stack: 'color: brown',
            };

            console.error(`%c⚠️ ErrorsManager: %c error with logToDFS - ${errLogToDFS}`, styles.title, styles.stack);
        }
    }

    createError({ type, error, messageCustom }) {
        const { message, stack } = error;
        const errorModel = {
            type,
            message: `${type ? `${type}: ` : ''}${messageCustom || message}`,
            stack,
            error,
            messageCustom,
        };

        if (isCancel(error)) {
            return null;
        }
        if (typeof this.log === 'function') {
            this.log(errorModel);
        }
        this.logToDFS(errorModel);

        return errorModel;
    }
}

const ErrorsManagerInstance = new ErrorsManager();

export default ErrorsManagerInstance;
