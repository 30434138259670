import { CellPDFView, CellLink } from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';

export default (t, isMobileSize) => [
    {
        key: 'nameCol',
        title: t('clientDashboard.documents.name'),
        width: 200,
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('nameCol.value'),
    },
    {
        key: 'type',
        title: t('clientDashboard.documents.type'),
        sorter: sorterString('type'),
        width: 180,
        hidden: isMobileSize,
    },
    {
        key: 'category',
        title: t('clientDashboard.documents.category'),
        sorter: sorterString('category'),
        width: 180,
        hidden: isMobileSize,
    },
    {
        key: 'createDate',
        title: t('clientDashboard.documents.createDate'),
        width: 160,
        minWidth: 60,
        sorter: sorterByDate('fullDate'),
        sortable: true,
        className: 'table-cell__date',
    },
    {
        key: 'contractId',
        title: t('clientDashboard.documents.accountPortfolio'),
        sorter: sorterString('contractId.label'),
        render: CellLink,
        sortable: true,
        width: 160,
        hidden: isMobileSize,
    },
    {
        key: 'icon',
        title: '',
        titleType: 'columns',
        width: 30,
        minWidth: 30,
    },
];
