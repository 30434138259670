import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Infobox } from 'ui-library';
import history from 'services/history';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { formDataSelector, questionnaireSelector, useRiskProfileSelector } from 'domain/RiskProfile';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import { adaptAnswers } from 'adaptors/adaptAnswers';
import { useWithInvestmentOverview } from '../../../Dashboard/hooks/useWithInvestmentOverview';
import { RiskProfileTemplate } from '../../templates/RiskProfileTemplate';
import './RiskProfileQuestionnaire.css';

const RiskProfileQuestionnaire = (props) => {
    const {
        match: { params: { group: groupStr } },
        location: { state },
    } = props;
    const group = +groupStr;
    const clientId = useSelector(contactIdSelector);
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);

    // ProductId selection
    const {
        data: dataOverview, isLoading: isLoadingOverview, error: errorOverview,
    } = useWithInvestmentOverview({ clientId });

    // Risks Profile Domain
    const {
        data, isLoading, error, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, errorPost, postQuestionnaire,
    } = useRiskProfileSelector(questionnaireSelector);
    const {
        data: formData, initializeData, saveData,
    } = useRiskProfileSelector(formDataSelector);

    // Data
    const productId = useMemo(() => dataOverview?.productIds?.[0], [dataOverview]);
    const isNextReview = useMemo(() => state?.redoStep || data?.Groups?.length === group + 1,
        [data, group, state]);
    const groupId = useMemo(() => data?.Groups?.[group]?.Id,
        [data, group]);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(() => validateQuestionnaire({ groupId, answers: formData }),
        [formData, groupId, validateQuestionnaire]);

    // Callbacks
    const onChange = useCallback(({ questionId, value }) => {
        saveData({ groupId, questionId, value });
    }, [groupId, saveData]);
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            const answers = adaptAnswers(data, formData);

            await postQuestionnaire({ productId, answers });
            setIsSubmitted(false);
            history.push(`/risk-profile/${isNextReview ? 'review' : group + 1}`);
        }
    }, [allQuestionsFilled, data, formData, group, isNextReview, postQuestionnaire, productId]);

    // Effects
    useEffect(() => {
        if (groupId) global.scrollTo({ top: 0, behavior: 'smooth' });
    }, [groupId]);
    useEffect(() => {
        if (productId) getQuestionnaire({ productId });
    }, [getQuestionnaire, productId]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({ Id }) => Id);

            initializeData({ groupIds });
        }
    }, [data, formData, initializeData]);

    return (
        <RiskProfileTemplate groups={data?.Groups} currentGroup={group}>
            <Preloader
                isLoading={isLoading || isLoadingOverview}
                error={error || errorOverview}
            >
                <EmptyContent data={data} text={t('dashboard.noData')}>
                    <QuestionnaireGroup
                        data={data?.Groups?.[group]}
                        value={formData?.[groupId] || {}}
                        errors={isSubmitted && errors}
                        onChange={onChange}
                    />
                    <ButtonsBlockRow
                        leftButton={group > 0 && {
                            text: t('riskProfile.back'),
                            loading: isLoadingPost,
                            onClick: () => {
                                history.push(`/risk-profile/${group - 1}`);
                            },
                        }}
                        primaryButton={{
                            text: t('confirmation.continue'),
                            loading: isLoadingPost,
                            onClick: onContinue,
                        }}
                    >
                        {errorPost && <Infobox error>{errorPost.message}</Infobox>}
                    </ButtonsBlockRow>
                </EmptyContent>
            </Preloader>
        </RiskProfileTemplate>
    );
};

RiskProfileQuestionnaire.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            group: PropTypes.string,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
        }),
    }).isRequired,
};

RiskProfileQuestionnaire.defaultProps = {
};

export default RiskProfileQuestionnaire;
