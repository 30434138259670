import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PortfoliosTable from 'components/Portfolios';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { usePortfolios } from './hooks/usePortfolios';
import { useProposals } from '../../../hooks/useProposals';
import './Portfolios.css';

function Portfolios(props) {
    // eslint-disable-next-line react/prop-types
    const { contactId: clientId } = props;
    const proposalsObj = useProposals(clientId);
    const portfolios = usePortfolios(clientId, proposalsObj);

    return <PortfoliosTable {...portfolios} />;
}

Portfolios.propTypes = {
    match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
};

Portfolios.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Portfolios);
