export const groups = {
    CONTACT_MANAGEMENT: 'ContactManagement',
    AUTHENTICATION: 'Authentication',
};

export const keys = {
    // Belong to "ContactManagement" group
    IS_USER_NAME_SAME_WITH_EMAIL: 'IsUserNameSameWithEmail',
    CONTACT_ACCESS_TOKEN_LIFETIME: 'ContactAccessTokenLifetime',
};
