import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    DatePicker,
    Select, TextInput, Title, Toggle, NumberInput,
} from 'ui-library';
import { Controller } from 'react-hook-form';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {
    getDateFormat, formatDate,
    compareDates, subtractUnits, isWeekendDay,
} from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import useLocale from 'locale/useLocale';
import PropTypes from 'prop-types';

import { useInternationalForm } from './hooks/useInternationalForm';
import { usePaymentsData } from '../../hooks/usePaymentsData';
import { executionDateDefault } from '../../utils';

const InternationalForm = ({
    listCurrencies, cashAccounts, onCancel, onNext, listCountries, listCosts,
}) => {
    const { t } = useTranslation();
    const { locale, numberSeparators } = useLocale();

    const { getPayment, savePayment } = usePaymentsData();

    const defaultData = getPayment(0);

    const {
        control, formErrors, values, onChange, handleSubmit,
        register, withoutIban, setWithoutIban,
    } = useInternationalForm({ defaultData, withoutIbanDefault: defaultData?.withoutIban });

    const onSubmit = useCallback(() => {
        savePayment({ 0: { ...values, withoutIban } });
        onNext();
    }, [values, withoutIban]);

    const defaultCurrency = useMemo(() => listCurrencies?.find((item) => item.label === 'USD'), [listCurrencies]);

    useEffect(() => {
        if (!defaultData?.currency?.value) onChange('currency')(defaultCurrency);
    }, [defaultCurrency, onChange, defaultData?.currency?.value]);

    const amountSelectAfter = useMemo(() => {
        if (listCurrencies.length > 0) {
            return (
                <Controller
                    name="currency"
                    control={control}
                    render={() => (
                        <Select
                            options={listCurrencies}
                            value={values?.currency?.value}
                            error={formErrors.currency}
                            onChange={(val) => onChange('currency')(
                                listCurrencies.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrencies, control, onChange, formErrors.currency, values?.currency?.value]);

    const costOptionsList = useMemo(() => (
        withoutIban ? listCosts?.noIban : listCosts?.iban
    ), [withoutIban, listCosts]);

    const txnCostValue = useMemo(() => (
        costOptionsList.find((item) => item?.value === defaultData?.txnCost?.value)
            || costOptionsList[0]
    ), [costOptionsList, defaultData]);

    useEffect(() => onChange('txnCost')(txnCostValue), [txnCostValue]);

    return (
        <div className="InternationalForm">
            <div className="payment-content">
                <header className="on-boarding_header">
                    <Title type={2}>{t('payments.internationalPayment')}</Title>
                </header>
                <div className="international-form">
                    <div className="credit-account">
                        <div className="group-title">
                            <Title type={3}>{t('payments.creditAccount')}</Title>
                        </div>
                        <Controller
                            name="iban"
                            control={control}
                            label={t('payments.iban')}
                            labelInfobox
                            onChange={onChange('iban')}
                            error={formErrors.iban}
                            value={values.iban}
                            disabled={withoutIban}
                            required
                            as={<TextInput />}
                            rules={{ required: true }}
                        />
                        <div className="toggle-block">
                            <span>{t('payments.noIbanAvailable')}</span>
                            <Toggle
                                value={withoutIban}
                                onChange={setWithoutIban}
                            />
                        </div>
                        {withoutIban && (
                            <>
                                <div className="group-title">
                                    <Title type={3}>{t('payments.payeesBank')}</Title>
                                </div>
                                <Controller
                                    name="accountNumber"
                                    control={control}
                                    as={<TextInput />}
                                    label={t('payments.accountNumber')}
                                    labelInfobox
                                    onChange={onChange('accountNumber')}
                                    error={formErrors.accountNumber}
                                    value={values.accountNumber}
                                    required
                                />
                                <Controller
                                    name="swiftCode"
                                    control={control}
                                    as={<TextInput />}
                                    label={t('payments.swiftCode')}
                                    labelInfobox
                                    onChange={onChange('swiftCode')}
                                    error={formErrors.swiftCode}
                                    value={values.swiftCode}
                                    required
                                />
                                <Controller
                                    name="bankCode"
                                    control={control}
                                    as={<TextInput />}
                                    label={t('payments.bankCode')}
                                    labelInfobox
                                    onChange={onChange('bankCode')}
                                    error={formErrors.bankCode}
                                    value={values.bankCode}
                                />
                            </>
                        )}
                    </div>
                    <div className="bank-details">
                        <div className="group-title">
                            <Title type={3}>{t('payments.bankDetails')}</Title>
                        </div>
                        <Controller
                            name="bankName"
                            control={control}
                            as={<TextInput />}
                            label={t('payments.bankName')}
                            labelInfobox
                            onChange={onChange('bankName')}
                            error={formErrors.bankName}
                            value={values.bankName}
                            required
                        />
                        <div className="one-line">
                            <Controller
                                name="bankPostCode"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.postcode')}
                                labelInfobox
                                onChange={onChange('bankPostCode')}
                                error={formErrors.bankPostCode}
                                value={values.bankPostCode}
                                required
                            />
                            <Controller
                                name="bankCity"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.city')}
                                labelInfobox
                                onChange={onChange('bankCity')}
                                error={formErrors.bankCity}
                                value={values.bankCity}
                                required
                            />
                        </div>
                        <Controller
                            control={control}
                            name="bankCountry"
                            render={() => (
                                <Select
                                    ref={register}
                                    name="bankCountry"
                                    value={values.bankCountry}
                                    error={formErrors.bankCountry}
                                    options={listCountries}
                                    label={t('payments.country')}
                                    onChange={(val) => onChange('bankCountry')(
                                        listCountries?.find((item) => item.value === val),
                                    )}
                                    hasSearch
                                    required
                                />
                            )}
                        />
                    </div>
                    <div className="payee">
                        <div className="group-title">
                            <Title type={3}>{t('payments.payee')}</Title>
                        </div>
                        <Controller
                            name="payeeName"
                            control={control}
                            as={<TextInput />}
                            label={t('payments.nameOfPayee')}
                            labelInfobox
                            onChange={onChange('payeeName')}
                            error={formErrors.payeeName}
                            value={values.payeeName}
                            required
                        />
                        <Controller
                            name="payeeAddressLine"
                            control={control}
                            as={<TextInput />}
                            label={t('payments.addressLine')}
                            labelInfobox
                            onChange={onChange('payeeAddressLine')}
                            error={formErrors.payeeAddressLine}
                            value={values.payeeAddressLine}
                        />
                        <div className="one-line">
                            <Controller
                                name="payeePostCode"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.postcode')}
                                labelInfobox
                                onChange={onChange('payeePostCode')}
                                error={formErrors.payeePostCode}
                                value={values.payeePostCode}
                                required
                            />
                            <Controller
                                name="payeeCity"
                                control={control}
                                as={<TextInput />}
                                label={t('payments.city')}
                                labelInfobox
                                onChange={onChange('payeeCity')}
                                error={formErrors.payeeCity}
                                value={values.payeeCity}
                                required
                            />
                        </div>
                        <Controller
                            control={control}
                            name="payeeCountry"
                            render={() => (
                                <Select
                                    ref={register}
                                    name="payeeCountry"
                                    value={values.payeeCountry}
                                    error={formErrors.payeeCountry}
                                    options={listCountries}
                                    label={t('payments.country')}
                                    onChange={(val) => onChange('payeeCountry')(
                                        listCountries?.find((item) => item.value === val),
                                    )}
                                    hasSearch
                                    required
                                />
                            )}
                        />
                    </div>
                    <div className="payment-details">
                        <div className="group-title">
                            <Title type={3}>{t('payments.paymentDetails')}</Title>
                        </div>
                        <div className="amount">
                            <Controller
                                name="amount"
                                control={control}
                                render={() => (
                                    <NumberInput
                                        addonAfter={amountSelectAfter}
                                        label={t('payments.amount')}
                                        labelInfobox
                                        onChange={onChange('amount')}
                                        error={formErrors.amount}
                                        value={values.amount}
                                        withSelectBox
                                        required
                                        {...numberSeparators}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            name="executionDate"
                            control={control}
                            defaultValue={executionDateDefault()}
                            render={() => (
                                <DatePicker
                                    label={t('payments.executionDate')}
                                    name="executionDate"
                                    value={values.executionDate ? formatDate(
                                        new Date(values.executionDate),
                                        getDateFormat(locale),
                                    ) : undefined}
                                    popupPosition="top"
                                    onChange={onChange('executionDate')}
                                    withInfobox={false}
                                    format={getDateFormat(locale)}
                                    labels={datePickerLocale(t, locale)}
                                    error={formErrors.executionDate}
                                    disabledDate={(current) => current
                                            && (compareDates(subtractUnits(new Date(), 1, 'minutes'), current) || isWeekendDay(current))}
                                />
                            )}
                        />
                        <Controller
                            name="personalNote"
                            control={control}
                            as={<TextInput />}
                            label={t('payments.personalNote')}
                            labelInfobox
                            onChange={onChange('personalNote')}
                            error={formErrors.personalNote}
                            value={values.personalNote}
                        />
                        <Controller
                            name="messageForPayee"
                            control={control}
                            label={t('payments.messageForPayee')}
                            labelInfobox
                            onChange={onChange('messageForPayee')}
                            error={formErrors.messageForPayee}
                            value={values.messageForPayee}
                            as={<TextInput />}
                        />
                    </div>
                    <div className="debit-account">
                        <div className="group-title">
                            <Title type={3}>{t('payments.txnCost')}</Title>
                        </div>
                        <Select
                            name="txnCost"
                            ref={register}
                            label={t('payments.txnCost')}
                            options={costOptionsList}
                            value={txnCostValue}
                            error={formErrors.txnCost}
                            onChange={(val) => onChange('txnCost')(
                                costOptionsList?.find((item) => item.value === val),
                            )}
                            required
                        />
                    </div>
                    <div className="debit-account">
                        <div className="group-title">
                            <Title type={3}>{t('payments.debitAccount')}</Title>
                        </div>
                        <Select
                            name="debitAccount"
                            ref={register}
                            label={t('payments.debitAccount')}
                            options={cashAccounts}
                            value={values.debitAccount}
                            error={formErrors.debitAccount}
                            onChange={(val) => onChange('debitAccount')(
                                cashAccounts?.find((item) => item.value === val),
                            )}
                            required
                        />
                    </div>
                </div>
            </div>
            <ButtonsBlockRow
                additionalButton={{
                    text: t('payments.cancel'),
                    onClick: onCancel,
                    type: 'danger',
                }}
                primaryButton={{
                    text: t('confirmation.continue'),
                    onClick: handleSubmit(onSubmit),
                }}
            />
        </div>
    );
};

InternationalForm.propTypes = {
    listCurrencies: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    cashAccounts: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    onCancel: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    listCountries: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    listCosts: PropTypes.shape({
        iban: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
        noIban: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })),
    }),
};

InternationalForm.defaultProps = {
    listCurrencies: null,
    cashAccounts: null,
    listCountries: null,
    listCosts: null,
};

export default InternationalForm;
