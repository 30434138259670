import React, {
    Reducer, useCallback, useReducer,
} from 'react';
import { isEmpty, isUndefined } from 'lodash/fp';
import { getErrorModelByStatus } from 'error/errors';
import { PASSWORD_POLICY_VALIDATION_FAILED, SUCCESS } from 'static/requestResults';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { PasswordChangeResult } from 'additiv-services/dist/types/services/security';
import { PasswordManipulationHookReturn, PasswordManipulationsHookState } from '../types';
import { buildPolicyErrorMessage } from '../utils';


enum ActionKind {
    PartialChange = 'PARTIAL_CHANGE',
}

const initState:PasswordManipulationsHookState = {
    password: undefined,
    confirmPassword: undefined,
    oldPassword: undefined,
    view: 0,
    isGeneralError: false,
    generalErrorMessage: undefined,
    isLoading: false,
    errors: {},
};

type Action = {
    type: ActionKind,
    payload: Partial<PasswordManipulationsHookState>
};

const reducer = (state:PasswordManipulationsHookState, action:Action) => {
    switch (action.type) {
        case ActionKind.PartialChange:
            return {
                ...state,
                ...action.payload,
            };
        default: return state;
    }
};

export const usePasswordManipulations = (
    {
        serviceMethod,
        validate,
    }: {
        serviceMethod: () => Promise<AxiosResponse<PasswordChangeResult>>,
        validate: (
            values: Record<string, string | undefined | null>,
        ) => Record<string, string>
    },
): PasswordManipulationHookReturn => {
    const [
        state,
        dispatch,
    ] = useReducer<Reducer<PasswordManipulationsHookState, Action>>(reducer, initState);
    const { t } = useTranslation();
    const partialStateUpdate = (partialState: Partial<PasswordManipulationsHookState>) => {
        dispatch({
            type: ActionKind.PartialChange,
            payload: partialState,
        });
    };
    const onChangeOldPasswordInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        partialStateUpdate({
            isGeneralError: false,
            oldPassword: event.target.value,
        });
    }, [dispatch]);
    const onChangePasswordInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        partialStateUpdate({ isGeneralError: false, password: event.target.value });
    }, [dispatch]);
    const onChangeConfirmPasswordInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            partialStateUpdate({ isGeneralError: false, confirmPassword: event?.target?.value });
        }, [],
    );
    const onSubmit = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();

        const initAllValues = {
            password: state.password,
            confirmPassword: state.confirmPassword,
            oldPassword: state.oldPassword,
        };

        Object.keys(initAllValues).forEach((key) => {
            initAllValues[key] = isUndefined(initAllValues[key]) ? null : initAllValues[key];
        });
        const errs = validate(initAllValues);

        partialStateUpdate({
            errors: errs,
            ...initAllValues,
        });

        if (isEmpty(errs)) {
            partialStateUpdate({
                isLoading: true,
            });
            serviceMethod().then((response) => {
                partialStateUpdate({
                    isLoading: false,
                });

                const resultType = response.data.Result;
                const error = getErrorModelByStatus(resultType);

                if (error !== undefined) {
                    partialStateUpdate({
                        isGeneralError: true,
                        generalErrorMessage: error.message,
                    });
                } else if (resultType === PASSWORD_POLICY_VALIDATION_FAILED) {
                    const validationResult = response.data.ValidationResult?.Policies;

                    partialStateUpdate({
                        isGeneralError: true,
                        generalErrorMessage: buildPolicyErrorMessage(validationResult, t),
                    });
                } else if (resultType === SUCCESS) {
                    partialStateUpdate({
                        view: 1,
                        isGeneralError: false,
                    });
                } else {
                    partialStateUpdate({
                        isGeneralError: true,
                        generalErrorMessage: undefined,
                    });
                }
            }).catch(() => {
                partialStateUpdate({
                    isGeneralError: true,
                    isLoading: false,
                    generalErrorMessage: undefined,
                });
            });
        }

        return false;
    }, [
        state.password,
        state.confirmPassword,
        state.oldPassword,
    ]);

    return {
        ...state,
        onChangeOldPasswordInput,
        onChangePasswordInput,
        onChangeConfirmPasswordInput,
        onSubmit,
    };
};
