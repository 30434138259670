import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import ReviewPageAdvisory from './products/ReviewPageAdvisory';
import ReviewPageDiscretionary from './products/ReviewPageDiscretionary';
import './ReviewPage.css';

function ReviewPage(props) {
    const { onPageChange } = props;

    // OnBoarding Domain
    const {
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);

    const ReviewPageByProduct = useMemo(() => generateObject({
        [ADVISORY]: ReviewPageAdvisory,
        [DISCRETIONARY]: ReviewPageDiscretionary,
        [EXECUTION_ONLY]: () => {
            onPageChange('summary');

            return null;
        },
        [DEPOSITS]: ReviewPageAdvisory,
    }), [onPageChange]);
    const ReviewPagePage = ReviewPageByProduct.getByName(product?.name) || (() => '');

    return (
        <ReviewPagePage {...props} />
    );
}

ReviewPage.propTypes = {
    onPageChange: PropTypes.func,
};

ReviewPage.defaultProps = {
    onPageChange: () => {},
};

export default ReviewPage;
