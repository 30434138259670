import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from 'services/history';
import { loginByURL } from '../../../redux/auth/authActions';

export const useHashToken = ({ hash }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (hash.length === 0) {
            history.push('/login');
        } else {
            try {
                const accessToken = hash.split('#token=')?.[1];
                const dataToken = accessToken.split('.')?.[1];
                const { cid, pid } = JSON.parse(atob(dataToken));


                dispatch(loginByURL({
                    urlContactId: cid,
                    urlAccessToken: accessToken,
                })).then(() => {
                    history.push(`/portfolios/${pid}`);
                });
            } catch (error) {
                history.push('/');
            }
        }
    }, [hash]);
};
