import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';

export const useProposals = (contactId) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const { i18n } = useTranslation();

    useEffect(() => {
        const params = { language: i18n.language };

        setError(null);
        setLoading(true);
        ServiceManager.portfolioManagement('getPortfolioProposals', [contactId, params])
            .then((response) => {
                try {
                    setData(response.data);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language, contactId]);

    return {
        data, isLoading, error,
    };
};
