import React from 'react';
import { Loader } from 'ui-library';
import { useHashToken } from './useHashToken';

export const HashLogin = ({ location: { hash } }) => {
    useHashToken({ hash });

    return (
        <Loader />
    );
};
