import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import history from 'services/history';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import { adaptAnswers } from 'adaptors/adaptAnswers';
import { useQuestionnaire } from 'domain/RiskProfile';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import ContentBox from 'ui-library/dist/components/ContentBox';
import { useClientProfile } from 'prodivers/clientProfile';

import './Questionnaire.css';

const Questionnaire = (props) => {
    const {
        match: { params: { group: groupStr } },
        location: { state },
    } = props;

    const [answers, setAnswers] = useState({});
    const group = +groupStr;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t } = useTranslation();

    const { clientId } = useClientProfile();

    const {
        data, isLoading, error, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, errorPost, postQuestionnaire,
    } = useQuestionnaire({ clientId, questionnaireTypeId: 2, productId: state?.productId });

    const isNextResult = useMemo(() => state?.redoStep
        || data?.Groups?.length === group + 1, [data, group, state]);

    // Data
    const groupId = useMemo(() => data?.Groups?.[group]?.Id,
        [data, group]);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(() => validateQuestionnaire({ groupId, answers }),
        [answers, groupId, validateQuestionnaire]);

    // Callbacks
    const onChange = useCallback(({ questionId, value }) => {
        const newData = { ...answers };

        if (questionId) {
            if (value) {
                newData[groupId][questionId] = value;
            } else {
                delete newData[groupId][questionId];
            }
        } else {
            newData[groupId] = value;
        }
        setAnswers(newData);
    }, [groupId, setAnswers, answers]);

    const onNextClick = async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            const adaptedAnswers = adaptAnswers(data, answers, true);

            await postQuestionnaire({ answers: adaptedAnswers });
            setIsSubmitted(false);
            history.push(`/knowledge-experience/${isNextResult ? 'results' : group + 1}`, state);
        }
    };

    // Effects
    useEffect(() => {
        if (groupId) global.scrollTo({ top: 0, behavior: 'smooth' });
    }, [groupId]);
    useEffect(() => {
        getQuestionnaire();
    }, [getQuestionnaire]);
    useEffect(() => {
        if (!Object.values(answers).length && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({ Id }) => Id);
            const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

            setAnswers(newData);
        }
    }, [data, setAnswers, answers]);
    useEffect(() => {
        if (state?.answers?.length && groupId) {
            const newData = state.answers?.reduce((acc, item) => ({
                ...acc,
                [groupId]: {
                    ...acc[groupId],
                    [item.Id]: item.Values,
                },

            }), {});

            setAnswers(newData);
        }
    }, [state?.answers, groupId]);

    return (
        <ContentBox
            className="knowledge-and-experience-details"
        >
            <Preloader
                isLoading={isLoading}
                error={error}
            >
                <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                    <QuestionnaireGroup
                        data={data?.Groups?.[group]}
                        title={data?.Name}
                        value={answers?.[groupId] || {}}
                        defaultValue={state?.values}
                        errors={isSubmitted && errors}
                        onChange={onChange}
                        disableDefaultValue
                    />
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('confirmation.back'),
                            loading: isLoadingPost,
                            onClick: () => {
                                if (group > 0) {
                                    history.push(`/knowledge-experience/${group - 1}`);
                                } else {
                                    history.push('/details');
                                }
                            },
                        }}
                        primaryButton={{
                            text: t('confirmation.continue'),
                            loading: isLoadingPost,
                            onClick: onNextClick,
                        }}
                    >
                        {errorPost && (<WidgetError accent error message={errorPost.message} />)}
                    </ButtonsBlockRow>
                </EmptyContent>
            </Preloader>
        </ContentBox>
    );
};

Questionnaire.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            group: PropTypes.string,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
            productId: PropTypes.string,
            productName: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.number),
            answers: PropTypes.arrayOf(PropTypes.shape({
                Answers: PropTypes.arrayOf(PropTypes.shape({
                    Id: PropTypes.number,
                    Text: PropTypes.string,
                })),
                Values: PropTypes.arrayOf(PropTypes.number),
                allAnswered: PropTypes.bool,
                Id: PropTypes.number,
            })),
        }),
    }).isRequired,
};

Questionnaire.defaultProps = {

};

export default Questionnaire;
