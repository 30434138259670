import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, Paragraph, Title,
} from 'ui-library';
import RadioGroupReview from 'components/RadioGroupReview';
import './ReviewSection.css';

const ReviewSection = (props) => {
    const {
        title,
        description,
        riskCategoryId,
        riskCategoryName,
        onRedo,
        onChangeRisk,
    } = props;
    const { t } = useTranslation();

    return (
        <div className="review-section" key={`review-section-${title}`}>
            <div className="review-section__description">
                <Title type={3}>{title}</Title>
                <Paragraph type="secondary">
                    {description}
                </Paragraph>
                {onRedo && (
                    <Button type="link" onClick={onRedo}>
                        {`${t('riskProfile.redo')} ${title.toLowerCase()}`}
                    </Button>
                )}
                {onChangeRisk && (
                    <Button type="link" onClick={onChangeRisk}>
                        {t('riskProfile.changeRisk')}
                    </Button>
                )}
            </div>
            <div className="result-radio">
                <span className="result-radio__label">
                    {riskCategoryName}
                </span>
                <RadioGroupReview
                    selected={riskCategoryId}
                />
            </div>
        </div>
    );
};

ReviewSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    riskCategoryId: PropTypes.number.isRequired,
    riskCategoryName: PropTypes.string.isRequired,
    onRedo: PropTypes.func,
    onChangeRisk: PropTypes.func,
};

ReviewSection.defaultProps = {
    onRedo: undefined,
    onChangeRisk: undefined,
};

export default ReviewSection;
