import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { transactionsColumns, transactionsMobileColumns } from '../../Confirmation/constants';

function Transactions({
    data, isLoading, error, defaultExpandAllRows,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data.confirmationData} text={t('confirmation.noTransactionsFound')}>
                <Table
                    data={data.confirmationData}
                    columns={isMobileSize ? transactionsMobileColumns(t) : transactionsColumns(t)}
                    expandedColumn="Name"
                    defaultExpandAllRows={defaultExpandAllRows}
                />
            </EmptyContent>
        </Preloader>
    );
}

Transactions.propTypes = {
    data: PropTypes.shape({
        Name: PropTypes.string,
        Date: PropTypes.string,
        Type: PropTypes.string,
        FxRate: PropTypes.number,
        Total: PropTypes.string,
        Number: PropTypes.number,
        Price: PropTypes.number,
        confirmationData: PropTypes.arrayOf(PropTypes.shape),
    }),
    isLoading: PropTypes.bool,
    defaultExpandAllRows: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Transactions.defaultProps = {
    data: [],
    isLoading: true,
    defaultExpandAllRows: false,
    error: null,
};

export default Transactions;
