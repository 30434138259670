import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Header, Modal, PageVariant, ProgressBar, Step,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/Footer';
import { Logo } from 'components/Logo';
import Preloader from 'components/Preloader';
import { useHistory } from 'react-router-dom';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useFormData } from 'domain/RiskProfile';
import { useStepControl } from './hooks/useStepControl';
import { useStepsByProduct } from './hooks/useStepsByProduct';
import './OnBoardingRouterTemplate.css';

const OnBoardingRouterTemplate = (props) => {
    const {
        clientId, step, changeStep, children,
    } = props;
    const { t } = useTranslation();
    const { isTabletSize } = useDetectedMobileDevice();
    const { clearData, clearSelectedRisk } = useFormData();
    const {
        clearAllOnBoarding, product, createdGoal,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const history = useHistory();

    useEffect(() => history.listen((location) => {
        if (!location.pathname.includes('onboarding')) {
            clearData();
            clearSelectedRisk();
            clearAllOnBoarding();
        }
    }), [history, clearAllOnBoarding, clearData, clearSelectedRisk]);

    const onBoardingClose = useCallback(() => {
        Modal.confirm({
            title: t('confirm.closeProcess'),
            content: t('onBoarding.closeModalText'),
            okText: t('confirmation.yes'),
            onOk: () => {
                clearData();
                clearSelectedRisk();
                clearAllOnBoarding();
                history.push('/dashboard');
            },
            cancelText: t('confirmation.no'),
            className: 'close-client-modal',
        });
    }, [clearAllOnBoarding, clearData, clearSelectedRisk, history, t]);
    const headerProps = useMemo(() => ({
        leftButton: { type: 'close', label: t('confirm.closeProcess') },
        onLeftButtonClick: onBoardingClose,
        rightButton: '',
    }), [onBoardingClose, t]);

    // Data
    const chosenProduct = useMemo(() => product?.id || createdGoal?.ProductId,
        [product?.id, createdGoal?.ProductId]);
    const chosenProductName = useMemo(() => product?.name || createdGoal?.ProductName,
        [product, createdGoal?.ProductName]);

    // Steps Control
    const {
        isLoading, haveMissingData, missingStepNumber, missingStep,
    } = useStepControl({ clientId, step, changeStep });
    const {
        progressBarCurrent, progressBarSteps, missingStepTitle,
    } = useStepsByProduct({ step, chosenProductName, missingStep });

    // Renderer
    const renderContent = useMemo(() => (haveMissingData
        ? (
            <PageVariant
                title={t('onBoarding.error')}
                type="error"
                message={t('onBoarding.missingDataFromPreviousStep')}
                actionButtonLabel={`${t('onBoarding.backToStep')} ${missingStepTitle}`}
                onActionButtonClick={() => changeStep(missingStepNumber)}
            />
        )
        : children),
    [haveMissingData, missingStepNumber, t, missingStepTitle, children, changeStep]);

    return (
        <>
            <Header
                {...headerProps}
                title={t('navigation.title')}
                content={<Logo />}
            />
            <main className="container">
                <div className="on-boarding">
                    {(step > 1 && chosenProduct) && (
                        <ProgressBar size={isTabletSize && 'small'} current={progressBarCurrent}>
                            {Object.keys(progressBarSteps || {}).map((key) => (
                                <Step key={key} title={progressBarSteps[key]} />
                            ))}
                        </ProgressBar>
                    )}
                    <Preloader isLoading={isLoading}>
                        {renderContent}
                    </Preloader>
                </div>
            </main>
            <Footer />
        </>
    );
};

OnBoardingRouterTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    clientId: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    changeStep: PropTypes.func.isRequired,
};

OnBoardingRouterTemplate.defaultProps = {};

export default OnBoardingRouterTemplate;
