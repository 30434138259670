import { useMemo } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { FormInput, EmailFormReturn } from '../types';


export const useEmailForm = (isUserNameSameWithEmail: boolean | null): EmailFormReturn => {
    const { t } = useTranslation();

    const schema = useMemo(() => object().shape({
        username: !isUserNameSameWithEmail && string().required(t('validation.mandatoryField')),
        email: string().email(t('validation.invalidEmail')).required(t('validation.mandatoryField')),
    }), [t, isUserNameSameWithEmail]);

    const resolver = useYupValidationResolver(schema);
    const {
        handleSubmit, errors, control, watch,
    } = useForm<FormInput>({ resolver, reValidateMode: 'onChange' });

    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), { username: null, email: null });

    return useMemo(() => ({
        handleFormSubmit: handleSubmit,
        control,
        values: watch(),
        formErrors,
    }), [
        handleSubmit,
        control,
        watch,
        formErrors,
    ]);
};
