import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { MODIFY_PORTFOLIO } from 'constants/constants';
import { adaptModify, adaptModelData, groupModifiedPositions } from '../adapters/adaptModify';

export const useModify = (options = {}) => {
    const {
        portfolio = {}, portfolioPositions, positionsModified, baseUrl,
    } = options;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const modifiedLocally = useMemo(
        () => JSON.parse(sessionStorage.getItem(MODIFY_PORTFOLIO)) || [], [],
    );
    const getModifiedByIdLocally = useCallback((paramId) => modifiedLocally
        .find(({ id }) => id === paramId), [modifiedLocally]);
    const saveModifiedLocally = useCallback((params) => {
        const positionsOld = modifiedLocally.filter(({ id }) => id !== params.id);
        const positionsNew = [...positionsOld, params];

        sessionStorage.setItem(MODIFY_PORTFOLIO, JSON.stringify(positionsNew));
    }, [modifiedLocally]);
    const clearModifiedLocally = useCallback(() => sessionStorage.removeItem(MODIFY_PORTFOLIO), []);

    const modifiedData = useMemo(() => adaptModify({
        initialPositions: portfolioPositions,
        changedPositions: positionsModified,
        currency: portfolio?.currency,
        portfolioValue: portfolio?.portfolioValue,
        modifiedLocally,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        portfolioPositions,
        positionsModified,
        portfolio.currency,
        portfolio.portfolioValue,
        modifiedLocally,
        baseUrl,
        t,
        getFormattedCurrency,
        getFormattedNumber,
    ]);
    const positions = useMemo(() => {
        if (!portfolio.currency || !portfolio.portfolioValue
            || !portfolioPositions?.length) {
            return [];
        }

        return groupModifiedPositions({
            portfolioValue: portfolio?.portfolioValue,
            portfolioCurrency: portfolio?.currency,
            positions: portfolioPositions,
            positionsModified,
            positionsModifiedAllocations: modifiedData?.positionsAllocation,
            baseUrl,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        });
    }, [
        portfolio.currency,
        portfolio.portfolioValue,
        portfolioPositions,
        positionsModified,
        modifiedData,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    ]);
    const modelData = useMemo(() => {
        if (!portfolio || !portfolioPositions?.length) {
            return null;
        }

        return adaptModelData({
            data: portfolio,
            positions: portfolioPositions,
            positionsModified,
            positionsModifiedAllocations: modifiedData?.positionsAllocation,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        });
    }, [
        portfolio,
        portfolioPositions,
        modifiedData,
        positionsModified,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    return {
        modifiedData,
        positions,
        modelData,
        saveModifiedLocally,
        getModifiedByIdLocally,
        clearModifiedLocally,
    };
};
