import { useTranslation } from 'react-i18next';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
    number, object, string,
} from 'yup';
import { useSelector } from 'react-redux';
import { get } from 'lodash/fp';
import MessageService from 'additiv-services/dist/services/core/messages';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { memberIdSelector } from 'redux/auth/authSelectors';
import ServiceManager from 'services/ServiceManager';
import history from 'services/history';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { $_GET } from 'utils/url';

const initState = {
    message: undefined,
    subject: undefined,
    contactReason: 1,
};

export const useNewMessage = ({ contactId, location }) => {
    const defState = location?.state || {};
    const backToDashboard = $_GET('back');
    const { t, i18n } = useTranslation();
    const memberId = useSelector(memberIdSelector);
    const [messageSubTypes, setMessageSubTypeMap] = useState([]);
    const [isSending, setSending] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const schema = useMemo(() => object().shape({
        contactReason: number().required(t('messages.validation.reasonWarning')),
        subject: string().required(t('messages.validation.subjectWarning')),
        message: string().required(t('messages.validation.messageWarning')),
    }), [t]);
    const resolver = useYupValidationResolver(schema);
    const {
        handleSubmit,
        errors,
        setValue,
        reset,
        control,
        watch,
        formState,
        trigger,
        setError,
        register,
    } = useForm({ resolver, reValidateMode: 'onChange' });

    useEffect(() => {
        register('contactReason');
    }, [register]);

    const values = watch();

    useEffect(() => {
        reset(initState);
    }, [reset, initState]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        let newValue = value;

        if (type === 'subject') {
            newValue = value?.currentTarget ? value?.currentTarget?.value : value;
            register('subject');
        }

        if (type === 'message') {
            newValue = value?.currentTarget ? value?.currentTarget?.currentValue : value;
        }

        setValue(type, newValue);

        if (formState.isSubmitted) trigger(type);
    }, [trigger, formState.isSubmitted, setValue]);


    useEffect(() => {
        ServiceManager.messageService('getMessageTypes', [i18n.language]).then((response) => {
            setMessageSubTypeMap(response.data[0].Subtypes);
            initState.contactReason = get('data[0].Subtypes[0].Id', response);

            if (defState.title) {
                onChange('contactReason')(20);
            } else {
                onChange('contactReason')(response.data[0].Subtypes[0].Id);
            }
        }).catch(handlerRequestCanceling(
            HandlerError({ setError, setLoading }),
        ));
    }, [i18n.language, defState.title]);

    useEffect(() => {
        if (defState.title && defState?.type === 'request') {
            onChange('subject')(`${t('research.requestProposal')} ${defState.title}`);
        } else if (defState.title) {
            onChange('subject')(`${t('proposal.rejectedProposal')} ${defState.title}`);
        }
    }, [defState.title, defState?.type, t]);

    const messageSubTypesOptions = useMemo(() => messageSubTypes.map(({ Id, Name }) => ({
        label: Name,
        value: Id,
    })), [messageSubTypes]);

    const sendMessage = useCallback(async () => {
        setSending(true);
        if (!values?.message
            && !values?.subject
            && !values?.contactReason
            && !contactId) return;
        try {
            const response = await ServiceManager.messageService('sendMessageFromContact', [{
                message: values.message,
                subject: values.subject,
                contactReason: values.contactReason,
                dtoAttachments: [],
                contactId,
            }]);

            if (response.data.Result === MessageService.MESSAGE_NOT_ALLOWED) {
                setSending(false);
                setError(t('messages.maliciousMessage'));
            } else if (response.data.Result === MessageService.SUCCESS) {
                setSending(false);
                if (defState.portfolioId) {
                    history.push(`/portfolios/${defState.portfolioId}`);
                } else {
                    history.push('/messages');
                }
            } else {
                setSending(false);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [values, memberId]);

    const onCancel = () => {
        if (defState.portfolioId && defState.proposalId) {
            history.push(`/portfolios/${defState.portfolioId}/proposal/${defState.proposalId}/reject-success`, defState);
        } else if (defState.researchId) {
            history.push(`/news/research/${defState.researchId}`);
        } else if (backToDashboard === 'relationshipManager') {
            history.push('/dashboard');
        } else {
            history.push('/messages');
        }
    };


    return {
        values,
        onChange,
        onCancel,
        formErrors,
        control,
        handleSubmit,
        isSending,
        messageSubTypesOptions,
        sendMessage,
        isLoading,
    };
};
