import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import SetupPayment from './pages/SetupPayment/SetupPayment';
import ReviewPayment from './pages/ReviewPayment/ReviewPayment';
import Confirmation from './pages/Confirmation';
import { PaymentsTemplate } from './templates/PaymentTemplate';
import Steps from './templates/Steps';

const Router = (props) => {
    const { match: { url, path }, location: { pathname } } = props;

    const clientId = useSelector(contactIdSelector);

    const onPrev = useCallback(() => {
        history.push(`${url}/setup`);
    }, [url]);

    const onNext = useCallback(() => {
        history.push(`${url}/review`);
    }, [url]);

    const pageProps = useMemo(() => ({
        clientId,
        onPrev,
        onNext,
    }), [onPrev, onNext]);

    const withProps = (Component) => (defProps) => <Component {...pageProps} {...defProps} />;

    const param = /payments/g.exec(pathname)?.input?.split('/')?.[2];

    const steps = {
        setup: 0,
        review: 1,
        confirmation: 2,
    };

    const step = steps[param];

    useEffect(() => {
        global.scrollTo({ top: 0, behavior: 'smooth' });
    }, [step]);

    return (
        <PaymentsTemplate>
            <Steps step={step || 0}>
                <Switch>
                    <Route exact path={`${path}/setup`} render={withProps(SetupPayment)} />
                    <Route exact path={`${path}/review`} render={withProps(ReviewPayment)} />
                    <Route exact path={`${path}/confirmation`} render={withProps(Confirmation)} />
                    <Redirect exact from={`${path}`} to={`${path}/setup`} />
                    <Redirect from="*" to="/404" />
                </Switch>
            </Steps>
        </PaymentsTemplate>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

Router.displayName = 'Router';

export default Router;
