import { useMemo, useState, useEffect } from 'react';

const getWindowDimensions = () => {
    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    return { width, height };
};

const detectDevice = ({ width }) => {
    const isDesktop = useMemo(() => width > 768, [width]);
    const isTablet = useMemo(() => width <= 768 && width > 480, [width]);
    const isMobile = useMemo(() => width <= 480, [width]);

    return { isDesktop, isTablet, isMobile };
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return detectDevice(windowDimensions);
};
