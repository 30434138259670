/* eslint no-underscore-dangle:off */
import { compose, createStore, applyMiddleware } from 'redux';
import { save as saveSession, load as loadedSession } from 'middleware/redux-sessionstorage';
import { get } from 'lodash/fp';
import { setup } from 'additiv-services';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import history from './services/history';
import { $_GET } from './utils/url';

global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = undefined;
const composeEnhancer = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadStateAndSetupServices = () => {
    const loaded = loadedSession({ states: ['auth'] });
    const loadedSessionData = loadedSession({ states: ['session'] });
    const jwtAccessToken = get('auth.user.jwtAccessToken', loaded);
    const withURLLogin = $_GET('contactId') && $_GET('accessToken') === undefined;

    setup({
        bearerAccessToken: withURLLogin
            ? undefined
            : jwtAccessToken && `Bearer ${jwtAccessToken}`,
    });

    if (withURLLogin) {
        return {};
    }

    return {
        ...loaded,
        ...loadedSessionData,
    };
};

export default ((preloadedState) => createStore(
    rootReducer,
    preloadedState,
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            saveSession({ states: ['auth'] }),
            saveSession({ states: ['session'] }),
        ),
    ),
))(loadStateAndSetupServices());
