import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    ContentBox, Header, Modal, ProgressBar, Step,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/Footer';
import { Logo } from 'components/Logo';
import { formDataSelector, useRiskProfileSelector } from 'domain/RiskProfile';

export const RiskProfileTemplate = (props) => {
    const {
        children,
        groups,
        currentGroup,
    } = props;
    const { t } = useTranslation();

    // Risks Profile Domain
    const {
        clearSelectedRisk, clearData,
    } = useRiskProfileSelector(formDataSelector);
    const riskProfileClose = useCallback(() => {
        Modal.confirm({
            title: t('riskProfile.closeProcess'),
            content: t('riskProfile.closeModalText'),
            okText: t('confirmation.yes'),
            onOk: () => {
                clearData();
                clearSelectedRisk();
                history.push('/');
            },
            cancelText: t('confirmation.no'),
            className: 'close-client-modal',
        });
    }, [clearData, clearSelectedRisk, t]);

    const riskProfileContinueLater = useCallback(() => {
        Modal.confirm({
            title: t('riskProfile.continueLater'),
            content: t('riskProfile.continueModalText'),
            okText: t('confirmation.yes'),
            onOk: () => history.push('/'),
            cancelText: t('confirmation.no'),
            className: 'close-client-modal',
        });
    }, [t]);
    const headerProps = useMemo(
        () => ({
            leftButton: { type: 'close', label: t('confirm.closeProcess') },
            onLeftButtonClick: riskProfileClose,
            rightButton: { type: 'delay', label: t('riskProfile.continueLater') },
            onRightButtonClick: riskProfileContinueLater,
        }),
        [riskProfileClose, riskProfileContinueLater, t],
    );

    return (
        <>
            <Header
                {...headerProps}
                title={t('login.title')}
                link={({ children: logoChildren }) => <span onClick={riskProfileClose} className="risk-logo" role="presentation">{logoChildren}</span>}
                content={<Logo />}
            />
            <main className="container risk-profile">
                {(groups.length && currentGroup !== undefined) ? (
                    <ProgressBar current={+currentGroup}>
                        {groups.map((group) => (
                            <Step key={group.Name} title={group.Name} />
                        ))}
                        <Step key="review" title={t('riskProfile.review')} />
                    </ProgressBar>
                ) : null}
                <ContentBox>
                    {children}
                </ContentBox>
            </main>
            <Footer />
        </>
    );
};

RiskProfileTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    currentGroup: PropTypes.number,
    groups: PropTypes.arrayOf(PropTypes.shape({

    })),
};

RiskProfileTemplate.defaultProps = {
    currentGroup: undefined,
    groups: [],
};

RiskProfileTemplate.displayName = 'RiskProfileTemplate';
