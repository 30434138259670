import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import {
    Button, Title, Paragraph, Toggle, Label as LabelUI,
} from 'ui-library';
import {
    knowledgeExperienceSelector,
    useOnBoardingSelector,
    onBoardingDataSelector,
} from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useExpandUniverse } from './hooks/useExpandUniverse';
import { collectAnswers, adaptResults } from '../../adapters/adaptKnowledgeExperience';
import { adaptAnswersQuestionnaire } from '../Questionnaire/adaptors/adaptAnswersQuestionnaire';
import './ExpandUniverse.css';

const ExpandUniverse = ({ onPageChange }) => {
    const { t } = useTranslation();

    // OnBoarding Domain
    const {
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);

    const {
        results: data, errorResults, isLoadingResults, getQuestionnaireResults,
        errorSending, isSending, sendKnowledgeExperience,
    } = useOnBoardingSelector(knowledgeExperienceSelector);

    // Data
    const resultsAdapted = useMemo(() => adaptResults(data?.Groups?.[0]?.Steps?.[0]),
        [data]);

    // Helpers hooks
    const {
        values, disabledToggle, toggledValues, onDownload, onToggle,
    } = useExpandUniverse(resultsAdapted?.negative);
    const answers = useMemo(() => collectAnswers(data), [data]);

    // Effects
    useEffect(() => {
        getQuestionnaireResults({ productId: +product?.id });
    }, [getQuestionnaireResults]);

    // Callbacks
    const onNextClick = async () => {
        const answersNew = {
            ...answers,
            [resultsAdapted.id]: answers?.[resultsAdapted.id]
                ? [...answers?.[resultsAdapted.id], ...values] : [...values],
        };
        const answersData = adaptAnswersQuestionnaire(data, [answersNew], [], true);

        await sendKnowledgeExperience(answersData);
        onPageChange('results');
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.expandYourInstrumentUniverse')}
            error={errorResults}
            isLoading={isLoadingResults}
            className="expand-universe"
            prevButton={{
                onClick: () => onPageChange('results'),
            }}
            nextButton={{
                loading: isSending,
                onClick: onNextClick,
            }}
        >
            <EmptyContent data={data?.Groups?.[0]} text={t('advisoryDashboard.noData')}>
                <Title type={3}>{t('onBoarding.howItWorks')}</Title>
                <Paragraph type="secondary">
                    <ol>
                        <li>{t('onBoarding.downloadEachDocumentOfInstrument')}</li>
                        <li>{t('onBoarding.itContainsAllImportantInformation')}</li>
                        <li>{t('onBoarding.readDocumentCarefullyAndConfirm')}</li>
                        <li>{t('onBoarding.inCaseYouHaveQuestions')}</li>
                    </ol>
                </Paragraph>
                {(resultsAdapted?.negative || []).map((item) => (
                    <div className="instrument-block">
                        <Title type={3}>{item.Text}</Title>
                        <Paragraph type="secondary">
                            {`${t('onBoarding.pleaseDownloadDocumentBelow')} ${item.Text}.`}
                        </Paragraph>
                        <Button type="secondary" size="small" onClick={onDownload(item)}>
                            {`${t('onBoarding.downloadFactsheet')} ${item.Text}`}
                        </Button>
                        <LabelUI label={t('confirmation.confirmation')} />
                        <div className="toggle-block">
                            <Toggle
                                value={toggledValues[item.Id]}
                                disabled={disabledToggle[item.Id]}
                                onChange={onToggle(item)}
                            />
                            <span>{t('onBoarding.IConfirmToHaveReadAndCompleted')}</span>
                        </div>
                    </div>
                ))}
            </EmptyContent>
            {errorSending && <WidgetError accent error message={errorSending.message} />}
        </OnBoardingBaseTemplate>
    );
};

ExpandUniverse.propTypes = {
    onPageChange: PropTypes.func,
};

ExpandUniverse.defaultProps = {
    onPageChange: () => { },
};

export default ExpandUniverse;
