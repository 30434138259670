import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui-library';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout as logoutAction } from '../redux/auth/authActions';
import autoLogout from '../worker/autoLogout.worker';

import './withAutoLogout.css';
import { useSetting } from '../hooks/useSetting';
import { groups, keys } from '../static/globalSettings';

const events = ['mousemove', 'click', 'scroll', 'keypress'];

export const withAutoLogout = (Component) => {
    const mapDispatchToProps = (dispatch) => ({
        logout: () => dispatch(logoutAction()),
    });

    const AutoLogout = (props) => {
        let modal = null;
        const { logout } = props;
        const { t } = useTranslation();
        const { Value: accessTokenExpirationPeriod } = useSetting(
            {
                group: groups.AUTHENTICATION,
                key: keys.CONTACT_ACCESS_TOKEN_LIFETIME,
            },
        );
        const autoLogoutWorker = useMemo(() => new Worker(autoLogout), []);
        const clearSessionTimeout = useCallback(() => {
            autoLogoutWorker.postMessage({ action: 'clear' });
        }, []);
        const resetTimeout = useCallback(() => {
            autoLogoutWorker.postMessage({ action: 'reset' });
        }, []);
        const continueSession = useCallback(() => {
            events.forEach((event) => {
                global.window.addEventListener(event, resetTimeout);
            });
            if (modal) modal.destroy();
            resetTimeout();
        }, [modal, resetTimeout]);
        const renderModalContent = useCallback((seconds) => (
            <>
                {t('session.content')}
                <br />
                <br />
                {`${t('session.remainingTime')}: `}
                {`${seconds} ${t('session.seconds')}`}
            </>
        ), [t]);
        const showModal = useCallback((seconds) => {
            events.forEach((event) => {
                global.window.removeEventListener(event, resetTimeout);
            });

            modal = Modal.success({
                title: t('session.title'),
                onOk: continueSession,
                okText: t('session.button'),
                content: renderModalContent(seconds),
                keyboard: false,
                className: 'auto-logout',
            });

            autoLogoutWorker.postMessage({ action: 'setCountdown' });
        }, [resetTimeout, continueSession, renderModalContent]);

        useEffect(() => {
            if (accessTokenExpirationPeriod === undefined) return;

            autoLogoutWorker.postMessage({ action: 'init', timeout: +accessTokenExpirationPeriod });
            events.forEach((event) => {
                global.window.addEventListener(event, resetTimeout);
            });

            return () => {
                events.forEach((event) => {
                    global.window.removeEventListener(event, resetTimeout);
                });
                clearSessionTimeout();
            };
        }, [accessTokenExpirationPeriod]);

        useEffect(() => {
            autoLogoutWorker.addEventListener('message', (e) => {
                if (e.data?.action === 'logout') {
                    if (modal) modal.destroy();
                    logout();
                }
                if (e.data?.action === 'showModal') {
                    showModal(e.data?.data);
                }
                if (e.data?.action === 'dialogCountdown') {
                    if (modal) modal.update({ content: renderModalContent(e.data?.data) });
                }
            });
        }, [logout, modal, renderModalContent, showModal, t]);

        return <Component t={t} {...props} />;
    };

    AutoLogout.propTypes = {
        logout: PropTypes.func.isRequired,
    };

    return connect(null, mapDispatchToProps)(AutoLogout);
};
