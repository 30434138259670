import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { CHANGE_STRATEGY } from 'constants/constants';
import { useModelPortfolio } from 'domain/Portfolio';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { redirectToReadOnlyPositionDetails } from 'pages/pages/Portfolios/pages/common/utils';

export const useStrategyChange = ({
    portfolio, portfolioPositions = [], baseUrl, strategyIdInitial = undefined,
} = {}) => {
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const [strategy, setStrategy] = useState(null);
    const [strategyId, setModelPortfolioId] = useState(null);
    const modelPortfolioOptions = useMemo(() => ({
        productID: portfolio?.productId,
        portfolioValue: portfolio?.portfolioValue,
        projectionYears: portfolio?.projectionYears,
        comparedAllocations: portfolio?.allocationGroups,
        portfolioSecuritiesValue: portfolio?.portfolioValue,
        recurringPayment: portfolio?.recurringPayment,
    }), [
        portfolio?.allocationGroups,
        portfolio?.portfolioValue,
        portfolio?.projectionYears,
        portfolio?.productId,
        portfolio?.recurringPayment,
    ]);
    const {
        data, isLoading: isLoadingStrategy, error: errorStrategy, getModelPortfolio,
    } = useModelPortfolio({ adaptOptions: modelPortfolioOptions });

    // Callbacks
    const onStrategyChange = useCallback((id) => {
        sessionStorage.setItem(CHANGE_STRATEGY, JSON.stringify(id));
        setModelPortfolioId(id);
        if (portfolio.modelPortfolioId === +id) {
            setStrategy(null);
        } else {
            getModelPortfolio(id, { currencyId: portfolio?.currencyId });
        }
    }, [getModelPortfolio, portfolio?.modelPortfolioId, portfolio?.currencyId]);
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
    };

    // Data
    const positions = useMemo(() => groupComparedPositions({
        positions: portfolioPositions,
        modelPositions: strategy?.Positions || portfolioPositions,
        portfolioCurrency: portfolio?.currency || portfolio?.Currency?.CurrencyCode,
        positionLink: baseUrl,
        t,
        portfolioTotalValue: portfolio?.InvestedAmount || portfolio?.portfolioValue,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        portfolio?.Currency?.CurrencyCode,
        portfolio?.InvestedAmount,
        portfolio?.portfolioValue,
        portfolio?.currency,
        portfolioPositions,
        strategy?.Positions,
        baseUrl,
        getFormattedCurrency,
        getFormattedNumber,
        t,
    ]);

    // Effects
    useEffect(() => {
        const modelId = JSON.parse(sessionStorage.getItem(CHANGE_STRATEGY))
            || strategyIdInitial || null;

        if (portfolio?.modelPortfolioId && modelId && portfolio?.modelPortfolioId !== +modelId) {
            getModelPortfolio(modelId, { currencyId: portfolio?.currencyId });
        }
        setModelPortfolioId(modelId);
    }, [getModelPortfolio, portfolio?.modelPortfolioId, portfolio?.currencyId, strategyIdInitial]);
    useEffect(() => {
        setStrategy(data);
    }, [data]);

    return {
        strategy,
        strategyId,
        positions,
        isLoadingStrategy,
        errorStrategy,
        onStrategyChange,
        onStrategyReset,
    };
};
