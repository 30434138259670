import { useCallback, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';

export const useAccountForm = ({ defaultData } = {}) => {
    const { t } = useTranslation();
    const schema = useMemo(() => object().shape({
        debitAccount: object().required(t('payments.validation.debitAccount')),
        creditAccount: object().required(t('payments.validation.creditAccount')),
        currency: object().required(t('payments.validation.currency')),
        amount: string().required(t('payments.validation.amount')),
        executionDate: string(),
    }), [t]);
    const resolver = useYupValidationResolver(schema);

    const {
        register, handleSubmit, errors, setValue, reset,
        control, watch, formState, trigger,
    } = useForm({ resolver, reValidateMode: 'onChange', shouldUnregister: false });

    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset(defaultData);
        }
    }, [reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        setValue(type, value);
        if (formState.isSubmitted) trigger(type);
    }, [setValue, trigger, formState.isSubmitted]);

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
        reset,
    };
};
