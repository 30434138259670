import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import { useFormatting } from 'locale';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { portfolioActions } from 'constants/constants';
import { getDocumentsPortfolios } from '../utils';
import { adaptPortfolios } from '../../Portfolios/adapters/adaptPortfolios';

export const usePortfolios = ({ clientId, proposals }) => {
    const [error, setError] = useState(null);
    const [isLoadingPortfolios, setLoadingPortfolios] = useState(true);
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();

    const getPortfolios = useCallback(async ({ documents }) => {
        setError(null);

        try {
            const params = { language: i18n.language };
            const { data: dataInvestment } = await SM.portfolioManagement('getInvestmentOverview', [clientId, params]);
            const filteredPortfolios = getDocumentsPortfolios({
                portfolios: dataInvestment.Portfolios, documents,
            });
            const portfoliosOverview = await Promise.all(filteredPortfolios.Portfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await SM.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));

            try {
                setLoadingPortfolios(false);

                return adaptPortfolios(
                    dataInvestment, {
                        proposals,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                        portfoliosOverview,
                    },
                );
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoadingPortfolios,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, i18n.language, t, getFormattedCurrency, proposals]);

    return {
        getPortfolios, isLoadingPortfolios, error,
    };
};
