import React from 'react';
import { useTranslation } from 'react-i18next';
import DarkLogoSvg from 'assets/images/default.svg';
import LightLogoSvg from 'assets/images/default-light.svg';
import MobileLogoSvg from 'assets/images/logo.svg';
import { useTheme } from 'theming';
import './Logo.css';
import { useDetectedMobileDevice } from '../../hooks/useDetectedMobileDevice';

export const Logo = () => {
    const { t } = useTranslation();

    const { theme } = useTheme();
    const { isMobileSize } = useDetectedMobileDevice();

    const themeLogo = theme.includes('dark') ? DarkLogoSvg : LightLogoSvg;

    return (
        <div>
            <img
                src={isMobileSize ? MobileLogoSvg : themeLogo}
                alt={t('login.title')}
                className="logo-icon"
            />
        </div>
    );
};
