import React from 'react';
import {
    FormGroup, TabPane, Tabs, Toggle,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import useTheme from 'theming/useTheme';
import './ThemeToggle.css';

const ThemeToggle = () => {
    const { t } = useTranslation();
    const { theme, setTheme } = useTheme();
    const defaultActiveKey = theme.includes('dark') ? 'dark' : 'light';
    const changeTabs = (key) => {
        if (theme.includes('dark') && key.includes('dark')) return;
        if (theme.includes('light') && key.includes('light')) return;
        if (theme.includes('contrast')) {
            setTheme(`${key}-contrast`);

            return;
        }

        setTheme(key);
    };

    return (
        <div className="ThemeToggle">
            <Tabs
                onChange={changeTabs}
                defaultActiveKey={defaultActiveKey}
            >
                <TabPane tab={t('dashboard.lightMode')} key="light">
                    <FormGroup>
                        <Toggle
                            value={theme === 'light-contrast'}
                            onChange={() => setTheme(theme === 'light' ? 'light-contrast' : 'light')}
                        />
                        <span>{t('dashboard.highContrastMode')}</span>
                    </FormGroup>
                </TabPane>
                <TabPane tab={t('dashboard.darkMode')} key="dark">
                    <FormGroup>
                        <Toggle
                            value={theme === 'dark-contrast'}
                            onChange={() => setTheme(theme === 'dark' ? 'dark-contrast' : 'dark')}
                        />
                        <span>{t('dashboard.highContrastMode')}</span>
                    </FormGroup>
                </TabPane>
            </Tabs>
        </div>
    );
};

ThemeToggle.propTypes = {
};

ThemeToggle.defaultProps = {
};

export default ThemeToggle;
