import { useContext } from 'react';

import { ThemingContext } from './ThemingContext';

export default () => {
    const context = useContext(ThemingContext);

    if (context === undefined) throw new Error('useTheme must be used within a ThemingProvider');

    return context;
};
