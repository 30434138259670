import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { adaptModelPortfolio } from '../adapters/adaptModelPortfolio';

const initialState = {
    data: {},
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useModelPortfolio = (options = {}) => {
    const {
        modelPortfolioId: modelPortfolioIdOption,
        adaptOptions: adaptOptionsOption = undefined,
        adaptData = adaptModelPortfolio,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getModelPortfolio = useCallback(async (
        modelPortfolioId = modelPortfolioIdOption,
        modelOptions = {},
    ) => {
        const { currencyId, adaptOptions = adaptOptionsOption } = modelOptions;

        if (!modelPortfolioId) return null;

        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        const params = { language };

        if (currencyId) params.currencyId = currencyId;

        try {
            const response = await SM.portfolioManagement('getModelPortfolio', [modelPortfolioId, params]);

            try {
                const adapted = adaptData({
                    data: response.data,
                    t,
                    getFormattedDate,
                    getFormattedNumber,
                    getFormattedCurrency,
                    ...adaptOptions,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: response.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return response.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        adaptData,
        adaptOptionsOption,
        modelPortfolioIdOption,
        getFormattedCurrency,
        getFormattedDate,
        getFormattedNumber,
        language,
        t,
    ]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getModelPortfolio();
    }, [getModelPortfolio, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getModelPortfolio,
    };
};
