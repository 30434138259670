import React from 'react';
import { WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Match } from 'types/common';
import { ResetPasswordTranslated } from './components/ResetPassword/ChangePassword';
import { useForgotPassword } from './hooks/useForgotPassword';
import { useChangePassword } from './hooks/useChangePassword';
import { ResetPasswordHookReturn } from './types';

interface ResetPasswordContainerProps extends WithTranslation{
    t: TFunction;
    match: Match<{ activationKey: string }>;
}

export const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = ({
    match,
    t,
}) => {
    let data: ResetPasswordHookReturn;

    if (!match?.params?.activationKey) {
        data = useChangePassword();
    } else {
        data = useForgotPassword({ match });
    }


    return (<ResetPasswordTranslated {...{ ...data, t }} />);
};
