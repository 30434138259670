import React, { useMemo } from 'react';
import uniq from 'lodash/uniq';
import {
    getPortfolioStatus,
    sortStatuses, CLOSED,
    IN_EXECUTION, NOT_IMPLEMENTED,
} from 'constants/portfolioStatuses';
import { usePortfolio } from 'domain/Portfolio';
import { useProposals } from 'hooks/useProposals';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import Preloader from 'components/Preloader';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import history from 'services/history';
import { PageNotFound } from '../../404';

const withPortfolioAccess = (Component) => (props) => {
    const { location: { state } } = props;
    const { portfolio: { data, isLoading, error } } = usePortfolio();
    const clientId = useSelector(contactIdSelector);

    const { data: proposalsData } = useProposals(clientId);

    const proposalsAdapted = adaptProposalsByPortfolioId(proposalsData);

    const proposalStatuses = sortStatuses(
        uniq((proposalsAdapted[data?.id] || []).map(({ status }) => status)),
    );

    const status = getPortfolioStatus({
        portfolioStatusId: data?.portfolioStatusId,
        isBreached: data?.isBreached,
        proposalStatus: proposalStatuses?.[0],
        isOrderBookBlocked: data?.hasPendingOrders,
    });

    const pageNotFound = useMemo(() => [
        CLOSED].includes(status), [status]);

    if (status === NOT_IMPLEMENTED) {
        history.push(`/portfolios/${data.id}/funding-information`, state);
    }

    if (status === IN_EXECUTION) {
        history.push(`/portfolios/${data.id}/pending-orders`, state);
    }

    if (data?.portfolioStatusId && pageNotFound) {
        return (
            <Preloader isLoading={isLoading} error={error}>
                <PageNotFound />
            </Preloader>
        );
    }

    return <Component status={status} {...props} />;
};


export default withPortfolioAccess;
