import ServerError from './ServerError';

const HandlerError = ({ setError, setLoading }) => (error) => {
    if ([setError, setLoading].every(((fn) => typeof fn === 'function'))) {
        setError(error.type !== undefined ? error : new ServerError(error));
        setLoading(false);
    } else {
        console.error('setError and setLoading should be function');
    }
};

export default HandlerError;
