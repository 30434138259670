import React, { useState, useCallback } from 'react';
import SM from 'services/ServiceManager';
import {
    Button, Modal, FileInput, notification,
} from 'ui-library';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { adaptFiles } from '../adapters/adaptFiles';


export const DocumentUpload = ({ contactId, onUpload }) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [inputWarning, setInputWarning] = useState();
    const { t } = useTranslation();
    const [uploadParams, setUploadParams] = useState();

    const hideModal = () => {
        setShowModal(false);
        setUploadParams();
        setError();
        setInputWarning();
        setIsLoading(false);
    };

    const uploadFile = useCallback(() => {
        setIsLoading(true);
        SM.documents('postDocument', [uploadParams])
            .then((response) => {
                const { data } = response;

                setIsLoading(false);
                notification.open({
                    content: `${uploadParams?.retrieveFileAsName} ${t('dashboard.documents.uploadSuccess')}`,
                });
                hideModal();
                onUpload(data);
            })
            .catch((err) => {
                setIsLoading(false);
                setUploadParams();
                const { status } = err.response;

                if (status === 422) {
                    const { data: { code, message } } = err.response;

                    if (code === 'ValidationFailed') {
                        setInputWarning(message);
                    }
                }
            });
    }, [uploadParams]);

    const onOk = useCallback(() => {
        if (!uploadParams || !uploadParams?.file) {
            setInputWarning(t('dashboard.documents.noFileUploadError'));

            return;
        }
        uploadFile();
    }, [uploadParams]);

    const onCancel = () => {
        hideModal();
    };

    const onInputChange = useCallback(async (files) => {
        setInputWarning();
        if (files.length === 0) {
            setUploadParams();

            return;
        }

        try {
            const fileParams = await adaptFiles(contactId, files);

            setUploadParams(fileParams);
        } catch (err) {
            setError(err);
        }
    }, [contactId]);

    return (
        <>
            <Button type="primary" size="small" onClick={() => setShowModal(true)}>
                {t('dashboard.documents.upload')}
            </Button>
            {showModal && (
                <Modal
                    title={t('dashboard.documents.uploadDocument')}
                    visible
                    okText={t('dashboard.documents.upload')}
                    cancelText={t('dashboard.documents.cancel')}
                    onOk={onOk}
                    onCancel={onCancel}
                >
                    <div className="documents-input">
                        <Preloader isLoading={isLoading} error={error} letChildrenToRenderOnError>
                            <FileInput
                                accept=".pdf, docx, .doc"
                                uploadText={t('dashboard.documents.selectFile')}
                                onChange={onInputChange}
                                error={inputWarning}
                            />
                        </Preloader>
                    </div>
                </Modal>
            )
            }
        </>
    );
};

DocumentUpload.propTypes = {
    contactId: PropTypes.number.isRequired,
    onUpload: PropTypes.func.isRequired,
};

DocumentUpload.defaultProps = {};
