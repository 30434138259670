import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { transformOptions } from 'utils';
import { useChangeStrategyConfig } from './useChangeStrategyConfig';

export const useStrategyList = (productId) => {
    const [errorStrategyList, setError] = useState(null);
    const [isLoadingStrategyList, setLoading] = useState(true);
    const [strategyList, setData] = useState([]);
    const {
        isStrategyWithinRiskBandwidth,
        showOnlyStrategiesWithinRiskBandwidths,
    } = useChangeStrategyConfig();

    const { i18n: { language } } = useTranslation();

    useEffect(() => {
        if (productId) {
            setLoading(true);

            ServiceManager.commonService('getRiskCategoriesByProduct', [productId, language])
                .then((response) => {
                    try {
                        const data = transformOptions(response.data);

                        setData(
                            showOnlyStrategiesWithinRiskBandwidths
                                ? data.filter((s) => isStrategyWithinRiskBandwidth(+s.value))
                                : data,
                        );
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }
    }, [productId, language]);

    return { strategyList, isLoadingStrategyList, errorStrategyList };
};
