import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptData } from '../pages/pages/Portfolios/pages/ModifyPortfolio/pages/AddPosition/adapters/adaptData';

export const useSecurityTypes = (options) => {
    const { withLiquidity = false } = (options || {});
    const [errorSecurityTypes, setError] = useState(null);
    const [isLoadingSecurityTypes, setLoading] = useState(true);
    const [dataSecurityTypes, setData] = useState({});

    const { i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        ServiceManager.commonService('getSecurityTypes', [i18n.language])
            .then((response) => {
                try {
                    setData(adaptData(response.data, { withLiquidity }));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language, withLiquidity]);

    return { dataSecurityTypes, isLoadingSecurityTypes, errorSecurityTypes };
};
