import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';

export const useLanguageId = () => {
    const { i18n } = useTranslation();

    const getLanguageId = useCallback(() => new Promise((resolve, reject) => {
        ServiceManager.commonService('getLanguages', [i18n.language])
            .then(({ data }) => {
                try {
                    resolve(data.find((item) => item.TwoLetterIsoCode === i18n.language));
                } catch (err) {
                    reject(err);
                    throw new AdapterError(err);
                }
            })
            .catch(reject);
    }), [i18n.language]);

    return { getLanguageId };
};
