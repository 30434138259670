import React, {
    createContext, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { getLocaleFromNavigator } from 'additiv-intl-formatting';

import localeService from './LocaleService';
import { getNumberSeparators } from './utils';

const LocaleContext = createContext({});

const { Provider, Consumer: LocaleConsumer } = LocaleContext;

const LocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState(localeService.localization);

    const onLangChange = () => {
        localeService.add('navigation', { priority: 3, locale: getLocaleFromNavigator() });
    };

    useEffect(() => {
        const subscription = localeService.subscribe(setLocale);

        // eslint-disable-next-line no-undef
        window.addEventListener('languagechange', onLangChange);

        return () => {
            // eslint-disable-next-line no-undef
            window.removeEventListener('languagechange', onLangChange);
            subscription.unsubscribe();
        };
    }, []);

    const numberSeparators = useMemo(() => getNumberSeparators(locale.locale), [locale]);

    const value = {
        ...locale,
        numberSeparators,
    };

    return <Provider value={value}>{children}</Provider>;
};

LocaleProvider.propTypes = {
    children: PropTypes.node,
};

LocaleProvider.defaultProps = {
    children: null,
};

export { LocaleProvider, LocaleConsumer, LocaleContext };
