import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { date, object, string } from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';
import useFormatting from 'locale/useFormatting';

export const useGoalForm = ({ defaultData, minimumInvestmentValue }) => {
    const [values, setValues] = useState(defaultData);
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();
    const minimumInvestmentValidate = useCallback((v) => +v >= minimumInvestmentValue,
        [minimumInvestmentValue]);
    const schema = useMemo(() => object().shape({
        goalName: string().required(t('onBoarding.goal.validation.goalNameText')),
        targetValue: string().required(t('onBoarding.goal.validation.targetValueText')),
        targetDate: date().required(t('onBoarding.goal.validation.targetDateText')),
        initialInvestment: string().required(t('onBoarding.goal.validation.initialInvestmentText')).test('tt', `${t('portfolio.minimumInvestment.error.part1')} ${getFormattedNumber(minimumInvestmentValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        })}${t('portfolio.minimumInvestment.error.part2')}`, minimumInvestmentValidate),
        recurringPaymentFrequency: object().required(t('onBoarding.goal.validation.recurringPaymentFrequencyText')),
        recurringPayment: string().required(t('onBoarding.goal.validation.recurringPaymentText')),
        selectedCurrency: object().required(t('validation.mandatoryField')),
    }), [t, minimumInvestmentValidate, getFormattedNumber, minimumInvestmentValue]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, control, formState, trigger, getValues,
    } = useForm({
        resolver, reValidateMode: 'onChange', shouldUnregister: false, defaultValues: defaultData,
    });

    useEffect(() => {
        register('goalName');
        register('targetValue');
        register('targetDate');
        register('initialInvestment');
        register('recurringPaymentFrequency');
        register('recurringPayment');
        register('selectedCurrency');
    }, [register]);
    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset(defaultData);
            setValues(defaultData);
        }
    }, [defaultData, reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        setValue(type, value);
        if (type === 'initialInvestment') {
            trigger(type);
        }
        if (formState.isSubmitted) trigger(type);
        setValues(getValues());
    }, [setValue, trigger, formState.isSubmitted, getValues]);

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values,
        onPartiallyChange: reset,
    };
};
