import React from 'react';
import {
    Title, Button, Infobox,
} from 'ui-library';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PasswordInput } from 'components/PasswordInput';
import AuthWrapper from 'components/AuthWrapper';
import { PasswordChangeFlow, ResetPasswordHookReturn } from '../../types';

import './ChangePassword.css';


interface ResetPasswordProps extends WithTranslation, ResetPasswordHookReturn {}

export const ChangePassword: React.FC<ResetPasswordProps> = ({
    view,
    errors,
    isGeneralError,
    onSubmit,
    t,
    generalErrorMessage,
    onChangeOldPasswordInput,
    onChangePasswordInput,
    onChangeConfirmPasswordInput,
    resetView,
    successView,
    isLoading,
    processType,
}) => {
    if (view === 0) {
        return (
            <AuthWrapper>
                <div className="ResetPassword content-box">
                    <Title type={2}>{t(resetView.title)}</Title>
                    {isGeneralError && (
                        <Infobox accent error>
                            {generalErrorMessage === undefined
                                ? t('resetPassword.somethingWrong')
                                : t(generalErrorMessage)}
                        </Infobox>
                    )}
                    <p className="info-text">{t(resetView.description)}</p>
                    <form onSubmit={onSubmit}>
                        {processType === PasswordChangeFlow.CHANGE_PASSWORD && (
                            <PasswordInput
                                label={t('resetPassword.oldPassword')}
                                placeholder={t('resetPassword.oldPasswordPlaceholder')}
                                error={t(errors.oldPassword)}
                                id="oldPassword"
                                onChange={onChangeOldPasswordInput}
                            />
                        )}
                        <PasswordInput
                            label={t('resetPassword.newPassword')}
                            placeholder={t('resetPassword.password')}
                            error={t(errors.password)}
                            id="password"
                            onChange={onChangePasswordInput}
                        />
                        <PasswordInput
                            label={t('resetPassword.confirmNewPassword')}
                            placeholder={t('resetPassword.password')}
                            error={t(errors.confirmPassword)}
                            id="password2"
                            onChange={onChangeConfirmPasswordInput}
                        />
                        <div className="buttons buttons-inline button-group button-group--horiz">
                            <Button type="primary" htmltype="submit" loading={isLoading}>
                                {t('resetPassword.save')}
                            </Button>
                            <Button
                                type="secondary"
                                onClick={successView.onBack}
                            >
                                {t(resetView.backURLButtonLabel)}
                            </Button>
                        </div>
                    </form>
                </div>
            </AuthWrapper>
        );
    }

    return (
        <AuthWrapper>
            <div className="ResetPassword content-box">
                <Title type={2}>{t(successView.title)}</Title>
                <p>{t(successView.description)}</p>
                <Button
                    type="primary"
                    onClick={successView.onBack}
                >
                    {t(successView.backURLButtonLabel)}
                </Button>
            </div>
        </AuthWrapper>
    );
};

ChangePassword.displayName = 'ResetPassword';

export const ResetPasswordTranslated = withTranslation()(ChangePassword);
