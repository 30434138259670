import {
    LocaleService,
    getLocaleFromCookie,
    getLocaleFromNavigator,
} from 'additiv-intl-formatting';

const locales = {
    cookie: {
        priority: 2,
        locale: getLocaleFromCookie('locale'),
    },
    navigation: {
        priority: 3,
        locale: getLocaleFromNavigator(),
    },
};

const localeService = new LocaleService(locales);

export default localeService;
