import { NA } from '../utils/formatting';

export const adaptBankInfo = (bank, bankBranch, country) => ({
    BankName: bank?.Name ?? NA,
    BIC: bank?.Bic ?? NA,
    BankAdress: {
        AddressLine1: bankBranch?.AddressLine1 ?? NA,
        AddressLine2: bankBranch?.AddressLine2 ?? NA,
        AddressLine3: bankBranch?.AddressLine3 ?? NA,
        AddressLine4: bankBranch?.AddressLine4 ?? NA,
        Country: country?.Name ?? NA,
    },
});
