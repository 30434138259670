import { CellSwitch } from 'components/renderers';
import {
    sorterByDate, sorterNumber, sorterString, SORT_DIRECTION_DESC,
} from 'utils/sorting';

export const transactionsColumns = (t) => [
    {
        key: 'Name',
        title: t('confirmation.transactionColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Date',
        title: t('confirmation.transactionColumns.date'),
        sortable: true,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 90,
    },
    {
        key: 'Type',
        title: t('confirmation.transactionColumns.type'),
        sorter: sorterString('Type'),
        width: 70,
    },
    {
        key: 'Number',
        title: t('confirmation.transactionColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
        width: 100,
    },
    {
        key: 'Price',
        title: t('confirmation.transactionColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
        width: 100,
    },
    {
        key: 'FxRate',
        title: t('confirmation.transactionColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
        width: 100,
    },
    {
        key: 'Total',
        title: t('confirmation.transactionColumns.total'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
        width: 150,
    },
    {
        key: 'Fee',
        minWidth: 50,
        title: t('confirmation.transactionColumns.fee'),
        className: 'text-right',
        sorter: sorterNumber('Fee'),
    },
];

export const transactionsMobileColumns = (t) => [
    {
        key: 'Name',
        title: t('confirmation.transactionColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 135,
    },
    {
        key: 'Total',
        title: t('confirmation.transactionColumns.total'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Total'),
    },
];

// TODO: unused code below, delete after approval
export const CHANGE_STRATEGY = 'change-strategy';

export const securityTransactionsColumns = [
    {
        key: 'name',
        title: 'Instrument name',
        sortable: true,
        render: CellSwitch,
        width: 350,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        title: 'Asset class',
        key: 'assetClass',
        width: 100,
    },
    {
        title: 'Units',
        key: 'units',
        className: 'text-right',
        width: 100,
    },
    {
        title: 'Price',
        key: 'price',
        className: 'text-right',
        width: 100,
    },
    {
        key: 'amount',
        title: 'Amount',
        className: 'text-right',
        width: 180,
    },
    {
        key: 'amountCurrency',
        title: 'Amount in portfolio currency',
        className: 'text-right',
        width: 220,
    },
];
