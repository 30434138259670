import { validateStringData, NA, validateData } from 'utils/formatting';
import { LIQUIDITY_TYPE } from 'constants/constants';
import { getParent } from 'utils';
import { sorterByDateCommon } from 'utils/sorting';

const validateType = (type) => {
    if (type === 'Credit') {
        return 'transactions.buy';
    }

    if (type === 'Debit') {
        return 'transactions.sell';
    }

    return type;
};

export const adaptTransactions = (data, options = {}) => {
    const { securities, baseUrl } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    const transactions = data?.Transactions || [];
    const currency = data?.Currency?.CurrencyCode;
    const securitiesCurrency = (securities || [])
        .reduce((acc, item) => ({ ...acc, [item.Id]: item }), {});
    let balance = 0;

    return transactions
        .sort((...rest) => sorterByDateCommon('Date')(...rest))
        .map((item) => {
            const type = t(validateType(validateStringData(item?.Type?.Name)));
            const multiplier = item?.Type?.Id === 1 ? 1 : -1;
            const validatePrice = validateStringData(item?.Price);
            const itemType = securitiesCurrency[item?.SecurityId]?.Type?.Name;
            const priceCurrencyCode = securitiesCurrency[item?.SecurityId]?.Currency?.CurrencyCode;
            const formattedNumber = item?.Quantity
                ? getFormattedNumber(item?.Quantity, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                })
                : NA;
            const credit = item?.Type?.Id === 1 ? item?.Value : undefined;
            const debit = item?.Type?.Id === 1 ? undefined : item?.Value;

            balance += multiplier * item?.Value;

            return {
                Name: {
                    label: validateStringData(item?.SecurityName),
                    link:
                        itemType === LIQUIDITY_TYPE
                            ? undefined
                            : `${baseUrl}/${item?.SecurityId}/2/overview`,
                    maxLength: 30,
                },
                Date: getFormattedDate(item?.Date),
                Id: item?.SecurityId,
                Number: formattedNumber,
                Price: getFormattedCurrency(validatePrice, { currency: priceCurrencyCode }),
                Value: getFormattedNumber(multiplier * item?.Value, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Total: getFormattedCurrency(item?.Value, {
                    currency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Balance: getFormattedNumber(balance, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                FxRate: getFormattedNumber(item?.FxRate, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                }),
                Type: type,
                Credit: credit && getFormattedNumber(credit, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Debit: debit && getFormattedNumber(debit, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Isin: validateData(securitiesCurrency[item?.SecurityId]?.Isin),
                Status: item?.Status.Name,
                SubAssetClass: securitiesCurrency[item?.SecurityId].AssetClass?.Name,
                Country: securitiesCurrency[item?.SecurityId]?.Country?.Name,
                Region: securitiesCurrency[item?.SecurityId]?.Country?.Continent?.Name,
                Sector: getParent(securitiesCurrency[item?.SecurityId]?.Sector).Name,
                SubSector: securitiesCurrency[item?.SecurityId]?.Sector?.Name,
            };
        });
};
