import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { useClientProfile } from 'prodivers/clientProfile';
import { adaptRMData } from '../adapters/adaptProfile';

export const useWithClientProfile = (clientId) => {
    const [errorMember, setError] = useState(null);
    const [isLoadingMember, setLoading] = useState(true);
    const [memberData, setMember] = useState({});
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { getFormattedPhoneNumber } = useFormatting();

    const { data: dataClient, isLoading, error } = useClientProfile();
    const data = useMemo(() => ({ ...dataClient, RMProfile: memberData }),
        [dataClient, memberData]);

    const getMemberInfo = useCallback(async () => {
        let responseMemberPicture;

        try {
            const responseMember = await ServiceManager.contactManagement('getContactMembers', [clientId]);
            const memberId = responseMember?.data?.[0]?.Id;

            if (memberId) {
                responseMemberPicture = await ServiceManager.contactManagement('getClientMemberPicture', [clientId, memberId]);
            }
            try {
                setMember(adaptRMData(
                    responseMember.data?.[0],
                    t,
                    getFormattedPhoneNumber,
                    responseMemberPicture?.data?.ImageContentBase64,
                ));
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);
        }
    }, [clientId, getFormattedPhoneNumber, i18n.language, t]);

    useEffect(() => {
        setError(null);
        setLoading(true);

        getMemberInfo();
    }, [getMemberInfo]);

    return {
        data,
        isLoading: isLoading || isLoadingMember,
        error: error || errorMember,
    };
};
