import PropTypes from 'prop-types';
import { renderers } from 'ui-library';
import { isToday, isTomorrow, isYesterday } from 'utils';
import { formatDate, formatTime } from 'utils/datetime';

const { CellWithMessageDate } = renderers;

const CellAdaptedMessageDate = (t, getFormattedDate = null, getFormattedTime = null) => ({
    date,
    isReplied,
}) => {
    if (isToday(date)) {
        return CellWithMessageDate({
            date: getFormattedTime ? getFormattedTime(date) : formatTime(date),
            isReplied,
        });
    }
    if (isTomorrow(date)) return CellWithMessageDate({ date: t('messages.tomorrow'), isReplied });
    if (isYesterday(date)) return CellWithMessageDate({ date: t('messages.yesterday'), isReplied });

    return CellWithMessageDate({
        date: getFormattedDate ? getFormattedDate(date) : formatDate(date),
        isReplied,
    });
};

CellAdaptedMessageDate.propTypes = {
    isReplied: PropTypes.bool,
    date: PropTypes.string,
};

CellAdaptedMessageDate.defaultProps = {
    isReplied: true,
    date: null,
};

export default CellAdaptedMessageDate;
