/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import './CellPDFView.css';

const CellPDFView = ({ value, onDownload }, { id, contactId }) => {
    if (value === undefined) return null;

    return (
        <button
            type="button"
            className="table-cell_linked cell-pdf-view"
            onClick={() => onDownload(id, contactId)}
        >
            {value}
        </button>
    );
};

CellPDFView.propTypes = {
    value: PropTypes.string,
    onDownload: PropTypes.func,
};

CellPDFView.defaultProps = {
    value: '',
    onDownload: () => {},
};

export default CellPDFView;
