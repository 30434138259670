import {
    getfromDataObject,
    validateNumber,
} from 'utils/formatting';
import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';
import { adaptProjection } from 'adaptors/adaptAllocations';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';

export const adaptProposalDetails = (data, options = {}) => {
    const { currency: Currency, securitiesValue, portfolioValue } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    const currency = Currency?.CurrencyCode;
    const currencyId = Currency?.Id;
    const positions = data?.Positions || [];

    return {
        id: data?.Id,
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        proposalId: data?.Id,
        strategy: getfromDataObject(data, 'RiskCategory.Name'),
        createdDate: getFormattedDate(data?.CreateDate),
        constraints: data?.OptimizationConstraints || {},
        riskCategoryId: data?.RiskCategory?.Id,
        modelPortfolioId: data?.ModelPortfolioId,
        investmentAllocation: {
            chart: adaptAllocationGroups({
                positions,
                currency,
                t,
                getFormattedCurrency,
                securitiesValue: securitiesValue || 0,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
            }),
            currency,
        },
        investmentAllocations: (data?.Positions || []).map((item) => ({
            InstrumentId: item.Security.Id,
            Allocation: Number(item.Allocation),
        })),
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: portfolioValue,
        }),
        riskReturn: [
            +(validateNumber(data?.CalculatedRisk) * 100),
            +(validateNumber(data?.CalculatedExpectedReturn) * 100),
        ],
    };
};
