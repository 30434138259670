import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import {
    SUCCESS,
    UNEXPECTED_ERROR,
    UNEXPECTED_ERROR_OR_INVALID_PASSCODE,
} from 'static/requestResults';
import ServiceManager from 'services/ServiceManager';
import { getErrorModelByStatus } from 'error/authErrors';
import { FuturaePasscode } from '../FuturaePasscode';
import { RememberMe } from '../RememberMe/RememberMe';
import { FUTURAE, MTAN } from '../loginTypes';
import { MTanPasscode } from '../MTan';

const REMEMBER_ME = 'REMEMBER_ME';
const PASSCODE = 'PASSCODE';

export class LoginPasscode extends Component {
    constructor(props) {
        super(props);

        let view = PASSCODE;

        if (props.provider === FUTURAE) {
            if (props.isTrustedDevice === undefined) {
                view = REMEMBER_ME;
            }
        }

        this.state = {
            isLoading: false,
            error: undefined,
            isTrustedDevice: props.isTrustedDevice,
            view,
        };
        this.validatePasscode = this.validatePasscode.bind(this);
    }

    setTrustedBrowser = (value) => {
        this.setState({
            isTrustedDevice: value,
            view: PASSCODE,
        });
    };

    validatePasscode = (passcode) => {
        const {
            contactId, onPasscodeSuccess, onSuccess, preAuthenticateSessionId,
        } = this.props;
        const { isTrustedDevice } = this.state;

        this.setState({
            isLoading: true,
        });

        ServiceManager.Security('authenticate', [{
            urlParams: { contactId },
            payload: {
                Passcode: passcode,
                SetTrusted: isTrustedDevice,
                PreAuthenticateSessionId: preAuthenticateSessionId,
            },
        }])
            .then((response) => {
                const resultType = get('data.Result', response);
                const error = getErrorModelByStatus(resultType);

                this.setState({
                    isLoading: false,
                });
                if (error !== undefined) {
                    this.setState({
                        error: error.message,
                    });
                } else if (resultType === SUCCESS) {
                    onPasscodeSuccess(response.data);
                    onSuccess();
                } else {
                    this.setState({
                        error: getErrorModelByStatus(UNEXPECTED_ERROR).message,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error: getErrorModelByStatus(
                        UNEXPECTED_ERROR_OR_INVALID_PASSCODE,
                    ).message,
                    isLoading: false,
                });
            });
    };

    render() {
        const { isLoading, error, view } = this.state;
        const {
            passcodeLength, provider, onLogin, t,
        } = this.props;
        const Passcode = provider === MTAN ? MTanPasscode : FuturaePasscode;

        return (
            <>
                {view === PASSCODE ? (
                    <Passcode
                        isLoading={isLoading}
                        error={error}
                        isMTan={provider === MTAN}
                        onSubmit={this.validatePasscode}
                        passcodeLength={passcodeLength}
                        onCancel={onLogin}
                    />
                ) : (
                    <RememberMe
                        title={t('enrollDevice.twoFactorApproval')}
                        isInline={false}
                        onSubmit={this.setTrustedBrowser}
                        t={t}
                    />
                )}
            </>
        );
    }
}

LoginPasscode.propTypes = {
    onPasscodeSuccess: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    passcodeLength: PropTypes.number.isRequired,
    contactId: PropTypes.number.isRequired,
    preAuthenticateSessionId: PropTypes.string.isRequired,
    isTrustedDevice: PropTypes.bool,
    provider: PropTypes.string.isRequired,
    onLogin: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

LoginPasscode.defaultProps = {
    isTrustedDevice: undefined,
};

LoginPasscode.displayName = 'LoginPasscode';

export default LoginPasscode;
