import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Title, Button } from 'ui-library';
import { Match } from 'types/common';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import { Loading } from 'components/Loading';
import AuthWrapper from 'components/AuthWrapper';
import { userSelect } from './selectors';
import { UserData, ActivationReturn } from './types';
import './UserActivation.css';

interface UserActivationProps extends WithTranslation {
    match: Match<{ activationKey: string }>
}

const UserActivationNT: React.FC<UserActivationProps> = ({
    match: { params: { activationKey } },
    t,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<UserData | null>(null);
    const [activated, setActivated] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        ServiceManager.Security(
            'activateUser',
            [{
                payload: { ActivationKey: decodeURIComponent(activationKey) },
            }],
        ).then(({ data }: AxiosResponse<ActivationReturn>) => {
            setUser(userSelect(data));
            setActivated(true);
            setLoading(false);
        }).catch(() => {
            setActivated(false);
            setLoading(false);
        });
    }, []);

    if (isLoading) return <Loading />;

    return (
        <>
            {activated ? (
                <AuthWrapper>
                    <div className="UserActivation content-box">
                        <Title type={2}>{t('activation.userActivationSuccessful')}</Title>
                        <p className="m-b-md">
                            {`${t('activation.welcomeToBank')} ${user?.username} ${t('activation.accountCreated')}`}
                        </p>
                        {user?.isPasswordSet ? (
                            <Button
                                type="primary"
                                onClick={() => history.push('/login')}
                            >
                                {t('activation.logIn')}
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => history.push(
                                    `/reset-password/${user?.username}/${activationKey}`,
                                )}
                            >
                                {t('activation.setPassword')}
                            </Button>
                        )}
                    </div>
                </AuthWrapper>
            ) : (
                <AuthWrapper>
                    <div className="UserActivation content-box">
                        <Title type={2}>{t('activation.activationError')}</Title>
                        <p>
                            {t('activation.notActivatedPleaseContact')}
                            &nbsp;
                            <Link to="/support">{t('activation.support')}</Link>
                            .
                        </p>
                    </div>
                </AuthWrapper>
            )}
        </>
    );
};

export const UserActivation = withTranslation()(UserActivationNT);
