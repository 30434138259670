import { sum } from 'utils';
import {
    getDatesDifference, getFirstDayOfUnit, getFirstDayOfYear, subtractUnits,
} from '../../../utils/datetime';

const filterByBookingType = (data) => data.filter((item) => item?.BookingType === '241');

export const adaptCashFlows = (options = {}) => {
    const {
        historicalPerformance,
        depositMonth, depositYTD, depositSI,
        transferInMonth, transferInYTD, transferInSI,
        withdrawalsMonth, withdrawalsYTD, withdrawalsSI,
        transferOutMonth, transferOutYTD, transferOutSI,
    } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);

    const monthFrom = subtractUnits(getFirstDayOfUnit('month'), 1, 'day');
    const YTDFrom = subtractUnits(getFirstDayOfYear(), 1, 'day');
    const YTDIdx = (historicalPerformance || [])
        .findIndex(({ Date }) => getDatesDifference(Date, YTDFrom, 'days') >= 0);
    const MonthIdx = (historicalPerformance || [])
        .findIndex(({ Date }) => getDatesDifference(Date, monthFrom, 'days') >= 0);

    // Beginning value
    const beginYTD = historicalPerformance?.[YTDIdx]?.Amount || 0;
    const beginMonth = historicalPerformance?.[MonthIdx]?.Amount || 0;
    const beginSI = 0;

    // Deposits (Cash)
    const depositValueMonth = sum(filterByBookingType(depositMonth?.Transactions || []), 'Value');
    const depositValueYTD = sum(filterByBookingType(depositYTD?.Transactions || []), 'Value');
    const depositValueSI = sum(filterByBookingType(depositSI?.Transactions || []), 'Value');

    // Transfer in (Positions)
    const transferInValueMonth = sum(filterByBookingType(transferInMonth?.Transactions || []), 'Value');
    const transferInValueYTD = sum(filterByBookingType(transferInYTD?.Transactions || []), 'Value');
    const transferInValueSI = sum(filterByBookingType(transferInSI?.Transactions || []), 'Value');

    // Withdrawals (Cash)
    const withdrawalsValueMonth = sum(filterByBookingType(withdrawalsMonth?.Transactions || []), 'Value');
    const withdrawalsValueYTD = sum(filterByBookingType(withdrawalsYTD?.Transactions || []), 'Value');
    const withdrawalsValueSI = sum(filterByBookingType(withdrawalsSI?.Transactions || []), 'Value');

    // Transfer out ( (Positions)
    const transferOutValueMonth = sum(filterByBookingType(transferOutMonth?.Transactions || []), 'Value');
    const transferOutValueYTD = sum(filterByBookingType(transferOutYTD?.Transactions || []), 'Value');
    const transferOutValueSI = sum(filterByBookingType(transferOutSI?.Transactions || []), 'Value');

    // End value cash flows
    const endCashFlowsMonth = beginMonth + depositValueMonth + transferInValueMonth
        - withdrawalsValueMonth - transferOutValueMonth;
    const endCashFlowsYTD = beginYTD + depositValueYTD + transferInValueYTD
        - withdrawalsValueYTD - transferOutValueYTD;
    const endCashFlowsSI = beginSI + depositValueSI + transferInValueSI
        - withdrawalsValueSI - transferOutValueSI;

    // End amount
    const endAmount = [...(historicalPerformance || [])].pop()?.Amount || 0;

    // End value cash flows
    const performanceMonth = endAmount - endCashFlowsMonth;
    const performanceYTD = endAmount - endCashFlowsYTD;
    const performanceSI = endAmount - endCashFlowsSI;

    return [
        {
            KeyValue: t('cashFlows.beginningValue'),
            CurrentMonth: getFormattedNumber(beginMonth),
            YTD: getFormattedNumber(beginYTD),
            SinceInception: getFormattedNumber(beginSI),
        },
        {
            KeyValue: t('cashFlows.depositsCash'),
            CurrentMonth: getFormattedNumber(depositValueMonth),
            YTD: getFormattedNumber(depositValueYTD),
            SinceInception: getFormattedNumber(depositValueSI),
        },
        {
            KeyValue: t('cashFlows.transferInPositions'),
            CurrentMonth: getFormattedNumber(transferInValueMonth),
            YTD: getFormattedNumber(transferInValueYTD),
            SinceInception: getFormattedNumber(transferInValueSI),
        },
        {
            KeyValue: t('cashFlows.withdrawalsCash'),
            CurrentMonth: getFormattedNumber(withdrawalsValueMonth),
            YTD: getFormattedNumber(withdrawalsValueYTD),
            SinceInception: getFormattedNumber(withdrawalsValueSI),
        },
        {
            KeyValue: t('cashFlows.transferOutPositions'),
            CurrentMonth: getFormattedNumber(transferOutValueMonth),
            YTD: getFormattedNumber(transferOutValueYTD),
            SinceInception: getFormattedNumber(transferOutValueSI),
        },
        {
            KeyValue: t('cashFlows.endValueCashFlows'),
            CurrentMonth: getFormattedNumber(endCashFlowsMonth),
            YTD: getFormattedNumber(endCashFlowsYTD),
            SinceInception: getFormattedNumber(endCashFlowsSI),
        },
        {
            KeyValue: t('cashFlows.performance'),
            CurrentMonth: getFormattedNumber(performanceMonth),
            YTD: getFormattedNumber(performanceYTD),
            SinceInception: getFormattedNumber(performanceSI),
        },
        {
            KeyValue: t('cashFlows.endValue'),
            CurrentMonth: getFormattedNumber(endAmount),
            YTD: getFormattedNumber(endAmount),
            SinceInception: getFormattedNumber(endAmount),
        },
    ];
};
