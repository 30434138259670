import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLocale } from 'ui-library';
import { importLocale } from 'utils/datetime';
import { languagesAdapt } from '../adaptors/languagesAdapt';
import { changeLanguageDirection } from '../utils/languageDirection';

export const useLanguageSwitcher = () => {
    const { i18n, t } = useTranslation();

    const languages = useMemo(() => languagesAdapt(
        global.availableLanguages, t, (lang) => i18n.dir(lang),
    ), [t, i18n]);

    const changeLanguage = useCallback(
        (lang) => {
            i18n.changeLanguage(lang);
            importLocale(lang);
            changeLocale(lang);
            changeLanguageDirection(i18n.dir());
        },
        [i18n.language, i18n.dir],
    );

    useEffect(() => {
        importLocale(i18n.language);
        changeLocale(i18n.language);
        changeLanguageDirection(i18n.dir());
    }, []);

    useEffect(() => {
        try {
            if (!global.availableLanguages.map(({ value }) => value).includes(i18n.language)) {
                if (i18n.options.fallbackLng && i18n.options.fallbackLng.length) {
                    changeLanguage(i18n.options.fallbackLng[0]);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [i18n.language]);

    return {
        languages, changeLanguage, language: i18n.language, t,
    };
};
