import { filter } from 'lodash/fp';
import { validateStringData } from 'utils/formatting';
import { mapIdToProposalStatus } from '../constants';

export const adaptProposals = (data, portfolioId, getFormattedDate, t) => {
    const filteredData = filter(
        {
            PortfolioProposalStatusId: 2,
            PortfolioId: Number(portfolioId),
        },
        data,
    );

    return filteredData.map(({
        CreateDate, Name, PortfolioProposalStatusId, Id,
    }) => ({
        Id,
        Name: {
            label: validateStringData(Name),
            link: `/portfolios/${portfolioId}/proposal/${Id}`,
            maxLength: 90,
        },
        Date: getFormattedDate(CreateDate),
        Status: t(mapIdToProposalStatus[PortfolioProposalStatusId]),
    }));
};
