import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useCaptcha } from 'hooks/useCaptcha';
import { FieldRenderer } from '../Field/index';
import './UserAndPassword.css';
import { PasswordField } from '../PasswordField';

const UserAndPassword = ({
    submitLabel,
    title,
    rememberMe,
    submitting,
    handleSubmit,
    error,
    info,
    onSubmit,
    bottomLinks,
}) => {
    const { t } = useTranslation();
    const { getCaptchaToken } = useCaptcha();
    const dispatch = useDispatch();

    const withCaptcha = async (formValues) => {
        const captchaToken = await getCaptchaToken();

        if (captchaToken) {
            formValues.captchaToken = captchaToken;
        }

        return onSubmit(formValues, dispatch);
    };

    const submitForm = (e) => {
        e.preventDefault();
        handleSubmit(withCaptcha)();
    };

    return (
        <form onSubmit={submitForm}>
            <div className="content-pane">
                {title !== null && <Title type={2}>{t(title)}</Title>}
                {info}
                {submitting || !error ? null : error}
                <Field
                    name="username"
                    type="text"
                    id="username"
                    autoComplete="off"
                    label={t('common.USERNAME')}
                    component={FieldRenderer}
                />
                <br />
                <Field
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="off"
                    label={t('common.PASSWORD')}
                    component={PasswordField}
                />
                {rememberMe}
                <br />
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button type="primary" htmltype="submit" disabled={submitting}>
                        {t(submitLabel)}
                    </Button>
                    {bottomLinks}
                    {/* <div className="or-line">
                        <span>{t('login.or')}</span>
                    </div>
                    <Button type="secondary" onClick={onSignUp} disabled={submitting}>
                        {t('login.signUp')}
                    </Button> */}
                </div>
            </div>
        </form>
    );
};

UserAndPassword.propTypes = {
    bottomLinks: PropTypes.instanceOf(Object),
    error: PropTypes.instanceOf(Object),
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    rememberMe: PropTypes.func,
    info: PropTypes.string,
    title: PropTypes.string,
    submitLabel: PropTypes.string.isRequired,
};

UserAndPassword.defaultProps = {
    bottomLinks: null,
    rememberMe: null,
    error: null,
    info: null,
    title: null,
};

UserAndPassword.displayName = 'UserAndPassword';

export default UserAndPassword;
