import { useState, useEffect } from 'react';
import ServiceManager from 'services/ServiceManager';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';
import HandlerError from '../errors/HandlerError';
import ServerError from '../errors/ServerError';

export interface Setting {
    Key?: string,
    Value?: string,
    Group?: string
}

export const useSetting = ({ group, key } : { group: string, key: string }): Setting => {
    const [data, setData] = useState<Setting | undefined>();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const response: AxiosResponse<Setting> = await
                ServiceManager.commonService(
                    'getGlobalSettingByGroupAndKey',
                    [
                        group, key,
                    ],
                );

                setData(response.data);
            } catch (err: any) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: (val) => dispatch({ type: 'setError', payload: val }),
                        setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                    }),
                )(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }
        })();
    }, []);

    return {
        ...data,
    };
};
