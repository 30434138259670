import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { sorterByDateCommon } from 'utils/sorting';
import { NEW_PROPOSAL_ID, BLOCKED, CLOSED } from 'constants/portfolioStatuses';
import InfoboxWithButton from '../InfoboxWithButton';

function NewProposalInfobox(props) {
    const {
        proposals,
        portfolios,
    } = props;
    const { t } = useTranslation();
    const proposalsActive = useMemo(() => proposals
        .filter((item) => {
            const foundPortfolio = portfolios && portfolios.find(
                (portfolio) => portfolio.Id === item.PortfolioId
                    && portfolio.Status.value !== (BLOCKED || CLOSED),
            );

            return (item.PortfolioProposalStatusId === NEW_PROPOSAL_ID && foundPortfolio)
                && item.PortfolioId;
        })
        .sort(sorterByDateCommon('CreateDate')), [proposals, portfolios]);

    const proposalsByPortfolio = useMemo(() => proposalsActive.reduce((acc, item) => ({
        ...acc,
        [item.PortfolioId]: (acc[item.PortfolioId] || 0) + 1,
    }), {}), [proposalsActive]);
    const reviewProposals = useCallback(() => {
        if (proposalsActive.length === 1) {
            const { PortfolioId, Id } = proposalsActive[0];

            history.push(`/portfolios/${PortfolioId}/proposal/${Id}`);

            return;
        }
        if (Object.keys(proposalsByPortfolio)?.length > 1) {
            history.push('/portfolios');

            return;
        }
        const portfolioWithProposals = Object.keys(proposalsByPortfolio)[0];

        history.push(`/portfolios/${portfolioWithProposals}`, { scrollToProposals: true });
    }, [proposalsActive, proposalsByPortfolio]);

    if (proposalsActive.length === 0 || !portfolios) return null;

    return (
        <div className="new-proposal-infobox">
            <InfoboxWithButton buttonText={t('dashboard.review')} buttonProps={{ onClick: reviewProposals }}>
                {t('dashboard.infoMessage')}
            </InfoboxWithButton>
        </div>
    );
}

NewProposalInfobox.propTypes = {
    proposals: PropTypes.arrayOf(PropTypes.shape({
        CreateDate: PropTypes.string,
        PortfolioId: PropTypes.number,
        PortfolioProposalStatusId: PropTypes.number,
    })),
    portfolios: PropTypes.arrayOf(PropTypes.shape({
        CreateDate: PropTypes.string,
        PortfolioId: PropTypes.number,
        PortfolioProposalStatusId: PropTypes.number,
    })),
};

NewProposalInfobox.defaultProps = {
    proposals: [],
    portfolios: undefined,
};

export default NewProposalInfobox;
