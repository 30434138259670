import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Column, Title, Row,
} from 'ui-library';
import { useSelector } from 'react-redux';
import { useBankInfo } from 'hooks/useBankInfo';
import { contactIdSelector } from 'redux/auth/authSelectors';
import './FundingInformation.css';
import ContentBox from 'ui-library/dist/components/ContentBox';
import Preloader from 'components/Preloader';
import { useClientProfile } from 'prodivers/clientProfile';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import PageHeader from 'components/PageHeader';
import { useAccounts } from '../SinglePortfolioView/pages/PositionDetails/hooks/useAccounts';

const FundingInformation = (props) => {
    const { t } = useTranslation();
    const clientId = useSelector(contactIdSelector);
    const {
        match: { params: { portfolioId } },
        location: { state },
    } = props;
    const fromReview = state?.fromReview || false;

    const { ...information } = useClientProfile();

    const {
        dataRaw: accounts,
        isLoading: isLoadingAccounts,
        errorAccounts,
    } = useAccounts(clientId, portfolioId);

    const Iban = useMemo(() => accounts?.filter(
        ({ Account: { Type } }) => Type.Id === 1,
    )?.[0]?.Account?.Iban, [accounts]);

    const {
        isLoadingBankInfo, errorBankInfo, bankInfo,
    } = useBankInfo({ contactId: clientId });

    const handleBackClick = () => {
        const fromOrigin = state && state?.fromOrigin;
        const currentPage = state && state?.currentPage;
        const currentTab = state && state?.currentTab;

        if (fromReview) {
            history.push('/risk-profile', { step: 3 });

            return;
        }

        if (!fromOrigin) {
            history.push('/portfolios');

            return;
        }

        history.push(`/${fromOrigin}`, { currentPage, currentTab });
    };

    return (
        <Preloader
            isLoading={isLoadingBankInfo || isLoadingAccounts}
            error={errorBankInfo || errorAccounts}
        >
            <ContentBox className="funding-information">
                <PageHeader title={t('fundingInformation.title')} />
                <div className="funding-information-content">
                    <Row>
                        <Column size="12">
                            <div className="group-title funding-information-details">
                                <Title type={3}>{t('fundingInformation.details')}</Title>
                            </div>
                        </Column>
                    </Row>
                    <Row className="bank-info">
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('onBoarding.iban')}</Title>
                                <p className="bank-info-description">{Iban || ''}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('onBoarding.bic')}</Title>
                                <p className="bank-info-description">{bankInfo.BIC}</p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('onBoarding.beneficiary')}</Title>
                                <p className="bank-info-description">
                                    {`${information?.data?.Name}`}
                                </p>
                                <p className="bank-info-description">
                                    {information?.data?.Street}
                                    {' '}
                                    {information?.data?.StreetNumber}
                                </p>
                                <p className="bank-info-description">
                                    {information?.data?.PostalCode}
                                    {' '}
                                    {`${information?.data?.City || ''}, ${information?.data?.State || ''}`}
                                </p>
                                <p className="bank-info-description">
                                    {information?.data?.Country}
                                </p>
                            </div>
                        </Column>
                        <Column size="6">
                            <div className="group-title">
                                <Title type={2}>{t('onBoarding.bank')}</Title>
                                <p className="bank-info-description">{bankInfo.BankName}</p>
                                <p className="bank-info-description">
                                    {bankInfo.BankAdress?.AddressLine1}
                                    {' '}
                                    {bankInfo.BankAdress?.AddressLine2}
                                </p>
                                <p className="bank-info-description">
                                    {bankInfo.BankAdress?.AddressLine3}
                                    {' '}
                                    {bankInfo.BankAdress?.AddressLine4}
                                </p>
                                <p className="bank-info-description">
                                    {bankInfo.BankAdress?.Country}
                                </p>
                            </div>
                        </Column>
                    </Row>
                </div>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('payments.back'),
                        onClick: handleBackClick,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
};

FundingInformation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromOrigin: PropTypes.string,
            currentPage: PropTypes.number,
            currentTab: PropTypes.string,
            fromReview: PropTypes.bool,
        }),
    }),
};

FundingInformation.defaultProps = {
    location: {
        state: {},
    },
};

export default FundingInformation;
