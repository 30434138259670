import React, { useMemo } from 'react';
import uniq from 'lodash/uniq';
import { PageVariant } from 'ui-library';
import { useTranslation } from 'react-i18next';
import {
    getPortfolioStatus,
    BLOCKED, PENDING_ORDERS,
    sortStatuses, CLOSED,
    IN_EXECUTION, NOT_IMPLEMENTED,
} from 'constants/portfolioStatuses';
import { usePortfolio } from 'domain/Portfolio';
import { useProposals } from 'hooks/useProposals';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import Preloader from 'components/Preloader';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { PageNotFound } from '../../404';

const withActionAccess = (Component) => {
    const Action = (props) => {
        const { portfolio: { data, isLoading, error } } = usePortfolio();
        const clientId = useSelector(contactIdSelector);

        const { data: proposalsData } = useProposals(clientId);
        const { t } = useTranslation();

        const proposalsAdapted = adaptProposalsByPortfolioId(proposalsData);

        const proposalStatuses = sortStatuses(
            uniq((proposalsAdapted[data?.id] || []).map(({ status }) => status)),
        );

        const status = getPortfolioStatus({
            portfolioStatusId: data?.portfolioStatusId,
            isBreached: data?.isBreached,
            proposalStatus: proposalStatuses?.[0],
            isOrderBookBlocked: data?.hasPendingOrders,
        });

        const noActions = useMemo(() => [
            PENDING_ORDERS].includes(status), [status]);

        const pageNotFound = useMemo(() => [
            CLOSED,
            BLOCKED,
            NOT_IMPLEMENTED,
            IN_EXECUTION].includes(status), [status]);

        if (pageNotFound) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageNotFound />
                </Preloader>
            );
        }

        if (noActions) {
            return (
                <Preloader isLoading={isLoading} error={error}>
                    <PageVariantWrapper>
                        <PageVariant
                            type="error"
                            title={t('error.title')}
                            message={t('portfolio.actionNotAvailable')}
                        />
                    </PageVariantWrapper>
                </Preloader>
            );
        }

        Action.propTypes = {
        };

        return <Component status={status} {...props} />;
    };

    return Action;
};

export default withActionAccess;
