import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageVariant, Infobox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import './Start.css';

const Start = (props) => {
    const { onNext } = props;
    const { t } = useTranslation();

    // OnBoarding Domain
    const { clearData } = useOnBoardingSelector(onBoardingDataSelector);

    // Effects
    useEffect(() => {
        clearData();
    }, [clearData]);

    // Callbacks
    const onHelpClick = () => {
        const unbluButton = document.getElementsByClassName('x-unblu-launcher-button')?.[0];

        if (unbluButton) unbluButton.click();
    };

    return (
        <div className="start-page">
            <PageVariant
                title={t('startPage.title')}
                message={t('startPage.message')}
                primaryButtonLabel={t('startPage.button')}
                onPrimaryButtonClick={() => {
                    onNext();
                }}
            >
                <button className="Info-box__button" type="button" onClick={onHelpClick}>
                    <Infobox>
                        {t('startPage.infoBox')}
                    </Infobox>
                </button>
            </PageVariant>
        </div>
    );
};

Start.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            clientId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    onNext: PropTypes.func,
};

Start.defaultProps = {
    onNext: () => {},
};

export default Start;
