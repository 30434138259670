import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import history from 'services/history';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { PageNotFound } from '../../../../../404';

function ProposalRejectSuccess(props) {
    const {
        location,
        match: { params: { portfolioId, proposalId } },
    } = props;
    const { t } = useTranslation();

    if (!location?.state?.title) {
        return (
            <PageNotFound />
        );
    }

    return (
        <PageVariantWrapper>
            <PageVariant
                type="success"
                title={`${t('confirmation.theProposal')} ${location.state.title} ${t('confirmation.isRejected')}`}
                actionButtonLabel={t('confirmation.backToPortfolio')}
                onActionButtonClick={() => {
                    history.push(`/portfolios/${portfolioId}`);
                }}
                primaryButtonLabel={t('confirmation.contactRM')}
                onPrimaryButtonClick={() => {
                    history.push('/messages/new', {
                        portfolioId,
                        proposalId,
                        title: location.state.title,
                    });
                }}
            />
        </PageVariantWrapper>
    );
}

ProposalRejectSuccess.propTypes = {
    match: PropTypes.shape({
        params: {
            portfolioId: PropTypes.string.isRequired,
        },
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        state: {
            title: PropTypes.string,
        },
    }).isRequired,
};

ProposalRejectSuccess.defaultProps = {};

export default ProposalRejectSuccess;
