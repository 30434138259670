import { useContext } from 'react';
import PortfolioContext from './context';

const useSelector = (callback) => {
    const { getValue } = useContext(PortfolioContext);

    if (getValue === undefined) {
        throw new Error('usePortfolio can only be used inside PortfolioOverviewProvider');
    }

    return getValue(callback);
};

export default useSelector;
