import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptParentsAllocations } from '../adapters/adaptParentsAllocations';

export const useParentsAllocations = (data, isLoading, currency) => {
    const [errorAllocation, setError] = useState(null);
    const [isLoadingAllocation, setLoading] = useState(true);
    const [investmentAllocation, setData] = useState({
        chart: [{ data: { pie: {}, bar: {}, list: {} } }],
    });
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setLoading(true);

        if (!isLoading) {
            Promise.all([
                ServiceManager.commonService('getSectors', [i18n.language]),
                ServiceManager.commonService('getAssetClasses', [i18n.language]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        setData(
                            adaptParentsAllocations(data, response[0], response[1], currency, t),
                        );
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setData(adaptParentsAllocations(data, [], [], currency, t));
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }
    }, [isLoading, i18n.language]);

    return { investmentAllocation, isLoadingAllocation, errorAllocation };
};
