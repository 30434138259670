import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import CalculatingGoal from './pages/CalculatingGoal';
import SetupPortfolioExO from './pages/SetupPortfolioExO';
import Optimize from './pages/Optimize';
import ModifyGoal from '../ModifyGoal';
import AddPosition from '../AddPosition';
import PositionDetails from '../PositionDetails';

const Router = (props) => {
    const { match: { url, path } } = props;
    const onPageChange = (pageKey) => {
        history.push(`${url[url.length - 1] === '/' ? url : `${url}/`}${pageKey}`);
    };
    const withProps = (Component, isModify) => (defProps) => (
        <Component {...props} {...defProps} isModify={isModify} onPageChange={onPageChange} />
    );

    // OnBoarding Domain
    const {
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);

    const MainPage = useMemo(() => generateObject({
        [ADVISORY]: CalculatingGoal,
        [DISCRETIONARY]: CalculatingGoal,
        [EXECUTION_ONLY]: SetupPortfolioExO,
        [DEPOSITS]: CalculatingGoal,
    }).getByName(product?.name), [product?.name]);
    const OptimizePage = useMemo(() => generateObject({
        [ADVISORY]: Optimize,
        [DISCRETIONARY]: Optimize,
        [EXECUTION_ONLY]: null,
        [DEPOSITS]: Optimize,
    }).getByName(product?.name), [product?.name]);

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(MainPage)} />
            {OptimizePage
                ? <Route exact path={`${path}/optimize`} render={withProps(OptimizePage)} />
                : (
                    <>
                        <Route exact path={`${path}/modify`} render={withProps(ModifyGoal)} />
                        <Route exact path={`${path}/modify/position/:positionId`} render={withProps(PositionDetails)} />
                        <Route exact path={`${path}/add-position`} render={withProps(AddPosition)} />
                        <Route exact path={`${path}/add-position/:positionId`} render={withProps(PositionDetails, true)} />
                    </>
                )
            }
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
