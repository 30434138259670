import { renderers } from 'ui-library';
import {
    CellInputText,
    CellValueFormatted,
    CellWithPlusMinusIcons,
    CellSwitch,
    CellPercent,
    CellNameAndInput,
} from 'components/renderers';
import { sorterNumber, sorterString, SORT_DIRECTION_DESC } from 'utils/sorting';

export const QUICK_EDIT = 'quick-edit';

const { CellWithIcon } = renderers;

export const quickColumns = (t, getFormattedNumber, numberSeparators) => [
    {
        key: 'Name',
        title: t('portfolios.quickEditColumns.name'),
        sortable: true,
        sorter: sorterString('Name'),
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        key: 'ISIN',
        title: t('portfolios.modifyColumns.isin'),
        width: 120,
        minWidth: 120,
        hidden: true,
        sorter: sorterString('ISIN'),
    },
    {
        key: 'Value',
        title: t('portfolios.quickEditColumns.value'),
        className: 'text-right',
        width: 180,
        minWidth: 180,
        render: CellValueFormatted(getFormattedNumber),
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Units',
        title: t('portfolios.quickEditColumns.units'),
        className: 'text-right',
        hidden: true,
        width: 100,
        minWidth: 100,
        sorter: sorterNumber('Units'),
    },
    {
        key: 'Currency',
        title: t('portfolios.modifyColumns.currency'),
        hidden: true,
        width: 100,
        minWidth: 100,
        sorter: sorterString('Currency'),
    },
    {
        key: 'LatestPrice',
        title: t('portfolios.quickEditColumns.lastPrice'),
        className: 'text-right',
        hidden: true,
        width: 150,
        minWidth: 150,
        sorter: sorterNumber('LatestPrice'),
    },
    {
        key: 'ValuationInSecurityCurrency',
        title: t('portfolios.quickEditColumns.valuationInSecurityCurrency'),
        className: 'text-right',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('ValuationInSecurityCurrency'),
    },
    {
        key: 'UnrealizedGainLoss',
        title: t('portfolios.quickEditColumns.unrealizedGainLoss'),
        className: 'text-right',
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterNumber('UnrealizedGainLoss'),
    },
    {
        key: 'Performance',
        title: t('portfolios.quickEditColumns.performanceSI'),
        className: 'text-right',
        render: CellPercent(getFormattedNumber),
        width: 160,
        minWidth: 160,
        sorter: sorterNumber('Performance'),
    },
    {
        key: 'Allocation',
        title: t('portfolios.quickEditColumns.allocation'),
        className: 'text-right',
        width: 120,
        minWidth: 120,
        sorter: sorterNumber('Allocation'),
    },
    {
        key: 'New',
        title: t('portfolios.quickEditColumns.new'),
        className: 'with-input text-right',
        render: CellInputText(numberSeparators),
        width: 170,
        minWidth: 170,
        sorter: sorterNumber('New.value'),
    },
    {
        key: 'Icon',
        title: '',
        render: CellWithPlusMinusIcons,
        width: 70,
        minWidth: 70,
    },
    {
        key: 'Actions',
        title: '',
        headerRenderer: CellWithIcon,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
    },
];

export const quickMobileColumns = (isSmallSize, t, numberSeparators) => [
    {
        key: 'NameAndNewAllocation',
        title: t('portfolios.quickEditColumns.name'),
        sortable: true,
        sorter: sorterString('NameAndNewAllocation.label'),
        minWidth: 250,
        className: 'with-link',
        render: CellNameAndInput(numberSeparators),
    },
];
