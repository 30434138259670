import React, {
    useEffect, useMemo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Table,
    Button,
    Modal,
    Infobox,
} from 'ui-library';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import { useLocale, useFormatting } from 'locale';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { CHANGE_MODEL, CHANGE_STRATEGY } from 'constants/constants';
import {
    goalCreationSelector,
    goalSummarySelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import {
    useModelPortfolio,
    usePortfolioQuickEdit,
} from 'domain/Portfolio';
import { formatDate } from 'utils/datetime';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { adaptPositions, adaptModifyPositions } from '../../adapters/adaptModifyPositions';
import { getModifyColumns } from './constants';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import { adaptConstraints } from '../ReviewPortfolio/adaptors/adaptConstraints';
import { adaptSecuritiesForSave } from './adapters/adaptSecuritiesForSave';
import './ModifyGoal.css';

const ModifyGoal = (props) => {
    const {
        onPageChange, changeStep, location: { pathname },
    } = props;
    const { t, i18n: { language } } = useTranslation();
    const { numberSeparators } = useLocale();
    const { getFormattedNumber, getFormattedDate, getFormattedCurrency } = useFormatting();
    const [isLiquiditySet, setIsLiquiditySet] = useState(false);
    const [modifiedPositions, setModifiedPositions] = useState([]);

    // OnBoarding Domain
    const {
        product, getGoalDetails, clearModifiedPositions, saveProposalId, getRiskCategory,
        createdProposalId, getModifiedPositions, saveModifiedPositions, clearCreatedProposalId,
        saveReviewData, clearGoalChanges,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        isLoading, error, getModelPortfolioId,
        isLoadingLiquidity, errorLiquidity, getLiquidity,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        saveProposal, isLoadingSave, errorSave,
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();
    const {
        dataSummary, changesNonManual, changes, changesManual,
        initializePositions, onIncrease, onDecrease, onAllocationChange, onRemove,
    } = usePortfolioQuickEdit();

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const projectionYears = useMemo(() => formatDate(goalDetails.targetDate, 'YYYY')
        - formatDate(new Date(), 'YYYY'), [goalDetails.targetDate]);
    const optionsForAdapt = useMemo(
        () => ({
            currency: goalDetails.selectedCurrency,
            portfolioCurrency: goalDetails.selectedCurrency?.label,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioTotalValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            productName: product.name,
            projectionYears,
            language,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            nameLength: 35,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
        }),
        [
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            product.name,
            language,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            pathname,
        ],
    );
    const positionsModel = useMemo(() => adaptPositions(modelPortfolio?.Positions, optionsForAdapt),
        [modelPortfolio?.Positions, optionsForAdapt]);
    const handleDelete = useCallback((securityId) => {
        Modal.confirm({
            title: t('onBoarding.remove'),
            content: t('onBoarding.deletePosition.confirmation'),
            okText: t('confirmation.confirm'),
            onOk: () => {
                const changedData = onRemove(securityId);

                saveModified({ raw: changedData?.changes });
            },
            cancelText: t('confirmation.cancel'),
        });
    }, [onRemove, t]);
    const getBaseCurrency = useMemo(() => generateObject({
        [ADVISORY]: null,
        [DISCRETIONARY]: null,
        [EXECUTION_ONLY]: getModifiedPositions()?.positions?.length ? null : getLiquidity,
        [DEPOSITS]: null,
    }).getByName(product?.name), [product?.name, getModifiedPositions]);
    const positionsAdapted = useMemo(() => adaptModifyPositions({
        positions: modifiedPositions?.positions?.length
            ? modifiedPositions.positions : positionsModel,
        changes,
        changesNonManual,
        dataSummary,
        onChange: onAllocationChange(true),
        onIncrease,
        onDecrease,
        handleDelete,
        ...optionsForAdapt,
    }), [
        modifiedPositions,
        positionsModel,
        changes,
        changesNonManual,
        dataSummary,
        onAllocationChange,
        onIncrease,
        onDecrease,
        handleDelete,
        optionsForAdapt,
    ]);
    const total = +dataSummary?.New?.toFixed(2);

    // Benchmark
    const { benchmarkSelected } = useBenchmarks({
        currencyId: goalDetails.selectedCurrency?.value,
        productId: +product?.id,
    }, isLoading, true);

    // Effects
    useEffect(() => {
        if (isLiquiditySet) setModifiedPositions(getModifiedPositions());
    }, [isLiquiditySet, getModifiedPositions]);
    useEffect(() => {
        (async () => {
            if (getBaseCurrency) return;
            const riskCategoryId = (await getRiskCategory())?.Id;
            const modelObj = await getModelPortfolioId({ productId: +product?.id, riskCategoryId });

            getModelPortfolio(modelObj?.Id, { currencyId: goalDetails.selectedCurrency?.value });
        })();
    }, [
        getRiskCategory,
        getModelPortfolioId,
        getModelPortfolio,
        product?.id,
        goalDetails.selectedCurrency?.value,
    ]);
    useEffect(() => {
        const modelPositions = modelPortfolio?.Positions?.length ? modelPortfolio?.Positions
            .map((item) => ({ ...item, Allocation: item.Allocation * 100 })) : undefined;
        const basedPositions = modelPositions || (
            (getModifiedPositions()?.raw || [])
                .filter((item) => item.CurrencyId)
                .map((item) => ({ ...item, Allocation: 100 }))
        );

        initializePositions({
            changes: modifiedPositions?.raw?.length ? modifiedPositions.raw : null,
            positions: basedPositions,
        });
    }, [initializePositions, modifiedPositions, modelPortfolio?.Positions]);
    useEffect(() => {
        if (getBaseCurrency) {
            (async () => {
                const liquidityData = await getBaseCurrency({
                    currencyId: goalDetails.selectedCurrency?.value,
                    productId: +product.id,
                });
                const liquidity = { ...liquidityData, Id: liquidityData.id };
                const positionToSave = adaptPositions(
                    [{ Security: liquidity, Allocation: 1 }], { ...optionsForAdapt, isNew: true },
                );

                saveModifiedPositions({
                    positions: positionToSave,
                    raw: [{
                        Id: liquidity.Id,
                        CurrencyId: goalDetails.selectedCurrency?.value,
                        Allocation: 100,
                    }],
                });
                setIsLiquiditySet(true);
            })();
        } else {
            setIsLiquiditySet(true);
        }
    }, [getBaseCurrency, goalDetails.selectedCurrency?.value, product.id]);

    // Callbacks
    const saveModified = ({ raw } = {}) => {
        saveModifiedPositions({
            positions: positionsAdapted.positions,
            raw: raw || changes,
        });
        setModifiedPositions(getModifiedPositions());
    };
    const nextAction = useMemo(() => generateObject({
        [ADVISORY]: () => onPageChange(''),
        [DISCRETIONARY]: () => onPageChange(''),
        [EXECUTION_ONLY]: () => changeStep(5),
        [DEPOSITS]: () => onPageChange(''),
    }).getByName(product?.name), [onPageChange, changeStep, product?.name]);
    const onNext = useCallback(() => {
        saveModified();
        clearGoalChanges();
        sessionStorage.removeItem(CHANGE_STRATEGY);
        sessionStorage.removeItem(CHANGE_MODEL);
        nextAction();
    }, [clearGoalChanges, nextAction, saveModified]);
    const handleModify = async () => {
        const isUpdate = typeof createdProposalId === 'number';
        const InstrumentAllocations = adaptSecuritiesForSave({
            data: positionsAdapted.positions, changesManual, IsNotAdvised: isUpdate,
        });

        if (isUpdate) {
            const params = {
                ModelPortfolioId: {
                    DoUpdate: true,
                    Value: null,
                },
                OptimizationConstraints: {
                    DoUpdate: true,
                    Value: adaptConstraints(modelPortfolio?.OptimizationConstraints),
                },
                InstrumentAllocations: {
                    DoUpdate: true,
                    Value: InstrumentAllocations,
                },
            };

            await updateProposal(createdProposalId, params);
        } else {
            const params = {
                InstrumentAllocations,
                Name: goalDetails?.goalName,
                RecommendedInvestment: +goalDetails?.initialInvestment,
                ProductId: +product.id,
                CurrencyId: goalDetails?.selectedCurrency.value,
                OptimizationConstraints: adaptConstraints(modelPortfolio?.OptimizationConstraints),
            };
            const savedProposalData = await saveProposal(params);

            if (savedProposalData?.Id) {
                saveProposalId(savedProposalData.Id);
            }
        }

        saveReviewData({
            modelPortfolioId: modelPortfolio?.Id,
            benchmarkId: benchmarkSelected || modelPortfolio?.BenchmarkId,
        });

        onNext();
    };
    const onPrev = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                clearModifiedPositions();
                clearCreatedProposalId();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const handleAddPosition = () => {
        saveModified();
        onPageChange('add-position');
    };

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('portfolios.modify')}`}
            error={error || errorMP || errorLiquidity}
            isLoading={isLoading || isLoadingMP || isLoadingLiquidity}
            className="goal-review on-boarding_modify"
            prevButton={{
                onClick: onPrev,
            }}
            nextButton={{
                text: t('onBoarding.save'),
                disabled: total !== 100,
                loading: isLoadingSave || isLoadingUpdate,
                onClick: handleModify,
            }}
            topAction={(
                <Button
                    type="secondary"
                    size="small"
                    disabled={isLoadingMP}
                    onClick={handleAddPosition}
                >
                    {t('portfolios.addPosition')}
                </Button>
            )}
        >
            <EmptyContent data={positionsAdapted.positions} text={t('advisoryDashboard.noData')}>
                <div className="modify-goal_content">
                    <Table
                        columns={getModifyColumns(t, numberSeparators)}
                        data={positionsAdapted.positions}
                        footer={positionsAdapted.footer}
                        defaultExpandAllRows
                    />
                    {total !== 100 && <WidgetError accent error message={t('portfolios.errorSum')} />}
                    {errorSave && <Infobox error>{errorSave.message}</Infobox>}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                </div>
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
};

ModifyGoal.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    onPageChange: PropTypes.func,
    changeStep: PropTypes.func,
};

ModifyGoal.defaultProps = {
    onPageChange: () => {},
    changeStep: () => {},
};

export default ModifyGoal;
