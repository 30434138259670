export const GET_LIST_STARTED = 'list-preview/GET_LIST_STARTED';
export const GET_LIST_SUCCEEDED = 'list-preview/GET_LIST_SUCCEEDED';
export const GET_LIST_FAILED = 'list-preview/GET_LIST_FAILED';
export const getListData = (dispatch, store, getData, getDataCallback) => async (params) => {
    dispatch({ type: GET_LIST_STARTED });
    try {
        const paramsToSet = {
            currentPage: store?.currentPage,
            pageSize: store?.pageSize,
            filters: store?.filters,
            ...store?.sorting,
            ...params,
        };
        const data = await getData(paramsToSet);

        getDataCallback(params);

        return dispatch({ type: GET_LIST_SUCCEEDED, payload: data });
    } catch (err) {
        return dispatch({ type: GET_LIST_FAILED, payload: err });
    }
};

export const PREVIEW_CLEAR = 'list-preview/GET_PREVIEW_CLEAR';
export const clearPreview = (dispatch) => {
    dispatch({ type: PREVIEW_CLEAR });
};

export const GET_PREVIEW_STARTED = 'list-preview/GET_PREVIEW_STARTED';
export const GET_PREVIEW_SUCCEEDED = 'list-preview/GET_PREVIEW_SUCCEEDED';
export const GET_PREVIEW_FAILED = 'list-preview/GET_PREVIEW_FAILED';
export const getPreviewData = (dispatch, getData) => async (id, item) => {
    dispatch({ type: GET_PREVIEW_STARTED });

    if (item && item.isFull) {
        return dispatch({
            type: GET_PREVIEW_SUCCEEDED,
            payload: { ...item, isSelected: true },
        });
    }
    try {
        const data = await getData(id);

        return dispatch({
            type: GET_PREVIEW_SUCCEEDED,
            payload: data,
        });
    } catch (err) {
        return dispatch({
            type: GET_PREVIEW_FAILED,
            payload: err,
        });
    }
};

export const FILTER_STARTED = 'list-preview/FILTER_STARTED';
export const FILTER_SUCCEEDED = 'list-preview/FILTER_SUCCEEDED';
export const FILTER_FAILED = 'list-preview/FILTER_FAILED';
export const onFilter = (dispatch, store, onSearch) => async (value) => {
    dispatch({ type: FILTER_STARTED });
    try {
        const params = {
            currentPage: store?.currentPage,
            pageSize: store?.pageSize,
            filters: value,
            ...store?.sorting,
        };
        const data = await onSearch(value, params);
        let payload = { filters: value };

        if (data?.data) payload = { ...payload, ...data };
        else payload.data = data;

        return dispatch({ type: FILTER_SUCCEEDED, payload });
    } catch (err) {
        return dispatch({ type: FILTER_FAILED, payload: err });
    }
};

export const SELECT_ITEM = 'list-preview/SELECT_ITEM';
export const selectItem = (dispatch, store, getData) => (id) => {
    if (store.isPreviewLoading && store.selected !== undefined) return;

    dispatch({ type: SELECT_ITEM, payload: id });
    const selected = store.list.find((item) => item.id === id);

    getPreviewData(dispatch, getData)(id, selected);
};

export const SET_CURRENT_PAGE_SUCCEEDED = 'list-preview/SET_CURRENT_PAGE_SUCCEEDED';
export const setCurrentPage = (dispatch) => (page) => dispatch(
    { type: SET_CURRENT_PAGE_SUCCEEDED, payload: page },
);

export const DELETE_PREVIEW_STARTED = 'list-preview/DELETE_PREVIEW_STARTED';
export const DELETE_PREVIEW_SUCCEEDED = 'list-preview/DELETE_PREVIEW_SUCCEEDED';
export const DELETE_PREVIEW_FAILED = 'list-preview/DELETE_PREVIEW_FAILED';
export const deletePreview = (dispatch, store, deleteItem, deleteCallback) => async (id) => {
    dispatch({ type: DELETE_PREVIEW_STARTED, payload: id });
    try {
        await deleteItem(id);

        dispatch({ type: DELETE_PREVIEW_SUCCEEDED, payload: id });

        const params = {};

        if (store.total - 1 === store.pageSize * (store.currentPage - 1)) {
            setCurrentPage(dispatch)(store.currentPage - 1);
            params.currentPage = store.currentPage - 1;
        }

        deleteCallback(params);

        return id;
    } catch (err) {
        return dispatch({ type: DELETE_PREVIEW_FAILED, payload: err });
    }
};
