import React, {
    useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import { useFormatting } from 'locale';
import { LIST_COLUMNS, LIST_SORTING, PAGE_SIZE } from '../constants';

function ListTable(props) {
    const {
        isLoading,
        error,
        onRowSelect,
        needUpdate,
        getData,
        data,
        total,
        preSelect,
        noData,
        columns,
        setStoreCurrentPage,
        currentPage,
        active,
        sorting,
        useDynamicSorting,
        noDataButtonText,
        noDataOnClick,
    } = props;

    const { getFormattedDate, getFormattedTime } = useFormatting();
    const { t } = useTranslation();
    const sortingTable = useMemo(() => (useDynamicSorting
        ? { key: sorting?.sortColumn, direction: sorting?.sortOrder }
        : null), [useDynamicSorting, sorting]);

    const onRowClick = useCallback((record) => ({
        onClick: (e) => {
            if (e.target.className === 'checkbox-input') {
                return;
            }
            onRowSelect(record.id);
        },
    }), [onRowSelect]);
    const onPaginationChange = useCallback((page) => {
        if (data?.length < total) {
            getData({
                currentPage: page, pageSize: PAGE_SIZE, ...sorting,
            });
        }
    }, [data.length, getData, sorting, total]);
    const onSortingChange = useCallback((sortColumn) => (dataSort, config) => {
        if (sorting?.sortColumn !== sortColumn || sorting?.sortOrder !== config.direction) {
            getData({
                currentPage, pageSize: PAGE_SIZE, sortColumn, sortOrder: config.direction,
            });
        }

        return dataSort;
    }, [currentPage, getData, sorting?.sortColumn, sorting?.sortOrder]);

    useEffect(() => {
        if (needUpdate === true && active === true) {
            getData({ pageSize: PAGE_SIZE, currentPage: 1 });
        }
    }, [needUpdate, active]);
    useEffect(() => {
        if (active && isLoading === false && (preSelect !== undefined || data?.[0] !== undefined)) {
            onRowSelect(preSelect || data?.[0].id);
        }
    }, [isLoading, active]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent
                data={data}
                text={noData}
                buttonText={noDataButtonText}
                onClick={noDataOnClick}
            >
                <Table
                    columns={columns(
                        t, getFormattedDate, getFormattedTime, useDynamicSorting && onSortingChange,
                    )}
                    data={data}
                    sorting={sortingTable || LIST_SORTING}
                    onRow={onRowClick}
                    paging={{
                        total,
                        currentPage,
                        pageSize: PAGE_SIZE,
                    }}
                    mini
                    onChange={(pagination) => {
                        if (pagination.currentPage
                            && pagination.currentPage !== currentPage
                            && (pagination.currentPage - 1) * PAGE_SIZE < total) {
                            setStoreCurrentPage(pagination.currentPage);
                            onPaginationChange(pagination.currentPage);
                        }
                    }}
                    headerControls={{
                        sortByLabel: t('headerControls.sortBy'),
                    }}
                />
            </EmptyContent>
        </Preloader>
    );
}

ListTable.propTypes = {
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    needUpdate: PropTypes.bool,
    getData: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Checkbox: PropTypes.string,
            Subject: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            Date: PropTypes.string,
            id: PropTypes.number,
        }),
    ).isRequired,
    total: PropTypes.number.isRequired,
    preSelect: PropTypes.number,
    noData: PropTypes.string.isRequired,
    columns: PropTypes.func,
    setStoreCurrentPage: PropTypes.func,
    currentPage: PropTypes.func.isRequired,
    active: PropTypes.bool,
    sorting: PropTypes.shape({
        sortOrder: PropTypes.string,
        sortColumn: PropTypes.string,
    }),
    useDynamicSorting: PropTypes.bool,
    noDataOnClick: PropTypes.func,
    noDataButtonText: PropTypes.string,
};

ListTable.defaultProps = {
    error: null,
    needUpdate: false,
    preSelect: undefined,
    columns: LIST_COLUMNS,
    setStoreCurrentPage: () => {},
    active: true,
    sorting: null,
    useDynamicSorting: false,
    noDataOnClick: () => {},
    noDataButtonText: undefined,
};

export default ListTable;
