import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from 'services/history';
import ReviewPage from './ReviewPage';
import Summary from './pages/Summary';
import ChangeModel from './pages/ChangeModel';
import ChangeStrategy from './pages/ChangeStrategy';
import ModifyGoal from '../ModifyGoal';
import Optimize from '../GoalAndPortfolioSetup/pages/Optimize';
import AddPosition from '../AddPosition';
import PositionDetails from '../PositionDetails';
import { Compare, ComparePortfolioPositions } from './pages/Compare';

const Router = (props) => {
    const { match: { url, path } } = props;
    const onPageChange = (pageKey) => {
        history.push(`${url[url.length - 1] === '/' ? url : `${url}/`}${pageKey}`);
    };
    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} onPageChange={onPageChange} />
    );

    return (
        <Switch>
            <Route exact path={`${path}`} render={withProps(ReviewPage)} />
            <Route exact path={`${path}/summary`} render={withProps(Summary)} />
            <Route exact path={`${path}/change-strategy`} render={withProps(ChangeStrategy)} />
            <Route exact path={`${path}/modify`} render={withProps(ModifyGoal)} />
            <Route exact path={`${path}/add-position`} render={withProps(AddPosition)} />
            <Route exact path={`${path}/add-position/:positionId`} render={withProps((p) => PositionDetails({ ...p, isModify: true }))} />
            <Route exact path={`${path}/compare-positions`} render={withProps(ComparePortfolioPositions)} />
            <Route exact path={`${path}/compare`} render={withProps(Compare)} />
            <Route exact path={`${path}/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/change-strategy/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/summary/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/modify/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/compare-positions/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/compare/position/:positionId`} render={withProps(PositionDetails)} />
            <Route exact path={`${path}/optimize`} render={withProps((p) => Optimize({ ...p, onNext: () => onPageChange('') }))} />
            <Route exact path={`${path}/change-model`} render={withProps(ChangeModel)} />
            <Route path={`${path}/change-model/position/:positionId`} render={withProps(PositionDetails)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
