import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'ui-library';
import { OverviewPanel } from '../Overview';
import { getClassNames } from '../../utils';
import { OverviewColumn } from './components/OverviewColumn';
import './OverviewInformation.css';

const OverviewInformation = (
    {
        rowsData,
        columnClassName: sharedColumnClassName,
        columnSize: sharedColumnSize,
        panelClassName,
    },
) => (
    <OverviewPanel className={getClassNames(panelClassName, 'overview-information')}>
        {rowsData.map(({ columnsData }) => (
            <Row key={columnsData}>
                {columnsData.map((
                    {
                        columnClassName,
                        overviewItemsInfo,
                        emptyColumn = false,
                        columnSize = ['md-6', 'sm-6', 'lg-3'],
                    },
                ) => (
                    <OverviewColumn
                        key={overviewItemsInfo}
                        className={getClassNames(columnClassName, sharedColumnClassName)}
                        size={sharedColumnSize || columnSize}
                        emptyColumn={emptyColumn}
                        overviewItems={overviewItemsInfo}
                    />
                ))}
            </Row>
        ))}
    </OverviewPanel>
);

OverviewInformation.propTypes = {
    rowsData: PropTypes.arrayOf(PropTypes.shape({
        columnsData: PropTypes.shape({
            columnClassName: PropTypes.string,
            columnSize: PropTypes.arrayOf(PropTypes.string),
            overviewItemsInfo: PropTypes.arrayOf(PropTypes.shape({})),
            emptyColumn: PropTypes.bool,
        }),
    })).isRequired,
    columnSize: PropTypes.arrayOf(PropTypes.string),
    columnClassName: PropTypes.string,
    panelClassName: PropTypes.string,
};

OverviewInformation.defaultProps = {
    columnSize: null,
    columnClassName: '',
    panelClassName: '',
};


export default React.memo(OverviewInformation);
