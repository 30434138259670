import { TYPE_CURRENCY } from '../../../utils/formatting';

export const adaptStrategyOverview = (
    {
        portfolioValue,
        currency,
        showStrategy,
        t,
        strategy,
        onStrategyChange,
        renderStrategySelect,
        renderModelSelect,
        showModelPortfolio,
    },
) => {
    const extraColumns = onStrategyChange ? [
        {
            overviewItemsInfo: [{
                customRender: renderStrategySelect,
            }],
        },
        showModelPortfolio ? {
            overviewItemsInfo: [{
                customRender: renderModelSelect,
            }],
        } : {},
    ] : [];


    return [{
        columnsData: [
            {
                overviewItemsInfo: [{
                    title: t('overview.portfolioValue'),
                    value: portfolioValue,
                    type: TYPE_CURRENCY,
                    currency,
                }],
            },
            {
                overviewItemsInfo: [{
                    title: t('overview.strategy'),
                    value: strategy,
                }],
                emptyColumn: !showStrategy,
            },
            ...extraColumns,
        ],
    },
    ];
};
