import { renderers } from 'ui-library';
import { sorterByDate, sorterString } from 'utils/sorting';
import { CellAdaptedMessageDate } from 'components/renderers';

const { CellWithMessageTitleClient } = renderers;

// Messages table
export const MESSAGES_COLUMNS = (t, getFormattedDate, getFormattedTime, onSortingChange) => [
    {
        key: 'Subject',
        title: t('messages.sortColumns.subject'),
        sortable: true,
        className: 'table-cell__subject',
        render: CellWithMessageTitleClient,
        sorter: onSortingChange ? onSortingChange('Subject') : sorterString('Subject.title'),
    },
    {
        key: 'Date',
        title: t('messages.sortColumns.date'),
        render: CellAdaptedMessageDate(t, getFormattedDate, getFormattedTime),
        sortable: true,
        className: 'table-cell__date text-right',
        sorter: onSortingChange ? onSortingChange('Date') : sorterByDate('Date.date'),
    },
];

export const MESSAGES_SORTING = { key: 'Date', direction: 'desc' };
