import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

export const useSecurityTypeIndex = (loadInitially = true) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(loadInitially);
    const [typeId, setTypeId] = useState(undefined);
    const { i18n } = useTranslation();

    const getTypeIndexId = useCallback(async () => {
        try {
            setError(null);
            setLoading(true);

            const rensponse = await ServiceManager.commonService('getSecurityTypes', [i18n.language]);

            try {
                const typeIndex = rensponse?.data.length && rensponse.data.find(({ ExternalId }) => ExternalId === 'Index');

                setTypeId(typeIndex.Id);
                setLoading(false);

                return typeIndex.Id;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (loadInitially) {
            getTypeIndexId();
        }
    }, [getTypeIndexId, loadInitially]);

    return {
        typeId, isLoadingTypeId: isLoading, errorTypeId: error, getTypeIndexId,
    };
};
