export const SELECTED_PRODUCT_OFFER = 'selectedProductOffer';
export const SELECTED_PRODUCT = 'selectedProduct';
export const SELECTED_GOAL_DETAILS = 'selectedGoalDetails';
export const SELECTED_GOAL_CHANGES = 'selectedGoalChanges';
export const CREATED_GOAL = 'createdGoal';
export const SELECTED_RISK_APPETITE = 'selectedRiskAppetite';
export const SELECTED_RISK_CAPACITY = 'selectedRiskCapacity';
export const RISK_PROFILE = 'riskProfile';
export const MODIFY_POSITIONS_MODIFIED = 'modifiedPositions';
export const CREATED_PROPOSAL_ID = 'createdProposalId';
export const IBAN = 'IBAN';
export const KNOWLEDGE_EXPERIENCE = 'knowledgeExperience';
export const FEE_PLATFORM_ID = 2;
export const REVIEW_DATA = 'onBoardingReviewData';


export const stepTitles = (t) => ({
    product: t('onBoarding.product'),
    riskAppetite: t('onBoarding.riskProfile'),
    knowledgeExperience: t('onBoarding.knowledgeExperience'),
    goal: t('onBoarding.goal'),
    review: t('onBoarding.review'),
    completion: t('onBoarding.completion'),
    portfolioSetup: t('onBoarding.portfolioSetup'),
});
