
export const customizationObject = {
    padding: '0',
    fontFamily: 'Helvetica, Arial, sans-serif',
    gFontFamily: 'Roboto',
    baseFontSize: '16px',
};
export const getCssUrl = (theme) => `${window.location.origin}/styles/themes/clarity-${theme}.css`;
export const MODULE = 'impact-highlights';
export const SECTIONS = 'KEY_SCORES';
export const SCORES_IDS = 'AIR_POLLUTION, BIODIVERSITY_LOSS, CLIMATE_CHANGE, EMPLOYEE_FATALITIES, GENDER_INEQUALITY, JOB_CREATION, TRANSPARENCY, WASTE_MANAGEMENT, WATER_SCARCITY';

export const PORTFOLIO_NAME = 'Hybrid Wealth';
