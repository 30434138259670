import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioButtonsGroup from 'components/RadioBlocks/RadioBlocks';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { useFormatting } from 'locale';
import {
    useOnBoardingSelector,
    onBoardingDataSelector,
    productsSelector,
} from 'domain/OnBoarding';
import {
    Paragraph, Title, Row, Column, Icon,
} from 'ui-library';
import { NA } from 'utils/formatting';
import { FEE_PLATFORM_ID } from 'domain/OnBoarding/constants';
import Infobox from 'ui-library/dist/components/Infobox';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import { adaptProducts } from './adaptors/adaptProducts';
import './ProductSelection.css';

const getFee = (fees, getFormattedNumber) => {
    const platform = (fees || [])?.find((i) => i.FeeType.Id === FEE_PLATFORM_ID);
    const other = fees?.[0];

    if (platform) {
        return platform.IsPercentage ? `${getFormattedNumber(platform.FeeValue * 100)}%` : platform.FeeValue;
    }

    if (other) {
        return other.IsPercentage ? `${getFormattedNumber(other.FeeValue * 100)}%` : other.FeeValue;
    }

    return NA;
};

const ProductSelection = (props) => {
    const {
        changeStep, onNext,
    } = props;
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    // OnBoarding Domain
    const {
        saveProduct, product: savedProduct, clearRiskAppetite, clearRiskCapacity, productOffer,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data, isLoading, error, getProducts,
        getRiskCategories,
        riskCategoriesData,
        riskCategoriesError,
        riskCategoriesIsLoading,
    } = useOnBoardingSelector(productsSelector);

    const [selected, setSelected] = useState(savedProduct?.id || null);
    const [selectedError, setSelectedError] = useState(false);


    // Effects
    useEffect(() => {
        getProducts();
    }, [getProducts]);

    useEffect(() => {
        if (selected) {
            getRiskCategories(selected);
        }
    }, [getRiskCategories, selected]);

    useEffect(() => {
        if (selected) {
            setSelectedError(false);
        }
    }, [selected]);
    // Data
    const products = useMemo(() => adaptProducts(data, {
        productOffer,
    }), [data, productOffer]);

    const options = useMemo(() => (products || []).map((item) => ({
        value: item.id.toString(),
        label: {
            name: item?.name,
            id: item?.id.toString(),
            image: item?.image,
        },
    })), [products]);


    // Selected product
    const onChange = (value) => {
        setSelected(value);
        clearRiskAppetite();
        clearRiskCapacity();
    };

    const selectedProduct = useMemo(
        () => products.find(({ id }) => id.toString() === selected),
        [selected, products],
    );

    const {
        productDescription, keyAdvantages, currencyCodes, fee, riskCategories,
    } = useMemo(() => ({
        productDescription: selectedProduct?.description?.split('-')?.[0],
        keyAdvantages: selectedProduct?.description?.split('-')?.filter((_, i) => i !== 0),
        currencyCodes: selectedProduct?.currencies?.map((item) => item.CurrencyCode).join(', '),
        fee: getFee(selectedProduct?.fees, getFormattedNumber),
        riskCategories: riskCategoriesData?.map((item) => item.Name).join(', '),
    }),
    [
        riskCategoriesData,
        selectedProduct,
        getFormattedNumber,
    ]);

    const productValues = useMemo(() => (
        [
            {
                name: 'Minimum investment',
                value: selectedProduct?.minimumInvestmentValue
                    ? getFormattedNumber(selectedProduct?.minimumInvestmentValue, {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                    }) : NA,
            },
            {
                name: 'Fee',
                value: fee,
            },
            {
                name: 'Reference currencies',
                value: currencyCodes,
            },
            {
                name: 'Risk categories',
                value: riskCategories,
            },
        ]
    ), [selectedProduct?.minimumInvestmentValue,
        fee, currencyCodes, riskCategories, getFormattedNumber]);

    // Handlers/callbacks
    const nextAction = useMemo(() => generateObject({
        [ADVISORY]: onNext,
        [DISCRETIONARY]: onNext,
        [EXECUTION_ONLY]: () => changeStep(3),
        [DEPOSITS]: onNext,
    }), [onNext, changeStep]);
    const onNextWithSelect = useCallback(() => {
        if (selected) {
            const option = products.find(({ id }) => id.toString() === selected);
            const product = { id: selected, name: option?.name };

            saveProduct(product);
            if (typeof nextAction.getByName(product.name) === 'function') {
                nextAction.getByName(product.name)();
            }
        } else {
            setSelectedError(true);
        }
    }, [nextAction, products, saveProduct, selected]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.selectProduct')}
            error={error}
            isLoading={isLoading}
            classNameContent="product-selection"
            nextButton={{
                onClick: onNextWithSelect,
            }}
            prevButton={{
                onClick: () => changeStep(1),
            }}
        >
            <Preloader isLoading={isLoading} error={error}>
                <div className="group-title">
                    <Title type={3}>{t('onBoarding.selectProduct.subTitle')}</Title>
                    <Paragraph type="secondary">{t('onBoarding.selectProduct.text')}</Paragraph>
                </div>
                <EmptyContent data={data} text={t('dashboard.noData')}>
                    <RadioButtonsGroup
                        label=""
                        value={selected}
                        options={options}
                        onChange={onChange}
                    />
                    {selected && (
                        <Preloader isLoading={riskCategoriesIsLoading} error={riskCategoriesError}>
                            <Row className="product-details">
                                <Column size="12" className="product-details-title">
                                    <Title type={3}>{t('onBoarding.selectProduct.productDetails.subTitle')}</Title>
                                </Column>
                                {productDescription && (
                                    <Column size={['sm-12', 'md-6']}>
                                        <Paragraph type="secondary">{productDescription}</Paragraph>
                                    </Column>
                                )}
                                <Column size={['sm-12', 'md-6']} className="product-kpi">
                                    {(productValues || []).map((item) => (
                                        <div className="field" key={item.name}>
                                            <span className="field-name">{item.name}</span>
                                            <span className="field-value">{item.value}</span>
                                        </div>
                                    ))}
                                </Column>
                            </Row>
                            {keyAdvantages?.length > 0 && (
                                <Row className="product-key-advantages">
                                    <Column size="12">
                                        <Title type={3}>{t('onBoarding.selectProduct.keyAdvantages.subTitle')}</Title>
                                    </Column>
                                    {(keyAdvantages || []).map((item) => (
                                        <Column size={['sm-12', 'md-4']} className={`key-advantage ${item?.length < 25 ? 'short-text' : ''}`}>
                                            <div className="icon">
                                                <Icon type="check-filled" size={16} />
                                            </div>
                                            <Paragraph type="secondary">{item}</Paragraph>
                                        </Column>
                                    ))}
                                </Row>
                            )}
                        </Preloader>
                    )}
                </EmptyContent>
                {selectedError && (
                    <Infobox error className="validation-error">
                        <div>{t('onBoarding.selectProduct.validation')}</div>
                    </Infobox>
                )}
            </Preloader>
        </OnBoardingBaseTemplate>
    );
};

ProductSelection.propTypes = {
    onNext: PropTypes.func,
    changeStep: PropTypes.func,
};

ProductSelection.defaultProps = {
    onNext: () => {},
    changeStep: () => {},
};

export default ProductSelection;
