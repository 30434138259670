import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import {
    Button, Title, Paragraph, Toggle, Label as LabelUI,
} from 'ui-library';
import ContentBox from 'ui-library/dist/components/ContentBox';
import useClientProfile from 'prodivers/clientProfile/hooks/useClientProfile';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import { useKnowledgeExperience } from '../../hooks/useKnowledgeExperience';
import { useExpandUniverse } from './hooks/useExpandUniverse';
import { collectAnswers } from '../../adapters/adaptKnowledgeExperience';

import './ExpandUniverse.css';

const ExpandUniverse = (props) => {
    const {
        location: { state },
    } = props;
    const { t } = useTranslation();
    const { clientId } = useClientProfile();
    const {
        results: data, resultsAdapted, errorResults, isLoadingResults, getQuestionnaireResults,
        isSending, errorSending, sendKnowledgeExperience,
    } = useKnowledgeExperience({ contactId: clientId, productId: state?.productId });
    const {
        values, disabledToggle, toggledValues, onDownload, onToggle,
    } = useExpandUniverse(resultsAdapted?.negative);
    const answers = useMemo(() => collectAnswers(data), [data]);

    useEffect(() => {
        getQuestionnaireResults();
    }, [getQuestionnaireResults]);

    const onNextClick = async () => {
        const answersNew = {
            ...answers,
            [resultsAdapted.id]: answers?.[resultsAdapted.id]
                ? [...answers?.[resultsAdapted.id], ...values] : [...values],
        };

        await sendKnowledgeExperience(answersNew, data, true);
        history.push('/knowledge-experience/results', state);
    };

    return (
        <ContentBox className="expand-universe-details">
            <Preloader
                isLoading={isLoadingResults}
                error={errorResults}
            >
                <EmptyContent data={data?.Groups?.[0]} text={t('advisoryDashboard.noData')}>
                    <Title type={2}>{t('onBoarding.expandYourInstrumentUniverse')}</Title>
                    <div className="expand-universe-content-details">
                        <Title type={3}>{t('onBoarding.howItWorks')}</Title>
                        <Paragraph type="secondary">
                            <ol>
                                <li>{t('onBoarding.downloadEachDocumentOfInstrument')}</li>
                                <li>{t('onBoarding.itContainsAllImportantInformation')}</li>
                                <li>{t('onBoarding.readDocumentCarefullyAndConfirm')}</li>
                                <li>{t('onBoarding.inCaseYouHaveQuestions')}</li>
                            </ol>
                        </Paragraph>
                        {(resultsAdapted?.negative || []).map((item) => (
                            <div className="instrument-block">
                                <Title type={3}>{item.Text}</Title>
                                <Paragraph type="secondary">
                                    {`${t('onBoarding.pleaseDownloadDocumentBelow')} ${item.Text}.`}
                                </Paragraph>
                                <Button type="secondary" size="small" onClick={onDownload(item)}>
                                    {`${t('onBoarding.downloadFactsheet')} ${item.Text}`}
                                </Button>
                                <LabelUI label={t('confirmation.confirmation')} />
                                <div className="toggle-block">
                                    <Toggle
                                        value={toggledValues[item.Id]}
                                        disabled={disabledToggle[item.Id]}
                                        onChange={onToggle(item)}
                                    />
                                    <span>{t('onBoarding.IConfirmToHaveReadAndCompleted')}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('confirmation.back'),
                            onClick: () => {
                                history.push('/knowledge-experience/results', state);
                            },
                        }}
                        primaryButton={{
                            text: t('confirmation.continue'),
                            onClick: onNextClick,
                            loading: isSending,
                        }}
                    >
                        {errorSending
                        && <WidgetError accent error message={errorSending.message} />}
                    </ButtonsBlockRow>
                </EmptyContent>
            </Preloader>
        </ContentBox>
    );
};

ExpandUniverse.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
            productId: PropTypes.string,
            productName: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.number),
            answers: PropTypes.arrayOf(PropTypes.shape({
                Answers: PropTypes.arrayOf(PropTypes.shape({
                    Id: PropTypes.number,
                    Text: PropTypes.string,
                })),
                Values: PropTypes.arrayOf(PropTypes.number),
                allAnswered: PropTypes.bool,
                Id: PropTypes.number,
            })),
        }),
    }).isRequired,
};

ExpandUniverse.defaultProps = {
};

export default ExpandUniverse;
