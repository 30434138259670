import { useState, useMemo } from 'react';

// use skipLoading: true if it is needed to avoid loading icon
// on the whole list when getting the next items
export const useLoadMoreNews = ({
    initialNews, pageSize, getNewsData, skipLoading,
}) => {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);

    const nextArticles = async (newsData) => {
        try {
            const nextNewsItems = await getNewsData({
                pageSize, page: page + 1, skipLoading,
            });
            const nextArticleList = [...newsData, ...nextNewsItems];

            setPage(page + 1);
            setArticles(nextArticleList);

            return true;
        } catch (error) {
            return false;
        }
    };

    const newsList = useMemo(() => {
        if (!articles.length) return initialNews;

        return articles;
    }, [initialNews, articles]);

    return { nextArticles, newsList };
};
