import React, { useCallback, useState } from 'react';
import './RadioBlocks.css';
import { getClassNames } from 'utils';
import { Infobox } from 'ui-library';
import PropTypes from 'prop-types';
import ImageWithPlaceholder from '../ImageWithPlaceholder';

export default function RadioBlocks({
    options, value, onChange, withoutImg = false, error,
}) {
    const [checked, setChecked] = useState(value);

    const onChangeValue = useCallback((e) => {
        setChecked(e.target.value);
        onChange(e.target.value);
    }, [onChange, setChecked]);

    return (
        <>
            <div className="radio-buttons">
                <div className="radio-buttons-container">
                    {options.map((item) => (
                        <label
                            htmlFor={item.label?.id}
                            className={getClassNames(
                                'radio-button-container',
                                { 'without-img': withoutImg || !item?.label?.image },
                            )}
                            key={item.label?.id}
                        >
                            <input
                                id={item.label?.id}
                                type="radio"
                                value={item?.value}
                                checked={checked === item?.value}
                                onChange={onChangeValue}
                            />
                            <span className="checkmark">
                                {!withoutImg && item?.label?.image
                                && (
                                    <div className="checkmark-img">
                                        <ImageWithPlaceholder
                                            alt={item.label?.name}
                                            src={item.label?.image}
                                        />
                                    </div>
                                ) }
                            </span>
                            <div className="info">
                                {checked === item?.value && <div className="check" /> }
                                {item?.label?.name?.length > 0 && <span className="name">{item.label.name}</span>}
                                {item?.label && item?.label?.description && item?.label?.description?.length > 0 && <span className="description">{item.label.description}</span>}
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            {error && <Infobox error>{error}</Infobox>}
        </>
    );
}

RadioBlocks.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any),
    value: PropTypes.string,
    onChange: PropTypes.func,
    withoutImg: PropTypes.bool,
    error: PropTypes.string,
};

RadioBlocks.defaultProps = {
    options: [],
    value: null,
    onChange: () => {},
    withoutImg: false,
    error: null,
};
