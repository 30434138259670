import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { contactIdSelector, isAuthenticatedSelector } from 'redux/auth/authSelectors';
import { connect } from 'react-redux';
import { logout } from 'redux/auth/authActions';
import history from 'services/history';
import { useNotifications } from 'hooks/useNotifications';
import { useClientProfile } from 'prodivers/clientProfile';
import { useUnreadMessages } from 'prodivers/unreadMessages';
import { Header } from '../components/Header';

const HeaderContainer = ({ contactId, isAuthenticated, ...props }) => {
    const { productIds } = useClientProfile();
    const unreadMessages = useUnreadMessages();
    const notifications = useNotifications();
    const { data: dataClient } = useClientProfile();

    const profileData = useMemo(() => ({
        name: dataClient?.Name,
        image: dataClient?.Img || '',
        riskProfile: dataClient?.ChosenRiskCategory,
        portfolios: dataClient?.Portfolios,
        hasProducts: productIds?.length > 0,
    }), [dataClient, productIds]);

    return (
        <Header
            {...props}
            profile={profileData}
            notifications={notifications}
            unreadMessages={unreadMessages > 0}
            isAuthenticated={isAuthenticated}
        />
    );
};

HeaderContainer.propTypes = {
    contactId: PropTypes.number.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
    contactId: contactIdSelector(state),
});

const mapDispatchToProps = {
    logout,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, ownProps, stateProps, {
        onRightButtonClick: () => {
            if (stateProps.isAuthenticated === true) {
                dispatchProps.logout();
            } else {
                history.push('/login');
            }
        },
    },
);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HeaderContainer);
