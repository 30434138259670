import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Title, Button } from 'ui-library';
import { useTranslation } from 'react-i18next';
import MasonryGrid from 'components/MasonryGrid';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import NewsItem from './NewsItem';
import './News.css';

function News({
    data,
    isLoading,
    error,
    withLink,
    title,
    pageSize,
    nextArticles,
    loadMore,
    nextArticlesSize,
    isAnnouncements,
}) {
    const { t } = useTranslation();
    const [listSize, setListSize] = useState(pageSize);
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const sectionTitle = useMemo(() => title || t('news.title'), [title]);

    const slice = useCallback(
        (arr) => arr.slice(0, listSize),
        [listSize],
    );

    const onLoadMore = async () => {
        if (listSize === data.length) {
            setIsLoadingItems(true);
            setListSize(listSize + nextArticlesSize);

            const nextNews = await nextArticles(data);

            setIsLoadingItems(!nextNews);
        }

        if (listSize < data.length) {
            setListSize(listSize + nextArticlesSize);
        }
    };

    const renderLoadMoreButton = useMemo(() => {
        if (
            (isAnnouncements && listSize >= data.length)
            || (!isLoadingItems && listSize > data.length)
            || !loadMore
        ) return null;

        return (
            <div className="load-more">
                <Button
                    type="secondary"
                    text={t('news.LoadMore')}
                    className="load-more--btn"
                    onClick={onLoadMore}
                    loading={isLoadingItems}
                    size="small"
                >
                    {t('news.LoadMore')}
                </Button>
            </div>
        );
    }, [data, listSize, isLoadingItems]);

    return (
        <PageSection>
            <Preloader isLoading={isLoading} error={error}>
                <div className="news">
                    {withLink ? (
                        <Title type={2} link="/news/external">
                            {sectionTitle}
                        </Title>
                    ) : (
                        <Title type={2}>{sectionTitle}</Title>
                    )}
                    <EmptyContent data={data} text={t('news.noNews')}>
                        <MasonryGrid columnsBreakPoints={{ 912: 3, 688: 2, 576: 1 }}>
                            {slice(data).map((item) => (
                                <NewsItem {...item} key={item.id} />
                            ))}
                        </MasonryGrid>
                    </EmptyContent>
                    {renderLoadMoreButton}
                </div>
            </Preloader>
        </PageSection>
    );
}

News.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
            link: PropTypes.string,
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    withLink: PropTypes.bool,
    title: PropTypes.string,
    pageSize: PropTypes.number,
    nextArticles: PropTypes.func,
    nextArticlesSize: PropTypes.number,
    loadMore: PropTypes.bool,
    isAnnouncements: PropTypes.bool,
};

News.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    withLink: false,
    title: null,
    pageSize: 6,
    nextArticles: () => {},
    nextArticlesSize: 3,
    loadMore: false,
    isAnnouncements: false,
};

export default News;
