import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useProps } from '../store-provider';
import Preview from '../components/Preview';
import { deletePreview, getListData } from '../actions';

const PreviewContainer = ({ deleteItem, ...props }) => {
    const dispatch = useDispatch();
    const store = useStore();
    const { getData, getDataCallback } = useProps();
    const deleteCallback = useMemo(() => getListData(dispatch, store, getData, getDataCallback),
        [getListData, dispatch, store, getData, getDataCallback]);

    return (
        <Preview
            {...props}
            item={store.preview}
            error={store.errorPreview}
            isLoading={store.isPreviewLoading}
            deletePreview={deletePreview(dispatch, store, deleteItem, deleteCallback)}
            deleteCallback={deleteCallback}
        />
    );
};

PreviewContainer.propTypes = {
    deleteItem: PropTypes.func,
};

PreviewContainer.defaultProps = {
    deleteItem: () => {},
};

export default PreviewContainer;
