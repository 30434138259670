import { useCallback } from 'react';
import { Match } from 'types/common';
import ServiceManager from 'services/ServiceManager';
import history from 'services/history';
import { ResultResponseData } from 'additiv-services/dist/types/services/security';
import { AxiosResponse } from 'axios';
import { usePasswordManipulations } from './usePasswordManipulations';
import { PasswordChangeFlow, ResetPasswordHookReturn } from '../types';
import { validate } from '../validators/forgotPasswordValidation';


export const useForgotPassword = (
    route: { match: Match<{ activationKey: string }> },
):ResetPasswordHookReturn => {
    const activationKey = decodeURIComponent(route.match?.params?.activationKey);
    const passwordManipulationsData = usePasswordManipulations({
        validate,
        serviceMethod: ():Promise<AxiosResponse<ResultResponseData>> => (
            ServiceManager.Security('changePasswordByActivationCodeWithPolicies', [{
                payload: {
                    ActivationKey: activationKey,
                    Password: passwordManipulationsData.password,
                    ConfirmPassword: passwordManipulationsData.confirmPassword,
                },
            }])
        ),
    });

    const onBack = useCallback(() => {
        history.push('/login');
    }, []);

    return {
        ...passwordManipulationsData,
        processType: PasswordChangeFlow.FORGOT_PASSWORD,
        resetView: {
            title: 'resetPassword.passwordCreation',
            description: 'resetPassword.enterNewPassword',
            backURLButtonLabel: 'resetPassword.cancel',
        },
        successView: {
            title: 'resetPassword.newPasswordIsSet',
            description: 'resetPassword.passwordIsReadyToUse',
            backURLButtonLabel: 'resetPassword.goToLogin',
            onBack,
        },
    };
};
