import React from 'react';
import { Select } from 'ui-library';
import './LanguageSwitcher.css';
import { useLanguageSwitcher } from '../../hooks/useLanguageSwitcher';

const LanguageSwitcher = () => {
    const {
        languages, language, changeLanguage, t,
    } = useLanguageSwitcher();

    if (languages.length) {
        return (
            <div className="LanguageSwitcher">
                <Select
                    options={languages}
                    placeholder={t('common.selectLanguage')}
                    value={language}
                    onChange={changeLanguage}
                />
            </div>
        );
    }

    return null;
};

export default LanguageSwitcher;
