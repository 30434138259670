import React from 'react';
import { PageVariant } from 'ui-library';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { useClientProfile } from '../prodivers/clientProfile';

const withRiskProfileAccess = (Component) => (props) => {
    const { t } = useTranslation();
    const { productIds, isLoadingOverview, errorOverview } = useClientProfile();

    if (productIds?.length > 0) return <Component {...props} />;

    return (
        <Preloader isLoading={isLoadingOverview} error={errorOverview}>
            <PageVariantWrapper>
                <PageVariant
                    type="error"
                    title={t('error.title')}
                    message={t('common.PAGE_NOT_FOUND')}
                    actionButtonLabel={t('confirmation.back')}
                    onActionButtonClick={history.goBack}
                />
            </PageVariantWrapper>
        </Preloader>
    );
};

export default withRiskProfileAccess;
