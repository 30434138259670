import uniq from 'lodash/uniq';
import { getfromDataObject, validateStringData } from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { adaptProposalsByPortfolioId } from 'adaptors/adaptProposals';
import {
    sortStatuses, portfolioStatuses,
    portfolioStatusesColors, PENDING_ORDERS, NOT_IMPLEMENTED, IN_EXECUTION, BLOCKED, CLOSED,
    getPortfolioStatus,
    PORTFOLIO_CLOSED_ID,
} from 'constants/portfolioStatuses';
import { EXECUTION_ONLY_ID } from 'constants/portfolioProducts';

const filterPortfolios = (portfolios) => portfolios.filter(
    (item) => item.PortfolioTypeId === portfolioTypeIds.MAIN,
);

export const adaptPortfolios = (data, {
    proposals, portfolioActions, t, getFormattedCurrency, portfoliosOverview,
}) => {
    if (!data.Portfolios) return [];

    const portfolios = filterPortfolios(data.Portfolios);
    const proposalsAdapted = adaptProposalsByPortfolioId(proposals);
    const pendingOrders = portfoliosOverview?.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item?.HasPendingOrders,
    }), {}) || {};

    return portfolios.filter((item) => item.PortfolioStatusId !== PORTFOLIO_CLOSED_ID)
        .map((item) => {
            const proposalStatuses = sortStatuses(
                uniq((proposalsAdapted[item.Id] || []).map(({ status }) => status)),
            );

            const portfolioStatus = getPortfolioStatus({
                portfolioStatusId: item.PortfolioStatusId,
                isBreached: item?.IsBreached,
                isOrderBookBlocked: pendingOrders?.[item.Id],
                proposalStatus: proposalStatuses?.[0],
            });

            const link = () => {
                if (portfolioStatus === NOT_IMPLEMENTED) {
                    return `/portfolios/${item.Id}/funding-information`;
                }
                if (portfolioStatus === IN_EXECUTION) {
                    return `/portfolios/${item.Id}/pending-orders`;
                }

                return `/portfolios/${item.Id}`;
            };

            const isStrategyHidden = EXECUTION_ONLY_ID.includes(getfromDataObject(item, 'Product.Id'));

            return ({
                UId: item.UId,
                Name: {
                    label: validateStringData(item.Name),
                    link: link(),
                    disabled: portfolioStatus === CLOSED,
                },
                Product: getfromDataObject(item, 'Product.Name'),
                Profile: isStrategyHidden ? '' : validateStringData(item.RiskCategory.Name),
                Status: {
                    text: portfolioStatuses(t)[portfolioStatus] || '-',
                    value: portfolioStatus,
                    color: portfolioStatusesColors[portfolioStatus] || '',
                },
                Value: getFormattedCurrency(item.CurrentValue, {
                    currency: item.Currency.CurrencyCode,
                }),
                Performance: item.CurrentPerformance ? item.CurrentPerformance * 100 : undefined,
                Actions: {
                    value: 'action',
                    actions: portfolioActions({
                        id: item.Id,
                        productName: item.Product.Name,
                        t,
                        withEditActions:
                        portfolioStatus !== PENDING_ORDERS && portfolioStatus !== BLOCKED,
                        withPendingOrders: portfolioStatus === IN_EXECUTION,
                        withFundingInformation: portfolioStatus === NOT_IMPLEMENTED,
                    }),
                    disabled: portfolioStatus === CLOSED,
                },
            });
        });
};
