export const adaptSecuritiesForSave = ({
    data, changesManual, IsNotAdvised = false,
} = {}) => (data || []).map((group) => (
    group.children.map(({ id, new: { value } }) => {
        const allocation = (changesManual || []).find((item) => item.Id === id)?.Allocation
            || value;

        return {
            InstrumentId: id,
            Allocation: allocation / 100,
            IsNotAdvised,
        };
    })))
    .flat()
    .filter((i) => i.Allocation > 0);
