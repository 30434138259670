import { useEffect, useMemo } from 'react';
import {
    date, number, object, string,
} from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';
import { addUnits } from 'utils/datetime';
import {
    BUY, GOOD_TILL_DATE, LIMIT, MARKET, SELL, STOP,
} from '../constants';

const tomorrow = addUnits(new Date(), 1, 'day');

export const useTradeCardForm = ({ defaultData, price, tradingUnit } = {}) => {
    const { t } = useTranslation();
    const schema = useMemo(() => object().shape({
        order: string().required(t('validation.mandatoryField')),
        modificationType: number(),
        orderType: string().required(t('validation.mandatoryField')),
        tradeQuantity: number().required(t('validation.mandatoryField'))
            .min(tradingUnit, `${t('position.tradeQuantityMinError')} ${tradingUnit}`),
        limit: number()
            .when('orderType === LIMIT', (limit, schemaCur, { parent }) => (parent.orderType === LIMIT
                ? schemaCur.required(t(`position.limitPriceError.limit${parent?.order}`)) : schemaCur))
            .when('orderType === STOP', (limit, schemaCur, { parent }) => (parent.orderType === STOP
                ? schemaCur.required(t(`position.limitPriceError.${parent?.order?.toLowerCase()}`)) : schemaCur))
            .when('order === BUY', (stop, schemaCur, { parent }) => (parent.orderType === STOP && parent.order === BUY
                ? schemaCur.min(parent.stop, t('position.limitPriceError.buy')) : schemaCur))
            .when('order === SELL', (stop, schemaCur, { parent }) => (parent.orderType === STOP && parent.order === SELL
                ? schemaCur.max(parent.stop, t('position.limitPriceError.sell')) : schemaCur)),
        stop: number()
            .when('orderType === STOP', (stop, schemaCur, { parent }) => (parent.orderType === STOP
                ? schemaCur.required(t(`position.stopPriceError.${parent?.order?.toLowerCase()}`)) : schemaCur))
            .when('order === BUY', (stop, schemaCur, { parent }) => (parent.orderType === STOP && parent.order === BUY
                ? schemaCur.min(price, t('position.stopPriceError.buy')) : schemaCur))
            .when('order === SELL', (stop, schemaCur, { parent }) => (parent.orderType === STOP && parent.order === SELL
                ? schemaCur.max(price, t('position.stopPriceError.sell')) : schemaCur)),
        fundingAccount: number().required(t('position.validation.fundingAccount')),
        validity: string().required(t('validation.mandatoryField')),
        validityDate: date()
            .when('validity === GOOD_TILL_DATE', (limit, schemaCur, { parent }) => (parent.validity === GOOD_TILL_DATE
                ? schemaCur.required(t('position.validation.validityDate')).min(tomorrow, t('position.validation.validityDate'))
                : schemaCur)),
    }), [t, price, tradingUnit]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, control, watch, formState, trigger,
    } = useForm({ resolver, reValidateMode: 'onChange' });

    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset({
                order: defaultData.order,
                orderType: defaultData.orderType,
                tradeQuantity: defaultData.quantity,
                limit: defaultData.limit,
                stop: defaultData.stop,
                fundingAccount: defaultData.fundingAccount,
                validity: defaultData.validity,
            });
        }
    }, [defaultData, reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = (type) => (value) => {
        if (type === 'order') {
            setValue('stop', undefined);
            setValue('tradeQuantity', undefined);
        }
        if (type === 'orderType') {
            if (value === LIMIT) {
                setValue('stop', undefined);
            }

            if (value === MARKET) {
                setValue('limit', undefined);
                setValue('stop', undefined);
            }
        }
        if (type === 'validity') {
            register('validityDate');
        }
        setValue(type, value);
        if (formState.isSubmitted) {
            trigger(type);
            if (['order', 'orderType', 'limit', 'stop'].includes(type)) {
                trigger('limit');
                trigger('stop');
            }
        }
    };

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
    };
};
