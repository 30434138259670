export const adaptSecuritiesHistory = (historicalData) => (historicalData || []).map((item) => {
    const itemKeys = Object.keys(item);
    const newObject = {};

    itemKeys.forEach((key) => {
        newObject[key.charAt(0).toLocaleUpperCase() + key.slice(1)] = item[key];
    });

    return newObject;
});
