import { createSelector } from 'reselect/lib/index';
import { get } from 'lodash/fp';
import { isSessionValidSelector } from '../session/sessionSelectors';

const roles = {
    PRIVATE: 1,
    CORPORATE: 2,
    MANAGER: 4,
    ADMIN: 8,
};

export const authSelector = (state) => state.auth;
export const errorSelector = (state) => state.error;

export const isRenewAccessTokenProcessStartedSelector = (state) => (
    authSelector(state).isRenewAccessTokenProcessStarted === true
);
export const isAuthenticatedSelector = createSelector(
    authSelector,
    isSessionValidSelector,
    (auth, isSessionValid) => auth.isAuthenticated && isSessionValid === true,
);

export const isLoginDoneSelector = (state) => authSelector(state).isAuthenticated === true;

export const isJWTBaseLoginSelector = (state) => authSelector(state).isJWTBaseLogin === true;

export const isFistStepDoneSelector = createSelector(authSelector, (auth) => auth.isFistStepDone);
export const userSelector = createSelector(authSelector, (auth) => get('user', auth));
export const passcodeLengthSelector = createSelector(userSelector, (user) => get('passcodeLength', user));
export const rememberMeSelector = createSelector(userSelector, (user) => get('rememberMe', user));
export const factorSelector = createSelector(userSelector, (user) => get('factor', user));
export const providerSelector = createSelector(userSelector, (user) => get('provider', user));
export const memberIdSelector = createSelector(userSelector, (user) => get('relationshipManagerId', user));
export const memberPhoneSelector = createSelector(userSelector, (user) => get('relationshipManagerPhone', user));
export const contactIdSelector = createSelector(userSelector, (user) => get('contactId', user));
export const sessionIdSelector = createSelector(userSelector, (user) => get('sessionId', user));
export const isUserActiveSelector = createSelector(userSelector, (user) => get('isActive', user));
export const jwtSelector = createSelector(userSelector, (user) => get('jwt', user));
export const jwtAccessTokenSelector = createSelector(userSelector, (user) => get('jwtAccessToken', user));
export const bearerAccessTokenSelector = createSelector(
    userSelector,
    (user) => `Bearer ${get('jwtAccessToken', user)}`,
);
export const contactTypeIdSelector = createSelector(userSelector, (user) => get('contactTypeId', user));
export const isTwoFactorEnabledSelector = createSelector(userSelector, (user) => get('isTwoFactorEnabled', user));
export const qrCodeSelector = createSelector(userSelector, (user) => get('AsynchronousParameters.QrCodeUrl', user));
export const hasPrivateRoleSelector = createSelector(
    contactTypeIdSelector,
    (contactTypeId) => contactTypeId & roles.PRIVATE,
);
export const hasCorporateRoleSelector = createSelector(
    contactTypeIdSelector,
    (contactTypeId) => contactTypeId & roles.CORPORATE,
);
export const hasManagerRoleSelector = createSelector(
    contactTypeIdSelector,
    (contactTypeId) => contactTypeId & roles.MANAGER,
);
export const hasAdminRoleSelector = createSelector(
    contactTypeIdSelector,
    (contactTypeId) => contactTypeId & roles.ADMIN,
);
export const usernameSelector = createSelector(userSelector, (user) => user.username);
export const passwordSelector = createSelector(userSelector, (user) => user.password);
export const preAuthenticateSessionIdSelector = createSelector(
    userSelector,
    (user) => user.preAuthenticateSessionId,
);
export const contactFirstNameSelector = createSelector(userSelector, (user) => get('contactFirstName', user));
export const contactLasNameSelector = createSelector(userSelector, (user) => get('contactLasName', user));
export const isUserNameAndLastNameAvailableSelector = createSelector(
    contactFirstNameSelector,
    contactLasNameSelector,
    (contactFirstName, contactLasName) => (
        contactFirstName !== undefined && contactLasName !== undefined
    ),
);

export const userNameAndLastNameSelector = createSelector(
    isUserNameAndLastNameAvailableSelector,
    contactFirstNameSelector,
    contactLasNameSelector,
    (isUserNameAndLastNameAvailable, contactFirstName, contactLasName) => (
        isUserNameAndLastNameAvailable === true
            ? `${contactFirstName} ${contactLasName}`
            : undefined),
);
