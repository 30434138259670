import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { ForgotPasswordResponseData } from 'additiv-services/dist/types/services/security';
import ServiceManager from 'services/ServiceManager';
import { EMAIL_INVALID } from 'static/requestResults';
import { CONTACT_NOT_ACTIVATED_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from 'error/commonErrors';
import { getErrorModelByStatus } from '../errors';
import { MTAN, RESET_PASSWORD_ERROR } from '../static/views';
import {
    EmailForgotPasswordSubmitReturn, MTanlenght, SessionId, FormInput,
} from '../types';

interface EmailForgotPasswordSubmitProps {
    setError: (value: string | undefined) => void,
    setView: (value: number) => void,
    setSubmitting: (value: boolean) => void,
}

export const useEmailForgotPasswordSubmit = ({
    setError,
    setView,
    setSubmitting,
}: EmailForgotPasswordSubmitProps): EmailForgotPasswordSubmitReturn => {
    const [sessionId, setSessionId] = useState<SessionId>(null);
    const [mTANLength, setMTANLength] = useState<MTanlenght>(undefined);

    const handleSubmit = ({ email, username }: FormInput) => {
        if (email) {
            setSubmitting(true);

            ServiceManager.Security('forgotPassword', [
                {
                    payload:
                    {
                        UserName: username,
                        Email: email,
                    },
                },
            ]).then(({ data }: AxiosResponse<ForgotPasswordResponseData>) => {
                const responseSessionId = data?.SessionId;

                setSessionId(responseSessionId);
                setSubmitting(false);
                if (responseSessionId !== undefined) {
                    ServiceManager.commonService('lengthOfMTan')
                        .then((mtanData) => {
                            setError(undefined);
                            setMTANLength(Number(mtanData.data.Value));
                            setView(MTAN);
                        })
                        .catch(() => {
                            setError(undefined);
                            setView(RESET_PASSWORD_ERROR);
                        });
                } else {
                    setError(UNEXPECTED_ERROR_MESSAGE);
                }
            }).catch(({ response }) => {
                const serverResponseStatus = response.status;
                let errorMsg: string | undefined;

                setSubmitting(false);
                if (serverResponseStatus === 422) {
                    errorMsg = CONTACT_NOT_ACTIVATED_MESSAGE;
                } else if (serverResponseStatus === 500) {
                    errorMsg = UNEXPECTED_ERROR_MESSAGE;
                }

                setError(errorMsg);
            });
        } else {
            setError(getErrorModelByStatus(EMAIL_INVALID)?.message);
        }
    };


    return {
        handleSubmit,
        mTANLength,
        sessionId,
    };
};
