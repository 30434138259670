import React from 'react';
import PropTypes from 'prop-types';
import './AccordionWrapper.css';
import { getClassNames } from '../../utils';

const AccordionWrapper = ({
    children,
    className,
}) => {
    const classNames = getClassNames('AccordionWrapper', {
        className,
    });

    return (
        <div className={classNames}>
            {children}
        </div>
    );
};


AccordionWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
};

AccordionWrapper.defaultProps = {
    children: '',
    className: '',
};

export default AccordionWrapper;
