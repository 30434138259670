import { OVERVIEW_SIZE_SMALL } from '../../../../../../components/Overview';
import { TYPE_CURRENCY } from '../../../../../../utils/formatting';

export const adaptReviewPageOverview = ({
    portfolioValue, currency, t, product, strategy, onStrategyChange, onModelChange, modelPortfolio,
}) => (
    [
        {
            columnsData: [
                {
                    overviewItemsInfo: [
                        {
                            title: t('onBoarding.goal.initialInvestment'),
                            value: portfolioValue,
                            type: TYPE_CURRENCY,
                            currency,
                        },
                    ],
                },
                {
                    overviewItemsInfo: [
                        {
                            title: t('overview.product'),
                            value: product,
                        },
                    ],
                },
                {
                    overviewItemsInfo: [
                        {
                            title: t('overview.strategy'),
                            value: strategy,
                            linkLabel: t('overview.changeStrategy'),
                            link: onStrategyChange,
                        },
                    ],
                },
                {
                    overviewItemsInfo: [
                        {
                            title: t('overview.modelPortfolio'),
                            value: modelPortfolio,
                            linkLabel: t('overview.changeModel'),
                            size: OVERVIEW_SIZE_SMALL,
                            link: onModelChange,
                        },
                    ],
                },
            ],
        },
    ]
);
