import React, {
    useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Accordion, AccordionPanel as Panel, Row,
    Column, Infobox, Table,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import {
    orderBookSelector,
    portfolioSelector,
    usePortfolioSelector,
} from 'domain/Portfolio';
import AccordionWrapper from 'components/AccordionWrapper';
import ContentBox from 'ui-library/dist/components/ContentBox';
import PageHeader from 'components/PageHeader';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { getOrdersColumns } from '../SinglePortfolioView/constants';

function PendingOrders(props) {
    const { location: { state } } = props;
    const fromReview = state?.fromReview || false;
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(['1']);

    // Portfolio Domain
    const {
        data, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        if (data?.productId) {
            getOrderBook({ adaptOptions: { productId: data?.productId } });
        }
    }, [data?.productId, getOrderBook]);

    const handleBackClick = () => {
        const fromOrigin = state && state?.fromOrigin;
        const currentPage = state && state?.currentPage;
        const currentTab = state && state?.currentTab;

        if (fromReview) {
            history.push('/risk-profile', { step: 3 });

            return;
        }

        if (!fromOrigin) {
            history.push('/portfolios');

            return;
        }

        history.push(`/${fromOrigin}`, { currentPage, currentTab });
    };

    return (
        <Row className="single-portfolio">
            <Column size="12">
                <Preloader
                    isLoading={isLoading || isLoadingOrderBook}
                    error={error || errorOrderBook}
                >
                    {orderBook && <Infobox>{t('portfolios.orderBookMessage')}</Infobox>}
                    <ContentBox underline={false} className="portfolio-content">
                        <PageHeader
                            title={data?.title}
                            breadCrumbsCurrent={data.backTitle}
                            breadCrumbsChildren={[
                                {
                                    to: '/portfolios',
                                    label: t('portfolios.title'),
                                },
                            ]}
                        />
                        <AccordionWrapper>
                            <Accordion activeKeys={activeKey} onChange={setActiveKey}>
                                <Panel header={t('portfolios.pendingOrder')} className="pending-order" key="1">
                                    <Table
                                        className="positions-table"
                                        data={orderBook || []}
                                        columns={getOrdersColumns(t)}
                                        expandedColumn="name"
                                        defaultExpandAllRows
                                    />
                                </Panel>
                            </Accordion>
                        </AccordionWrapper>
                    </ContentBox>
                    <ButtonsBlockRow
                        leftButton={{
                            text: t('payments.back'),
                            onClick: handleBackClick,
                        }}
                    />
                </Preloader>
            </Column>
        </Row>
    );
}

PendingOrders.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromReview: PropTypes.bool,
            fromOrigin: PropTypes.string,
            currentPage: PropTypes.string,
            currentTab: PropTypes.string,
        }),
    }),
};

PendingOrders.defaultProps = {
    location: {
        state: {},
    },
};

export default PendingOrders;
