import { useEffect, useCallback } from 'react';

const SITE_KEY = globalThis?.AppConfig?.CAPTCHA_KEY;
const VERIFY_ACTION = 'verifyToken';

// TODO: find a way to be able to pass token login verification on visual regression and localhost
// const isVisualRegressionEnvironment = global.window.location.hostname.includes('azurewebsites');


export const useCaptcha = () => {
    const getCaptchaToken = useCallback(async () => (
        global.window.grecaptcha
            .execute(SITE_KEY, { action: VERIFY_ACTION })
            .then((token) => token)
            .catch((execError) => execError)
    ), [SITE_KEY, VERIFY_ACTION]);

    // Add captcha script
    useEffect(() => {
        const script = document.createElement('script');

        script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
        document.body.appendChild(script);
    }, []);

    return {
        getCaptchaToken,
    };
};
