import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'ui-library';
import './RadioGroupReview.css';

const RadioGroupReview = ({ selected, number }) => (
    <div className="review-radio-group">
        {Array.from({ length: number }, (v, index) => (
            <RadioButton
                key={`review-radio-${index + 1}`}
                label=""
                checked={index < selected}
                disabled={index >= selected}
                onChange={() => {}}
            />
        ))}
    </div>
);

RadioGroupReview.propTypes = {
    selected: PropTypes.number,
    number: PropTypes.number,
};

RadioGroupReview.defaultProps = {
    selected: 0,
    number: 5,
};

export default RadioGroupReview;
