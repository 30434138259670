import axios from 'axios';
import Security from 'additiv-services/dist/services/core/security';

const CLARITY = 'clarity';

class Clarity {
    static basicAuth = undefined;

    static clarityUrl = `${global.AppConfig.CLARITY_URL}/clarity/v1`;

    static setBasicAuth(basicAuth) {
        this.basicAuth = basicAuth;
    }

    static get generalSettings() {
        return {
            headers: {
                Authorization: `Bearer ${this.basicAuth}`,
                'Content-Type': 'application/json',
            },
        };
    }

    static getRequest(url) {
        return axios.get(url, this.generalSettings);
    }

    static putRequest(url, data) {
        return axios.put(url, data, this.generalSettings);
    }

    static postRequest(url, data) {
        return axios.post(url, data, this.generalSettings);
    }

    static deleteRequest(url) {
        return axios.delete(url, this.generalSettings);
    }

    static generateToken() {
        return Security.getAccessTokenForExtension(
            { payload: { type: CLARITY }, config: { isSecure: true } },
        );
    }

    static getPortfolios() {
        return this.getRequest(`${this.clarityUrl}/public/portfolios`);
    }

    static getPortfolio(id) {
        return this.getRequest(`${this.clarityUrl}/public/portfolios/${id}`);
    }

    static postPortfolios(data) {
        return this.postRequest(`${this.clarityUrl}/public/portfolios`, data);
    }

    static putPortfolio(id, data) {
        return this.putRequest(`${this.clarityUrl}/public/portfolios/${id}`, data);
    }

    static deletePortfolio(id) {
        return this.deleteRequest(`${this.clarityUrl}/public/portfolios/${id}`);
    }
}

export default Clarity;
