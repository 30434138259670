import { useEffect, useMemo, useState } from 'react';
import { getTimestamp } from 'utils/datetime';
import { useTranslation } from 'react-i18next';


export const useGoalReach = (options = {}) => {
    const {
        goalState,
        projectionResponse,
    } = options;

    const [isGoalReach, setIsGoalReach] = useState(true);
    const { t } = useTranslation();

    const dataExternal = useMemo(() => {
        if (goalState?.targetDate
            && goalState?.targetValue
            && projectionResponse) {
            const values = projectionResponse[projectionResponse.length - 1].Values;
            const projectionDate = values?.[values.length - 1]?.Date;

            const date = getTimestamp(
                goalState.targetDate,
            ) < getTimestamp(projectionDate)
                ? getTimestamp(goalState.targetDate)
                : getTimestamp(projectionDate);

            return (
                [{
                    name: t('onBoarding.goal.yourGoal'),
                    data: [[date, Number(goalState.targetValue)]],
                    type: 'scatter',
                }]
            );
        }


        return [];
    }, [
        goalState?.targetValue,
        goalState?.targetDate,
        projectionResponse,
    ]);

    useEffect(() => {
        if (goalState?.targetValue && projectionResponse) {
            const values = projectionResponse?.[projectionResponse.length - 1]?.Values;
            const value = values?.[values?.length - 1]?.Value;

            if (1.01 * value > goalState?.targetValue) {
                setIsGoalReach(true);
            } else {
                setIsGoalReach(false);
            }
        }
    }, [projectionResponse, goalState?.targetValue]);

    return {
        dataExternal,
        isGoalReach,
    };
};
