/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Infobox } from 'ui-library';
import { DEVICE_NOT_ENROLLED } from 'static/requestResults';
import UserAndPassword from 'components/Form/UserAndPassword';
import Link from 'ui-library/dist/components/Link';
import EnrollDeviceByUserAndPassword from '../EnrollDeviceByUserAndPassword/EnrollDeviceByUserAndPasswordContainer';

export const FORM_NAME = 'login';
export const LOGIN_FORM_VIEW = 'LOGIN_FORM_VIEW';
export const ENROLL_DEVICE_VIEW = 'ENROLL_DEVICE_VIEW';
const formTitle = 'login.LOGIN';

const renderForgotLink = (t) => (
    <Link to="/forgot-password" type="secondary">
        {t('login.FORGOT_PASSWORD')}
    </Link>
);

class LoginForm extends Component {
    constructor() {
        super();

        this.state = {
            view: LOGIN_FORM_VIEW,
        };
    }

    enrollDevice = () => {
        this.setState({
            view: ENROLL_DEVICE_VIEW,
        });

        return false;
    };

    login = () => {
        const { reset } = this.props;

        reset();
        this.setState({
            view: LOGIN_FORM_VIEW,
        });
    };

    render() {
        const {
            t, error, submitting, handleSubmit, onSubmit,
        } = this.props;
        const { view } = this.state;

        let errorContent = null;

        if (error !== undefined && error !== null) {
            if (typeof error === 'string') {
                errorContent = (
                    <div className="validation-summary-errors">
                        <Infobox accent error>
                            {t(error)}
                        </Infobox>
                    </div>
                );
            } else if (typeof error === 'object') {
                switch (error.type) {
                    case DEVICE_NOT_ENROLLED:
                        errorContent = (
                            <div className="validation-summary-errors">
                                <Infobox error>
                                    {`${t('login.2FARequired')} `}
                                    <a
                                        href="#"
                                        className="enrollDevice"
                                        rel="noopener noreferrer"
                                        onClick={this.enrollDevice}
                                    >
                                        {`${t('login.EnrollDevice')}`}
                                    </a>
                                    .
                                </Infobox>
                            </div>
                        );
                        break;
                    default:
                }
            }
        }

        if (view === LOGIN_FORM_VIEW) {
            return (
                <UserAndPassword
                    title={formTitle}
                    bottomLinks={renderForgotLink(t)}
                    error={errorContent}
                    submitting={submitting}
                    submitLabel={formTitle}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                />
            );
        }
        if (view === ENROLL_DEVICE_VIEW) {
            return <EnrollDeviceByUserAndPassword login={this.login} />;
        }

        return null;
    }
}

LoginForm.propTypes = {
    error:
        PropTypes.string
        || PropTypes.shape({
            message: PropTypes.string,
            type: PropTypes.string,
        }),
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
    error: undefined,
};

LoginForm.displayName = 'LoginForm';

export default LoginForm;
