import ErrorsManager from './ErrorsManager';

export const API_ERROR = 'API ERROR';
export const ADAPTOR_ERROR = 'ADAPTOR ERROR';
export const BUSINESS_ERROR = 'BUSINESS ERROR';
export const COMPONENT_ERROR = 'COMPONENT ERROR';
export const UNRECOGNIZED_ERROR = 'UNRECOGNIZED ERROR';

const possibleTypes = [
    API_ERROR,
    ADAPTOR_ERROR,
    BUSINESS_ERROR,
    COMPONENT_ERROR,
];

class ErrorWithType extends Error {
    constructor({ type, error }) {
        super(error);
        Error.captureStackTrace(this, ErrorWithType);

        return ErrorsManager.createError({
            type: possibleTypes.includes(type) ? type : UNRECOGNIZED_ERROR,
            error,
        });
    }
}

export { ErrorWithType };
