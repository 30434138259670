import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import { getClassNames } from 'utils';
import { useCustomPanel } from '../../hooks/useCustomPanel';
import './LeftNavigation.css';

const CUSTOM_CLASS = 'custom-form';
const CUSTOM_PANEL_BASE_CLASS = 'custom-component-';

const LeftNavigation = (props) => {
    const {
        schema, onChange,
    } = props;
    const [active, setActive] = useState();
    const [activeSubLabel, setActiveSubLabel] = useState();

    const customContent = useCustomPanel(schema.custom);

    const content = [...customContent];

    // Callbacks
    const changeActive = (key) => {
        setActive(key);
        if (typeof onChange === 'function') onChange(key);
    };
    const onMenuItemClick = ({ key }) => {
        const block = (document).querySelector(`.${CUSTOM_PANEL_BASE_CLASS}${key}`);

        if (block) {
            block.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setActiveSubLabel(key);
        }
    };
    const setActiveBlock = ({ key }) => {
        const customBlocks = (document)?.querySelectorAll(`.${CUSTOM_CLASS} > .${CUSTOM_PANEL_BASE_CLASS}panel`);

        customBlocks.forEach((customBlock) => {
            if (customBlock.classList.contains(`${CUSTOM_PANEL_BASE_CLASS}${key}`)) customBlock.classList.add('active');
            else customBlock.classList.remove('active');
        });
    };
    const onGroupTitleClick = ({ key, subLabels, custom }, disableScroll = false) => {
        if (!subLabels?.length) onMenuItemClick({ key, custom });
        changeActive(key);

        setActiveBlock({ key }, disableScroll);
        setActiveSubLabel(null);
    };
    const renderSubLabels = (subLabels, key) => {
        if (!subLabels?.length) return null;

        return (
            <div className={getClassNames('sub-block', { 'sub-block__opened': active === key })}>
                {subLabels.map((subLabel) => (
                    <Button
                        key={subLabel.key}
                        type="link"
                        className={getClassNames('sub-label', { active: activeSubLabel === subLabel.key })}
                        onClick={() => onMenuItemClick(subLabel)}
                    >
                        {subLabel.title}
                    </Button>
                ))}
            </div>
        );
    };

    // Effects;
    useEffect(() => {
        if (!active && content?.[0]) {
            setTimeout(() => {
                onGroupTitleClick(content[0], true);
            }, 500);
        }
    }, [content, active]);

    if (!schema) return null;

    return (
        <div className="client__navigation">
            {content.map((item) => (
                <Fragment key={item.key}>
                    <Button
                        type="link"
                        className={getClassNames({ active: active === item.key })}
                        onClick={() => onGroupTitleClick(item)}
                    >
                        {item.title}
                    </Button>
                    {renderSubLabels(item.subLabels, item.key)}
                </Fragment>
            ))}
        </div>
    );
};

LeftNavigation.propTypes = {
    schema: PropTypes.shape({
        custom: PropTypes.shape({
            [PropTypes.string]: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.shape,
            ]),
        }),
    }),
    onChange: PropTypes.func,
};

LeftNavigation.defaultProps = {
    schema: null,
    onChange: null,
};

export default LeftNavigation;
