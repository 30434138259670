export const defaultMinMaxForEmptyData = {
    min: 55806,
    max: 85131,
};

export const projectionOptional = (t, yTitle, yAxisFormat, tooltipFormat) => ({
    yTitle,
    yAxisFormat,
    tooltipFormat,
    topLabel: t('confirmation.veryStrongStock'),
    bottomLabel: t('confirmation.veryWeakStock'),
    middleLabel: t('confirmation.expectedValue'),
    middleTopLabel: t('confirmation.strongStock'),
    middleBottomLabel: t('confirmation.weakStock'),
});
