import {
    ActionCellWithIconAndText,
    CellInputText,
    CellWithPlusMinusIcons,
    CellWithNewIconAndText,
} from 'components/renderers';
import { sorterString } from 'utils/sorting';

export const getModifyColumns = (t, numberSeparators) => ([
    {
        key: 'nameNew',
        title: t('portfolios.columns.name'),
        sortable: true,
        render: CellWithNewIconAndText,
        sorter: sorterString('name'),
        width: 300,
        minWidth: 300,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valuation',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        width: 150,
        minWidth: 150,
    },
    {
        key: 'allocation',
        title: t('Allocation.columns.allocation'),
        className: 'text-right',
        width: 180,
        minWidth: 180,
    },
    {
        key: 'new',
        title: t('portfolios.quickEditColumns.new'),
        className: 'with-input text-right modify-goal__cell-new',
        render: CellInputText(numberSeparators),
        width: 170,
        minWidth: 170,
    },
    {
        key: 'icon',
        render: CellWithPlusMinusIcons,
        width: 80,
        minWidth: 80,
        title: '',
        className: 'modify-goal__cell-with-icons',
    },
    {
        key: 'actions',
        title: t('portfolios.columns.actions'),
        render: ActionCellWithIconAndText,
        className: 'text-right',
        width: 90,
        minWidth: 90,
    },
]);
