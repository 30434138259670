import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import ProjectionTooltip from 'components/ProjectionTooltip';
import ServiceManager from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { formatNumberRounding } from 'utils/formatting';
import { projectionOptional } from 'constants/goalCreation';
import { adaptProjectionData, filteredByEndDate, findMinAndMax } from 'adaptors/adaptProjection';
import { useFormatting } from 'locale';

const INACTIVITY_SECONDS = 0.4;
let timeoutId;

export const useProjection = (options) => {
    const {
        portfolio,
        strategy,
        currency,
        endDate,
        additionalOptions: {
            calculateMinMax,
            withDelay = false,
        } = {},
    } = (options || {});
    const { i18n: { language }, t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const [isLoadingProjection, setLoading] = useState(false);
    const [errorProjection, setError] = useState(null);
    const [projectionData, setData] = useState([]);
    const [projectionResponse, setProjectionResponse] = useState(null);

    // Optional
    const yAxisFormat = useCallback((num) => formatNumberRounding(num, getFormattedNumber),
        [getFormattedNumber]);
    const tooltipFormat = useCallback(({ point, series }) => ReactDOMServer
        .renderToString(ProjectionTooltip({
            point, series, currency, t, getFormattedCurrency,
        })), [currency, getFormattedCurrency, t]);
    const optional = useMemo(() => ({
        ...projectionOptional(t, currency, yAxisFormat, tooltipFormat),
        ...(calculateMinMax || strategy ? findMinAndMax(projectionData) : {}),
    }), [currency, t, yAxisFormat, projectionData, calculateMinMax, strategy]);

    // Callbacks
    const calculatePerformance = useCallback(async (optionsPerformance) => {
        const {
            InitialInvestmentAmount, CurrencyId, InstrumentAllocations,
        } = optionsPerformance;

        try {
            if (InitialInvestmentAmount && CurrencyId
                && InstrumentAllocations) {
                const response = await ServiceManager.performance('calculateProjectionPerformance', [optionsPerformance, { language }]);

                setProjectionResponse(response.data);

                return adaptProjectionData(filteredByEndDate(response?.data, endDate));
            }
        } catch (err) {
            handlerRequestCanceling(() => {
                HandlerError({ setError, setLoading: () => {} });
            })(err);
        }

        return null;
    }, [language, endDate]);

    // Effects
    useEffect(() => {
        if (!portfolio) return;
        (async () => {
            setLoading(true);

            if (withDelay) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(async () => {
                    const data = await calculatePerformance(portfolio);

                    setData([data, data]);
                    setLoading(false);
                }, INACTIVITY_SECONDS * 1000);
            } else {
                const data = await calculatePerformance(portfolio);

                setData([data, data]);
                setLoading(false);
            }
        })();
    }, [calculatePerformance, withDelay, portfolio]);
    useEffect(() => {
        if (!strategy) return;
        (async () => {
            setLoading(true);

            const data = await calculatePerformance(strategy);

            setData((oldData) => [oldData?.[0], data]);
            setLoading(false);
        })();
    }, [calculatePerformance, strategy]);

    return {
        projection: {
            charts: projectionData,
            optional,
        },
        errorProjection,
        isLoadingProjection,
        projectionResponse,
    };
};
