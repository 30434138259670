import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBar as SearchBarUI } from 'ui-library';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';

const SearchBar = ({ onSelect, names, placeholder }) => {
    const [data, setData] = useState([]);

    const { isMobileSize } = useDetectedMobileDevice();

    const onSearchSelect = (values) => {
        onSelect(values);
        setData([]);
    };

    const onSearch = (value) => {
        setData(value.length < 3 ? [] : names);
    };

    return (
        <SearchBarUI
            placeholder={placeholder}
            data={data}
            options={data}
            onSelect={onSearchSelect}
            onSearch={onSearch}
            className="search-wrapper"
            isMobile={isMobileSize}
        />
    );
};

SearchBar.propTypes = {
    onSelect: PropTypes.func.isRequired,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string.isRequired,
};

SearchBar.defaultProps = {};

export default SearchBar;
