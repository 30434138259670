import React from 'react';
import PropTypes from 'prop-types';
import News from 'components/News';
import { useTranslation } from 'react-i18next';
import { useResearch } from '../../hooks/useResearch';
import { useLoadMoreNews } from '../../hooks/useLoadMoreNews';

function ResearchPage() {
    const { t } = useTranslation();
    const pageSize = 9;
    const {
        list: researchList, errorList, isLoadingList, getResearches,
    } = useResearch({
        listSize: pageSize, pageNumber: 1, loadListInitially: true,
    });
    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: researchList,
        pageSize,
        getNewsData: getResearches,
        skipLoading: true,
    });

    return (
        <News
            title={t('research.title')}
            data={newsList}
            isLoading={isLoadingList}
            error={errorList}
            pageSize={pageSize}
            nextArticles={nextArticles}
            nextArticlesSize={3}
            loadMore
        />
    );
}

ResearchPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
};

ResearchPage.defaultProps = {};

export default ResearchPage;
