import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StoreProvider as UILibraryThemeProvider } from 'ui-library';
import { ConnectedRouter } from 'connected-react-router';
import history from 'services/history';
import './configs/setupAdditivServices';
import './translations/i18n';
import { NotificationProvider } from 'additiv-react-signalr';
import { ApolloClientProvider } from 'services/ApolloClient';
import store from './store';
import App from './App';
import { LocaleProvider } from './locale';
import { UnreadMessagesProvider } from './prodivers/unreadMessages';
import ErrorHandler from './errors';
import SM from './services/ServiceManager';

const errorHandlerLog = (errorModel) => {
    const styles = {
        title: 'color: brown; font-size: 16px; font-weight: bold;',
        type: 'color: white; background-color: brown; font-size: 14px; font-weight: bold; border-radius: 3px;',
        stack: 'color: brown',
    };

    console.error(`%c⚠️ New Error with type: %c ${errorModel.type} \n%c${errorModel.stack}`, styles.title, styles.type, styles.stack);
};

ErrorHandler.configure({
    log: errorHandlerLog,
    DFSLoggerConfig: {
        enabled: true,
        endpointFunc: (data) => SM.middleware('log', [data]),
    },
});

ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
        <ApolloClientProvider>
            <ConnectedRouter history={history}>
                <LocaleProvider>
                    <UnreadMessagesProvider>
                        <NotificationProvider>
                            <UILibraryThemeProvider>
                                <App />
                            </UILibraryThemeProvider>
                        </NotificationProvider>
                    </UnreadMessagesProvider>
                </LocaleProvider>
            </ConnectedRouter>
        </ApolloClientProvider>
    </Provider>,
    document.getElementById('root'),
);
