import { validateStringData } from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import {
    PORTFOLIO_CLOSED_ID,
} from 'constants/portfolioStatuses';

const filterPortfolios = (portfolios) => portfolios.filter(
    (item) => item.PortfolioTypeId === portfolioTypeIds.MAIN,
);

export const adaptReviewPortfolios = ({
    data, getFormattedCurrency, riskName,
}) => {
    if (!data.Portfolios) return [];

    const portfolios = filterPortfolios(data.Portfolios);

    return portfolios.filter((item) => item.PortfolioStatusId !== PORTFOLIO_CLOSED_ID)
        .map((item) => ({
            Name: validateStringData(item.Name),
            Product: item.Product.Name,
            Strategy: {
                currentRiskName: riskName,
                strategyRiskName: item.RiskCategory?.Name,
            },
            Value: getFormattedCurrency(item.CurrentValue, {
                currency: item.Currency.CurrencyCode,
            }),
        }));
};
