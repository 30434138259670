/* global gtag */
import history from 'services/history';

class GoogleAnalytics {
    constructor(tagId, options) {
        this.isLoaded = false;
        this.tagId = tagId;
        this.options = options || {};
    }

    start() {
        if (!this.tagId && process.env.NODE_ENV !== 'development') {
            throw new Error('Google Analytic: tagId should be defined');
        }
        this.initialize();
        this.listen();
    }

    initialize() {
        if (this.isLoaded) return;

        const firstScriptOnPage = document.getElementsByTagName('script')[0];
        const src = `https://www.googletagmanager.com/gtag/js?id=${this.tagId}`;
        const scriptGA = document.createElement('script');

        scriptGA.async = 1;
        scriptGA.src = src;
        firstScriptOnPage.parentNode.insertBefore(scriptGA, firstScriptOnPage);

        /* eslint-disable */
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            dataLayer.push(arguments);
        };
        /* eslint-enable */

        gtag('js', new Date());
        gtag('config', this.tagId, { send_page_view: false });

        this.isLoaded = true;
    }

    listen() {
        const { disableUrls = [] } = this.options;
        const isLocationDisabled = (l) => disableUrls
            .some((url) => l.match(new RegExp(url, 'g'))?.length);

        history.listen((location) => {
            if (isLocationDisabled(location.pathname)) return;
            gtag('event', 'page_view', {
                page_path: location.pathname,
                page_title: location.pathname,
            });
        });
    }
}

export default GoogleAnalytics;
