import React from 'react';
import PropTypes from 'prop-types';
import Details from './Details';

const DetailsContainer = (props) => (
    <Details {...props} />
);

DetailsContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }).isRequired,
};

DetailsContainer.defaultProps = {
};

export default DetailsContainer;
