import React, { useMemo, useState, useEffect } from 'react';
import history from 'services/history';

// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, isUndefined, get } from 'lodash/fp';
import {
    BreadCrumbs,
    Button,
    Column,
    ContentBox,
    Row,
    Select,
    TextInput,
    Textarea,
    Title,
} from 'ui-library';
import ServiceManager from 'services/ServiceManager';
import PageSection from 'components/PageSection/PageSection';
import { validate } from 'components/SendMessage/validation';
import Preloader from 'components/Preloader';
import { contactIdSelector, memberIdSelector } from 'redux/auth/authSelectors';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import MessageService from 'additiv-services/dist/services/core/messages';
import { checkFilesWithoutContent } from './adaptor';
import { formatOriginalMessage } from './utils/utils';

const initState = {
    message: undefined,
    subject: undefined,
    filesUploaded: [],
    contactReason: '1',
    isSending: false,
    errors: {},
    generalErrorMessage: null,
    messageSubTypeMap: [],
};

const errorPostMessage = 'Failed to send message - please contact '
    + 'Customer Support for assistance if the problem persists';
const maliciousdMessage = 'Your subject or message contains malicious data. Please remove it in order to continue.';
const fileWithoutContentMessage = 'The document is empty. Please, select a different file and try again';

function NewMessage(props) {
    // eslint-disable-next-line react/prop-types
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const [messageSubTypeMap, setMessageSubTypeMap] = useState([]);
    const [contactReason, setContactReason] = useState('1');
    // eslint-disable-next-line no-unused-vars
    const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(undefined);
    const [originalMessage, setOriginalMessage] = useState('');
    const [isSending, setSending] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [filesUploaded, setFilesUploaded] = useState([]);

    const subject = useMemo(() => props.location.state?.title, [props.location.state]);

    useEffect(() => {
        ServiceManager.messageService('getMessageTypes', [i18n.language])
            .then((response) => {
                setMessageSubTypeMap(response.data[0].Subtypes);
                let subtypeId = get('location.state.subtypeId', props);

                subtypeId = subtypeId === undefined ? response.data[0].Subtypes[0].Id : subtypeId;
                initState.contactReason = get('data[0].Subtypes[0].Id', response);
                setContactReason(subtypeId);
            })
            .catch(console.log);
    }, [i18n.language]);

    useEffect(() => {
        ServiceManager.messageService('getContactThreads', [
            get('location.state.contactId', props),
            {
                ThreadId: get('location.state.threadId', props),
                TextLength: 0,
                PageNumber: 1,
                PageSize: 100000,
            },
        ])
            .then((response) => {
                setOriginalMessage(
                    formatOriginalMessage(response.data.Data[0].Messages, getFormattedDate),
                );
            })
            .catch(console.log);
    }, []);

    const postMessage = (dtoAttachments) => {
        // eslint-disable-next-line react/prop-types
        const isfileWithoutContent = checkFilesWithoutContent(dtoAttachments);

        if (!isfileWithoutContent) {
            ServiceManager.messageService('replyMessage', [
                {
                    personId: get('location.state.contactId', props),
                    dtoAttachments,
                    message,
                    subject,
                    messageId: get('match.params.messageId', props),
                },
            ])
                .then((response) => {
                    if (response.data.Result === MessageService.MESSAGE_NOT_ALLOWED) {
                        setSending(false);
                        setGeneralErrorMessage(maliciousdMessage);
                    } else if (response.data.Result === MessageService.SUCCESS) {
                        setMessage(initState.message);
                        setContactReason(messageSubTypeMap[0].Id);
                        setSending(initState.isSending);
                        setErrors(initState.errors);
                        setGeneralErrorMessage(initState.generalErrorMessage);
                        history.push('/messages');
                    } else {
                        setSending(false);
                        setGeneralErrorMessage(errorPostMessage);
                    }
                })
                .catch(() => {
                    setSending(false);
                    setGeneralErrorMessage(errorPostMessage);
                });
        } else {
            setSending(false);
            setGeneralErrorMessage(fileWithoutContentMessage);
        }
    };

    const submitMessageHandler = (e) => {
        if (isSending) {
            return;
        }
        e.preventDefault();

        const initAllValues = {
            message,
            subject,
            contactReason,
        };

        Object.keys(initAllValues).forEach((key) => {
            initAllValues[key] = isUndefined(initAllValues[key]) ? null : initAllValues[key];
        });

        const errorsByValidate = validate(initAllValues);

        if (!isEmpty(errorsByValidate)) {
            setErrors(errorsByValidate);

            return;
        }

        setMessage(initState.message);
        setContactReason(initState.contactReason);
        setSending(initState.isSending);
        setErrors(initState.errors);
        setGeneralErrorMessage(initState.generalErrorMessage);

        // eslint-disable-next-line react/prop-types
        const { metadataReferenceId, contactId } = props;

        setSending(true);
        const dtoAttachments = [];

        if (filesUploaded) {
            filesUploaded.forEach((item) => {
                const dto = {
                    OriginalFilename: item.name,
                    RetrieveFileAsName: item.name,
                    File: item.file,
                    CustomAttributes: item.customAttributes,
                    MetadataReferenceId: metadataReferenceId,
                    ExternalId: 'A123',
                    ContractId: 1334,
                    OwnerId: contactId,
                };

                dtoAttachments.push(dto);
            });
        }
        postMessage(dtoAttachments);
    };

    const handleMessageInputChange = (event) => {
        const { value } = event.currentTarget;

        setMessage(value);
        setGeneralErrorMessage(null);
        setErrors(
            validate({
                ...errors,
                message: value,
            }),
        );
    };

    const onCancel = () => {
        history.push('/messages');
    };

    return (
        <PageSection className="reply-message">
            <ContentBox>
                <Row>
                    <Column size={['sm-12']}>
                        <BreadCrumbs current={`${t('messages.reply')}: ${subject}`}>
                            <Link to="/messages">{t('messages.title')}</Link>
                        </BreadCrumbs>
                        <Title type={1} className="mb-0">
                            {`${t('messages.reply')}: ${subject}`}
                        </Title>
                    </Column>
                </Row>
                <hr />
                <Preloader isLoading={false} error={null}>
                    <Row>
                        <Column size={['sm-6']}>
                            <Select
                                disabled
                                value={contactReason}
                                options={messageSubTypeMap.map(({ Id, Name }) => ({
                                    label: Name,
                                    value: Id,
                                }))}
                                label={t('messages.category')}
                                withInfobox={false}
                                required
                            />
                            <br />
                            <TextInput
                                defaultValue={subject}
                                label={t('messages.subject')}
                                disabled
                                required
                            />
                            <br />
                            <Textarea
                                label={t('messages.message')}
                                defaultValue={message}
                                error={errors && errors.message}
                                onChange={handleMessageInputChange}
                                required
                            />
                            <br />
                            <Textarea
                                label={t('messages.originalMessage')}
                                value={originalMessage}
                                readonly
                                disabled
                            />
                        </Column>
                    </Row>
                    <Row className="buttons">
                        <Column size={12} className="text-sm-right button-group">
                            <Button type="secondary" size="small" onClick={onCancel}>
                                {t('messages.cancel')}
                            </Button>
                            <Button type="primary" size="small" onClick={submitMessageHandler}>
                                {t('messages.send')}
                            </Button>
                        </Column>
                    </Row>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

NewMessage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    // data: PropTypes.any.isRequired,
};

NewMessage.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
    memberId: memberIdSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
