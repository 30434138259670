import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RiskProfileProvider } from 'domain/RiskProfile';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import RiskProfileReview from './pages/RiskProfileReview';
import RiskProfileQuestionnaire from './pages/RiskProfileQuestionnaire';

const Router = (props) => {
    const { match: { path }, clientId } = props;
    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} />
    );

    // OnBoarding Domain
    const { product } = useOnBoardingSelector(onBoardingDataSelector);

    return (
        <RiskProfileProvider
            options={{
                clientId,
                questionnaire: {
                    productId: +product?.id,
                },
                riskCategories: {
                    productId: +product?.id,
                },
            }}
        >
            <Switch>
                <Route path={`${path}/review`} render={withProps(RiskProfileReview)} />
                <Route path={`${path}/:group`} render={withProps(RiskProfileQuestionnaire)} />
                <Redirect to={`${path}/0`} />
            </Switch>
        </RiskProfileProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
    clientId: PropTypes.number.isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
