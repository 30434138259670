/* eslint-disable */
import {useCallback, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { adaptModelData, groupEditedPositions } from '../adapters/adaptQuickEdit';
import {QUICK_EDIT} from "../constants";

export const useQuickEdit = (options = {}) => {
    const {
        portfolio= {}, portfolioPositions, positionsEdited, baseUrl,
    } = options;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const positions = useMemo(() => {
        if (!portfolio.currency || !portfolio.portfolioValue
            || !portfolioPositions?.length || !positionsEdited?.length) {
            return [];
        }

        return groupEditedPositions({
            portfolioValue: portfolio?.portfolioValue,
            portfolioCurrency: portfolio?.currency,
            positions: portfolioPositions,
            positionsEdited,
            baseUrl,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        });
    }, [
        portfolio.currency,
        portfolio.portfolioValue,
        portfolioPositions,
        positionsEdited,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    ]);
    const modelData = useMemo(() => {
        if (!portfolio || !portfolioPositions?.length || !positionsEdited?.length) {
            return null;
        }

        return adaptModelData({
            data: portfolio,
            positions: portfolioPositions,
            positionsEdited,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        });
    }, [
        portfolio,
        portfolioPositions,
        positionsEdited,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    const clearQuickEditLocally = useCallback(() => sessionStorage.removeItem(QUICK_EDIT), []);

    return {
        positions,
        modelData,
        clearQuickEditLocally,
    };
};
