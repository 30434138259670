import React, { useMemo } from 'react';
import { Icon } from 'ui-library';
import { ReactComponent as ClosedEyeIcon } from '../../../../assets/images/eye-closed.svg';
import { ReactComponent as OpenedEyeIcon } from '../../../../assets/images/eye-open.svg';
import { getPrimaryDefault } from '../../../../constants/colors';
import './UnmaskPasswordButton.css';
import { UnmaskPasswordButtonProps } from './UnmaskPasswordButton.types';

const UnmaskPasswordButton: React.FC<UnmaskPasswordButtonProps> = ({
    onClick,
    isClosedEyeIcon,
    iconSize = 18,
}) => {
    const MaskIcon = useMemo(
        () => (isClosedEyeIcon ? ClosedEyeIcon : OpenedEyeIcon),
        [isClosedEyeIcon],
    );

    return (
        <button
            type="button"
            onClick={onClick}
            className="unmask-password-button"
        >
            <Icon
                component={MaskIcon}
                size={iconSize}
                color={getPrimaryDefault()}
            />
        </button>
    );
};

export default React.memo(UnmaskPasswordButton);
