import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import WidgetError from 'components/WidgetError';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import history from 'services/history';
import {
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useQuestionnaire } from 'domain/RiskProfile';
import { adaptAnswers } from 'adaptors/adaptAnswers';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './Questionnaire.css';

const ANSWERS_DELAY = 1000;

const Questionnaire = (props) => {
    const {
        match: { params: { group: groupStr } },
        location: { state },
        onNext,
        onPrev, changeStep, clientId,
    } = props;
    const [answers, setAnswers] = useState({});
    const group = +groupStr;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t } = useTranslation();

    // Hooks
    const { product, saveKnowledgeAndExperience } = useOnBoardingSelector(onBoardingDataSelector);

    const {
        data, isLoading, error, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, errorPost, postQuestionnaire,
    } = useQuestionnaire({ clientId, questionnaireTypeId: 2, productId: product?.id });

    const onPrevByProduct = useMemo(() => generateObject({
        [ADVISORY]: onPrev,
        [DISCRETIONARY]: onPrev,
        [EXECUTION_ONLY]: () => changeStep(1, 'product'),
        [DEPOSITS]: onPrev,
    }).getByName(product?.name), [onPrev, changeStep, product?.name]);

    const onlyOneStep = useMemo(() => generateObject({
        [ADVISORY]: false,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: false,
    }).getByName(product?.name), [product?.name]);

    const isNextResult = useMemo(() => state?.redoStep
        || data?.Groups?.length === group + 1, [data, group, state]);

    const errorMessage = useMemo(() => generateObject({
        [ADVISORY]: t('validation.mandatoryField'),
        [DISCRETIONARY]: t('onBoarding.discretionaryProductDisclaimer.error'),
        [EXECUTION_ONLY]: t('validation.mandatoryField'),
        [DEPOSITS]: t('validation.mandatoryField'),
    }).getByName(product?.name), [product?.name]);

    // Data
    const groupId = useMemo(() => data?.Groups?.[group]?.Id,
        [data, group]);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(() => validateQuestionnaire({ groupId, answers, errorMessage }),
        [answers, groupId, validateQuestionnaire]);

    // Callbacks
    const onChange = useCallback(({ questionId, value }) => {
        const newData = { ...answers };

        if (questionId) {
            if (value) {
                newData[groupId][questionId] = value;
            } else {
                delete newData[groupId][questionId];
            }
        } else {
            newData[groupId] = value;
        }
        setAnswers(newData);
    }, [groupId, setAnswers, answers]);

    const onPrevClick = async () => {
        if (group === 0) {
            onPrevByProduct();
        } else {
            history.push(`/onboarding/step/3/${group - 1}`);
        }
    };

    const onNextClick = async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            const adaptedAnswers = adaptAnswers(data, answers, true);

            await postQuestionnaire({ answers: adaptedAnswers });
            setTimeout(() => {
                setIsSubmitted(false);
                if (onlyOneStep) {
                    saveKnowledgeAndExperience({ hasPositiveInstruments: true });
                    onNext();
                } else {
                    history.push(`/onboarding/step/3/${isNextResult ? 'results' : group + 1}`);
                }
            }, ANSWERS_DELAY);
        }
    };

    // Effects
    useEffect(() => {
        if (groupId) global.scrollTo({ top: 0, behavior: 'smooth' });
    }, [groupId]);
    useEffect(() => {
        getQuestionnaire();
    }, [getQuestionnaire]);
    useEffect(() => {
        if (!Object.values(answers).length && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({ Id }) => Id);
            const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

            setAnswers(newData);
        }
    }, [data, setAnswers, answers]);
    useEffect(() => {
        saveKnowledgeAndExperience({ hasPositiveInstruments: false });
    }, []);

    return (
        <OnBoardingBaseTemplate
            error={error}
            isLoading={isLoading}
            className="knowledge-and-experience"
            prevButton={{
                loading: isLoadingPost,
                onClick: onPrevClick,
            }}
            nextButton={{
                loading: isLoadingPost,
                onClick: onNextClick,
            }}
        >
            <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                <QuestionnaireGroup
                    data={data?.Groups?.[group]}
                    title={data?.Name}
                    value={answers?.[groupId] || {}}
                    errors={isSubmitted && errors}
                    onChange={onChange}
                />
            </EmptyContent>
            {errorPost && (<WidgetError accent error message={errorPost.message} />)}
        </OnBoardingBaseTemplate>
    );
};

Questionnaire.propTypes = {
    onPrev: PropTypes.func,
    changeStep: PropTypes.func,
    clientId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            group: PropTypes.string,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
        }),
    }).isRequired,
};

Questionnaire.defaultProps = {
    onPrev: () => { },
    changeStep: () => { },
};

export default Questionnaire;
