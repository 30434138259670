import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { adaptProposalDetails } from '../adapters/adaptProposalDetails';
import { useModelPortfolio } from './useModelPortfolio';

const initialState = {
    data: {
        positions: [],
    },
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useProposalDetails = (options = {}) => {
    const {
        clientId,
        portfolioId,
        proposalId: proposalIdOption,
        adaptData = adaptProposalDetails,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);
    // External hook
    const { getModelPortfolio } = useModelPortfolio({ clientId, portfolioId });

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getProposalDetails = useCallback(async (
        proposalId = proposalIdOption, { adaptOptions } = {},
    ) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        const params = { language };

        try {
            const response = await SM.portfolioManagement('getPortfolioProposal', [clientId, proposalId, params]);
            const { ModelPortfolioId, CalculatedRisk, ProductId } = (response?.data || {});
            let RiskCategory = null;
            let OptimizationConstraints = {};

            if (ModelPortfolioId && typeof getModelPortfolio === 'function') {
                const modelData = await getModelPortfolio(ModelPortfolioId);

                RiskCategory = modelData?.RiskCategory;
                OptimizationConstraints = modelData?.OptimizationConstraints;
            } else {
                const riskCategories = await SM.commonService('getRiskCategoriesByProduct', [ProductId, language]);

                RiskCategory = riskCategories.data?.find(
                    ({ Min, Max }) => Min <= CalculatedRisk && CalculatedRisk <= Max,
                );
                OptimizationConstraints = RiskCategory?.OptimizationConstraints;
            }

            try {
                const adapted = adaptData({
                    OptimizationConstraints,
                    ...response.data,
                    RiskCategory,
                }, {
                    clientId,
                    t,
                    getFormattedDate,
                    getFormattedNumber,
                    getFormattedCurrency,
                    ...adaptOptions,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: response.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        proposalIdOption,
        language,
        portfolioId,
        getModelPortfolio,
        adaptData,
        clientId,
        t,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getProposalDetails();
    }, [getProposalDetails, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getProposalDetails,
    };
};
