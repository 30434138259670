import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiskProfileProvider } from 'domain/RiskProfile';
import { contactIdSelector } from 'redux/auth/authSelectors';
import withRiskProfileAccess from 'hocs/withRiskProfileAccess';
import RiskProfileQuestionnaire from './pages/RiskProfileQuestionnaire';
import RiskProfileReview from './pages/RiskProfileReview';

const Router = ({ match: { path } }) => {
    const clientId = useSelector(contactIdSelector);

    return (
        <RiskProfileProvider options={{ clientId }}>
            <Switch>
                <Route path={`${path}/review`} component={RiskProfileReview} />
                <Route path={`${path}/:group`} component={RiskProfileQuestionnaire} />
                <Redirect to={`${path}/0`} />
            </Switch>
        </RiskProfileProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
};

Router.defaultProps = {};

export default withRiskProfileAccess(Router);
