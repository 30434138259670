import { getParent, roundAllocations, sum } from 'utils';
import { isLiquidity } from 'utils/portfolio';
import {
    formatBigNumber, percentIsZero, validateData, validateNumber,
} from 'utils/formatting';
import { sortAssets } from 'utils/sortingAllocation';
import { adaptProjection } from 'adaptors/adaptAllocations';
import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';
import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';
import { redirectToReadOnlyPositionDetails } from '../../common/utils';

export const groupEditedPositions = ({
    portfolioValue, portfolioCurrency, positions = [], positionsEdited, baseUrl,
    t, getFormattedNumber, getFormattedCurrency,
}) => {
    const roundedAllocation = positions?.length ? roundAllocations(positions) : [];
    const roundedNewAllocation = positionsEdited?.length
        ? roundAllocations(positionsEdited, 1000, 1000, 100000) : [];
    const groupToValues = positions.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAllocData = roundedAllocation.find((n) => n.id === securityData.Id);
        const roundedNewAllocData = roundedNewAllocation.find((n) => n.id === securityData.Id);
        const roundedAlloc = roundedAllocData ? roundedAllocData.value : 0;
        const roundedNewAlloc = roundedNewAllocData ? roundedNewAllocData.value : 0;
        const currentValueData = item?.InvestmentValuePortfolioCurrency || (roundedAlloc
            ? (roundedAlloc * portfolioValue) / 100
            : 0);
        const newValueChange = roundedAlloc === roundedNewAlloc
            ? item?.InvestmentValuePortfolioCurrency : undefined;
        const newValueData = newValueChange || (roundedNewAlloc
            ? (roundedNewAlloc * portfolioValue) / 100
            : 0);

        const accParent = getParent(securityData.AssetClass).Name;
        const baseUrlLink = baseUrl && `${baseUrl}/${securityData.Id}/2`;
        const link = isLiquidity(securityData) ? undefined : baseUrlLink;
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push({
            id: securityData.Id,
            name: {
                label: name,
                link,
                maxLength: 28,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    currentValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    newValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            allocationD: {
                currentValue: `${getFormattedNumber(roundedAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(roundedNewAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            allocationData: roundedAlloc,
            allocationNewData: roundedNewAlloc,
            allocation: `${percentIsZero(roundedAlloc)}%`,
            currency,
            units: getFormattedNumber(
                validateNumber(item.Quantity),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            performance: getFormattedNumber(
                validateNumber(item.Performance),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            latestPrice: getFormattedNumber(
                validateNumber(item.ValuationPrice),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuation: getFormattedCurrency(
                validateNumber(item.InvestmentValuePortfolioCurrency),
                {
                    currency: portfolioCurrency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            currentValueData,
            newValueData,
            fxRate: getFormattedNumber(validateNumber(item.FxRate), {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            valuationData: getFormattedNumber(
                validateNumber(item.InvestmentValuePortfolioCurrency),
            ),
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance)),
            actions: isLiquidity(securityData)
                ? {}
                : {
                    value: 'action',
                    actions: [
                        {
                            text: t('clientDashboard.portfolio.showMoreDetails'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 1,
                            ),
                        },
                        {
                            text: t('clientDashboard.portfolio.showPerformance'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 2,
                            ),
                        },
                    ],
                },
        });

        return accumulator;
    }, {});
    const parentAssetClasses = positions.map((item) => getParent(item.Security.AssetClass));

    return sortAssets(Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];
        const id = parentAssetClasses.find((i) => i.Name === key).Id;

        return {
            id,
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), { currency: portfolioCurrency }),
            allocation: `${percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData')))}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            allocationNewData: sum(groupMembersData, 'allocationNewData'),
            allocationD: {
                currentValue: `${getFormattedNumber(sum(groupMembersData, 'allocationData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(sum(groupMembersData, 'allocationNewData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    sum(groupMembersData, 'currentValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    sum(groupMembersData, 'newValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    }));
};

export const adaptModelData = ({
    data, positions, positionsEdited, t, getFormattedNumber, getFormattedCurrency,
}) => {
    const positionsEditedObj = positionsEdited ? positionsEdited.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.Allocation,
    }), {}) : {};
    const positionsEditedFull = positions.map((item) => ({
        ...item,
        Allocation: (positionsEditedObj[item.Security.Id] || 0) / 100,
        ValuationPrice: data?.portfolioValue
            * (positionsEditedObj[item.Security.Id] || 0) / 100,
        InvestmentValuePortfolioCurrency: data?.portfolioValue
            * (positionsEditedObj[item.Security.Id] || 0) / 100,
    }));

    return ({
        ...data,
        investmentAllocation: {
            chart: adaptAllocationGroups({
                securitiesValue: data?.portfolioValue,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
                positions: positionsEditedFull,
                currency: data?.currency,
                t,
                getFormattedNumber,
                getFormattedCurrency,
            }),
            currency: data?.currency,
        },
        investmentAllocations: positionsEdited.map((item) => ({
            Allocation: Number((item.Allocation / 100).toFixed(4)),
            InstrumentId: item.Id,
        })).filter(({ Allocation }) => Allocation !== 0),
        projection: adaptProjection({
            data: positionsEdited.map((item) => ({
                Allocation: Number((item.Allocation / 100).toFixed(4)),
                Id: item.Id,
            })).filter(({ Allocation }) => Allocation !== 0),
            CurrencyId: data?.currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: data?.portfolioValue,
        }),
    });
};
