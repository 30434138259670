import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import './EmptyContent.css';

const EmptyContent = ({
    data, text, buttonText, onClick, children,
}) => {
    if (data === null || (data && data.length === 0)) {
        return [
            text && <p className="widget__empty" key={1}>{text}</p>,
            buttonText && (
                <div className="widget__button" key={2}>
                    <Button type="secondary" size="small" onClick={onClick}>
                        {buttonText}
                    </Button>
                </div>
            ),
        ];
    }

    return children;
};

EmptyContent.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    text: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
};

EmptyContent.defaultProps = {
    data: [],
    text: null,
    onClick: () => {},
    buttonText: null,
};

export default EmptyContent;
