import React from 'react';
import PropTypes from 'prop-types';
import { renderers } from 'ui-library';

const { CellLinked, CellTooltiped } = renderers;

function CellSwitch(props) {
    if (props.label === undefined) {
        // eslint-disable-next-line react/destructuring-assignment
        return <CellTooltiped value={props?.value || props} maxLength={props?.maxLength || 15} />;
    }

    return (
        CellLinked({
            // eslint-disable-next-line react/destructuring-assignment
            value: props.label,
            // eslint-disable-next-line react/destructuring-assignment
            link: props.link,
            // eslint-disable-next-line react/destructuring-assignment
            maxLength: props.maxLength,
        })
    );
}

CellSwitch.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string,
    maxLength: PropTypes.number,
};

CellSwitch.defaultProps = {
    value: undefined,
    label: undefined,
    link: '',
    maxLength: undefined,
};

export default CellSwitch;
