import React, { useEffect } from 'react';
import { PageVariant } from 'ui-library';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import PageVariantWrapper from 'components/PageVariantWrapper';
import { useInstrumentAccess } from 'domain/Instrument';

const withInstrumentAccess = (Component) => (props) => {
    const { match: { params: { positionId } } } = props;
    const { t } = useTranslation();
    const {
        data, isLoading, error, getInstrumentAccess,
    } = useInstrumentAccess({ instrumentId: positionId });

    useEffect(() => {
        getInstrumentAccess();
    }, [getInstrumentAccess]);

    if (!data.isActive || !data.isInvestable) {
        return (
            <Preloader isLoading={isLoading} error={error}>
                <PageVariantWrapper>
                    <PageVariant
                        type="error"
                        title={t('error.title')}
                        message={t('common.PAGE_NOT_FOUND')}
                        actionButtonLabel={t('confirmation.back')}
                        onActionButtonClick={history.goBack}
                    />
                </PageVariantWrapper>
            </Preloader>
        );
    }

    return <Component {...props} />;
};

export default withInstrumentAccess;
