import React, { useState } from 'react';
import {
    Column, TabPane, Tabs, TabsExtraItem, Title, Row, AccordionPanel as Panel, Accordion,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import PropTypes from 'prop-types';
import { useFormatting } from 'locale';
import { allocationSAPercentFormat } from 'utils/formatting';
import { PIE, BAR, LIST } from 'constants/constants';
import ChartType from 'components/ChartType';
import './AllocationCompare.css';

function AllocationCompare({
    data, dataNew, isLoading, error, titles,
}) {
    const { t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const [chartView, setChartView] = useState(PIE);
    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => getFormattedCurrency(number, {
        currency: data.currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Tabs
                className="investment-allocation investment-allocation-compare"
                defaultActiveKey="1"
                tabBarExtraContent={(
                    <>
                        <TabsExtraItem
                            icon="chart-pie"
                            isActive={chartView === PIE}
                            onClick={changeView(PIE)}
                        />
                        <TabsExtraItem
                            icon="chart-bar-horizontal"
                            isActive={chartView === BAR}
                            onClick={changeView(BAR)}
                        />
                        <TabsExtraItem
                            icon="list"
                            isActive={chartView === LIST}
                            onClick={changeView(LIST)}
                        />
                    </>
                )}
            >
                {data.chart.map((item, i) => (
                    <TabPane tab={item.name} key={(i + 1).toString()}>
                        {chartView === LIST ? (
                            <Accordion defaultActiveKey={['1', '2']} className="list-chart">
                                <Panel header={titles[0] || t('confirmation.current')} key="1" className="current">
                                    <EmptyContent data={item.data} text={t('confirmation.noData')}>
                                        <ChartType
                                            data={item.data[chartView].data}
                                            title={item.title || item.name}
                                            labels={item.data[chartView].labels}
                                            currency={data.currency}
                                            chartView={chartView}
                                            labelFormat={labelFormat}
                                            numberFormat={numberFormat}
                                            percentFormat={allocationSAPercentFormat}
                                            barHeight={60}
                                            t={t}
                                        />
                                    </EmptyContent>
                                </Panel>
                                <Panel header={titles[1] || t('confirmation.new')} key="2" className="new">
                                    <EmptyContent
                                        data={dataNew.chart[i].data}
                                        text={t('confirmation.noData')}
                                    >
                                        <ChartType
                                            data={dataNew.chart[i].data[chartView].data}
                                            title={dataNew.chart[i].title || dataNew.chart[i].name}
                                            labels={dataNew.chart[i].data[chartView].labels}
                                            chartView={chartView}
                                            labelFormat={labelFormat}
                                            currency={dataNew.currency}
                                            numberFormat={numberFormat}
                                            percentFormat={allocationSAPercentFormat}
                                            barHeight={60}
                                            t={t}
                                        />
                                    </EmptyContent>
                                </Panel>
                            </Accordion>
                        ) : (
                            <Row className="mb-0">
                                <Column size={['sm-6']}>
                                    <Title type={3} className="mb-3">{titles[0] || t('confirmation.current')}</Title>
                                    <EmptyContent data={item.data} text={t('confirmation.noData')}>
                                        <ChartType
                                            data={item.data[chartView].data}
                                            title={item.title || item.name}
                                            labels={item.data[chartView].labels}
                                            currency={data.currency}
                                            chartView={chartView}
                                            labelFormat={labelFormat}
                                            numberFormat={numberFormat}
                                            percentFormat={allocationSAPercentFormat}
                                            barHeight={60}
                                            t={t}
                                        />
                                    </EmptyContent>
                                </Column>
                                <Column size={['sm-6']}>
                                    <Title type={3} className="mb-3">{titles[1] || t('confirmation.new')}</Title>
                                    <EmptyContent
                                        data={dataNew.chart[i].data}
                                        text={t('confirmation.noData')}
                                    >
                                        <ChartType
                                            data={dataNew.chart[i].data[chartView].data}
                                            title={dataNew.chart[i].title || dataNew.chart[i].name}
                                            labels={dataNew.chart[i].data[chartView].labels}
                                            chartView={chartView}
                                            labelFormat={labelFormat}
                                            currency={dataNew.currency}
                                            numberFormat={numberFormat}
                                            percentFormat={allocationSAPercentFormat}
                                            barHeight={60}
                                            t={t}
                                        />
                                    </EmptyContent>
                                </Column>
                            </Row>
                        )}
                    </TabPane>
                ))}
            </Tabs>
        </Preloader>
    );
}

AllocationCompare.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        }),
                    ),
                }),
            }),
        ),
        currency: PropTypes.string,
    }),
    dataNew: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        }),
                    ),
                }),
            }),
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    titles: PropTypes.arrayOf(PropTypes.string),
};

AllocationCompare.defaultProps = {
    data: { chart: [] },
    dataNew: { chart: [] },
    isLoading: true,
    error: null,
    titles: [],
};

export default AllocationCompare;
