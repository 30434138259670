import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Rebalance from './Rebalance';
import DetailedOrders from './pages/DetailedOrders';
import PositionDetailsOverview from '../SinglePortfolioView/pages/PositionDetails/PositionDetailsOverview';
import withActionAccess from '../../hocs/withActionAccess';

const Router = (props) => {
    const { match: { path } } = props;

    return (
        <Switch>
            <Route exact path={`${path}`} component={Rebalance} />
            <Route exact path={`${path}/orders`} component={DetailedOrders} />
            <Route
                exact
                path={`${path}/position/:productId/:positionId/:tabId`}
                component={PositionDetailsOverview}
            />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.displayName = 'Router';

export default withActionAccess(Router);
