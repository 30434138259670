import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ContentBox, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { getClassNames } from 'utils';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import './OnBoardingBaseTemplate.css';

const OnBoardingBaseTemplate = (props) => {
    const {
        title,
        error,
        isLoading,
        className: classNameProp,
        classNameContent,
        prevButton,
        additionalButton,
        additionalComponent,
        nextButton,
        children,
        topAction,
        buttonsRowError,
        topInfoboxContent,
    } = props;
    const [isLoadingDefault, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const className = getClassNames('on-boarding_inner', classNameProp);
    const contentClassName = getClassNames('stepper-content', classNameContent);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000);
    }, []);

    return (
        <Preloader isLoading={isLoadingDefault || isLoading} error={error}>
            {topInfoboxContent && (
                <div className="top-info-box">
                    {topInfoboxContent}
                </div>
            )}
            <ContentBox className={className}>
                {title && (
                    <header className="on-boarding_header">
                        <Title type={2}>{title}</Title>
                        {topAction}
                    </header>
                )}
                <div className={contentClassName}>
                    {children}
                </div>
                <ButtonsBlockRow
                    leftButton={prevButton && {
                        text: t('onBoarding.back'),
                        ...prevButton,
                    }}
                    additionalButton={additionalButton}
                    additionalComponent={additionalComponent}
                    primaryButton={nextButton && {
                        text: t('onBoarding.next'),
                        ...nextButton,
                    }}
                >
                    {buttonsRowError}
                </ButtonsBlockRow>
            </ContentBox>
        </Preloader>
    );
};

OnBoardingBaseTemplate.propTypes = {
    data: PropTypes.shape({}),
    title: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    classNameContent: PropTypes.string,
    prevButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    additionalButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    nextButton: PropTypes.shape({
        onClick: PropTypes.func,
        text: PropTypes.string,
        type: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    additionalComponent: PropTypes.node,
    children: PropTypes.node,
    topAction: PropTypes.node,
    buttonsRowError: PropTypes.node,
    topInfoboxContent: PropTypes.node,
};

OnBoardingBaseTemplate.defaultProps = {
    data: {},
    title: null,
    error: null,
    isLoading: false,
    className: undefined,
    classNameContent: undefined,
    prevButton: null,
    additionalButton: null,
    nextButton: null,
    additionalComponent: null,
    children: null,
    topAction: null,
    buttonsRowError: null,
    topInfoboxContent: undefined,
};

export default OnBoardingBaseTemplate;
