import React, {
    useState, useMemo, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from 'constants/constants';
import history from 'services/history';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { Infobox, Table } from 'ui-library';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import AddPositionSearchForm from 'components/AddPositionSearchForm';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useCurrencies } from 'hooks/useCurrencies';
import { useSecurityTypes } from 'hooks/useSecurityTypes';
import { useSecuritiesSearch } from 'hooks/useSecuritiesSearch';
import { usePagination } from 'hooks/usePagination';
import { adaptSecurities } from 'adaptors/adaptSecurities';
import { useRatings } from 'hooks/useRatings';
import { getSecuritiesColumns } from './constants';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import './AddPosition.css';

const AddPositionPage = (props) => {
    const { onPageChange, location: { pathname } } = props;
    const { t, i18n: { language } } = useTranslation();
    const [selected, setSelected] = useState();
    const [selectedError, setSelectedError] = useState(false);
    const { page, onPaginationChange } = usePagination();
    const clientId = useSelector(contactIdSelector);

    // OnBoarding Domain
    const { product } = useOnBoardingSelector(onBoardingDataSelector);

    // Data
    const [searchCriteria, setSearchCriteria] = useState({
        SearchTerm: undefined,
        SecurityTypeIds: undefined,
        CurrencyIds: undefined,
        RatingAgencyIds: undefined,
        RatingIds: undefined,
        Page: page,
    });
    const baseUrl = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}`;

    // Helpers hooks
    const {
        register, handleSubmit, setValue, watch,
    } = useForm();
    const { dataCurrency, isLoadingCurrency, errorCurrency } = useCurrencies();
    const {
        dataSecurityTypes, isLoadingSecurityTypes, errorSecurityTypes,
    } = useSecurityTypes({ withLiquidity: true });
    const {
        dataRatingAgencies, dataRatings, getRatings,
        isLoadingRatingAgencies, errorRatingAgencies, isLoadingRatings,
    } = useRatings();
    const searchOptions = useMemo(() => (searchCriteria.SecurityTypeIds?.length ? {
        ...searchCriteria,
        PageSize: PAGE_SIZE,
        isActive: true,
        isInvestable: true,
        contactId: clientId,
    } : null), [searchCriteria, clientId]);
    const {
        dataSecurities, totalSecurities, isLoadingSecurities, errorSecurities,
    } = useSecuritiesSearch({
        adaptSecurities: (d) => adaptSecurities(d, { language, baseUrl }),
        productId: +product?.id,
        params: searchOptions,
    });

    // Callbacks
    const onChange = useCallback((pagination) => {
        onPaginationChange(pagination);

        if (pagination?.currentPage !== searchCriteria.Page && !isLoadingSecurities) {
            setSearchCriteria((arr) => ({ ...arr, Page: pagination?.currentPage }));
        }
    }, [onPaginationChange, searchCriteria.Page, isLoadingSecurities]);
    const handleFilterSubmit = useCallback((data) => {
        onPaginationChange({ currentPage: 1 });
        setSearchCriteria((arr) => ({
            ...arr,
            ...data,
            RatingAgencyIds: data.RatingAgencyIds ? [+data.RatingAgencyIds] : undefined,
            SecurityTypeIds: data.SecurityTypeIds?.length
                ? data.SecurityTypeIds
                : dataSecurityTypes.map(({ value }) => value),
            Page: 1,
        }));
    }, [onPaginationChange, dataSecurityTypes]);
    const handleSelect = ([id]) => {
        setSelected(id);
    };
    const handleNext = () => {
        if (selected) {
            history.push(`${baseUrl}${selected}`);
        } else {
            setSelectedError(true);
        }
    };

    // Effects
    useEffect(() => {
        if (dataSecurityTypes?.length) {
            setSearchCriteria((arr) => ({
                ...arr, SecurityTypeIds: dataSecurityTypes.map(({ value }) => value),
            }));
        }
    }, [dataSecurityTypes]);
    useEffect(() => {
        if (selected) setSelectedError(false);
    }, [selected]);
    useEffect(() => {
        register({ name: 'SearchTerm' });
        register({ name: 'SecurityTypeIds' });
        register({ name: 'CurrencyIds' });
        register({ name: 'RatingAgencyIds' });
        register({ name: 'RatingIds' });
    }, [register]);

    /* Ratings */
    const values = watch();
    const [dataRatingsByAgencyIdsCached, setDataRatingsByAgencyIdsCached] = useState({});
    const ratingAgencyId = values?.RatingAgencyIds;

    useEffect(() => {
        setValue('RatingIds', undefined);
    }, [ratingAgencyId]);

    useEffect(() => {
        if (dataRatings?.length > 0) {
            const obj = dataRatings?.reduce((acc, item) => ({
                ...acc,
                [item?.ratingAgencyId]: dataRatings,
            }), {});

            setDataRatingsByAgencyIdsCached((prev) => ({
                ...prev,
                ...obj,
            }));
        }
    }, [dataRatings]);

    useEffect(() => {
        if (ratingAgencyId && !dataRatingsByAgencyIdsCached[ratingAgencyId]) {
            getRatings(ratingAgencyId);
        }
    }, [ratingAgencyId, getRatings, dataRatingsByAgencyIdsCached]);


    return (
        <OnBoardingBaseTemplate
            title={t('portfolios.addPosition')}
            error={errorCurrency || errorSecurityTypes || errorRatingAgencies}
            isLoading={isLoadingCurrency || isLoadingSecurityTypes || isLoadingRatingAgencies}
            className="goal-review on-boarding_add-position"
            prevButton={{
                onClick: () => onPageChange('modify'),
            }}
            nextButton={{
                onClick: handleNext,
            }}
        >
            <div className="add-position__content">
                <AddPositionSearchForm
                    searchDisabled={isLoadingSecurities}
                    onSearch={handleSubmit(handleFilterSubmit)}
                    onChangeISIN={(e) => setValue('SearchTerm', e?.target?.value)}
                    typeOptions={dataSecurityTypes}
                    onChangeType={(v) => setValue('SecurityTypeIds', v)}
                    currencyOptions={dataCurrency}
                    onChangeCurrency={(v) => setValue('CurrencyIds', v)}
                    ratingAgencyOptions={dataRatingAgencies || []}
                    onChangeRatingAgency={(v) => setValue('RatingAgencyIds', v)}
                    ratingOptions={dataRatingsByAgencyIdsCached[ratingAgencyId] || []}
                    onChangeRating={(v) => setValue('RatingIds', v)}
                    isLoadingRatings={isLoadingRatings}
                    ratingAgencyValue={ratingAgencyId}
                    ratingValue={values?.RatingIds}
                />
                <Preloader isLoading={isLoadingSecurities} error={errorSecurities}>
                    <EmptyContent data={dataSecurities} text={t('addPosition.noSecurities')}>
                        <Table
                            columns={getSecuritiesColumns(t)}
                            data={dataSecurities}
                            onChange={onChange}
                            rowSelection={{
                                type: 'radio',
                                onChange: handleSelect,
                            }}
                            paging={{
                                total: totalSecurities,
                                currentPage: page,
                                pageSize: PAGE_SIZE,
                            }}
                        />
                    </EmptyContent>
                </Preloader>
            </div>
            {selectedError && (
                <Infobox error className="validation-error">
                    <div>{t('portfolios.validation.addPosition')}</div>
                </Infobox>
            )}
        </OnBoardingBaseTemplate>
    );
};

AddPositionPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    onPageChange: PropTypes.func,
};

AddPositionPage.defaultProps = {
    onPageChange: () => { },
};

export default AddPositionPage;
