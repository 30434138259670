import React, { useMemo } from 'react';
import { Badge } from 'ui-library';
import PropTypes from 'prop-types';

const CellStatusBadge = ({ text, color, value }) => {
    const badgeColor = useMemo(() => {
        if (['notImplemented', 'blocked'].includes(value)) {
            return 'default';
        }

        return color;
    }, [value]);

    return <Badge title={text} color={badgeColor} />;
};

CellStatusBadge.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.string,
};

CellStatusBadge.defaultProps = {
    text: null,
    color: null,
    value: null,
};

export default CellStatusBadge;
