import { useCallback, useEffect, useReducer } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useTranslation } from 'react-i18next';
import { getfromDataObject } from 'utils/formatting';
import { adaptAnswersQuestionnaire as adaptAnswers } from 'adaptors/adaptAnswersQuestionnaire';
import { adaptResults } from '../adapters/adaptKnowledgeExperience';

const initialState = {
    data: { Groups: [{ Steps: [{ Questions: [] }] }] },
    rawData: { Groups: [{ Steps: [{ Questions: [] }] }] },
    error: null,
    isLoading: false,
    errorSending: null,
    isSending: false,
    results: { Groups: [{ Steps: [{ Questions: [] }] }] },
    errorResults: null,
    isLoadingResults: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, rawData: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setErrorSending':
            return { ...state, errorSending: action.payload };
        case 'setSending':
            return { ...state, isSending: action.payload };
        case 'setResults':
            return { ...state, results: action.payload };
        case 'setResultsAdapted':
            return { ...state, resultsAdapted: action.payload };
        case 'setErrorResults':
            return { ...state, errorResults: action.payload };
        case 'setIsLoadingResults':
            return { ...state, isLoadingResults: action.payload };
        default:
            return state;
    }
};

export const useKnowledgeExperience = (options = {}) => {
    const { contactId, productId, loadInitially = false } = (options || {});
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n } = useTranslation();

    // Callbacks
    const getQuestionnaire = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        const questionnairesData = {
            Language: i18n.language,
            ProductId: productId,
            QuestionnaireTypeId: 2,
            ContactTypeId: 1,
        };

        try {
            const response = await ServiceManager.compliance('filterQuestionnaires', [questionnairesData]);

            try {
                if (response.data[0]) {
                    const adapted = getfromDataObject(response.data[0], 'Groups[0].Steps[0]', {});

                    dispatch({ type: 'setData', payload: adapted });
                    dispatch({ type: 'setDataRaw', payload: response.data[0] });
                }
                dispatch({ type: 'setIsLoading', payload: false });
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);
        }
    }, [i18n.language]);
    const sendKnowledgeExperience = useCallback(
        (answers, object = undefined, isEmptyGroup) => new Promise((
            resolve, reject,
        ) => {
            dispatch({ type: 'setErrorSending', payload: null });
            dispatch({ type: 'setSending', payload: true });
            (async () => {
                let answersData = null;
                const rawData = object || state.rawData;

                try {
                    answersData = adaptAnswers(rawData, [answers], [], isEmptyGroup);
                } catch (err) {
                    handlerRequestCanceling(
                        () => {
                            dispatch({ type: 'setErrorSending', payload: new AdapterError(err) });
                            dispatch({ type: 'setSending', payload: false });
                        },
                    )(err);
                    reject();
                }

                try {
                    if (answersData) {
                        await ServiceManager.compliance('postAnswers', [contactId, answersData]);
                        dispatch({ type: 'setSending', payload: false });
                        resolve();
                    }
                } catch (err) {
                    handlerRequestCanceling(
                        HandlerError({
                            setError: (val) => dispatch({ type: 'setErrorSending', payload: val }),
                            setLoading: (val) => dispatch({ type: 'setSending', payload: val }),
                        }),
                    )(err);
                    reject();
                }
            })();
        }), [contactId, state.rawData],
    );
    const getQuestionnaireResults = useCallback(async () => {
        dispatch({ type: 'setErrorResults', payload: null });
        dispatch({ type: 'setIsLoadingResults', payload: true });

        const params = {
            Language: i18n.language,
            IsComplete: true,
            ProductId: productId,
            QuestionnaireTypeId: 2,
        };

        try {
            const response = await ServiceManager.compliance('searchQuestionnairesByContact', [contactId, params]);

            try {
                const result = response?.data?.Questionnaire?.Groups?.[0]?.Steps?.[0];

                dispatch({ type: 'setResults', payload: response?.data?.Questionnaire });
                dispatch({ type: 'setResultsAdapted', payload: adaptResults(result) });
                dispatch({ type: 'setIsLoadingResults', payload: false });
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorResults', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingResults', payload: val }),
                }),
            )(err);
        }
    }, [contactId, i18n.language]);

    // Effects
    useEffect(() => {
        if (loadInitially) getQuestionnaire();
    }, [getQuestionnaire, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getQuestionnaire,
        isSending: state.isSending,
        errorSending: state.errorSending,
        sendKnowledgeExperience,
        results: state.results,
        resultsAdapted: state.resultsAdapted,
        errorResults: state.errorResults,
        isLoadingResults: state.isLoadingResults,
        getQuestionnaireResults,
    };
};
