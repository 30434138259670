import React, {
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ContentBox, Title, Row, Column,
} from 'ui-library';
import PageSection from 'components/PageSection';
import LoadingOverlap from 'components/LoadingOverlap';
import { useClientProfile } from 'prodivers/clientProfile';
import LeftNavigation from './components/LeftNavigation';
import DetailsCustomContent from './components/DetailsCustomContent';
import { customSchema } from './constants';

import './Details.css';

const Details = () => {
    const { clientId } = useClientProfile();
    const { t } = useTranslation();
    const [hasActive, setHasActive] = useState(false);

    const schema = {
        custom: customSchema({ t, clientId }),
    };

    return (
        <PageSection>
            <ContentBox className="client-details">
                <Title type={2} className="underlined">{t('details.title')}</Title>
                <Row>
                    <Column size={['sm-3', 'lg-3']}>
                        <LeftNavigation
                            schema={schema}
                            onChange={setHasActive}
                        />
                    </Column>
                    <Column size={['sm-9', 'lg-9']}>
                        <LoadingOverlap isLoading={!hasActive}>
                            <div className="details-custom-content">
                                <DetailsCustomContent
                                    schema={schema.custom}
                                />
                            </div>
                        </LoadingOverlap>
                    </Column>
                </Row>
            </ContentBox>
        </PageSection>
    );
};

Details.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ dfsClientId: PropTypes.string }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({ step: PropTypes.number }),
    }),
};

Details.defaultProps = {
    location: {
        state: {},
    },
};

export default Details;
