export const validate = (values) => {
    const errors = {};

    if (!values.username) {
        errors.username = 'validation.requireUsername';
    }

    if (!values.password) {
        errors.password = 'validation.requirePassword';
    }

    return errors;
};
