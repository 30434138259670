/* eslint-disable import/no-duplicates */
import React, { useState } from 'react';
import placeholder from 'assets/images/news.svg';
import { ReactComponent as PlaceholderSvg } from 'assets/images/news.svg';
import PropTypes from 'prop-types';
import './ImageWithPlaceholder.css';
import { getClassNames } from '../../utils';


const ImageWithPlaceholder = (props) => {
    const {
        src, alt, imagePlaceholder, className,
    } = props;
    const [error, setError] = useState(false);

    const showPlaceholder = !src || error;
    const type = src?.split(';')?.[0]?.split('/')?.[1];
    const classNames = getClassNames(
        className,
        { 'image-svg': type === 'svg+xml' },
        { 'image-with-placeholder': showPlaceholder },
    );

    if (type === 'svg+xml' || showPlaceholder) {
        return (
            <div
                className={classNames}
                style={!showPlaceholder ? { maskImage: `url(${src})`, WebkitMaskImage: `url(${src})` } : {}}
            >
                {showPlaceholder && <PlaceholderSvg />}
            </div>
        );
    }

    return (
        <img
            src={src || placeholder}
            alt={alt}
            className={classNames}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${imagePlaceholder || placeholder}`;
                setError(true);
            }}
        />
    );
};

ImageWithPlaceholder.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    imagePlaceholder: PropTypes.string,
};

ImageWithPlaceholder.defaultProps = {
    src: '',
    className: '',
    alt: '',
    imagePlaceholder: null,
};

export default ImageWithPlaceholder;
