import { renderers } from 'ui-library';
import { CellAdaptedMessageDate } from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';

const { CellWithMessageTitle } = renderers;

// Pagination
export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 8;
export const TOTAL_PAGES = 10;

// List-Preview table
export const LIST_COLUMNS = (t, getFormattedDate, getFormattedTime, onSortingChange) => [
    {
        key: 'Subject',
        title: 'messages.sortColumns.subject',
        sortable: true,
        render: CellWithMessageTitle,
        width: 270,
        sorter: onSortingChange ? onSortingChange('Subject') : sorterString('Subject.title'),
    },
    {
        key: 'Date',
        title: 'messages.sortColumns.date',
        render: CellAdaptedMessageDate(t, getFormattedDate, getFormattedTime),
        sortable: true,
        className: 'table-cell__date text-right',
        sorter: onSortingChange ? onSortingChange('Date') : sorterByDate('Date.date'),
    },
];

export const LIST_SORTING = { key: 'Date', direction: 'desc' };
