/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Title, Infobox } from 'ui-library';
import Passcode from 'components/Passcode';
import { useTranslation } from 'react-i18next';

import './MTan.css';

interface MTanProps {
    passcodeLength?: number
    onSubmit: (value: string) => void
    onChange: (value: string) => void
    error?: string
    onCancel: () => void
    isLoading?: boolean
}

export const MTan: React.FC<MTanProps> = ({
    passcodeLength, onSubmit, onChange, error, onCancel, isLoading,
}) => {
    const [passcode, setPasscode] = useState<string>('');

    const { t } = useTranslation();
    const changeTanHandler = (value: string) => {
        setPasscode(value);
        onChange(value);
    };

    const submit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        onSubmit(passcode);
    };

    return (
        <div className="MTan content-pane">
            <form onSubmit={submit}>
                <Title type={2}>{t('resetPassword.SmsSecurityCode')}</Title>
                {typeof error === 'string' && (
                    <div className="validation-summary-errors">
                        <Infobox error>{t(error)}</Infobox>
                    </div>
                )}
                <p className="info-text">{t('resetPassword.enterTheSmsCode')}</p>
                <Passcode onChange={changeTanHandler} passcodeLength={passcodeLength} />
                <Title type={3}>{t('resetPassword.noSMS')}</Title>
                <p>{t('resetPassword.noSMSExpanation')}</p>
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button
                        type="primary"
                        htmltype="submit"
                        disabled={isLoading || passcode.length !== passcodeLength}
                    >
                        {isLoading
                            ? t('resetPassword.validating')
                            : t('resetPassword.confirmation')}
                    </Button>
                    <Button type="link" className="cancel-button" onClick={onCancel}>
                        {t('resetPassword.cancel')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

MTan.defaultProps = {
    passcodeLength: 9,
    error: undefined,
    isLoading: undefined,
};
