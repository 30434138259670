import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'ui-library';

import './Passcode.css';

const Passcode = ({ passcodeLength, onChange }) => {
    const passcodeInputs = [];
    const passcodeArr = useMemo(() => {
        const passcode = [];

        for (let i = 0; i < passcodeLength; i += 1) {
            passcode[i] = '';
        }

        return passcode;
    }, [passcodeLength]);
    const [passcode, setPasscode] = useState(passcodeArr);

    useEffect(() => {
        if (passcodeInputs[0]) {
            passcodeInputs[0].focus();
        }
    }, []);

    const changeTanHandler = (index) => (e) => {
        const clonedPasscode = [...passcode];
        const { value } = e.currentTarget;
        const valueLength = value.length;

        if (valueLength > 1) {
            const maxValue = Math.min(passcodeLength, index + valueLength);
            const focusIndex = Math.min(maxValue, passcodeLength - 1);

            for (let i = index; i < maxValue; i += 1) {
                clonedPasscode[i] = value[i - index];
            }

            passcodeInputs[focusIndex].focus();
        } else {
            [clonedPasscode[index]] = value;
            if (value !== undefined && value !== '' && index !== passcodeLength - 1) {
                if (passcodeInputs[index + 1]) {
                    passcodeInputs[index + 1].focus();
                }
            }
        }

        onChange(clonedPasscode.join(''));
        setPasscode(clonedPasscode);
    };

    const keyupTanHandler = (index) => (e) => {
        e.target.select();
        const event = e || global.event;
        const { selectionStart, value } = event.currentTarget;
        const charCode = event.keyCode || event.which;

        if (charCode === 8 && value === '' && index !== 0) {
            if (passcodeInputs[index - 1]) passcodeInputs[index - 1].focus();
        }
        if (charCode === 37 && index !== 0 && (value === '' || selectionStart === 0)) {
            if (passcodeInputs[index - 1]) passcodeInputs[index - 1].focus();
        }
        if (
            charCode === 39
            && index !== passcodeLength - 1
            && (value === '' || selectionStart === 1)
        ) {
            if (passcodeInputs[index + 1]) passcodeInputs[index + 1].focus();
        }
    };

    const setRef = (index) => (ref) => {
        passcodeInputs[index] = ref || undefined;
    };

    return (
        <div className="Passcode passcode-inputs">
            {passcode.map(
                (value, index) => (
                    <TextInput
                        key={index.toString()}
                        name={`otp${index}`}
                        id={`otp${index}`}
                        aria-label={`otp${index}`}
                        autoComplete="off"
                        value={passcode[index]}
                        ref={setRef(index)}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        onChange={changeTanHandler(index)}
                        onKeyUp={keyupTanHandler(index)}
                    />
                ),
                this,
            )}
        </div>
    );
};

Passcode.propTypes = {
    passcodeLength: PropTypes.number,
    onChange: PropTypes.func,
};

Passcode.defaultProps = {
    passcodeLength: 6,
    onChange: () => {},
};

export default Passcode;
