import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { adaptBankInfo } from 'adaptors/adaptBankInfo';
import { IBAN } from 'constants/constants';

export const useBankInfo = ({
    contactId,
}) => {
    const [errorBankInfo, setError] = useState(null);
    const [isLoadingBankInfo, setLoading] = useState(true);
    const [bankInfo, setData] = useState({});
    const { i18n: { language } } = useTranslation();

    const getBankInfoData = useCallback(async () => {
        try {
            setLoading(true);
            const contactMemberResponse = await ServiceManager.contactManagement('getContactMembers', [contactId]);

            if (contactMemberResponse.data) {
                const { BankId, BranchId } = contactMemberResponse.data?.[0];

                const bankResponse = await ServiceManager.bank('getBank', [BankId]);

                const bankBranchResponse = await ServiceManager.bank('getBankBranch', [BranchId]);

                const countriesResponse = await ServiceManager.commonService('getCountries', [language]);

                const country = countriesResponse?.data.find(
                    (i) => i.Id === bankBranchResponse?.data?.CountryId,
                );

                try {
                    setData(adaptBankInfo(
                        bankResponse?.data, bankBranchResponse?.data, country,
                    ));
                    setLoading(false);

                    return contactMemberResponse.data;
                } catch (err) {
                    throw new AdapterError(err);
                }
            }

            return null;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, contactId]);

    useEffect(() => {
        getBankInfoData();
    }, [getBankInfoData]);


    // Iban
    const Iban = JSON.parse(sessionStorage.getItem(IBAN)) || '';
    const saveIban = useCallback((data) => {
        sessionStorage.setItem(IBAN, JSON.stringify(data));
    }, []);
    const clearIban = useCallback(() => {
        sessionStorage.removeItem(IBAN);
    }, []);

    return {
        isLoadingBankInfo, errorBankInfo, bankInfo, Iban, saveIban, clearIban,
    };
};
