import React from 'react';

export type View = 0 | 1;

export enum PasswordChangeFlow {
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
}

interface ResetViewParams {
    title: string;
    description: string
    backURLButtonLabel: string;
}

interface SuccessViewParams extends ResetViewParams {
    onBack: () => void;
}

export interface PasswordManipulationsHookState {
    password: string | undefined;
    confirmPassword: string | undefined;
    oldPassword: string | undefined;
    view: View,
    isLoading: boolean,
    isGeneralError: boolean;
    generalErrorMessage: string | undefined;
    errors: Record<string, string>;
}

export interface PasswordManipulationHookReturn extends PasswordManipulationsHookState {
    onChangePasswordInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeConfirmPasswordInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeOldPasswordInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export interface ResetPasswordHookReturn extends PasswordManipulationHookReturn {
    resetView: ResetViewParams;
    successView: SuccessViewParams;
    processType: PasswordChangeFlow;
}
