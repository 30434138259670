import { useCallback, useState } from 'react';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';

export const useDeleteTradingCart = (clientId, portfolioId) => {
    const [isDeleting, setDeleting] = useState(false);
    const [errorDeleting, setError] = useState(null);

    const onDelete = useCallback(() => {
        setDeleting(true);
        setError(null);
        ServiceManager.portfolioManagement('deleteTradingCartOrder', [clientId, portfolioId])
            .then(() => {
                try {
                    setDeleting(false);
                    history.push(`/portfolios/${portfolioId}`);
                } catch (err) {
                    setDeleting(false);
                    setError(err);
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setDeleting(false);
                setError(err);
            });
    }, [clientId, portfolioId]);

    return { onDelete, isDeleting, errorDeleting };
};
