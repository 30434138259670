import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from 'utils';
import { formatValueByType, getColorClass } from 'utils/formatting';
import classNames from 'classnames';
import { useFormatting } from 'locale';
import {
    OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZES, TYPE_TEXT, VALUE_TYPES,
} from './constants';

import './OverviewItem.css';

function OverviewItem({
    title, htmlTitle, value, size, type, colored, currency, hideOnMobile,
}) {
    const { getFormattedNumber } = useFormatting();

    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`,
    );

    const hideOnMobileClassName = useMemo(
        () => classNames('overview-item', {
            'hide-on-mobile': hideOnMobile,
        }),
        [hideOnMobile],
    );

    return (
        <div className={hideOnMobileClassName}>
            {title && (
                <span title={htmlTitle} className="overview-item__title">
                    {title}
                </span>
            )}
            <div>
                <span className={className}>
                    {formatValueByType(value, type, colored, getFormattedNumber)}
                </span>
                {currency !== null && <span className="overview-item__currency">{currency}</span>}
            </div>
        </div>
    );
}

OverviewItem.propTypes = {
    title: PropTypes.string.isRequired,
    htmlTitle: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOf(OVERVIEW_SIZES),
    type: PropTypes.oneOf(VALUE_TYPES),
    colored: PropTypes.bool,
    currency: PropTypes.string,
    hideOnMobile: PropTypes.bool,
};

OverviewItem.defaultProps = {
    htmlTitle: '',
    size: OVERVIEW_SIZE_LAGRE,
    type: TYPE_TEXT,
    colored: false,
    currency: '',
    hideOnMobile: false,
};

export default OverviewItem;
