import { checkNullData } from 'utils';

export const adaptListCurrencies = (data) => {
    if (checkNullData(data)) return [];

    return [
        ...data
            .map(({ Id, Name }) => ({ label: Name, value: Id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    ];
};
