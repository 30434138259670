import { checkNullData } from 'utils';
import { NA, validateStringData } from 'utils/formatting';
import { ORDER_TYPE } from 'constants/portfolio';
import { isLiquidity } from 'utils/portfolio';

export const adaptOrderBook = (data, options = {}) => {
    if (checkNullData(data)
        || checkNullData(data.Results)
        || data?.TotalCount === 0
    ) return false;

    const securities = options?.securities;

    const orders = data?.Results;

    if (orders.length === 0) return false;

    const { productId, portfolioId } = options;
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    const baseUrl = `/portfolios/${portfolioId}/position/${productId}`;
    const groupToValues = orders.reduce((acc, item) => {
        const type = t(ORDER_TYPE[item?.OrderTypeId]);
        const link = isLiquidity(item?.SecurityId)
            ? undefined : `${baseUrl}/${item?.SecurityId}/2/overview`;
        const accumulator = { ...acc };

        const security = securities.find((i) => i.Id === item?.SecurityId);

        accumulator[type] = acc[type] || [];
        accumulator[type].push({
            Id: item?.SecurityId,
            Name: {
                label: validateStringData(security?.Name) || NA,
                link,
                maxLength: 40,
            },
            Date: getFormattedDate(item?.CreateDate),
            Type: type,
            Number: item?.Quantity ? getFormattedNumber(Math.abs(item?.Quantity), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) : NA,
            Price: item?.Price ? getFormattedNumber(item?.Price, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) : NA,
            FxRate: getFormattedNumber(item?.FxRate, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            Total: getFormattedCurrency(Math.abs(item?.AmountSecurityCurrency), {
                currency: security?.Currency?.CurrencyCode || NA,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
        });

        return accumulator;
    }, {});

    return Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        return {
            Name: key,
            children: groupMembersData,
        };
    });
};
