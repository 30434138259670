import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, Paragraph, ContentBox } from 'ui-library';
import { getAdaptedDate } from 'utils/formatting';
import { useFormatting } from 'locale';
import { LinkNews } from './LinkNews';
import news from '../../assets/images/news.svg';
import './NewsItem.css';

const NewsItem = (props) => {
    const {
        id, image, title, date, content, linkBase,
    } = props;
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedTime } = useFormatting();

    const renderDate = useCallback((itemDate) => {
        const adapted = getAdaptedDate(itemDate, t);

        if (!Date.parse(adapted)) return getFormattedTime(itemDate);

        return getFormattedDate(adapted);
    }, [
        getAdaptedDate,
        getFormattedTime,
        getFormattedDate,
        t,
    ]);

    return (
        <ContentBox className="news-item">
            <LinkNews {...props} linkBase={linkBase} id={id}>
                <div className="news-item-image-wrapper">
                    {image
                        ? <img className="news-item-image" src={image} alt="news-img" />
                        : <span className="news-item-image-placeholder" src={news} alt="news-img-placeholder" />}
                </div>
                <div className="news-item-content">
                    <div className="news-item-title">
                        <Title type={4}>{title}</Title>
                        <Paragraph className="news-item-date">{renderDate(date)}</Paragraph>
                    </div>
                    <div>
                        <p>{content}</p>
                    </div>
                </div>
            </LinkNews>
        </ContentBox>
    );
};

NewsItem.propTypes = {
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    linkBase: PropTypes.string,
};

NewsItem.defaultProps = {
    id: null,
    image: null,
    title: null,
    date: null,
    content: null,
    linkBase: null,
};

export default NewsItem;
