import { validateStringData } from 'utils/formatting';
import { LIQUIDITY_TYPE } from 'constants/constants';
import { getParent } from 'utils';
import { parseXml } from 'utils/xmlParser';

export const adaptSecurities = (data, { baseUrl, baseUrlEnd, language }) => (data || [])
    .map((item) => {
        const {
            assetClass, currency, id, isin, name, type,
        } = item;
        // TODO: HWM-3060 - Remove parseXml of the front
        const assetClassName = parseXml(assetClass?.name)?.root?.[language]?.['#text'];
        const assetParentName = parseXml(getParent(assetClass, 'parent').name)?.root?.[language]?.['#text'];
        const typeName = parseXml(type?.name)?.root?.[language]?.['#text'];

        return {
            key: id,
            Name: {
                id,
                value: validateStringData(name),
                label: validateStringData(name),
                link: type?.name === LIQUIDITY_TYPE ? undefined : `${baseUrl}${id}${baseUrlEnd || ''}`,
                maxLength: 33,
                name: 'position',
            },
            id,
            Isin: validateStringData(isin),
            AssetClass: validateStringData(assetParentName),
            SubAssetClass: validateStringData(assetClassName),
            Currency: validateStringData(currency?.isoCode),
            Type: validateStringData(typeName),
        };
    });
