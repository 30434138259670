import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberRounding } from 'utils/formatting';
import PropTypes from 'prop-types';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import useFormatting from 'locale/useFormatting';
import { investorPerformanceSelectValues, YTD } from 'filters/investementPerformanceFilters';
import {
    ContentBox, LineChart, TabPane, Tabs, TabsExtraItem, Title,
} from 'ui-library';
import PageSection from 'components/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import CustomRangeSelect from 'components/CustomRangeSelect';
import { lineChartLabels } from 'constants/constants';
import { useWithInvestmentOverviewPerformance } from '../hooks/useWithInvestmentOverviewPerformance';

const chartValueFormat = {
    performance: {
        symbol: '%',
        precision: 2,
        thousand: ',',
        decimal: '.',
        format: '%v%s',
    },
    assets: {
        symbol: 'K',
        precision: 0,
        thousand: '.',
        decimal: ',',
        format: '%v%s',
    },
};

const filterNamespace = 'dashboard.assetDevelopment.filters';
const getInvestorPerformanceSelectOptions = (t) => investorPerformanceSelectValues.map((item) => ({
    ...item,
    label: t(`${filterNamespace}.${item.value}`),
}));

const AssetDevelopment = ({ clientId, currency, isLoading: isLoadingProp }) => {
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedXAxisFormat } = useFormatting();
    const { isMobileSize } = useDetectedMobileDevice();
    const [currentFilter, setCurrentFilter] = useState(YTD);

    // Hooks
    const { data, isLoading, error } = useWithInvestmentOverviewPerformance(
        currentFilter,
        clientId,
        currency,
        isLoadingProp,
        isMobileSize,
    );

    // Helpers
    const yAxisFormat = useMemo(() => ({
        performance: ({ value }) => `${getFormattedNumber(value)}%`,
        assets: ({ value }) => formatNumberRounding(value, getFormattedNumber),
    }), [getFormattedNumber]);
    const onFilterChange = useCallback((value) => {
        setCurrentFilter(value);
    }, []);
    const filters = useMemo(() => getInvestorPerformanceSelectOptions(t), [t]);

    // Renderers
    const renderTabs = useMemo(() => (
        <Tabs
            defaultActiveKey="1"
            style={{
                overflow: 'visible',
            }}
            className="asset-development"
            tabBarExtraContent={(
                <TabsExtraItem>
                    <CustomRangeSelect
                        width={!isMobileSize ? 160 : undefined}
                        value={currentFilter}
                        options={filters}
                        onChange={onFilterChange}
                    />
                </TabsExtraItem>
            )}
        >
            <TabPane tab={t('dashboard.assetDevelopment.tabs.performance')} key="1">
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.performance[0].data} text={t('dashboard.noData')}>
                        <LineChart
                            data={data.performance}
                            height={252}
                            yAxisFormat={yAxisFormat.performance}
                            valueFormat={chartValueFormat.performance}
                            tooltipFormat={data.performance[0].tooltipFormat}
                            xAxisFormat={getFormattedXAxisFormat}
                            labels={lineChartLabels(t)}
                        />
                    </EmptyContent>
                </Preloader>
            </TabPane>
            <TabPane tab={t('dashboard.assetDevelopment.tabs.assets')} key="2">
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.assets[0].data} text={t('dashboard.noData')}>
                        <LineChart
                            data={data.assets}
                            height={252}
                            yTitle={currency}
                            valueFormat={chartValueFormat.assets}
                            yAxisFormat={yAxisFormat.assets}
                            tooltipFormat={data.assets[0].tooltipFormat}
                            xAxisFormat={getFormattedXAxisFormat}
                            labels={lineChartLabels(t)}
                        />
                    </EmptyContent>
                </Preloader>
            </TabPane>
        </Tabs>
    ), [
        currency,
        isMobileSize,
        currentFilter,
        data,
        error,
        filters,
        getFormattedXAxisFormat,
        isLoading,
        onFilterChange,
        t,
        yAxisFormat,
    ]);

    return (
        <PageSection>
            <ContentBox underline={false}>
                <Title type={2}>{t('dashboard.assetDevelopment.title')}</Title>
                {renderTabs}
            </ContentBox>
        </PageSection>
    );
};

AssetDevelopment.propTypes = {
    currency: PropTypes.string.isRequired,
    clientId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

AssetDevelopment.defaultProps = {};

export default AssetDevelopment;
