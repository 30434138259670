import { checkNullData } from 'utils';
import {
    getTimestamp, formatDateInUTC,
} from 'utils/datetime';

const getValueByKey = (object, key) => {
    const value = object.Content.find((item) => item.Key === key);

    return value !== undefined ? value.Value : null;
};

export const adaptNews = (data) => {
    if (checkNullData(data)) return [];

    return data
        .map((item) => ({
            id: item.Id,
            image: getValueByKey(item, 'image'),
            title: getValueByKey(item, 'title'),
            dateSort: formatDateInUTC(getValueByKey(item, 'date'), 'DD.MM.YYYY HH:mm'),
            date: formatDateInUTC(getValueByKey(item, 'date'), 'DD.MM.YYYY HH:mm'),
            content: getValueByKey(item, 'body'),
            link: getValueByKey(item, 'link'),
        }));
};

export const adaptRssNews = (data) => {
    if (checkNullData(data)) return [];

    return data
        .map((item) => ({
            id: getTimestamp(new Date(item.publishDate)),
            image: item.image,
            title: item.title,
            dateSort: formatDateInUTC(item.publishDate),
            date: formatDateInUTC(item.publishDate),
            content: item.description,
            link: item.link,
        }));
};
