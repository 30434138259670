import React, { useState, useEffect } from 'react';
import { getOr } from 'lodash/fp';
import { $_GET } from 'utils/url';
import history from 'services/history';
import { Loading } from 'components/Loading';
import AuthWrapper from 'components/AuthWrapper';
import LoginForm from '../LoginForm/LoginFormContainer';
import { TwoFASwitcher } from '../2FA/TwoFASwitcher';
import { PASSCODE } from '../loginTypes';
import { Router, LoginByURL } from '../../types';
import './Login.css';


export interface LoginProps {
    loginByURL: (val: LoginByURL) => void
    reset2FA: () => void
    isFistStepDone?: boolean
    isLoginDone: boolean
    factor?: string
    provider?: string
    redirectToDashboard: () => void
    router?: Router
}

export const Login: React.FC<LoginProps> = ({
    loginByURL,
    reset2FA,
    isFistStepDone,
    isLoginDone,
    factor,
    provider,
    redirectToDashboard,
    router,
}) => {
    const urlContactId = $_GET('contactId');
    const urlAccessToken = $_GET('accessToken');
    const [isURLLogin, setIsURLLogin] = useState(urlContactId && urlAccessToken);
    const [isOfflineMode, setOfflineMode] = useState(false);
    const [transactionProps, setTransactionProps] = useState(false);

    // Effects
    useEffect(() => {
        setIsURLLogin(urlContactId && urlAccessToken);
    }, [urlContactId, urlAccessToken]);

    useEffect(() => {
        reset2FA();
        if (isURLLogin) {
            loginByURL({ urlContactId, urlAccessToken });
        }
    }, [isURLLogin, loginByURL, urlContactId, urlAccessToken]);

    const activateOfflineMode = (transaction) => {
        setOfflineMode(true);
        setTransactionProps(transaction);
    };

    const fromPathname = getOr(undefined, 'location.state.from.pathname', router);

    if (isLoginDone) {
        if (fromPathname && fromPathname !== '/login' && fromPathname !== '/') {
            history.push(fromPathname);
        } else {
            redirectToDashboard();
        }

        return null;
    }

    const mergedFactor = isOfflineMode ? PASSCODE : factor;

    if (isURLLogin) return <Loading />;

    return (
        <AuthWrapper>
            {isFistStepDone ? (
                TwoFASwitcher({
                    onSuccess: redirectToDashboard,
                    onOfflineMode: activateOfflineMode,
                    factor: mergedFactor,
                    provider,
                    transactionProps,
                })
            ) : (
                <LoginForm />
            )}
        </AuthWrapper>
    );
};

Login.displayName = 'Login';
