import { useContext } from 'react';
import { ClientProfileContext } from '../ClientProfileProvider';

export default () => {
    const context = useContext(ClientProfileContext);

    if (context === undefined) throw new Error('useClientProfile must be used within a ClientProfileProvider');

    return context;
};
